/* eslint-disable keyword-spacing */
export const getMutedItemsString = (list, keyName) => {
    const mutedArr = list.reduce((acc, item) => {
        const { notifications } = item;
        const keyWorld = item[keyName];
        if (notifications) return [...acc, keyWorld];
        return acc;
    }, []);

    if (mutedArr.length) return `${mutedArr.join(', ')} muted this alert`;
    return false;
};
