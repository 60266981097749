

export const VIEW_TYPES_LIST = {
    DEFAULT: 'default',
    NUMBER: 'number'
}

export const COLOR_TYPES_LIST = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
}

export const SIZES_LIST = {
    SM: "sm",
    MD: "md",
};
