/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { useDispatch } from 'react-redux';
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import Button from '../../../ui-core/button';

import { removeUserByIDOperation } from '../../../../routes/users-and-permissions/users/users.operations';
import { VIEW_TYPES_LIST } from '../../../ui-core/button/button.constants';
import { clearModalState } from '../../modal.actions';

import styles from './styles.scss';

const DeleteUserConfirmation = ({ id = '' }) => {
    const dispatch = useDispatch();
    const [isWorking, setWorking] = useState(false);

    const handleDeleteButton = () => {
        setWorking(true);
        try {
            dispatch(removeUserByIDOperation(id));
        } catch (error) {
            console.log('handleDeleteButton error', error);
        } finally {
            dispatch(clearModalState());
            setWorking(false);
        }
    };

    return (
        <div className={styles['delete-user-confirmation']}>
            <p className={styles['delete-user-confirmation__title']}>
                Are you sure you want to delete this user?
            </p>
            <p className={styles['delete-user-confirmation__description']}>
                This is an irreversible action!
            </p>
            <div className={styles['delete-user-confirmation__buttons']}>
                <div className={styles['delete-user-confirmation__button']}>
                    <Button
                        handleClick={() => dispatch(clearModalState())}
                        label={'No, cancel'}
                    />
                </div>
                <div className={styles['delete-user-confirmation__button']}>
                    <Button
                        handleClick={handleDeleteButton}
                        label={'Yes, delete'}
                        viewType={VIEW_TYPES_LIST.PRIMARY}
                        loading={isWorking}
                        disabled={isWorking}
                    />
                </div>
            </div>
        </div>
    );
};

DeleteUserConfirmation.PropTypes = {
    id: PropTypes.string.isRequired
};

export default DeleteUserConfirmation;
