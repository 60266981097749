import ThemeSwitch from "../theme-switch";
import SvgIcon from "../../../../components/ui-core/svg-icon";

import styles from "./styles.scss";

const AuthHeader = () => {
    return (
        <div className={styles["auth-header"]}>
            <div className={styles["auth-header__back"]}>
                {/* <SvgIcon type={"arrow-back"} />
                <a
                    href="https://lyt.ai/"
                    className={styles["auth-header__back-text"]}
                >
                    Back to website
                </a> */}
            </div>
            <div className={styles["auth-header__theme"]}>
                <ThemeSwitch />
            </div>
        </div>
    );
};

export default AuthHeader;
