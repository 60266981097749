import PropTypes from 'prop-types';
import Badge from '../../badge';
import Info from '../../info';
import SvgIcon from '../../svg-icon';
import { COLOR_TYPES_LIST, VIEW_TYPES_LIST } from '../../badge/badge.constants';
import { VIEW_TYPES } from './type-selection.constants';

import styles from './styles.scss';

const TypeSelection = ({
    title = '',
    options = [],
    onChange = () => {
    },
    active = '',
    viewType = VIEW_TYPES.DEFAULT,
    id = '',
    disabled = false,
    description = '',
}) => {
    const setStylesClasses = (key) => {
        const isActive = active === key;

        return `${
            isActive
                ? styles['type-selection--active']
                : styles['type-selection__control']
        }`;
    };

    return (
        <div
            className={`${styles['type-selection']} ${
                styles[`type-selection--${viewType}`]
            } ${disabled ? styles['type-selection--disabled'] : ''}`}
        >
            {viewType != VIEW_TYPES.INLINE
                && <div className={styles['type-selection__head']}>
                    <p className={styles['type-selection__title']}>{title}</p>
                    {description.length > 0
                        && <div className={styles['type-selection__description']}>
                            <Info
                                message={description}
                                icon={<SvgIcon type={'info-filled'} />}
                            />
                        </div>
                    }
                </div>
            }
            <div className={styles['type-selection__inline-controls']}>
                {viewType === VIEW_TYPES.INLINE
                    && <span className={styles['type-selection__title']}>
                        {title}
                    </span>
                }
                <div className={styles['type-selection__controls']}>
                    {options.map(({ key, label, count }) =>
                        <button
                            key={`${key + label}`}
                            type="button"
                            className={setStylesClasses(key)}
                            onClick={() => onChange(key)}
                        >
                            {label}
                            {count > 0
                                && <div
                                    className={styles['type-selection__badge']}
                                >
                                    <Badge
                                        colorType={COLOR_TYPES_LIST.PRIMARY}
                                        viewType={VIEW_TYPES_LIST.NUMBER}
                                        content={count}
                                        disabled={active !== key}
                                    />
                                </div>
                            }
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

TypeSelection.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    active: PropTypes.string.isRequired,
    viewType: PropTypes.oneOf(Object.values(VIEW_TYPES)),
    title: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    description: PropTypes.string,
};

export default TypeSelection;
