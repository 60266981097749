import { types } from './auth.types';
import jwtDecode from 'jwt-decode';

const initialState = {
    access: false,
    access_token: '',
    token_expiration: 0,
    refresh_token: '',
    otp: '',
    message: '',
    whileTesting: '',
    notification: '',
    loading: false,
    error: '',
    inviter: '',
    account_approved: false,
    logging_in_email: '',
};

const session = (state = initialState, { type, payload }) => {
    switch (type) {
    // login

    case types.SEND_LOGIN_FORM_REQUEST:
        return {
            ...state,
            loading: true,
            error: '',
            logging_in_email: payload && payload.email ? payload.email : '',
        };
    case types.SEND_LOGIN_FORM_SUCCESS:
        return {
            ...state,
            ...payload,
            loading: false,
            error: '',
        };
    case types.SEND_LOGIN_FORM_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // login-otp

    case types.SEND_LOGIN_OTP_FORM_REQUEST:
        return {
            ...state,
            loading: true,
            error: '',
        };
    case types.SEND_LOGIN_OTP_FORM_SUCCESS:
        return {
            ...state,
            ...payload,
            access: true,
            loading: false,
            error: '',
            logging_in_email: '',
        };
    case types.SEND_LOGIN_OTP_FORM_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // registration

    case types.SEND_REGISTRATION_FORM_REQUEST:
        return {
            ...state,
            loading: true,
            error: '',
        };
    case types.SEND_REGISTRATION_FORM_SUCCESS:
        return {
            ...state,
            loading: false,
            inviter: payload.email,
            account_approved: payload.account_approved,
            error: '',
        };
    case types.SEND_REGISTRATION_FORM_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };


        // forgot

    case types.SEND_FORGOT_CONFIRMATION_FORM_REQUEST:
        return {
            ...state,
            loading: true,
            error: '',
        };
    case types.SEND_FORGOT_CONFIRMATION_FORM_SUCCESS:
        return {
            ...state,
            ...payload,
            loading: false,
            error: '',
        };
    case types.SEND_FORGOT_CONFIRMATION_FORM_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

    case types.SEND_FORGOT_CODE_FORM_REQUEST:
        return {
            ...state,
            loading: true,
            error: '',
        };
    case types.SEND_FORGOT_CODE_FORM_SUCCESS:
        return {
            ...state,
            ...payload,
            loading: false,
            error: '',
        };
    case types.SEND_FORGOT_CODE_FORM_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

    case types.SEND_FORGOT_PASSWORD_FORM_REQUEST:
        return {
            ...state,
            loading: true,
            error: '',
        };
    case types.SEND_FORGOT_PASSWORD_FORM_SUCCESS:
        return {
            ...initialState,
            loading: false,
            error: '',
        };
    case types.SEND_FORGOT_PASSWORD_FORM_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // other

    case types.RECEIVE_AUTH0_SUCCESS:
        return {
            ...state,
            access: true,
            loading: false,
            access_token: payload.token,
            token_expiration: jwtDecode(payload.token).exp,
        };

    case types.CLEAR_AUTH_MUTUAL_DATA:
        return {
            ...state,
            loading: false,
            error: '',
        };

    case types.UPDATE_TOKEN:
        return {
            ...state,
            ...payload
        };

    case types.CLEAR_SESSION:
        return initialState;

    default:
        return state;
    }
};


export default session;
