export const types = {
    GET_INTERSECTIONS_LIST_REQUEST:
        "intersections/spm/GET_INTERSECTIONS_LIST_REQUEST",
    GET_INTERSECTIONS_LIST_SUCCESS:
        "intersections/spm/GET_INTERSECTIONS_LIST_SUCCESS",
    GET_INTERSECTIONS_LIST_ERROR: "intersections/spm/GET_INTERSECTIONS_LIST_ERROR",

    GET_INTERSECTION_BY_ID_REQUEST:
        "intersections/spm/GET_INTERSECTION_BY_ID_REQUEST",
    GET_INTERSECTION_BY_ID_SUCCESS:
        "intersections/spm/GET_INTERSECTION_BY_ID_SUCCESS",
    GET_INTERSECTION_BY_ID_ERROR: "intersections/spm/GET_INTERSECTION_BY_ID_ERROR",

    CLEAR_INTERSECTION_DATA: "intersections/spm/CLEAR_INTERSECTION_DATA",

    GET_INTERSECTION_HISTORY_REQUEST:
        "intersections/spm/GET_INTERSECTION_HISTORY_REQUEST",
    GET_INTERSECTION_HISTORY_SUCCESS:
        "intersections/spm/GET_INTERSECTION_HISTORY_SUCCESS",
    GET_INTERSECTION_HISTORY_ERROR:
        "intersections/spm/GET_INTERSECTION_HISTORY_ERROR",

    GET_LIVE_MODE_STATUS: "intersections/spm/GET_LIVE_MODE_STATUS",
    SET_LIVE_MODE_STATUS: "intersections/spm/SET_LIVE_MODE_STATUS",

    GET_DATERANGE_STATUS: "intersections/spm/GET_DAATERANGE_STATUS",
    SET_DATERANGE_STATUS: "intersections/spm/SET_DAATERANGE_STATUS",

    SET_LIVE_DATA: "intersections/spm/SET_LIVE_DATA",

    GET_INFORMATION_BY_DATE_AND_TIME_REQUEST:
        "intersections/spm/GET_INFORMATION_BY_DATE_AND_TIME_REQUEST",
    GET_INFORMATION_BY_DATE_AND_TIME_SUCCESS:
        "intersections/spm/GET_INFORMATION_BY_DATE_AND_TIME_SUCCESS",
    GET_INFORMATION_BY_DATE_AND_TIME_ERROR:
        "intersections/spm/GET_INFORMATION_BY_DATE_AND_TIME_ERROR",
};
