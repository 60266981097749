/* eslint-disable keyword-spacing */
import { types } from './users.types';

const initialState = {
    list: [],
    filteredList: [],
    pagination: {},
    loading: true,
    error: '',
};

const mergedUsers = (state, payload) => payload.data.reduce((acc, incomingUser) => {
    const foundUser = state.list.find(user => user.user_id === incomingUser.id);
    if (!foundUser && incomingUser.account_approved) {
        acc.push({user_id: incomingUser.id, ...incomingUser});
    }
    return acc;
}, state.list);

const users = (state = initialState, { type, payload }) => {
    switch (type) {
    // list
    case types.GET_USERS_LIST_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.GET_USERS_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            list: payload.data.data,
            filteredList: payload.data.data,
            pagination: payload.data.pagination || {}
        };
    case types.GET_USERS_LIST_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };
    case types.MERGE_USERS_LISTS:
        return {
            ...state,
            loading: false,
            list: mergedUsers(state, payload.data),
            filteredList: mergedUsers(state, payload.data),
            pagination: payload.pagination || {}
        };

    case types.GET_ADMIN_LIST_REQUEST:
        return {
            ...state,
            loading: true
        };
    case types.GET_ADMIN_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            list: payload.data,
            filteredList: payload.data,
            pagination: payload.pagination || {}
        };
    case types.GET_ADMIN_LIST_ERROR:
        return {
            ...state,
            loading: false,
            error: payload
        };

        // update user
    case types.UPDATE_USER_REQUEST:
        return {
            ...state,
            loading: true
        };
    case types.UPDATE_USER_SUCCESS:
        return {
            ...state,
            loading: false,
            list: state.list.map(item => {
                if (item.user_id === payload.user_id) {
                    Object.assign(item, payload);
                }
                return item;
            }),
            filteredList: state.filteredList.map(item => {
                if (item.user_id === payload.user_id) {
                    Object.assign(item, payload);
                }
                return item;
            })
        };
    case types.UPDATE_USER_ERROR:
        return {
            ...state,
            loading: false,
            error: payload
        };

        // delete user
    case types.REMOVE_USER_BY_ID_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.REMOVE_USER_BY_ID_SUCCESS:
        return {
            ...state,
            loading: false,
            list: state.list.filter((item) => item.user_id !== payload),
            filteredList: state.filteredList.filter(
                (item) => item.user_id !== payload
            ),
        };
    case types.REMOVE_USER_BY_ID_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // set user role
    case types.SET_USER_ROLE_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.SET_USER_ROLE_SUCCESS:
        return {
            ...state,
            loading: false,
            list: state.list.map((item) => {
                if (item.user_id == payload.user_id) {
                    item.usertype = payload.role;
                }
                return item;
            }),
            filteredList: state.filteredList.map((item) => {
                if (item.user_id == payload.user_id) {
                    item.usertype = payload.role;
                }
                return item;
            }),
        };
    case types.SET_USER_ROLE_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // remove approved city
    case types.REMOVE_USER_APPROVED_CITY_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.REMOVE_USER_APPROVED_CITY_SUCCESS:
        return {
            ...state,
            loading: false,
            list: state.list.map((item) => {
                if (item.user_id == payload.userID) {
                    item.agencies.map((agencyItem) => {
                        if (agencyItem.id == payload.agencyID) {
                            agencyItem.cities = agencyItem.cities.filter(
                                (cityItem) => cityItem.id !== payload.cityID
                            );
                        }
                        return agencyItem;
                    });
                }
                return item;
            }),
            filteredList: state.filteredList.map((item) => {
                if (item.user_id == payload.userID) {
                    item.agencies.map((agencyItem) => {
                        if (agencyItem.id == payload.agencyID) {
                            agencyItem.cities = agencyItem.cities.filter(
                                (cityItem) => cityItem.id !== payload.cityID
                            );
                        }
                        return agencyItem;
                    });
                }
                return item;
            }),
        };
    case types.REMOVE_USER_APPROVED_CITY_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // remove approved agency
    case types.REMOVE_USER_APPROVED_AGENCY_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.REMOVE_USER_APPROVED_AGENCY_SUCCESS:
        return {
            ...state,
            loading: false,
            list: state.list.map((item) => {
                if (item.user_id == payload.userID) {
                    item.agencies = item.agencies.filter(
                        (agencyItem) => agencyItem.id != payload.agencyID
                    );
                    console.log(item);
                }
                return item;
            }),
            filteredList: state.filteredList.map((item) => {
                if (item.user_id == payload.userID) {
                    item.agencies = item.agencies.filter(
                        (agencyItem) => agencyItem.id != payload.agencyID
                    );
                }
                return item;
            }),
        };
    case types.REMOVE_USER_APPROVED_AGENCY_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // set user approved agencies
    case types.SET_USER_APPROVED_AGENCIES_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.SET_USER_APPROVED_AGENCIES_SUCCESS:
        return {
            ...state,
            list: state.list.map((item) => {
                if (item.user_id == payload.user_id) {
                    item = payload;
                }

                return item;
            }),
            filteredList: state.filteredList.map((item) => {
                if (item.user_id == payload.user_id) {
                    item = payload;
                }

                return item;
            }),
        };
    case types.SET_USER_APPROVED_AGENCIES_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // set user approved city
    case types.SET_USER_APPROVED_CITIES_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.SET_USER_APPROVED_CITIES_SUCCESS:
        return {
            ...state,
            list: state.list.map((item) => {
                if (item.user_id == payload.user_id) {
                    item = payload;
                }

                return item;
            }),
            filteredList: state.filteredList.map((item) => {
                if (item.user_id == payload.user_id) {
                    item = payload;
                }

                return item;
            }),
        };
    case types.SET_USER_APPROVED_CITIES_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

    case types.FILTER_USERS_LIST_BY_NAME: {
        return {
            ...state,
            filteredList: state.list.filter((item) => {
                const fullName
                    = `${item.firstname} ${item.lastname}`.toLowerCase();

                return fullName.includes(payload.toLowerCase());
            }),
        };
    }
    default:
        return state;
    }
};

export default users;
