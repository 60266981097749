export const types = {
    SET_MAP_STYLE: 'dashboard/map/SET_MAP_STYLE',

    //City information
    GET_CITY_INFORMATION_BY_ID_REQUEST: 'dashboard/map/GET_CITY_INFORMATION_BY_ID_REQUEST',
    GET_CITY_INFORMATION_BY_ID_SUCCESS: 'dashboard/map/GET_CITY_INFORMATION_BY_ID_SUCCESS',
    GET_CITY_INFORMATION_BY_ID_ERROR: 'dashboard/map/GET_CITY_INFORMATION_BY_ID_ERROR',

    // Routes
    GET_MAP_ROUTES_REQUEST: 'dashboard/map/GET_MAP_ROUTES_REQUEST',
    GET_MAP_ROUTES_SUCCESS: 'dashboard/map/GET_MAP_ROUTES_SUCCESS',
    GET_MAP_ROUTES_ERROR: 'dashboard/map/GET_MAP_ROUTES_ERROR',

    GET_MAP_ROUTE_BY_ID_REQUEST: 'dashboard/map/GET_MAP_ROUTE_BY_ID_REQUEST',
    GET_MAP_ROUTE_BY_ID_SUCCESS: 'dashboard/map/GET_MAP_ROUTE_BY_ID_SUCCESS',
    GET_MAP_ROUTE_BY_ID_ERROR: 'dashboard/map/GET_MAP_ROUTE_BY_ID_ERROR',

    GET_MAP_ROUTE_DETAILS_BY_ID_REQUEST: 'dashboard/map/GET_MAP_ROUTE_DETAILS_BY_ID_REQUEST',
    GET_MAP_ROUTE_DETAILS_BY_ID_SUCCESS: 'dashboard/map/GET_MAP_ROUTE__DETAILS_BY_ID_SUCCESS',
    GET_MAP_ROUTE_DETAILS_BY_ID_ERROR: 'dashboard/map/GET_MAP_ROUTE_DETAILS_BY_ID_ERROR',

    SET_MAP_ROUTE_ID: 'dashboard/map/SET_MAP_ROUTE_ID',
    SET_ROUTE_LINE_COLOR: 'dashboard/map/SET_ROUTE_LINE_COLOR',
    RESET_MAP_ROUTE: 'dashboard/map/RESET_MAP_ROUTE',

    // Stops
    GET_MAP_STOPS_REQUEST: 'dashboard/map/GET_MAP_STOPS_REQUEST',
    GET_MAP_STOPS_SUCCESS: 'dashboard/map/GET_MAP_STOPS_SUCCESS',
    GET_MAP_STOPS_ERROR: 'dashboard/map/GET_MAP_STOPS_ERROR',

    // Insight
    GET_MAP_INSIGHT_REQUEST: 'dashboard/map/GET_MAP_INSIGHT_REQUEST',
    GET_MAP_INSIGHT_SUCCESS: 'dashboard/map/GET_MAP_INSIGHT_SUCCESS',
    GET_MAP_INSIGHT_ERROR: 'dashboard/map/GET_MAP_INSIGHT_ERROR',

    // fire-station
    GET_MAP_FIRE_STATION_REQUEST: 'dashboard/map/GET_MAP_FIRE_STATION_REQUEST',
    GET_MAP_FIRE_STATION_SUCCESS: 'dashboard/map/GET_MAP_FIRE_STATION_SUCCESS',
    GET_MAP_FIRE_STATION_ERROR: 'dashboard/map/GET_MAP_FIRE_STATION_ERROR',

    RESET_MAP_INFORMATION: 'dashboard/map/RESET_MAP_INFORMATION',

    GET_MAP_STOPS_BY_ID_REQUEST: 'dashboard/map/GET_MAP_STOPS_BY_ID_REQUEST',
    GET_MAP_STOPS_BY_ID_SUCCESS: 'dashboard/map/GET_MAP_STOPS_BY_ID_SUCCESS',
    GET_MAP_STOPS_BY_ID_ERROR: 'dashboard/map/GET_MAP_STOPS_BY_ID_ERROR',

    //traffic-lights

    GET_MAP_TRAFFIC_LIGHTS_REQUEST: 'dashboard/map/GET_MAP_TRAFFIC_LIGHTS_REQUEST',
    GET_MAP_TRAFFIC_LIGHTS_SUCCESS: 'dashboard/map/GET_MAP_TRAFFIC_LIGHTS_SUCCESS',
    GET_MAP_TRAFFIC_LIGHTS_ERROR: 'dashboard/map/GET_MAP_TRAFFIC_LIGHTS_ERROR',

    //popup

    SET_MAP_POPUP_INFORMATION: 'dashboard/map/SET_MAP_POPUP_INFORMATION',

    // visibility

    SET_MAP_LAYER_VISIBILITY_STATUS:
        'dashboard/map/SET_MAP_LAYER_VISIBILITY_STATUS',
};
