/* eslint-disable keyword-spacing */
import { useEffect, useRef, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import SvgIcon from '../../svg-icon';
import RoutesItem from './components/routes-item';
import WidgetsManagementItem from './components/widgets-management-item';
import ShareAlertsItem from './components/share-alerts-item';
import IntersectionSpmItem from './components/intersection-spm-item';

import { SVG_CODES } from '../../svg-icon/svg-icon.constants';
import { DATA_LIST_ITEM_TYPES } from './list-with-search.constants';

import styles from './styles.scss';

const ListWithSearch = ({
    handleClick = () => { },
    dataList = [],
    placeholder = 'Search for a metric',
    itemType = DATA_LIST_ITEM_TYPES.ROUTES,
    isInitialOpenDropdown = true,
    value = '',
}) => {
    const [filteredDataList, setFilteredDataList] = useState(dataList);
    const [searchString, setSearchString] = useState('');
    const [isOpen, setOpenStatus] = useState(isInitialOpenDropdown);
    const inputRef = useRef(null);
    const wrapperRef = useRef(null);

    useEffect(() => {
        setFilteredDataList(dataList);
        setSearchString(value);

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [dataList]);

    useEffect(() => {
        if (value) {
            setSearchString(value);
            // filterDataListBySearchString(value);
        }
    }, [value]);

    const handleOutsideClick = ({ target }) => {
        if (wrapperRef.current && !wrapperRef.current.contains(target)) {
            setOpenStatus(false);
        }
    };

    const handleItemClick = (data) => {
        if (itemType === DATA_LIST_ITEM_TYPES.SHARE_ALERTS) {
            setOpenStatus(false);
            setSearchString(data.description);
            setFilteredDataList(dataList);
        }

        if (itemType === DATA_LIST_ITEM_TYPES.INTERSECTION_SPM) {
            setOpenStatus(false);
            setSearchString(''); // reset input box to empty now that a choice was made
            setFilteredDataList(dataList);
        }

        if (itemType === DATA_LIST_ITEM_TYPES.ROUTES) {
            setOpenStatus(false);
            setSearchString(data.route_short_name);
            handleClick(data);
        }

        handleClick(data);
    };

    const handleFocus = () => setOpenStatus(true);

    const generateListLayout = () => {
        switch (itemType) {
        case DATA_LIST_ITEM_TYPES.ROUTES:
            return (
                <div
                    className={
                        styles[
                            `list-with-search__list-type--${itemType}-container`
                        ]
                    }
                >
                    {filteredDataList.length
                        ? filteredDataList.map((item) =>
                            <RoutesItem
                                key={item.id}
                                listItem={item}
                                handleClick={handleItemClick}
                            />
                        )
                        : <p
                            className={
                                styles['list-with-search__nothing-found']
                            }
                        >
                            {!dataList.length && !searchString
                                ? 'No additional items to select'
                                : 'Nothing found. Try to modify your request!'}
                        </p>
                    }
                </div>
            );
        case DATA_LIST_ITEM_TYPES.WIDGETS:
            return (
                <div
                    className={
                        styles[
                            `list-with-search__list-type--${itemType}-container`
                        ]
                    }
                >
                    {filteredDataList.length
                        ? filteredDataList.map((item) =>
                            <WidgetsManagementItem
                                key={item.id}
                                listItem={item}
                                handleClick={handleItemClick}
                            />
                        )
                        : <p
                            className={
                                styles['list-with-search__nothing-found']
                            }
                        >
                            {!dataList.length && !searchString
                                ? 'You already added all widgets'
                                : 'Nothing found. Try to modify your request!'}
                        </p>
                    }
                </div>
            );
        case DATA_LIST_ITEM_TYPES.SHARE_ALERTS:
            return (
                <div
                    className={
                        styles[
                            `list-with-search__list-type--${itemType}-container`
                        ]
                    }
                >
                    {filteredDataList.length
                        ? filteredDataList.map((item) =>
                            <ShareAlertsItem
                                key={item.id}
                                listItem={item}
                                handleClick={handleItemClick}
                            />
                        )
                        : <p
                            className={
                                styles['list-with-search__nothing-found']
                            }
                        >
                            {!dataList.length && !searchString
                                ? 'You already added all items'
                                : 'Nothing found. Try to modify your request!'}
                        </p>
                    }
                </div>
            );


        case DATA_LIST_ITEM_TYPES.INTERSECTION_SPM:
            return (
                <div
                    className={
                        styles[
                            `list-with-search__list-type--${itemType}-container`
                        ]
                    }
                >
                    {filteredDataList.length
                        ? filteredDataList.map((item) =>
                            <IntersectionSpmItem
                                key={item.id}
                                listItem={item}
                                handleClick={handleItemClick}
                            />
                        )
                        : <p
                            className={
                                styles['list-with-search__nothing-found']
                            }
                        >
                            {!dataList.length && !searchString
                                ? 'You already added all items'
                                : 'Nothing found. Try to modify your request!'}
                        </p>
                    }
                </div>
            );
        default:
            break;
        }
    };

    const filterDataListBySearchString = (stringToSearch) => {
        setSearchString(stringToSearch);
        let filteredData;


        if (itemType === DATA_LIST_ITEM_TYPES.SHARE_ALERTS) {
            filteredData = dataList.filter((item) => item.value.toLowerCase().includes(stringToSearch.toLowerCase()));
        } else {
            filteredData = dataList.filter((item) => {
                const combinedItemInformation = item.description + item.id;
                return combinedItemInformation
                    .toLowerCase()
                    .includes(stringToSearch.toLowerCase());
            });
        }

        setFilteredDataList(filteredData);
    };

    return (
        <div ref={wrapperRef} className={styles['list-with-search-container']}>
            <input
                onFocus={handleFocus}
                type="text"
                ref={inputRef}
                value={searchString}
                onChange={({ target }) =>
                    filterDataListBySearchString(target.value)
                }
                placeholder={placeholder}
                className={`${styles['list-with-search']} ${isOpen ? styles['list-with-search--active'] : ''
                }`}
            />
            <span className={styles['list-with-search__icon']}>
                <SvgIcon type={SVG_CODES.SEARCH} />
            </span>
            {isOpen && <>{generateListLayout()}</>}
        </div>
    );
};

ListWithSearch.propTypes = {
    handleClick: PropTypes.func.isRequired,
    dataList: PropTypes.array.isRequired,
    itemType: PropTypes.string.isRequired,
    viewType: PropTypes.string,
    placeholder: PropTypes.string,
    isInitialOpenDropdown: PropTypes.bool,
    value: PropTypes.string,
};

export default ListWithSearch;
