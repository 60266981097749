import * as yup from "yup";


export const schema = yup.object({
    range: yup.object({
        start_date: yup.string().required('To pause alert, enter the pause time range'),
        end_date: yup.string().required('To pause alert, enter the pause time range'),
    }),
}).required();

