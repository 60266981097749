import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

const SkeletonCriticalAlerts = ({ colors, ...props }) => (
    <ContentLoader
        width={'100%'}
        height={78.21}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <rect x="80" y="20" rx="6" ry="6" width="60%" height="13" />
        <rect x="80" y="50" rx="6" ry="6" width="20%" height="8" />
        <circle cx="40" cy="40" r="24" />
    </ContentLoader>
);

SkeletonCriticalAlerts.propTypes = {
    colors: PropTypes.object,
};

export default SkeletonCriticalAlerts;
