import ContentLoader from "react-content-loader";

const SkeletonCityTrafficPerformance = ({ colors, ...props }) => (
    <tr>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={56}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="15" y="8" rx="16" ry="16" width="32px" height="32px" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={56}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="15" y="15" rx="6" ry="6" width="40px" height="20" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={56}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="15" y="15" rx="6" ry="6" width="40px" height="20" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={56}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="15" y="15" rx="6" ry="6" width="40px" height="20" />
            </ContentLoader>
        </th>

        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={56}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="60%" y="15" rx="6" ry="6" width="40px" height="20" />
            </ContentLoader>
        </th>
    </tr>
);

export default SkeletonCityTrafficPerformance;
