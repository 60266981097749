import { route } from 'preact-router';

import { bookList } from '../../constants/bookList';
import SvgIcon from '../ui-core/svg-icon';
import styles from './styles.scss';

const InformationPanel = () =>
    <div className={styles['information-panel']}>
        <button style={{textAlign: 'left', backgroundColor: 'transparent'}}
            onClick={() => route(bookList.dashboard.entry_point)}
        >
            <SvgIcon type="logo-secondary" />
        </button>
        <p className={styles['information-panel__text']}>
                We’re building better communities through less traffic
        </p>
    </div>
    ;

export default InformationPanel;
