import { types } from './general.types';

// Critical Alerts

export const getCriticalAlertsRequest = () => ({
    type: types.GET_CRITICAL_ALERTS_REQUEST,
});

export const getCriticalAlertsSuccess = (data) => ({
    type: types.GET_CRITICAL_ALERTS_SUCCESS,
    payload: data,
});

export const getCriticalAlertsError = (error) => ({
    type: types.GET_CRITICAL_ALERTS_ERROR,
    payload: error,
});

// Traffic Lights

export const getTrafficLightsRequest = () => ({
    type: types.GET_TRAFFIC_LIGHTS_REQUEST,
});

export const getTrafficLightsSuccess = (data) => ({
    type: types.GET_TRAFFIC_LIGHTS_SUCCESS,
    payload: data,
});

export const getTrafficLightsError = (error) => ({
    type: types.GET_TRAFFIC_LIGHTS_ERROR,
    payload: error,
});

// Overall performance

export const getOverallPerformanceRequest = () => ({
    type: types.GET_OVERALL_PERFORMANCE_REQUEST,
});

export const getOverallPerformanceSuccess = (data) => ({
    type: types.GET_OVERALL_PERFORMANCE_SUCCESS,
    payload: data,
});

export const getOverallPerformanceError = (error) => ({
    type: types.GET_OVERALL_PERFORMANCE_ERROR,
    payload: error,
});

export const setOverallPerformanceFilterParameter = (data) => ({
    type: types.SET_OVERALL_PERFORMANCE_FILTER_PARAMETER,
    payload: data,
});

// Recent alerts -> Traffic Lights

export const getRecentAlertsTrafficLightsRequest = () => ({
    type: types.GET_RECENT_ALERTS_TRAFFIC_LIGHTS_REQUEST,
});

export const getRecentAlertsTrafficLightsSuccess = (data) => ({
    type: types.GET_RECENT_ALERTS_TRAFFIC_LIGHTS_SUCCESS,
    payload: data,
});

export const getRecentAlertsTrafficLightsError = (error) => ({
    type: types.GET_RECENT_ALERTS_TRAFFIC_LIGHTS_ERROR,
    payload: error,
});

// Recent alerts -> Vechile Information

export const getRecentAlertsVechileInformationRequest = () => ({
    type: types.GET_RECENT_ALERTS_VECHILE_INFORMATION_REQUEST,
});

export const getRecentAlertsVechileInformationSuccess = (data) => ({
    type: types.GET_RECENT_ALERTS_VECHILE_INFORMATION_SUCCESS,
    payload: data,
});

export const getRecentAlertsVechileInformationError = (error) => ({
    type: types.GET_RECENT_ALERTS_VECHILE_INFORMATION_ERROR,
    payload: error,
});

// Recent alerts -> Priority Requests

export const getRecentAlertsPriorityRequestsRequest = () => ({
    type: types.GET_RECENT_ALERTS_PRIORITY_REQUESTS_REQUEST,
});

export const getRecentAlertsPriorityRequestsSuccess = (data) => ({
    type: types.GET_RECENT_ALERTS_PRIORITY_REQUESTS_SUCCESS,
    payload: data,
});

export const getRecentAlertsPriorityRequestsError = (error) => ({
    type: types.GET_RECENT_ALERTS_PRIORITY_REQUESTS_ERROR,
    payload: error,
});

// Systemwide average red light delay chart

export const getSystemWideRedLightWaitChartRequest = () => ({
    type: types.GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_REQUEST,
});

export const getSystemWideRedLightWaitChartSuccess = (data) => ({
    type: types.GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_SUCCESS,
    payload: data,
});

export const getSystemWideRedLightWaitChartError = (error) => ({
    type: types.GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_ERROR,
    payload: error,
});

export const getSystemWideRedLightWaitChartViewType = (type = '') => ({
    type: types.SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_VIEW_TYPE,
    payload: type,
});

export const getSystemWideRedLightWaitChartFilterParameter = (parameter = '') => ({
    type: types.SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_FILTER_PARAMETER,
    payload: parameter,
});

// Systemwide average green light success rate chart

export const getSystemWideGreenLightSuccessChartRequest = () => ({
    type: types.GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_REQUEST,
});

export const getSystemWideGreenLightSuccessChartSuccess = (data) => ({
    type: types.GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_SUCCESS,
    payload: data,
});

export const getSystemWideGreenLightSuccessChartError = (error) => ({
    type: types.GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_ERROR,
    payload: error,
});

export const setSystemWideGreenLightSuccessChartViewType = (type = '') => ({
    type: types.SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_VIEW_TYPE,
    payload: type,
});

export const setSystemWideGreenLightSuccessChartFilterParameter = (parameter = '') => ({
    type: types.SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_FILTER_PARAMETER,
    payload: parameter,
});

// Systemwide chart

export const getSystemWideChartRequest = () => ({
    type: types.GET_SYSTEM_WIDE_CHART_REQUEST,
});

export const getSystemWideChartSuccess = (data = []) => ({
    type: types.GET_SYSTEM_WIDE_CHART_SUCCESS,
    payload: data,
});

export const getSystemWideChartError = (error = '') => ({
    type: types.GET_SYSTEM_WIDE_CHART_ERROR,
    payload: error,
});

export const setSystemWideChartViewType = (type = '') => ({
    type: types.SET_SYSTEM_WIDE_CHART_VIEW_TYPE,
    payload: type,
});

export const setSystemWideChartFilterParameter = (parameter = '') => ({
    type: types.SET_SYSTEM_WIDE_CHART_FILTER_PARAMETER,
    payload: parameter,
});

// Total TSP calls chart

export const getTotalTSPCallsChartRequest = () => ({
    type: types.GET_TOTAL_TSP_CALLS_CHART_REQUEST,
});

export const getTotalTSPCallsChartSuccess = (data = []) => ({
    type: types.GET_TOTAL_TSP_CALLS_CHART_SUCCESS,
    payload: data,
});

export const getTotalTSPCallsChartError = (error = '') => ({
    type: types.GET_TOTAL_TSP_CALLS_CHART_ERROR,
    payload: error,
});

export const setTotalTSPCallsChartViewType = (type = '') => ({
    type: types.SET_TOTAL_TSP_CALLS_CHART_VIEW_TYPE,
    payload: type,
});

export const setTotalTSPCallsChartFilterParameter = (parameter = '') => ({
    type: types.SET_TOTAL_TSP_CALLS_CHART_FILTER_PARAMETER,
    payload: parameter,
});

// Average Speed By Route Chart

export const getAverageSpeedByRouteChartRequest = () => ({
    type: types.GET_AVERAGE_SPEED_BY_ROUTE_CHART_REQUEST,
});

export const getAverageSpeedByRouteChartSuccess = (data = []) => ({
    type: types.GET_AVERAGE_SPEED_BY_ROUTE_CHART_SUCCESS,
    payload: data,
});

export const getAverageSpeedByRouteChartError = (error = '') => ({
    type: types.GET_AVERAGE_SPEED_BY_ROUTE_CHART_ERROR,
    payload: error,
});

export const setAverageSpeedByRouteChartFilterParameter = (parameter = '') => ({
    type: types.SET_AVERAGE_SPEED_BY_ROUTE_CHART_FILTER_PARAMETER,
    payload: parameter,
});

// Average Red Light Delay Chart

export const getAverageRedLightWaitTimeChartRequest = () => ({
    type: types.GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_REQUEST,
});

export const getAverageRedLightWaitTimeChartSuccess = (data = []) => ({
    type: types.GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_SUCCESS,
    payload: data,
});

export const getAverageRedLightWaitTimeChartError = (error = '') => ({
    type: types.GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_ERROR,
    payload: error,
});

export const setAverageRedLightWaitTimeChartFilterParameter = (
    parameter = ''
) => ({
    type: types.SET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_PARAMETER,
    payload: parameter,
});

// Travel Time By Day / Route

export const setTravelTimeByDayFilterParameter = (parameter = '') => ({
    type: types.SET_TRAVEL_TIME_BY_DAY_PARAMETER,
    payload: parameter,
});

export const getTravelTimeByDayRequest = () => ({
    type: types.GET_TRAVEL_TIME_BY_DAY_REQUEST,
});

export const getTravelTimeByDaySuccess = (data = []) => ({
    type: types.GET_TRAVEL_TIME_BY_DAY_SUCCESS,
    payload: data,
});

export const getTravelTimeByDayError = (error = '') => ({
    type: types.GET_TRAVEL_TIME_BY_DAY_ERROR,
    payload: error,
});

// AVL Health

export const getAVLHealthRequest = () => ({
    type: types.GET_AVL_HEALTH_REQUEST,
});

export const getAVLHealthSuccess = (data = []) => ({
    type: types.GET_AVL_HEALTH_SUCCESS,
    payload: data,
});

export const getAVLHealthError = (error = '') => ({
    type: types.GET_AVL_HEALTH_ERROR,
    payload: error,
});

export const setAVLHealthRange = (parameter = '') => ({
    type: types.SET_AVL_HEALTH_RANGE,
    payload: parameter,
});

// Signal Connectivity

export const getSignalConnectivityRequest = () => ({
    type: types.GET_SIGNAL_CONNECTIVITY_REQUEST,
});

export const getSignalConnectivitySuccess = (data = []) => ({
    type: types.GET_SIGNAL_CONNECTIVITY_SUCCESS,
    payload: data,
});

export const getSignalConnectivityError = (error = '') => ({
    type: types.GET_SIGNAL_CONNECTIVITY_ERROR,
    payload: error,
});

export const setSignalConnectivityRange = (parameter = '') => ({
    type: types.SET_SIGNAL_CONNECTIVITY_RANGE,
    payload: parameter,
});

// TSP Request Messages

export const getTSPRequestMessagesRequest = () => ({
    type: types.GET_TSP_REQUEST_MESSAGES_REQUEST,
});

export const getTSPRequestMessagesSuccess = (data = []) => ({
    type: types.GET_TSP_REQUEST_MESSAGES_SUCCESS,
    payload: data,
});

export const getTSPRequestMessagesError = (error = '') => ({
    type: types.GET_TSP_REQUEST_MESSAGES_ERROR,
    payload: error,
});

export const setTSPRequestMessagesRange = (parameter = '') => ({
    type: types.SET_TSP_REQUEST_MESSAGES_RANGE,
    payload: parameter,
});

// Signal Network Performance

export const getSignalNetworkPerformanceRequest = () => ({
    type: types.GET_SIGNAL_NETWORK_PERFORMANCE_REQUEST,
});

export const getSignalNetworkPerformanceSuccess = (data = []) => ({
    type: types.GET_SIGNAL_NETWORK_PERFORMANCE_SUCCESS,
    payload: data,
});

export const getSignalNetworkPerformanceError = (error = '') => ({
    type: types.GET_SIGNAL_NETWORK_PERFORMANCE_ERROR,
    payload: error,
});

export const setSignalNetworkPerformanceRange = (parameter = '') => ({
    type: types.SET_SIGNAL_NETWORK_PERFORMANCE_RANGE,
    payload: parameter,
});

