import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import Button from "../../../ui-core/button";
import DateRange from "../../../ui-core/inputs/date-range/date-range";
import Select from "../../../ui-core/inputs/select";

import { timesList, intersectionsList, typesList } from "../mute-alert/mute-alert-config";
import { OPTION_TYPES } from "../../../ui-core/inputs/select/select.constants";
import { VIEW_TYPES_LIST } from "../../../ui-core/button/button.constants";
import { hookFormGetObjectErrorMessage } from "../../../../utils/hook-forms";
import { schema } from "./mute-alert.schema";
import { clearModalState } from "../../modal.actions";

import styles from "./styles.scss";


const MuteAlert = () => {
    const { control, handleSubmit, formState: { errors }, getValues } = useForm({
        defaultValues: {
            range: {
                start_date: "",
                end_date: "",
            },
        },
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();

    const onSubmit = data => console.log(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles["mute-alert"]}>
            <p className={styles["mute-alert__title"]}>Mute alert</p>
            <div className={styles["mute-alert__select"]}>
                <Select
                    optionsList={typesList}
                    onOptionSelected={(selectedOptionValue) =>
                        console.log(selectedOptionValue)
                    }
                    type={OPTION_TYPES.OVERALL_PERFORMANCE}
                    defaultValue={typesList[0]}
                    label={"Alert type"}
                />
            </div>
            <div className={styles["mute-alert__select"]}>
                <Select
                    optionsList={intersectionsList}
                    onOptionSelected={(selectedOptionValue) =>
                        console.log(selectedOptionValue)
                    }
                    type={OPTION_TYPES.OVERALL_PERFORMANCE}
                    defaultValue={intersectionsList[0]}
                    label={"Intersection"}

                />
            </div>

            <div className={styles["mute-alert__range"]}>
                <Controller
                    name="range"
                    control={control}
                    render={({ field }) => <DateRange
                        label={"Mute time"}
                        value={field.value}
                        onChange={field.onChange}
                        startDate={field.value.start_date}
                        endDate={field.value.end_date}
                        timesList={timesList}
                        error={hookFormGetObjectErrorMessage("range", errors)}
                    />
                    }
                />
            </div>
            <div className={styles["mute-alert__buttons"]}>
                <div className={styles["mute-alert__button"]}>
                    <Button handleClick={() => dispatch(clearModalState())} label={"Cancel"} />
                </div>
                <div className={styles["mute-alert__button"]}>
                    <Button type={"submit"} label={"Pause alert"} viewType={VIEW_TYPES_LIST.PRIMARY} />
                </div>
            </div>

        </form>
    );
};


export default MuteAlert;
