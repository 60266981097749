import { Fragment } from "preact";
import { useState } from "preact/hooks";
import PropTypes from "prop-types";

import SvgIcon from "../../svg-icon";

import styles from "./styles.scss";

const PasswordField = ({ name, placeholder, value, onChange }) => {
    const [passwordShown, setPasswordShown] = useState(false);

    const handleVisibleStatus = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <Fragment>
            <input
                className={`${styles["field__input"]} ${styles["field__input--password"]}`}
                type={passwordShown ? "text" : "password"}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            <div
                className={styles["field__icon--eye"]}
                onClick={handleVisibleStatus}
            >
                {passwordShown ? (
                    <SvgIcon type="eye-opened" />
                ) : (
                    <SvgIcon type="eye-closed" />
                )}
            </div>
        </Fragment>
    );
};

PasswordField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    validation: PropTypes.object,
    onChange: PropTypes.func,
};

export default PasswordField;
