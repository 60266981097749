export const types = {
    // Critical Alerts

    GET_CRITICAL_ALERTS_REQUEST: 'dashboard/GET_CRITICAL_ALERTS_REQUEST',
    GET_CRITICAL_ALERTS_SUCCESS: 'dashboard/GET_CRITICAL_ALERTS_SUCCESS',
    GET_CRITICAL_ALERTS_ERROR: 'dashboard/GET_CRITICAL_ALERTS_ERROR',

    // Traffic Lights

    GET_TRAFFIC_LIGHTS_REQUEST: 'dashboard/GET_TRAFFIC_LIGHTS_REQUEST',
    GET_TRAFFIC_LIGHTS_SUCCESS: 'dashboard/GET_TRAFFIC_LIGHTS_SUCCESS',
    GET_TRAFFIC_LIGHTS_ERROR: 'dashboard/GET_TRAFFIC_LIGHTS_ERROR',

    // Overall performance

    GET_OVERALL_PERFORMANCE_REQUEST:
        'dashboard/GET_OVERALL_PERFORMANCE_REQUEST',
    GET_OVERALL_PERFORMANCE_SUCCESS:
        'dashboard/GET_OVERALL_PERFORMANCE_SUCCESS',
    GET_OVERALL_PERFORMANCE_ERROR: 'dashboard/GET_OVERALL_PERFORMANCE_ERROR',
    SET_OVERALL_PERFORMANCE_FILTER_PARAMETER:
        'dashboard/SET_OVERALL_PERFORMANCE_FILTER_PARAMETER',
    // Recent alerts -> Traffic Lights

    GET_RECENT_ALERTS_TRAFFIC_LIGHTS_REQUEST:
        'dashboard/GET_RECENT_ALERTS_TRAFFIC_LIGHTS_REQUEST',
    GET_RECENT_ALERTS_TRAFFIC_LIGHTS_SUCCESS:
        'dashboard/GET_RECENT_ALERTS_TRAFFIC_LIGHTS_SUCCESS',
    GET_RECENT_ALERTS_TRAFFIC_LIGHTS_ERROR:
        'dashboard/GET_RECENT_ALERTS_TRAFFIC_LIGHTS_ERROR',

    // Recent alerts -> Vechile Information

    GET_RECENT_ALERTS_VECHILE_INFORMATION_REQUEST:
        'dashboard/GET_RECENT_ALERTS_VECHILE_INFORMATION_REQUEST',
    GET_RECENT_ALERTS_VECHILE_INFORMATION_SUCCESS:
        'dashboard/GET_RECENT_ALERTS_VECHILE_INFORMATION_SUCCESS',
    GET_RECENT_ALERTS_VECHILE_INFORMATION_ERROR:
        'dashboard/GET_RECENT_ALERTS_VECHILE_INFORMATION_ERROR',

    // Recent alerts -> Priority Requests

    GET_RECENT_ALERTS_PRIORITY_REQUESTS_REQUEST:
        'dashboard/GET_RECENT_ALERTS_PRIORITY_REQUESTS_REQUEST',
    GET_RECENT_ALERTS_PRIORITY_REQUESTS_SUCCESS:
        'dashboard/GET_RECENT_ALERTS_PRIORITY_REQUESTS_SUCCESS',
    GET_RECENT_ALERTS_PRIORITY_REQUESTS_ERROR:
        'dashboard/GET_RECENT_ALERTS_PRIORITY_REQUESTS_ERROR',

    // Systemwide average red light delay chart

    GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_REQUEST: 'dashboard/GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_REQUEST',
    GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_SUCCESS: 'dashboard/GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_SUCCESS',
    GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_ERROR: 'dashboard/GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_ERROR',
    SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_VIEW_TYPE:
        'dashboard/SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_VIEW_TYPE',
    SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_FILTER_PARAMETER:
        'dashboard/SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_FILTER_PARAMETER',

    // Systemwide average green light success rate chart

    GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_REQUEST: 'dashboard/GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_REQUEST',
    GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_SUCCESS: 'dashboard/GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_SUCCESS',
    GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_ERROR: 'dashboard/GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_ERROR',
    SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_VIEW_TYPE:
        'dashboard/SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_VIEW_TYPE',
    SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_FILTER_PARAMETER:
        'dashboard/SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_FILTER_PARAMETER',

    // Systemwide chart

    GET_SYSTEM_WIDE_CHART_REQUEST: 'dashboard/GET_SYSTEM_WIDE_CHART_REQUEST',
    GET_SYSTEM_WIDE_CHART_SUCCESS: 'dashboard/GET_SYSTEM_WIDE_CHART_SUCCESS',
    GET_SYSTEM_WIDE_CHART_ERROR: 'dashboard/GET_SYSTEM_WIDE_CHART_ERROR',
    SET_SYSTEM_WIDE_CHART_VIEW_TYPE:
        'dashboard/SET_SYSTEM_WIDE_CHART_VIEW_TYPE',
    SET_SYSTEM_WIDE_CHART_FILTER_PARAMETER:
        'dashboard/SET_SYSTEM_WIDE_CHART_FILTER_PARAMETER',

    // Total TSP calls chart

    GET_TOTAL_TSP_CALLS_CHART_REQUEST:
        'dashboard/GET_TOTAL_TSP_CALLS_CHART_REQUEST',
    GET_TOTAL_TSP_CALLS_CHART_SUCCESS:
        'dashboard/GET_TOTAL_TSP_CALLS_CHART_SUCCESS',
    GET_TOTAL_TSP_CALLS_CHART_ERROR:
        'dashboard/GET_TOTAL_TSP_CALLS_CHART_ERROR',
    SET_TOTAL_TSP_CALLS_CHART_VIEW_TYPE:
        'dashboard/SET_TOTAL_TSP_CALLS_CHART_VIEW_TYPE',
    SET_TOTAL_TSP_CALLS_CHART_FILTER_PARAMETER:
        'dashboard/SET_TOTAL_TSP_CALLS_CHART_FILTER_PARAMETER',

    // Average Speed By Route Chart

    GET_AVERAGE_SPEED_BY_ROUTE_CHART_REQUEST:
        'dashboard/GET_AVERAGE_SPEED_BY_ROUTE_CHART_REQUEST',
    GET_AVERAGE_SPEED_BY_ROUTE_CHART_SUCCESS:
        'dashboard/GET_AVERAGE_SPEED_BY_ROUTE_CHART_SUCCESS',
    GET_AVERAGE_SPEED_BY_ROUTE_CHART_ERROR:
        'dashboard/GET_AVERAGE_SPEED_BY_ROUTE_CHART_ERROR',
    SET_AVERAGE_SPEED_BY_ROUTE_CHART_FILTER_PARAMETER:
        'dashboard/SET_AVERAGE_SPEED_BY_ROUTE_CHART_FILTER_PARAMETER',

    // Average Red Light Delay Chart

    GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_REQUEST:
        'dashboard/GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_REQUEST',
    GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_SUCCESS:
        'dashboard/GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_SUCCESS',
    GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_ERROR:
        'dashboard/GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_ERROR',
    SET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_PARAMETER:
        'dashboard/SET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_PARAMETER',

// Travel Time By Day

    GET_TRAVEL_TIME_BY_DAY_REQUEST:
        'dashboard/GET_TRAVEL_TIME_BY_DAY_REQUEST',
    GET_TRAVEL_TIME_BY_DAY_SUCCESS:
        'dashboard/GET_TRAVEL_TIME_BY_DAY_SUCCESS',
    GET_TRAVEL_TIME_BY_DAY_ERROR:
        'dashboard/GET_TRAVEL_TIME_BY_DAY_ERROR',
    SET_TRAVEL_TIME_BY_DAY_PARAMETER:
        'dashboard/SET_TRAVEL_TIME_BY_DAY_PARAMETER',

    // AVL Health

    GET_AVL_HEALTH_REQUEST: 'dashboard/GET_AVL_HEALTH_REQUEST',
    GET_AVL_HEALTH_SUCCESS: 'dashboard/GET_AVL_HEALTH_SUCCESS',
    GET_AVL_HEALTH_ERROR: 'dashboard/GET_AVL_HEALTH_ERROR',
    SET_AVL_HEALTH_RANGE: 'dashboard/SET_AVL_HEALTH_RANGE',

    // Signal Connectivity

    GET_SIGNAL_CONNECTIVITY_REQUEST: 'dashboard/GET_SIGNAL_CONNECTIVITY_REQUEST',
    GET_SIGNAL_CONNECTIVITY_SUCCESS: 'dashboard/GET_SIGNAL_CONNECTIVITY_SUCCESS',
    GET_SIGNAL_CONNECTIVITY_ERROR: 'dashboard/GET_SIGNAL_CONNECTIVITY_ERROR',
    SET_SIGNAL_CONNECTIVITY_RANGE: 'dashboard/SET_SIGNAL_CONNECTIVITY_RANGE',

    // TSP Request Messages

    GET_TSP_REQUEST_MESSAGES_REQUEST: 'dashboard/GET_TSP_REQUEST_MESSAGES_REQUEST',
    GET_TSP_REQUEST_MESSAGES_SUCCESS: 'dashboard/GET_TSP_REQUEST_MESSAGES_SUCCESS',
    GET_TSP_REQUEST_MESSAGES_ERROR: 'dashboard/GET_TSP_REQUEST_MESSAGES_ERROR',
    SET_TSP_REQUEST_MESSAGES_RANGE: 'dashboard/SET_TSP_REQUEST_MESSAGES_RANGE',

    // Signal Network Performance

    GET_SIGNAL_NETWORK_PERFORMANCE_REQUEST: 'dashboard/GET_SIGNAL_NETWORK_PERFORMANCE_REQUEST',
    GET_SIGNAL_NETWORK_PERFORMANCE_SUCCESS: 'dashboard/GET_SIGNAL_NETWORK_PERFORMANCE_SUCCESS',
    GET_SIGNAL_NETWORK_PERFORMANCE_ERROR: 'dashboard/GET_SIGNAL_NETWORK_PERFORMANCE_ERROR',
    SET_SIGNAL_NETWORK_PERFORMANCE_RANGE: 'dashboard/SET_SIGNAL_NETWORK_PERFORMANCE_RANGE',

};
