export const ROLEUNITS = {
    CREATE_BUSINESS_RULE: 'Create business rule',
    EDIT_SAVE_BUSINESS_RULE: 'Edit/save business rule',
    DELETE_BUSINESS_RULE: 'Delete business rule',
    VIEW_BUSINESS_RULE: 'View business rule',
    MOVE_AGENCY_OWNER_TO_ANOTHER_USER: 'Move agency owner to another user',
    AUTHORIZE_ADDITIONAL_AGENCY_ADMINS: 'Authorize additional agency admins',
    DOWNGRADE_ADMIN_TO_AGENCY_VIEWER: 'Downgrade admin to agency viewer',
    APPROVE_NEW_AGENCY_VIEWERS: 'Approve new agency viewers',
    REMOVE_AGENCY_VIEWERS: 'Remove agency viewers',
    INVITE_NEW_AGENCY_VIEWERS: 'Invite new agency viewers',
    CREATE_NEW_GROUP: 'Create new group',
    EDIT_GROUP: 'Edit group',
    DELETE_GROUP: 'Delete group',
    ASSIGN_USER_TO_GROUP: 'Assign user to group',
    DELETE_USER_FROM_GROUP: 'Delete user from group',
    VIEW_USERS_IN_GROUP: 'View users in group'
};

export const ROLE_GROUPS = [
    {nameGroup: 'TSP Business Rule Management', group_id: 1, inUse: false, data: [
        {isTrue: false, unit_id: '1', unit: 'Create business rule', key: 'CREATE_BUSINESS_RULE'},
        {isTrue: false, unit_id: '2', unit: 'Edit/save business rule', key: 'EDIT_SAVE_BUSINESS_RULE'},
        {isTrue: false, unit_id: '3', unit: 'Delete business rule', key: 'DELETE_BUSINESS_RULE'},
        {isTrue: false, unit_id: '4', unit: 'View business rule', key: 'VIEW_BUSINESS_RULE'}
    ]},
    {nameGroup: 'User Management', group_id: 2, inUse: true, data: [
        {isTrue: false, unit_id: '5', unit: 'Move agency owner to another user', key: 'MOVE_AGENCY_OWNER_TO_ANOTHER_USER'},
        {isTrue: false, unit_id: '6', unit: 'Authorize additional agency admins', key: 'AUTHORIZE_ADDITIONAL_AGENCY_ADMINS'},
        {isTrue: false, unit_id: '7', unit: 'Downgrade admin to agency viewer', key: 'DOWNGRADE_ADMIN_TO_AGENCY_VIEWER'},
        {isTrue: false, unit_id: '8', unit: 'Approve new agency viewers', key: 'APPROVE_NEW_AGENCY_VIEWERS'},
        {isTrue: false, unit_id: '9', unit: 'Remove agency viewers', key: 'REMOVE_AGENCY_VIEWERS'},
        {isTrue: false, unit_id: '10', unit: 'Invite new agency viewers', key: 'INVITE_NEW_AGENCY_VIEWERS'}
    ]},
    {nameGroup: 'Alert Group Management', group_id: 3, inUse: false, data: [
        {isTrue: false, unit_id: '11', unit: 'Create new group', key: 'CREATE_NEW_GROUP'},
        {isTrue: false, unit_id: '12', unit: 'Edit group', key: 'EDIT_GROUP'},
        {isTrue: false, unit_id: '13', unit: 'Delete group', key: 'DELETE_GROUP'},
        {isTrue: false, unit_id: '14', unit: 'Assign user to group', key: 'ASSIGN_USER_TO_GROUP'},
        {isTrue: false, unit_id: '15', unit: 'Delete user from group', key: 'DELETE_USER_FROM_GROUP'},
        {isTrue: false, unit_id: '16', unit: 'View users in group', key: 'VIEW_USERS_IN_GROUP'}
    ]}
];
