import { h } from 'preact';
import Match from 'preact-router/match';

import { setPageTitle } from './header.utils';

import Button from '../ui-core/button';
import Badge from '../ui-core/badge';

import IconSVG from '../ui-core/svg-icon';
import styles from './styles.scss';

const Header = () =>
    <header className={styles.header}>
        <Match>
            {({ url }) => (
                <h1 className={styles.header__title}>
                    {setPageTitle(url)}
                </h1>
            )}
        </Match>
        {process.env.npm_config_preact_app_environment !== 'production'
            && <nav className={styles.nav}>
                <Button label="Print PDF Report" />
                <Button label="Save page as .pdf" />
                <div className={styles.nav__icon}>
                    <IconSVG type="bell" />
                    <div className={styles.nav__badge}>
                        <Badge />
                    </div>
                </div>
            </nav>
        }
    </header>
    ;

export default Header;
