export const types = {
    //cities
    GET_CITIES_REQUEST: 'GET_CITIES_REQUEST',
    GET_CITIES_SUCCESS: 'GET_CITIES_SUCCESS',
    GET_CITIES_ERROR: 'GET_CITIES_ERROR',

    //agencies
    GET_AGENCIES_REQUEST: 'GET_AGENCIES_REQUEST',
    GET_AGENCIES_SUCCESS: 'GET_AGENCIES_SUCCESS',
    GET_AGENCIES_ERROR: 'GET_AGENCIES_ERROR',
};
