/* eslint-disable camelcase */
export const temporaryDataMyAlertsMuted = [
    {
        id: '1414dfdfwewerwer1414',
        alert: 'Unable to connect to the system',
        intersection: 'Powel & Milwaukie',
        muted_date: '16/09/2021, 8:12:19 AM',
        unmuted_date: '16/09/2022',
        type: 'lost-connection',
        history_changes: [
            {
                type: 'system',
                message: 'System error fixed',
                date: '16/09/2021, 8:12:19',
                status: 'fixed',
                id: '11sdsd214edsdfdfd',
            },
            {
                user: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                type: 'user',
                date: '16/09/2021, 8:12:19',
                status: 'opened',
                id: '1s1d214eddfdfd',
            },
            {
                user: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                type: 'user',
                date: '16/09/2021, 8:12:19',
                status: 'opened',
                id: '11214edrfvfdfd',
            },
            {
                user: {
                    first_name: 'Drake',
                    last_name: 'Baould',
                },
                type: 'user',
                date: '16/09/2021, 8:12:19',
                status: 'opened',
                id: '11214edfdfdwer',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
    {
        id: '1414dfdf14wewe2342314',
        alert: 'Missing LYT cloud system logs ',
        intersection: 'Powel & Milwaukie',
        muted_date: '16/09/2021, 8:12:19 AM',
        unmuted_date: '16/09/2022',
        type: 'lost-connection',
        history_changes: [
            {
                type: 'system',
                message: 'System error fixed',
                date: '16/09/2021, 8:12:19',
                status: 'fixed',
                id: '11sdsd214edsdfdfd',
            },
            {
                user: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                type: 'user',
                date: '16/09/2021, 8:12:19',
                status: 'opened',
                id: '1s1d214eddfdfd',
            },
            {
                user: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                type: 'user',
                date: '16/09/2021, 8:12:19',
                status: 'opened',
                id: '11214edrfvfdfd',
            },
            {
                user: {
                    first_name: 'Drake',
                    last_name: 'Baould',
                },
                type: 'user',
                date: '16/09/2021, 8:12:19',
                status: 'opened',
                id: '11214edfdfdwer',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
];
