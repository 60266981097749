/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { get } from '../../../api';
import { getUserCityId, getUserAgencyId } from '../../request.utils';


export const getMapRoutesRequest = async () => {
    const cityId = getUserCityId();
    const agencyId = getUserAgencyId();

    const url = `routes?agencies_id=${agencyId}&cities_id=${cityId}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getMapRouteByIdRequest = async (params = {}) => {
    const { id = '' } = params;
    const agencyId = getUserAgencyId();

    const url = `routes/routes/${id}?agencies_id=${agencyId}`;
    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getMapRouteDetailsByIdRequest = async (params = {}) => {
    const { id = '' } = params;
    const agencyId = getUserAgencyId();

    const url = `routes/route-details/${id}?agencies_id=${agencyId}`;
    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getMapStopsByIdRequest = async (params = {}) => {
    const { id = '' } = params;
    const agencyId = getUserAgencyId();

    const url = `stops/routes/${id}?agencies_id=${agencyId}`;
    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getMapTrafficLightsRequest = async () => {
    const cityId = getUserCityId();
    const url = `intersections?city_id=${cityId}&limit=500`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
