/* eslint-disable dot-notation */
/* eslint-disable keyword-spacing */
import { Router } from 'preact-router';
import { useEffect, useLayoutEffect, useRef } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Suspense } from 'preact/compat';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import { Auth0Provider } from '@auth0/auth0-react';

import { CustomProvider } from 'rsuite';

import Redirect from './redirect';
import Header from './header';
import Sidebar from './sidebar';
import ModalDistributor from './modal';
import InformationPanel from './information-panel';
import AuthHeader from '../routes/auth/components/auth-header';
import SvgIcon from './ui-core/svg-icon';
import Button from './ui-core/button';

import { userThemeSelector } from '../store/user/user.selectors';
import { privateRoutes, productionRoutes, stageRoutes, publicRoutes } from '../constants/routes.config';
import { sessionAccessSelector } from '../routes/auth/auth.selectors';
import { clearAuthMutualData, clearSession } from '../routes/auth/auth.actions';
import { bookList } from '../constants/bookList';
import { VIEW_TYPES_LIST } from './ui-core/button/button.constants';
import { getParamURL } from '../utils/url';

import styles from './styles.scss';

const App = () => {
    const dispatch = useDispatch();
    const access = useSelector((state) => sessionAccessSelector(state));
    const theme = useSelector(userThemeSelector);
    const scrollToTopButton = useRef(null);
    const isProduction = process.env.npm_config_preact_app_environment === 'production';
    const isStage = isProduction && window.location.hostname.includes('stage');

    ReactGA.initialize(process.env.npm_config_preact_app_google_analytics_key, {
        testMode: !isProduction
    });

    useLayoutEffect(() => {
        if (getParamURL('idUser') && getParamURL('token') && access) {
            console.log('app - clearSession');
            dispatch(clearSession());
        }
        if (getParamURL('invitation') && access) {
            console.log('app - clearSession');
            dispatch(clearSession());
        }
    }, []);

    useEffect(() => {
        const html = document.querySelector('html');
        html.dataset.theme = theme;
    }, [theme]);

    const scrollFunction = () => {
        if (scrollToTopButton?.current) {
            if (
                document.body.scrollTop > 20
                || document.documentElement.scrollTop > 20
            ) {
                scrollToTopButton.current.style.display = 'flex';
            } else {
                scrollToTopButton.current.style.display = 'none';
            }
        }
    };

    useEffect(() => {
        window.onscroll = () => scrollFunction();

        scrollFunction();

        return () => dispatch(clearAuthMutualData());
    }, []);

    return (
        <CustomProvider theme={theme.replace('theme-', '')} >
            <Auth0Provider
                domain={process.env.npm_config_preact_app_auth0_domain}
                clientId={process.env.npm_config_preact_app_auth0_clientid}
                authorizationParams={{
                    audience: process.env.npm_config_preact_app_auth0_audience,
                    scope: 'openid profile email read:user',
                    redirect_uri: `${window.location.origin}/login`,
                }}
            >
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {!access
                    ? <div className={styles['auth__wrapper']}>
                        <InformationPanel />
                        <div className={styles['auth__body']}>
                            <AuthHeader />
                            <Suspense fallback={<div>loading...</div>}>
                                <Router>
                                    {publicRoutes.map(
                                        ({
                                            component: Component,
                                            path,
                                            isDefault,
                                        }) =>
                                            <Component
                                                path={path}
                                                key={path}
                                                default={isDefault}
                                            />
                                    )}
                                    <div default><Redirect path="/" to={bookList.auth.login} /></div>
                                </Router>
                            </Suspense>
                        </div>
                    </div>
                    : <div className={styles['app__wrapper']} id="app">
                        <ModalDistributor />
                        <Sidebar />
                        <div className={styles['app__body']}>
                            <Header />
                            <Suspense fallback={<div>loading...</div>}>
                                <Router>
                                    {(isProduction ? isStage ? stageRoutes : productionRoutes : privateRoutes).map(
                                        ({
                                            component: Component,
                                            path,
                                            isDefault,
                                        }) =>
                                            <Component
                                                path={path}
                                                key={path}
                                                default={isDefault}
                                            />
                                    )}

                                    <Redirect
                                        path="/"
                                        to={bookList.dashboard.entry_point}
                                    />
                                </Router>
                            </Suspense>
                        </div>
                        <div
                            className={styles['app__to-top']}
                            ref={scrollToTopButton}
                        >
                            <Button
                                size="sm"
                                icon={<SvgIcon type="arrow-back" />}
                                viewType={VIEW_TYPES_LIST.LINK}
                                handleClick={() => {
                                    document.body.scrollTop = 0; // For Safari
                                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                                }}
                            ></Button>
                        </div>
                    </div>
                }
            </Auth0Provider>
        </CustomProvider>
    );
};

export default App;
