import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

const SkeletonRequestsCards = ({ colors }) => (
    <ContentLoader
        speed={2}
        width={612}
        height={500}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <rect x="0" y="0" rx="6" ry="6" width="612" height="87" />
        <rect x="0" y="100" rx="6" ry="6" width="612" height="87" />
        <rect x="0" y="200" rx="6" ry="6" width="612" height="87" />
        <rect x="0" y="300" rx="6" ry="6" width="612" height="87" />
    </ContentLoader>
);

SkeletonRequestsCards.propTypes = {
    colors: PropTypes.shape({
        background: PropTypes.string.isRequired,
        foreground: PropTypes.string.isRequired,
    }),
};

export default SkeletonRequestsCards;
