import { types } from './registration.types';

const initialState = {
    inviteData: {
        email: '',
        token: '',
    },
    registerWith: '', // empty, 'email' or 'sso'
    loading: true,
    error: '',
};

const registration = (state = initialState, { type, payload }) => {
    switch (type) {
    // validate invite request

    case types.SEND_VALIDATE_INVITE_REQUEST:
        return {
            ...state,
            loading: true,
            error: '',
        };
    case types.SEND_VALIDATE_INVITE_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };
    case types.SEND_VALIDATE_INVITE_SUCCESS:
        return {
            ...state,
            inviteData: {
                ...payload,
            },
            loading: false,
            error: '',
        };
    case types.SET_REGISTER_WITH:
        return {
            ...state,
            registerWith: payload,
        };
    default:
        return state;
    }
};

export default registration;
