import ContentLoader from "react-content-loader";

const SkeletonChartBar = ({ colors, height }) => {
    return (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={height || 460}
            backgroundColor={colors.background}
            foregroundColor={colors.foreground}
        >
            <rect x="100" y="200" rx="15" ry="15" width="60" height="260" />
            <rect x="200" y="300" rx="15" ry="15" width="60" height="160" />
            <rect x="300" y="100" rx="15" ry="15" width="60" height="360" />
            <rect x="400" y="60" rx="15" ry="15" width="60" height="400" />
            <rect x="500" y="300" rx="15" ry="15" width="60" height="160" />
            <rect x="600" y="260" rx="15" ry="15" width="60" height="200" />
            <rect x="700" y="360" rx="15" ry="15" width="60" height="100" />
            <rect x="800" y="160" rx="15" ry="15" width="60" height="360" />
            <rect x="900" y="100" rx="15" ry="15" width="60" height="360" />
            <rect x="1000" y="60" rx="15" ry="15" width="60" height="400" />
            <rect x="1100" y="300" rx="15" ry="15" width="60" height="160" />
            <rect x="1200" y="260" rx="15" ry="15" width="60" height="200" />
            <rect x="1300" y="200" rx="15" ry="15" width="60" height="260" />
            <rect x="1400" y="160" rx="15" ry="15" width="60" height="300" />
        </ContentLoader>
    );
};

export default SkeletonChartBar;
