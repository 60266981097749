import { h } from 'preact';
import PropTypes from 'prop-types';

import styles from './styles.scss';

const RoutesItem = ({
    listItem: { id, description, isFS, ...itemRest },
    handleClick,
}) => (
    <div
        key={id}
        className={styles['routes-item']}
        onClick={() => handleClick({ id, description, ...itemRest })}
    >
        <p className={styles['routes-item__title']}>{description}</p>
        {isFS && <div className={styles['routes-item__fs-label']}>FS</div>}
    </div>
);

RoutesItem.propTypes = {
    listItem: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default RoutesItem;
