import PropTypes from 'prop-types';

import Spinner from '../../spinner';

import { VIEW_TYPES_LIST, TYPES_LIST, SIZES_LIST } from './button.constants';

import styles from './styles.scss';

const Button = ({
    viewType = VIEW_TYPES_LIST.DEFAULT,
    size = SIZES_LIST.MD,
    type = TYPES_LIST.BUTTON,
    label = '',
    handleClick = () => {},
    loading = false,
    icon = null,
    responsive,
    disabled = false,
    rightIcon = null,
    form = null,
}) => {
    const className = `${styles.button} ${styles[`button--${viewType}`]} ${
        styles[`button--${size}`]
    } ${responsive ? styles['button--responsive'] : ''}`;

    return (
        <button
            disabled={disabled}
            type={type}
            onClick={handleClick}
            className={className}
            form={form}
        >
            {icon}
            {loading ? <Spinner size={20} /> : label}
            {rightIcon && (
                <span className={styles['button__icon--right']}>
                    {rightIcon}
                </span>
            )}
        </button>
    );
};

Button.propTypes = {
    viewType: PropTypes.oneOf(Object.values(VIEW_TYPES_LIST)),
    type: PropTypes.oneOf(Object.values(TYPES_LIST)),
    size: PropTypes.oneOf(Object.values(SIZES_LIST)),
    label: PropTypes.string,
    loading: PropTypes.bool,
    handleClick: PropTypes.func,
    icon: PropTypes.object,
    rightIcon: PropTypes.object,
    responsive: PropTypes.bool,
    disabled: PropTypes.bool,
    form: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Button;
