/* eslint-disable space-before-function-paren */
import { get, post, put } from '../../api';

export const sendLoginDataRequest = async (reqData = {}) => {
    const url = '/auth/login';

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const sendLoginOtpDataRequest = async (reqData = {}) => {
    const url = '/auth/loginotp';

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const sendRegistrationDataRequest = async (
    reqData = {},
) => {
    const url = '/users';
    console.log('reqData', reqData);

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch(err) {
        const { data } = err.response;

        throw data.message;
    }
};

export const sendRefreshTokenRequest = async () => {
    const url = '/auth/refresh_token';

    try {
        const { data } = await post(url);

        return data;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const sendNewPasswordRequest = async (token, reqData = {}) => {
    const url = `/users/forgot-password/recovery/${token}`;

    try {
        const { data } = await post(url, reqData);

        return data;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const sendCheckOtpRequest = async (params = {}) => {
    const { otp = '' } = params;

    const url = `/auth/otp?otp=${otp}`;

    try {
        await get(url);

        return true;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

// change forgot
export const sendUpdateForgotPasswordRequest = async (reqData = {}) => {
    const url = '/users/forgot-password/email';

    try {
        await post(url, reqData);

        return true;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const checkUserInvitationRequest = async (params = {}) => {
    const url = `/users/${params.userID}/invite/${params.inviteToken}`;

    try {
        // const response = await get(url);
        /////////////////// RPH - mock
        const response = {data: {email: 'richard@lyt.ai', token: params.inviteToken}};
        ///////////////////

        return response.data;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const sendSubmitInvitationRequest = async (
    reqData = {},
    params = {}
) => {
    const url = `/users/${params.userID}/invite/${params.inviteToken}`;

    try {
        const response = await put(url, reqData);

        return response;
    } catch(err) {
        const { data: errorData } = err.response;

        throw errorData;
    }
};

export const resendOtpRequest = async (email) => {
    const url = '/auth/resend-otp';

    try {
        const response = await post(url, { email });
        return response;
    } catch(err) {
        console.log('resendOtpRequest error', err);
    }
};

export const sendLogoutRequest = async () => {
    const url = '/auth/logout';

    try {
        const response = await post(url);
        return response;
    } catch(err) {
        console.log('sendLogoutRequest error', err);
    }
};
