import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

const SkeletonBRRoutesTable = ({ colors }) => (
    <table style={{ width: '100%' }}>
        <tr>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect x="10" y="20" rx="6" ry="6" width="90%" height="20" />
                </ContentLoader>
            </th>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect x="15" y="20" rx="6" ry="6" width="90%" height="20" />
                </ContentLoader>
            </th>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect x="15" y="20" rx="6" ry="6" width="100%" height="20" />
                </ContentLoader>
            </th>
        </tr>
    </table>
);

SkeletonBRRoutesTable.propTypes = {
    colors: PropTypes.shape({
        background: PropTypes.string.isRequired,
        foreground: PropTypes.string.isRequired,
    }),
};

export default SkeletonBRRoutesTable;
