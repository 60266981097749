/* eslint-disable camelcase */
export const temporaryDataMyAlertsSBM = [
    {
        id: '1414dfdf1232414',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: 'dfdf34765434',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '454dfdf54534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: 'dfddsdf343s4',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '14154677654674541414',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: 'dfdf34765434',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '454dfdf54534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: 'dfddsdf343s4',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '14154677654674541425525214',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: 'dfdf34765434',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '454dfdf54534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: 'dfddsdf343s4',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '14565756578554674541425525214',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: 'dfdf34765434',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '454dfdf54534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: 'dfddsdf343s4',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '1419889898981425525214',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: 'dfdf34765434',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '454dfdf54534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: 'dfddsdf343s4',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '141548888778788778525214',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: 'dfdf34765434',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '454dfdf54534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: 'dfddsdf343s4',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '6787888898989889825214',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: 'dfdf34765434',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '454dfdf54534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: 'dfddsdf343s4',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '14154677888888990000025525214',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: 'dfsdf3df434',
                notifications: false,
            },
        ],
    },
    {
        id: '141542212121231234674541425525214',
        intersection: 'Powel & Milwaukie',
        status: 'Blinking yellow',
        date: '16/09/2021, 8:12:19 AM',
        execution: 'early',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545rrrr3432d4523',
                notifications: false,
            },
        ],
    },
];
