import { types } from './cities-and-agencies.types';

const initialState = {
    cities: {
        list: [],
        loading: true,
        error: '',
    },
    agencies: {
        list: [],
        loading: true,
        error: '',
    },
};

const citiesAndAgencies = (state = initialState, { type, payload }) => {
    switch (type) {
    // cities
    case types.GET_CITIES_REQUEST:
        return {
            ...state,
            cities: {
                ...state.cities,
                loading: true,
            },
        };
    case types.GET_CITIES_SUCCESS:
        return {
            ...state,
            cities: {
                ...state.cities,
                list: payload,
                loading: false,
            },
        };
    case types.GET_CITIES_ERROR:
        return {
            ...state,
            cities: {
                ...state.cities,
                loading: false,
                error: payload,
            },
        };

        // agencies
    case types.GET_AGENCIES_REQUEST:
        return {
            ...state,
            agencies: {
                ...state.agencies,
                loading: true,
            },
        };
    case types.GET_AGENCIES_SUCCESS:
        return {
            ...state,
            agencies: {
                ...state.agencies,
                list: payload,
                loading: false,
            },
        };
    case types.GET_AGENCIES_ERROR:
        return {
            ...state,
            agencies: {
                ...state.agencies,
                loading: false,
                error: payload,
            },
        };
    default:
        return state;
    }
};

export default citiesAndAgencies;
