export const VIEW_TYPES_LIST = {
    ERROR: 'error',
    HAS_NO_DATA: 'has-no-data',
    MUST_SELECT_ROUTE: 'must-select-route',
    AGENCY_HAS_NO_ROUTES: 'agency-has-no-routes',
    AGENCY_HAS_CURRENT_INVITATIONS: 'no-current-invitations',
    CHART_HAS_AN_ERROR: 'chart-has-an-error',
    CRITICAL_ALERTS_ERROR: 'critical-alerts-error',
    TRAFFIC_LIGHTS_ERROR: 'traffic-lights-error',
    OVERALL_PERFORMANCE_ERROR: 'overall-performance-error',
    ROUTE_DETAILS_HAS_ERROR: 'route-details-has-error',
};

export const CONFIG_LIST = {
    [VIEW_TYPES_LIST.HAS_NO_DATA]: {
        title: 'This block has no data',
        description:
            'For some reason, the data cannot be displayed now. Please wait for data updates or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.MUST_SELECT_ROUTE]: {
        title: 'Route must be selected first',
        description:
            'In the search bar at the top of the page, select the route to display the details',
        button: {
            isVisible: false,
            label: '',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.ERROR]: {
        title: 'An error has occurred',
        description:
            'For some reason, the block or page occurred with an error. Please wait for data updates or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.AGENCY_HAS_NO_ROUTES]: {
        title: 'The selected agency has no stored routes',
        description:
            'Choose a different agency or please wait for data updates or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.AGENCY_HAS_CURRENT_INVITATIONS]: {
        title: 'There are no invitation requests for this agency',
        description:
            'Check back later for incoming requests.',
    },
    [VIEW_TYPES_LIST.CHART_HAS_AN_ERROR]: {
        title: 'A chart error has occurred',
        description:
            'For some reason, an error occurred while producing this chart. Please reload, wait for data updates, or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.CRITICAL_ALERTS_ERROR]: {
        title: 'Cannot produce critical alerts',
        description:
            'For some reason, an error occurred while producing the critical alerts. Please reload, wait for data updates, or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.TRAFFIC_LIGHTS_ERROR]: {
        title: 'Cannot produce traffic lights operational data',
        description:
            'For some reason, an error occurred while producing the traffic lights operational data. Please reload, wait for data updates, or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.OVERALL_PERFORMANCE_ERROR]: {
        title: 'Cannot produce overall performance data',
        description:
            'For some reason, an error occurred while producing the overall performance data. Please reload, wait for data updates, or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
    [VIEW_TYPES_LIST.ROUTE_DETAILS_HAS_ERROR]: {
        title: 'An error occurred in providing the route details',
        description:
            'For some reason, an error occurred while producing the route details. Please reload, wait for data updates, or contact support.',
        button: {
            isVisible: true,
            label: 'Contact Support',
            action: () => {},
        },
    },
};

