/* eslint-disable dot-notation */
/* eslint-disable keyword-spacing */
import { Fragment } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import Spinner from '../../../spinner';

import SvgIcon from '../../svg-icon';

import { OPTION_TYPES } from './select.constants';

import styles from './styles.scss';

const Select = ({
    optionsList = [],
    defaultSelectLabel = '',
    defaultValue = {},
    type = OPTION_TYPES.PRIMARY,
    onOptionSelected,
    icon = null,
    label = '',
    isNeedToShowSelectedValue = true,
    loading = false,
    setValueAfterSuccessRequest = false
}) => {
    const [isOpened, setIsOpened] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        defaultValue || {}
    );
    const wrapperRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = ({ target }) => {
        if (wrapperRef.current && !wrapperRef.current.contains(target)) {
            setIsOpened(false);
        }
    };

    const handleOptionClick = (option) => {
        if (isNeedToShowSelectedValue && !setValueAfterSuccessRequest) {
            setSelectedOption(option);
        }
        setIsOpened(false);
        if (setValueAfterSuccessRequest) {
            onOptionSelected({ option, callback: setSelectedOption });
        } else {
            onOptionSelected(option.value);
        }
    };

    const generateContentForOption = (option) => {
        switch (type) {
        case OPTION_TYPES.OVERALL_PERFORMANCE:
            return (
                <Fragment>
                    <span>{option.label}</span>
                    {option.additionalLabel
                        && <span
                            className={`${styles['select__options__item-additional-label']}`}
                        >
                            {option.additionalLabel}
                        </span>
                    }
                </Fragment>
            );
        case OPTION_TYPES.PRIMARY:
            return option.label;
        default:
            return option.label;
        }
    };

    const generateOptionClassName = () => {
        if (type !== OPTION_TYPES.PRIMARY) {
            return `${styles[`select__options__item-${type}`]}`;
        }

        return styles.select__options__item;
    };

    const generateLayoutForSelectedOption = () => {
        if (selectedOption.additionalLabel) {
            return (
                <Fragment>
                    <span>{selectedOption.label}</span>
                    <span
                        className={`${styles['select__options__item-additional-label']}`}
                    >
                        {selectedOption.additionalLabel}
                    </span>
                </Fragment>
            );
        }

        return <span>{ defaultValue?.label || selectedOption.label || defaultSelectLabel}</span>;
    };

    return (
        <>
            {label
                && <label className={styles['select__label']}>{label}</label>
            }
            <button className={styles.select} ref={wrapperRef} type="button">
                <div
                    className={`${styles[
                        `select__main-container${isOpened ? '--active' : ''
                        }`
                    ]
                    }`}
                    onClick={() => setIsOpened(!isOpened)}
                >
                    {icon}
                    {loading ? <Spinner size={10} color={[150, 150, 150]} /> : generateLayoutForSelectedOption()}
                    <span
                        className={`${styles[
                            `select__main__arrow${isOpened ? '--active' : ''
                            }`
                        ]
                        }`}
                    >
                        <SvgIcon type="arrow" />
                    </span>
                </div>
                {isOpened
                    && <ul className={`${styles['select__options-container']}`}>

                        {optionsList.length ? optionsList.map((option) =>
                            <li
                                className={generateOptionClassName()}
                                key={option.label}
                                onClick={() => handleOptionClick(option)}
                            >
                                {generateContentForOption(option)}
                            </li>
                        )
                            : <li className={styles['select__options__item--no-data']}>
                            No Additional Choices
                            </li>}
                    </ul>
                }
            </button>
        </>
    );
};

Select.propTypes = {
    optionsList: PropTypes.array.isRequired,
    defaultSelectLabel: PropTypes.string,
    type: PropTypes.oneOf(Object.values(OPTION_TYPES)),
    onOptionSelected: PropTypes.func.isRequired,
    icon: PropTypes.object,
    defaultValue: PropTypes.object,
    label: PropTypes.string,
    isNeedToShowSelectedValue: PropTypes.bool,
    loading: PropTypes.bool,
    setValueAfterSuccessRequest: PropTypes.bool
};

export default Select;
