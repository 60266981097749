/* eslint-disable keyword-spacing */
import PropTypes from 'prop-types';

import Info from '../info';
import { LONG_DESCRIPTIONS } from './long-description-config';

import styles from './styles.scss';

const isProduction = process.env.npm_config_preact_app_environment === 'production';

const LongDescription = ({
    title = '',
    longKey = '',
    iconType = 'info',
    location = ''
}) => {
    const fullDescription = (itemTitle, itemLongKey, itemIconType) =>
        <div className={styles['long-description-container']}>
            <span className={styles[`long-description${location === '' ? '' : '-'}${location}`]}>{itemTitle}
                <Info forDescription={location === 'card'} forHeading={location !== 'card'} message={LONG_DESCRIPTIONS[itemLongKey]
                    ? LONG_DESCRIPTIONS[itemLongKey]
                    : LONG_DESCRIPTIONS[itemTitle] ? LONG_DESCRIPTIONS[itemTitle] : 'No help available'} type={itemIconType}/>
            </span>
        </div>;

    if (!LONG_DESCRIPTIONS[longKey] && !LONG_DESCRIPTIONS[title]) {
        console.log(`No long description for item ${longKey}, with heading "${title}"`);
    }
    return <>
        {!isProduction
            ? fullDescription(title, longKey, iconType)
            : LONG_DESCRIPTIONS[longKey] && !LONG_DESCRIPTIONS[longKey].includes('No help')
                || LONG_DESCRIPTIONS[title] && !LONG_DESCRIPTIONS[title].includes('No help')
                ? fullDescription(title, longKey, iconType)
                : <span>{title}</span>
        }
    </>;
};

LongDescription.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    longKey: PropTypes.string,
    iconType: PropTypes.string,
    location: PropTypes.string,
};

export default LongDescription;
