import { types } from "./groups.types";

const initialState = {
    list: [],
    loading: true,
    error: "",
    users: {
        list: [],
        loading: true,
        error: "",
    },
};

const users = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.GET_GROUPS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_GROUPS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload,
            };
        case types.GET_GROUPS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        case types.GET_USERS_LIST_REQUEST:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: true,
                },
            };
        case types.GET_USERS_LIST_SUCCESS:
            return {
                ...state,
                users: {
                    ...state.users,
                    list: payload,
                    loading: false,
                },
            };
        case types.GET_USERS_LIST_ERROR:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: false,
                    error: payload,
                },
            };

        case types.ADD_NEW_GROUP_REQUEST:
        case types.ADD_NEW_MEMBERS_REQUEST:
        case types.DELETE_GROUP_REQUEST:
        case types.EDIT_GROUP_NAME_REQUEST:
        case types.DELETE_GROUP_MEMBER_REQUEST:
            return {
                ...state,
            };

        case types.ADD_NEW_GROUP_SUCCESS:
        case types.ADD_NEW_MEMBERS_SUCCESS:
        case types.DELETE_GROUP_SUCCESS:
        case types.EDIT_GROUP_NAME_SUCCESS:
        case types.DELETE_GROUP_MEMBER_SUCCESS:
            return {
                ...state,
                list: payload,
            };

        case types.ADD_NEW_GROUP_ERROR:
        case types.ADD_NEW_MEMBERS_ERROR:
        case types.DELETE_GROUP_ERROR:
        case types.EDIT_GROUP_NAME_ERROR:
        case types.DELETE_GROUP_MEMBER_ERROR:
            return {
                ...state,
                error: payload,
            };

        default:
            return state;
    }
};

export default users;
