/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import PropTypes from 'prop-types';

import SelectedItem from './components/selected-item';

import { getMutedItemsString } from './selectable-list.utils';
import { VIEW_TYPES, COLOR_TYPES } from './selectable-list.constants';

import styles from './styles.scss';

const SelectableList = ({
    list = [],
    colorType = COLOR_TYPES.PRIMARY,
    handleDelete,
    viewType = VIEW_TYPES.ACTIONS,
    isNeedToShowAvatar = true
}) => {
    const mutedInformation = getMutedItemsString(list, 'last_name');

    const className = `${styles['selectable-list']} ${styles[`selectable-list--${viewType}`]
    }`;

    return (
        <div className={className}>
            <div className={styles['selectable-list__items']}>
                {list.map(({ value, notifications, id, is_thereof }) =>
                    <SelectedItem
                        value={value}
                        colorType={is_thereof ? COLOR_TYPES.HIGHLIGHT : colorType}
                        notifications={notifications}
                        handleDelete={handleDelete}
                        viewType={viewType}
                        id={id}
                        isNeedToShowAvatar={isNeedToShowAvatar}
                    />
                )}
            </div>
            {mutedInformation
                && <div className={styles['selectable-list__description']}>
                    <p className={styles['selectable-list__description-text']}>
                        {mutedInformation}
                    </p>
                </div>
            }
        </div>
    );
};


SelectableList.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            notifications: PropTypes.bool,
            id: PropTypes.string,
        })
    ),
    viewType: PropTypes.oneOf(Object.values(VIEW_TYPES)),
    handleDelete: PropTypes.func,
    colorType: PropTypes.oneOf(Object.values(COLOR_TYPES)),
    isNeedToShowAvatar: PropTypes.bool
};


export default SelectableList;
