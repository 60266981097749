/* eslint-disable camelcase */
export const profileLabels = {
    firstname: 'First name',
    lastname: 'Last name',
    phone: 'Phone number',
    email: 'Email',
    notification_through: 'Preferred notification method'
};

export const notificationLabels = {
    phone: 'Text Message',
    email: 'E-mail'
};
