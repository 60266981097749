/* eslint-disable keyword-spacing */
import { useDispatch, useSelector } from 'react-redux';

import Modal from './modal';
import WidgetsManagement from './components/widgets-management/widgets-management';
import MuteAlert from './components/mute-alert';
import ShareAlert from './components/share-alert';
import NewGroup from './components/new-group';
import AddMembers from './components/add-members';
import DeleteGroupConfirmation from './components/delete-group-confirmation';
import InviteNewUser from './components/invite-new-user';
import ChangeProfileConfirmation from './components/change-profile-confirmation';
import DeleteUserConfirmation from './components/delete-user-confirmation/delete-user-confirmation';

import { clearModalState } from './modal.actions';
import { modalSelector } from './modal.selectors';
import { MODAL_TYPES } from './modal.constants';

const ModalDistributor = () => {
    const dispatch = useDispatch();
    const { type, isVisible, data } = useSelector(modalSelector);

    const setRequestedModalAsChildren = () => {
        switch (type) {
        case MODAL_TYPES.WIDGET_MANAGEMENT:
            return (
                <WidgetsManagement
                    closeModal={() => dispatch(clearModalState())}
                />
            );

        case MODAL_TYPES.SHARE_ALERT:
            return <ShareAlert selectedUsers={data.recipientsList} />;

        case MODAL_TYPES.MUTE_ALERTS:
            return <MuteAlert />;

        case MODAL_TYPES.GROUPS_ADD_NEW_GROUP:
            return <NewGroup />;

        case MODAL_TYPES.GROUPS_ADD_NEW_MEMBERS:
            return <AddMembers id={data.groupId} />;

        case MODAL_TYPES.GROUPS_GROUP_DELETE_CONFIRMATION:
            return <DeleteGroupConfirmation id={data.groupId} />;

        case MODAL_TYPES.INVITE_NEW_USER:
            return <InviteNewUser />;

        case MODAL_TYPES.CONFIRM_USER_UPDATE:
            return <ChangeProfileConfirmation fieldsToChange={data.dataToSend} onCancel={data.handleOnCancel}/>;

        case MODAL_TYPES.DELETE_USER_CONFIRMATION:
            return <DeleteUserConfirmation id={data.id} />;

        default:
            break;
        }
    };

    if (!isVisible) return null;

    return (
        <Modal onClose={() => dispatch(clearModalState())}>
            {setRequestedModalAsChildren()}
        </Modal>
    );
};

ModalDistributor.propTypes = {};

export default ModalDistributor;
