/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { get } from '../../../api';
import daysjs from 'dayjs';
import { getUserCityId, getUserAgencyId } from '../../request.utils';
import { PERIOD_LIST as OVERAL_PERFORMANCE_PERIOD_LIST } from '../../../../routes/dashboard/general/components/carousel/carousel.configs';
import { PERIOD_LIST as SYSTEM_WIDE_PERIOD_LIST } from '../../../../routes/dashboard/general/components/system-wide/system-wide.config';
import { PERIOD_LIST as TTC_PERIOD_LIST } from '../../../../routes/dashboard/general/components/total-tsp-calls/total-tsp-valls.config';
import { PERIOD_LIST as ARLWT_PERIOD_LIST } from '../../../../routes/dashboard/general/components/average-red-light-wait-time/average-red-light-wait-time.config';
import { PERIOD_LIST as ASBR_PERIOD_LIST } from '../../../../routes/dashboard/general/components/average-speed-by-route/average-speed-by-route.config';

export const getCriticalAlertsRequest = async (/*params = {}*/) => {
    const url = `/info/dashboard/critical-alerts?agencies_id=${getUserAgencyId()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getTrafficLightsRequest = async (params = {}) => {
    const { period = 'LAST_24_HOURS' } = params;

    const url = `/info/dashboard/traffic-light-operation-data?agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getOverallPerformanceRequest = async (params = {}) => {
    const { period = OVERAL_PERFORMANCE_PERIOD_LIST[0].value } = params;

    const url = `/info/dashboard/overall-performance?agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getRecentAlertsTrafficLightRequest = async (/*params = {}*/) => {
    const url = `/dashboard/general/trafficLight?${getUserCityId()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getRecentAlertsVechileInformationRequest = async (/*params = {}*/) => {
    const url = `/dashboard/general/vehicle?${getUserCityId()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getRecentAlertsPriorityRequestsRequest = async (/*params = {}*/) => {
    const url = `/dashboard/general/priorityReq?${getUserCityId()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getSystemWideRequest = async (params = {}) => {
    const { period = SYSTEM_WIDE_PERIOD_LIST[0].value } = params;

    const url = `/info/dashboard/charts/system-wide-green-light-success-rate-per-hour?agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getTotalTSPCallsChartRequest = async (params = {}) => {
    const { period = TTC_PERIOD_LIST[0].value } = params;

    const url = `/info/dashboard/charts/total-tsp-calls-per-hour?agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getAverageSpeedByRouteChartRequest = async (params = {}) => {
    const { period = ASBR_PERIOD_LIST[0].value } = params;
    const url = `/info/dashboard/charts/average-speed-by-route?agencies_id=${getUserAgencyId()}&period=${period}&route_key=${params.route}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getAverageRedLightWaitTimeChartRequest = async (params = {}) => {
    const { period = ARLWT_PERIOD_LIST[0].value, route, direction } = params;
    let directionURI = '';
    if (direction) directionURI = `&direction=${encodeURIComponent(direction)}`;
    const url = `/info/dashboard/charts/average-red-light-wait-time?agencies_id=${getUserAgencyId()}&period=${period}&route_key=${route}${directionURI}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getSWAvgRedLightWaitTimeChartRequest = async (params = {}) => {
    const url = `/info/dashboard/charts/sw-average-red-light-wait-time?agencies_id=${getUserAgencyId()}&route_key=${params.route}&period=${params.period}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getSWAvgGreenLightSuccessRateChartRequest = async (params = {}) => {
    const url = `/info/dashboard/charts/sw-average-green-light-success-rate?agencies_id=${getUserAgencyId()}&route_key=${params.route}&period=${params.period}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getTravelTimeByDayRequest = async (params = {}) => {
    const url = `/info/dashboard/charts/average-travel-time-by-route?agencies_id=${getUserAgencyId()}&route_key=${params.route}&period=LAST_24_HOURS`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getAVLHealthRequest = async (params = {}) => {
    const start_date = encodeURIComponent(daysjs(params.start).format('YYYY-MM-DD HH:mm'));
    const end_date = encodeURIComponent(daysjs(params.end).format('YYYY-MM-DD HH:mm'));
    const url = `/info/dashboard/avl-stats?agencies_id=${getUserAgencyId()}&start_datetime=${start_date}&end_datetime=${end_date}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getSignalConnectivityRequest = async (params = {}) => {
    const start_date = encodeURIComponent(daysjs(params.start).format('YYYY-MM-DD HH:mm'));
    const end_date = encodeURIComponent(daysjs(params.end).format('YYYY-MM-DD HH:mm'));
    const url = `/info/dashboard/traffic-signal-stats?agencies_id=${getUserAgencyId()}&start_datetime=${start_date}&end_datetime=${end_date}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getTSPRequestMessagesRequest = async (params = {}) => {
    const start_date = encodeURIComponent(daysjs(params.start).format('YYYY-MM-DD HH:mm'));
    const end_date = encodeURIComponent(daysjs(params.end).format('YYYY-MM-DD HH:mm'));
    const url = `/info/dashboard/tsp-request-stats?agencies_id=${getUserAgencyId()}&start_datetime=${start_date}&end_datetime=${end_date}`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getSignalNetworkPerformanceRequest = async (params = {}) => {
    const start_date = encodeURIComponent(daysjs(params.start).format('YYYY-MM-DD HH:mm'));
    const end_date = encodeURIComponent(daysjs(params.end).format('YYYY-MM-DD HH:mm'));
    const url = `/info/dashboard/signal-network-stats?agencies_id=${getUserAgencyId()}&start_datetime=${start_date}&end_datetime=${end_date}`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};
