import { types } from "./settings.types";

// Passord types

export const changePasswordRequest = () => ({
    type: types.CHANGE_PASSWORD_REQUEST
})

export const changePasswordSuccess = (data) => ({
    type: types.CHANGE_PASSWORD_SUCCESS,
    payload: data
})

export const changePasswordError = (error) => ({
    type: types.CHANGE_PASSWORD_ERROR,
    payload: error
})

// User types

export const updateUserRequest = () => ({
    type: types.UPDATE_USER_REQUEST
})

export const updateUserSuccess = (data) => ({
    type: types.UPDATE_USER_SUCCESS,
    payload: data
})

export const updateUserError = (error) => ({
    type: types.UPDATE_USER_ERROR,
    payload: error
})

// All widgets list

export const getAllWidgetsListRequest = () => ({
    type: types.GET_ALL_WIDGETS_LIST_REQUEST,
});

export const getAllWidgetsListSuccess = (data) => ({
    type: types.GET_ALL_WIDGETS_LIST_SUCCESS,
    payload: data,
});

export const getAllWidgetsListError = (error) => ({
    type: types.GET_ALL_WIDGETS_LIST_ERROR,
    payload: error,
});

export const setAllWidgetsList = (data) => ({
    type: types.SET_ALL_WIDGETS_LIST,
    payload: data,
});

// Added widgets list

export const getAddedWidgetsListRequest = () => ({
    type: types.GET_ADDED_WIDGETS_REQUEST,
});

export const getAddedWidgetsListSuccess = (data) => ({
    type: types.GET_ADDED_WIDGETS_SUCCESS,
    payload: data,
});

export const getAddedWidgetsListError = (error) => ({
    type: types.GET_ADDED_WIDGETS_ERROR,
    payload: error,
});

export const setAddedWidgetsList = (data) => ({
    type: types.SET_ADDED_WIDGETS_LIST,
    payload: data,
});

// Send added widgets

export const sendAddedWidgetsRequest = () => ({
    type: types.SEND_ADDED_WIDGETS_REQUEST,
});

export const sendAddedWidgetsSuccess = (data) => ({
    type: types.SEND_ADDED_WIDGETS_SUCCESS,
    payload: data,
});

export const sendAddedWidgetsError = (error) => ({
    type: types.SEND_ADDED_WIDGETS_ERROR,
    payload: error,
});
