export const permissionsMatrix = {
    LYT_ADMIN: {
        id: '4',
        name_role: 'LYT_Admin',
        CREATE_BUSINESS_RULE: true,
        EDIT_SAVE_BUSINESS_RULE: true,
        DELETE_BUSINESS_RULE: true,
        VIEW_BUSINESS_RULE: true,
        MOVE_AGENCY_OWNER_TO_ANOTHER_USER: true,
        AUTHORIZE_ADDITIONAL_AGENCY_ADMINS: true,
        DOWNGRADE_ADMIN_TO_AGENCY_VIEWER: true,
        APPROVE_NEW_AGENCY_VIEWERS: true,
        REMOVE_AGENCY_VIEWERS: true,
        INVITE_NEW_AGENCY_VIEWERS: true,
        CREATE_NEW_GROUP: true,
        EDIT_GROUP: true,
        DELETE_GROUP: true,
        ASSIGN_USER_TO_GROUP: true,
        DELETE_USER_FROM_GROUP: true,
        VIEW_USERS_IN_GROUP: true
    },
    LYT_EMPLOYEE: {
        id: '5',
        name_role: 'LYT_Employee',
        CREATE_BUSINESS_RULE: false,
        EDIT_SAVE_BUSINESS_RULE: false,
        DELETE_BUSINESS_RULE: false,
        VIEW_BUSINESS_RULE: true,
        MOVE_AGENCY_OWNER_TO_ANOTHER_USER: false,
        AUTHORIZE_ADDITIONAL_AGENCY_ADMINS: false,
        DOWNGRADE_ADMIN_TO_AGENCY_VIEWER: false,
        APPROVE_NEW_AGENCY_VIEWERS: false,
        REMOVE_AGENCY_VIEWERS: false,
        INVITE_NEW_AGENCY_VIEWERS: true,
        CREATE_NEW_GROUP: false,
        EDIT_GROUP: false,
        DELETE_GROUP: false,
        ASSIGN_USER_TO_GROUP: false,
        DELETE_USER_FROM_GROUP: false,
        VIEW_USERS_IN_GROUP: true
    },
    AGENCY_OWNER: {
        id: '1',
        name_role: 'Agency_Owner',
        CREATE_BUSINESS_RULE: true,
        EDIT_SAVE_BUSINESS_RULE: true,
        DELETE_BUSINESS_RULE: true,
        VIEW_BUSINESS_RULE: true,
        MOVE_AGENCY_OWNER_TO_ANOTHER_USER: true,
        AUTHORIZE_ADDITIONAL_AGENCY_ADMINS: true,
        DOWNGRADE_ADMIN_TO_AGENCY_VIEWER: true,
        APPROVE_NEW_AGENCY_VIEWERS: true,
        REMOVE_AGENCY_VIEWERS: true,
        INVITE_NEW_AGENCY_VIEWERS: true,
        CREATE_NEW_GROUP: true,
        EDIT_GROUP: true,
        DELETE_GROUP: true,
        ASSIGN_USER_TO_GROUP: true,
        DELETE_USER_FROM_GROUP: true,
        VIEW_USERS_IN_GROUP: true
    },
    AGENCY_ADMIN: {
        id: '2',
        name_role: 'Agency_Admin',
        CREATE_BUSINESS_RULE: false,
        EDIT_SAVE_BUSINESS_RULE: false,
        DELETE_BUSINESS_RULE: false,
        VIEW_BUSINESS_RULE: true,
        MOVE_AGENCY_OWNER_TO_ANOTHER_USER: false,
        AUTHORIZE_ADDITIONAL_AGENCY_ADMINS: false,
        DOWNGRADE_ADMIN_TO_AGENCY_VIEWER: false,
        APPROVE_NEW_AGENCY_VIEWERS: true,
        REMOVE_AGENCY_VIEWERS: true,
        INVITE_NEW_AGENCY_VIEWERS: true,
        CREATE_NEW_GROUP: true,
        EDIT_GROUP: true,
        DELETE_GROUP: true,
        ASSIGN_USER_TO_GROUP: true,
        DELETE_USER_FROM_GROUP: true,
        VIEW_USERS_IN_GROUP: true
    },
    AGENCY_VIEWER: {
        id: '3',
        name_role: 'Agency_Viewer',
        CREATE_BUSINESS_RULE: false,
        EDIT_SAVE_BUSINESS_RULE: false,
        DELETE_BUSINESS_RULE: false,
        VIEW_BUSINESS_RULE: true,
        MOVE_AGENCY_OWNER_TO_ANOTHER_USER: false,
        AUTHORIZE_ADDITIONAL_AGENCY_ADMINS: false,
        DOWNGRADE_ADMIN_TO_AGENCY_VIEWER: false,
        APPROVE_NEW_AGENCY_VIEWERS: false,
        REMOVE_AGENCY_VIEWERS: false,
        INVITE_NEW_AGENCY_VIEWERS: true,
        CREATE_NEW_GROUP: false,
        EDIT_GROUP: false,
        DELETE_GROUP: false,
        ASSIGN_USER_TO_GROUP: false,
        DELETE_USER_FROM_GROUP: false,
        VIEW_USERS_IN_GROUP: true
    }
};
