
export const PERIOD_LIST = [
    { value: 'LAST_24_HOURS', label: 'Last 24 hours' },
    { value: 'LAST_7_DAYS', label: 'Last 7 days' },
    { value: 'LAST_30_DAYS', label: 'Last 30 days' },
];

export const SORT_OPTIONS = [
    { value: 'STOP_ORDER', label: 'Intersection Order'},
    { value: 'INCREASING_WAIT_TIME', label: 'Delay (Increasing)'},
    { value: 'DECREASING_WAIT_TIME', label: 'Delay (Decreasing)'},
    { value: 'ALPHABETICAL', label: 'Alphabetical'},
];

export const testArr = [
    { x: 13, y: 0 },
    { x: 11.9, y: 1 },
    { x: 10.2, y: 2 },
    { x: 8.9, y: 3 },
    { x: 7.5, y: 4 },
    { x: 6.3, y: 5 },
    { x: 4, y: 6 },
    { x: 3, y: 7 },
    { x: 2.5, y: 8 },
];
