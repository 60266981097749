/* eslint-disable space-before-function-paren */
/* eslint-disable keyword-spacing */
import { toast } from 'react-toastify';

import * as actions from './requests.actions';
import * as requests from '../../../services/requests/users-and-permissions/requests/requests';
import { getCitiesOperation } from '../../../store/cities-and-agencies/cities-and-agencies.operations';
import { citiesSelector } from '../../../store/cities-and-agencies/cities-and-agencies.selectors';
import { isAdmin, isLYTAdmin } from '../../../store/user/user.utils';
import { getUserAgencyId } from '../../../services/requests/request.utils';

export const getRequestsListOperation = () => (dispatch, getState) => {
    dispatch(actions.getRequestsListRequest());

    return requests
        .getRequestsLists({userIsLytAdmin: isLYTAdmin()})
        .then(async (response) => {
            const { list } = citiesSelector(getState());

            if (!list.length) {
                await dispatch(getCitiesOperation());
            }

            dispatch(actions.getRequestsListSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getRequestsListError(error));
        });
};

export const sendApproveRequestOperation = (params) => (dispatch) => requests
    .approveRequest(params)
    .then(() => {
        dispatch(actions.sendApproveRequestSuccess(params.user_id));
        toast.success('Request was successfully approved!');
        dispatch(getRequestsListOperation());
    })
    .catch((error) => {
        toast.error(error.message);
    });

export const sendRejectRequestOperation = (userID) => (dispatch) => {
    const temporaryRequestTemplate = {
        // It is need for BE part...
        user_id: String(userID),
        acceptPlace: {
            agencies_id: getUserAgencyId(),
            cities_id: [],
            agencies_allow_id: [],
        },
        isReject: true,
    };

    return requests
        .approveRequest(temporaryRequestTemplate)
        .then(() => {
            toast.success('Request was successfully rejected!');
            dispatch(actions.sendRejectRequestSuccess(userID));
        })
        .catch((error) => {
            // dispatch(actions.getRequestsListError(error));
            toast.error(error.message);
        });
};
