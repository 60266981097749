export const types = {
    // chart expand/collapse status
    GET_CHARTS_EXPANSION_STATUS:
        'routes/GET_CHARTS_EXPANSION_STATUS',
    SET_CHARTS_EXPANDED:
        'routes/SET_CHARTS_EXPANDED',
    SET_CHARTS_COLLAPSED:
        'routes/SET_CHARTS_COLLAPSED',

    // get all routes for current agency
    GET_AGENCY_ROUTES_REQUEST:
        'routes/GET_AGENCY_ROUTES_REQUEST',
    GET_AGENCY_ROUTES_SUCCESS:
        'routes/GET_AGENCY_ROUTES_SUCCESS',
    GET_AGENCY_ROUTES_ERROR:
        'routes/GET_AGENCY_ROUTES_ERROR',

    // Traffic lights operation data & on time performance
    GET_TL_AND_ON_TIME_PERFORMANCE_REQUEST:
        'routes/GET_TL_AND_ON_TIME_PERFORMANCE_REQUEST',
    GET_TL_AND_ON_TIME_PERFORMANCE_SUCCESS:
        'routes/GET_TL_AND_ON_TIME_PERFORMANCE_SUCCESS',
    GET_TL_AND_ON_TIME_PERFORMANCE_ERROR:
        'routes/GET_TL_AND_ON_TIME_PERFORMANCE_ERROR',
    SET_TL_AND_ON_TIME_PERFORMANCE_FILTERS:
        'routes/SET_TL_AND_ON_TIME_PERFORMANCE_FILTERS',

    // TSP activity
    GET_TSP_ACTIVITY_REQUEST: 'routes/GET_TSP_ACTIVITY_REQUEST',
    GET_TSP_ACTIVITY_SUCCESS: 'routes/GET_TSP_ACTIVITY_SUCCESS',
    GET_TSP_ACTIVITY_ERROR: 'routes/GET_TSP_ACTIVITY_ERROR',
    SET_TSP_ACTIVITY_FILTERS: 'routes/SET_TSP_ACTIVITY_FILTERS',

    // Routes links
    GET_ROUTES_STOPS_REQUEST: 'routes/GET_ROUTES_STOPS_REQUEST',
    GET_ROUTES_STOPS_SUCCESS: 'routes/GET_ROUTES_STOPS_SUCCESS',
    GET_ROUTES_STOPS_ERROR: 'routes/GET_ROUTES_STOPS_ERROR',

    // Routes performance
    GET_ROUTES_PERFORMANCE_REQUEST: 'routes/GET_ROUTES_PERFORMANCE_REQUEST',
    GET_ROUTES_PERFORMANCE_SUCCESS: 'routes/GET_ROUTES_PERFORMANCE_SUCCESS',
    GET_ROUTES_PERFORMANCE_ERROR: 'routes/GET_ROUTES_PERFORMANCE_ERROR',
    SET_ROUTES_PERFORMANCE_FILTERS: 'routes/SET_ROUTES_PERFORMANCE_FILTERS',

    // Priority requests
    GET_PRIORITY_REQUESTS_REQUEST: 'routes/GET_PRIORITY_REQUESTS_REQUEST',
    GET_PRIORITY_REQUESTS_SUCCESS: 'routes/GET_PRIORITY_REQUESTS_SUCCESS',
    GET_PRIORITY_REQUESTS_ERROR: 'routes/GET_PRIORITY_REQUESTS_ERROR',
    SET_PRIORITY_REQUESTS_FILTERS: 'routes/SET_PRIORITY_REQUESTS_FILTERS',

    // Route details
    GET_ROUTE_DETAILS_REQUEST: 'routes/GET_ROUTE_DETAILS_REQUEST',
    GET_ROUTE_DETAILS_SUCCESS: 'routes/GET_ROUTE_DETAILS_SUCCESS',
    GET_ROUTE_DETAILS_ERROR: 'routes/GET_ROUTE_DETAILS_ERROR',
    SET_ROUTE_DETAILS_FILTERS: 'routes/SET_ROUTE_DETAILS_FILTERS',
    RESET_ROUTE_DETAILS: 'routes/RESET_ROUTE_DETAILS',

    // Headway
    GET_HEADWAY_REQUEST: 'routes/GET_HEADWAY_REQUEST',
    GET_HEADWAY_SUCCESS: 'routes/GET_HEADWAY_SUCCESS',
    GET_HEADWAY_ERROR: 'routes/GET_HEADWAY_ERROR',
    SET_HEADWAY_FILTERS: 'routes/SET_HEADWAY_FILTERS',
    SET_HEADWAY_DIRECTIONS: 'routes/SET_HEADWAY_DIRECTIONS',

    // Route performance by stop
    GET_ROUTE_PERFORMANCE_BY_STOP_REQUEST:
        'routes/GET_ROUTE_PERFORMANCE_BY_STOP_REQUEST',
    GET_ROUTE_PERFORMANCE_BY_STOP_SUCCESS:
        'routes/GET_ROUTE_PERFORMANCE_BY_STOP_SUCCESS',
    GET_ROUTE_PERFORMANCE_BY_STOP_ERROR:
        'routes/GET_ROUTE_PERFORMANCE_BY_STOP_ERROR',
    SET_ROUTE_PERFORMANCE_BY_STOP_FILTERS:
        'routes/SET_ROUTE_PERFORMANCE_BY_STOP_FILTERS',
    SET_ROUTE_PERFORMANCE_BY_STOP_DIRECTIONS:
        'routes/SET_ROUTE_PERFORMANCE_BY_STOP_DIRECTIONS',


    // Travel time
    GET_TRAVEL_TIME_REQUEST: 'routes/GET_TRAVEL_TIME_REQUEST',
    GET_TRAVEL_TIME_SUCCESS: 'routes/GET_TRAVEL_TIME_SUCCESS',
    GET_TRAVEL_TIME_ERROR: 'routes/GET_TRAVEL_TIME_ERROR',
    SET_TRAVEL_TIME_FILTERS: 'routes/SET_TRAVEL_TIME_FILTERS',

    // Red light delay
    GET_RED_LIGHT_WAIT_TIME_REQUEST: 'routes/GET_RED_LIGHT_WAIT_TIME_REQUEST',
    GET_RED_LIGHT_WAIT_TIME_SUCCESS: 'routes/GET_RED_LIGHT_WAIT_TIME_SUCCESS',
    GET_RED_LIGHT_WAIT_TIME_ERROR: 'routes/GET_RED_LIGHT_WAIT_TIME_ERROR',
    SET_RED_LIGHT_WAIT_TIME_FILTERS: 'routes/SET_RED_LIGHT_WAIT_TIME_FILTERS',

    // Bus stop dwell time
    GET_BUS_STOP_DWELL_TIME_REQUEST: 'routes/GET_BUS_STOP_DWELL_TIME_REQUEST',
    GET_BUS_STOP_DWELL_TIME_SUCCESS: 'routes/GET_BUS_STOP_DWELL_TIME_SUCCESS',
    GET_BUS_STOP_DWELL_TIME_ERROR: 'routes/GET_BUS_STOP_DWELL_TIME_ERROR',
    SET_BUS_STOP_DWELL_TIME_FILTERS: 'routes/SET_BUS_STOP_DWELL_TIME_FILTERS',

    // Average vehicle speed
    GET_AVERAGE_VEHICLE_SPEED_REQUEST:
        'routes/GET_AVERAGE_VEHICLE_SPEED_REQUEST',
    GET_AVERAGE_VEHICLE_SPEED_SUCCESS:
        'routes/GET_AVERAGE_VEHICLE_SPEED_SUCCESS',
    GET_AVERAGE_VEHICLE_SPEED_ERROR: 'routes/GET_AVERAGE_VEHICLE_SPEED_ERROR',
    SET_AVERAGE_VEHICLE_SPEED_FILTERS:
        'routes/SET_AVERAGE_VEHICLE_SPEED_FILTERS',

    // Segment travel time
    GET_SEGMENT_TRAVEL_TIME_REQUEST: 'routes/GET_SEGMENT_TRAVEL_TIME_REQUEST',
    GET_SEGMENT_TRAVEL_TIME_SUCCESS: 'routes/GET_SEGMENT_TRAVEL_TIME_SUCCESS',
    GET_SEGMENT_TRAVEL_TIME_ERROR: 'routes/GET_SEGMENT_TRAVEL_TIME_ERROR',
    SET_SEGMENT_TRAVEL_TIME_FILTERS: 'routes/SET_SEGMENT_TRAVEL_TIME_FILTERS',
    SET_SEGMENT_TRAVEL_TIME_DIRECTIONS: 'routes/SET_SEGMENT_TRAVEL_TIME_DIRECTIONS',
};
