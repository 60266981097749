import * as actions from "./settings.actions";
import * as requests from "../../services/requests/settings/settings";

export const getWidgetsListsOperation = () => (dispatch) => {
    dispatch(actions.getAllWidgetsListRequest());

    Promise.allSettled([
        requests.getAllWidgetsListRequest(),
        requests.getAddedWidgetsListRequest(),
    ])
        .then((response) => {
            dispatch(actions.getAddedWidgetsListSuccess(response[1]?.value));
            dispatch(actions.getAllWidgetsListSuccess(response[0]?.value));
        })
        .catch((error) => {
            dispatch(actions.getAllWidgetsListError(error));
            dispatch(actions.getAddedWidgetsListError(error));
        });
};

export const sendAddedWidgetsOperation =
    (data, ...callbacksArgs) =>
    (dispatch) => {
        dispatch(actions.sendAddedWidgetsRequest());
        return requests
            .sendAddedWidgets(data)
            .then((response) => {
                dispatch(actions.sendAddedWidgetsSuccess(response));
                callbacksArgs.forEach((callbackItem) => callbackItem());
            })
            .catch(({ message }) => {
                dispatch(actions.sendAddedWidgetsError(message));
            });
    };
