import { useSelector, useDispatch } from "react-redux";
import { userThemeSelector } from "../../../../store/user/user.selectors";
import { setUserTheme } from "../../../../store/user/user.actions";

import SvgIcon from "../../../../components/ui-core/svg-icon";

import styles from "./styles.scss";

const ThemeSwitch = () => {
    const theme = useSelector(userThemeSelector);
    const dispatch = useDispatch();

    return (
        <div
            className={styles["login-theme-switch"]}
            onClick={() => {
                if (theme === "theme-light") {
                    return dispatch(setUserTheme("theme-dark"));
                }
                dispatch(setUserTheme("theme-light"));
            }}
        >
            {theme === "theme-dark" && (
                <div className={styles[`login-theme-switch--sun`]}>
                    <SvgIcon type="switch-sun" />
                </div>
            )}
            {theme === "theme-light" && (
                <div className={styles[`login-theme-switch--moon`]}>
                    <SvgIcon type="switch-moon" />
                </div>
            )}
        </div>
    );
};

export default ThemeSwitch;
