import { combineReducers } from 'redux';

import users from './users/users.reducer';
import groups from './groups/groups.reducer';
import requests from './requests/requests.reducer';
import roles from './roles/roles.reducer';

const usersAndPermissions = combineReducers({
    users,
    requests,
    groups,
    roles,
});

export default usersAndPermissions;
