/* eslint-disable keyword-spacing */
import * as actions from './roles.actions';
import * as requests from '../../../services/requests/users-and-permissions/roles/roles';
import { isLYTAdmin } from '../../../store/user/user.utils';

export const getRolesTypesListsOperation = () => (dispatch) => {
    dispatch(actions.getRolesTypesListRequest());
    return requests
        .getRolesTypesListsRequest()
        .then((response) => {
            if (!isLYTAdmin()) {
                const filteredRoles = response.filter(r => !r.name_role.includes('LYT'));
                response = filteredRoles;
            }
            dispatch(actions.getRolesTypesListSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getRolesTypesListError(error.message));
        });
};

export const getAccessSettingsByTypeOperation = (params) => (dispatch) => {
    dispatch(actions.getAccessSettingsByTypeRequest());

    return requests
        .getAccessSettingsByTypeRequest(params)
        .then((response) => {
            dispatch(actions.getAccessSettingsByTypeSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getAccessSettingsByTypeError(error.message));
        });
};
