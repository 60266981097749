export const OPTION_TYPES = {
    OVERALL_PERFORMANCE: 'overall-performance',
    PRIMARY: 'primary',
    USER_CARD: 'user-card',
};

export const SECOND_PERIOD_OPTIONS = [
    {
        value: 'All Day',
        label: 'All Day',
    },
    {
        value: 'AM Peak (6-9 AM)',
        label: 'AM Peak (6-9 AM)',
    },
    {
        value: 'Early Morning (12-6 AM)',
        label: 'Early Morning (12-6 AM)',
    },
    {
        value: 'Evening (6PM-12AM)',
        label: 'Evening (6PM-12AM)',
    },
    {
        value: 'Midday (9AM - 3PM)',
        label: 'Midday (9AM - 3PM)',
    },
    {
        value: 'PM Peak (3-6PM)',
        label: 'PM Peak (3-6PM)',
    },
];

export const PERIOD_OPTIONS = [
    { value: 'LAST_24_HOURS', label: 'Last 24 hours' },
    { value: 'LAST_7_DAYS', label: 'Last 7 days' },
    { value: 'LAST_30_DAYS', label: 'Last 30 days' },
];
