/* eslint-disable no-unused-vars */
/* eslint-disable space-before-function-paren */
import * as actions from './routes.actions';
import * as requests from '../../services/requests/routes/routes';

export const getAgencyRoutesOperation
    = () =>
        async (dispatch) => {
            dispatch(actions.getAgencyRoutesRequest());

            try {
                const response = await requests
                    .getAgencyRoutes();
                dispatch(actions.getAgencyRoutesSuccess(response));
            } catch(error) {
                dispatch(actions.getAgencyRoutesError(String(error.message)));
            }
        };

export const getTLAndOnTimePerformanceOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getTLAndOnTimePerformanceRequest());

            try {
                const response = await requests
                    .getTrafficLightsOperationDataAndOnTimePerformanceRequest(params);
                dispatch(actions.getTLAndOnTimePerformanceSuccess(response));
            } catch(error) {
                dispatch(actions.getTLAndOnTimePerformanceError(String(error.message)));
            }
        };

export const getTSPActivityOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getTSPActivityRequest());

            try {
                const response = await requests
                    .getTSPActivityRequest(params);
                dispatch(
                    actions.getTSPActivitySuccess(response.result));
            } catch(error) {
                dispatch(actions.getTSPActivityError(String(error.message)));
            }
        };

export const getRoutesStopsOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getRoutesStopsRequest());

            try {
                const response = await requests
                    .getRoutesStopsRequest(params);
                dispatch(actions.getRoutesStopsSuccess(response));
            } catch(error) {
                dispatch(actions.getRoutesStopsError(String(error.message)));
            }
        };

export const getRoutesPerformanceOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getRoutesPerformanceRequest());

            try {
                const response = await requests
                    .getRoutePerformanceRequest(params);
                dispatch(actions.getRoutesPerformanceSuccess(response.result));
            } catch(error) {
                dispatch(actions.getRoutesPerformanceError(String(error.message)));
            }
        };

export const getPriorityRequestsOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getPriorityRequestsRequest());
            const mockData = [
                {
                    vehicle_id: '1984',
                    intersection: 'Powel & Milwaukie',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '7189',
                    intersection: 'SE Division Pl & SE 8th Ave',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '2718',
                    intersection: 'NW Division St & NW Angeline Ave',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '3681',
                    intersection: 'SE Division St & SE 174th Ave',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '1093',
                    intersection: 'SE Division St & NW Eastwood Ave ',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '1984',
                    intersection: 'Powel & Milwaukie',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '7189',
                    intersection: 'SE Division Pl & SE 8th Ave',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '2718',
                    intersection: 'NW Division St & NW Angeline Ave',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '3681',
                    intersection: 'SE Division St & SE 174th Ave',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '1093',
                    intersection: 'SE Division St & NW Eastwood Ave ',
                    date: '2021-08-02 8:12:19 AM',
                },
                {
                    vehicle_id: '3681',
                    intersection: 'SE Division St & SE 170th Ave',
                    date: '2021-08-02 8:12:19 AM',
                },
            ];

            try {
                const response = await requests
                    .getRouteTSPRequestsRequest(params);
                dispatch(actions.getPriorityRequestsSuccess(mockData));
            } catch(error) {
                dispatch(actions.getPriorityRequestsError(String(error.message)));
            }
        };

export const getRouteDetailsOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getRouteDetailsRequest());

            try {
                const response = await requests
                    .getRouteDetailsRequest(params);
                dispatch(actions.getRouteDetailsSuccess(response));
            } catch(error) {
                dispatch(actions.getRouteDetailsError(String(error.message)));
            }
        };

export const getHeadwayOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getHeadwayRequest());

            try {
                const response = await requests // replace mockData below with response
                    .getHeadwayRequest(params);
                dispatch(actions.getHeadwaySuccess(response));
            } catch(error) {
                dispatch(actions.getHeadwayError(String(error.message)));
            }
        };

export const getRoutePerformanceByStopOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getRoutePerformanceByStopRequest());

            try {
                const response = await requests
                    .getRoutePerformanceByStopRequest(params);
                dispatch(actions.getRoutePerformanceByStopSuccess(response));
            } catch(error) {
                dispatch(actions.getRoutePerformanceByStopError(String(error.message)));
            }
        };

export const getTravelTimeOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getTravelTimeRequest());

            try {
                const response = await requests // replace mockData below with response
                    .getTravelTimeRequest(params);
                dispatch(actions.getTravelTimeSuccess(response));
            } catch(error) {
                dispatch(actions.getTravelTimeError(String(error.message)));
            }
        };

export const getRedLightWaitTimeOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getRedLightWaitTimeRequest());

            const mockData = [
                { x: 14, y: 0 },
                { x: 2, y: 1 },
                { x: 9, y: 2 },
                { x: 3, y: 3 },
                { x: 2, y: 4 },
                { x: 7, y: 5 },
                { x: 16, y: 6 },
                { x: 12, y: 7 },
                { x: 16, y: 8 },
            ];

            try {
                const response = await requests // replace mockData below with response
                    .getRedLightWaitTimeRequest(params);
                dispatch(actions.getRedLightWaitTimeSuccess(mockData));
            } catch(error) {
                dispatch(actions.getRedLightWaitTimeError(String(error.message)));
            }
        };

export const getBusStopDwellTimeOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getBusStopDwellTimeRequest());

            try {
                const response = await requests
                    .getBusStopDwellTimeRequest(params);
                dispatch(actions.getBusStopDwellTimeSuccess(response));
            } catch(error) {
                dispatch(actions.getBusStopDwellTimeError(String(error.message)));
            }
        };

export const getSegmentTravelTimeOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getSegmentTravelTimeRequest());

            try {
                const response = await requests // replace mockData below with response
                    .getSegmentTravelTimeRequest(params);
                dispatch(actions.getSegmentTravelTimeSuccess(response));
            } catch(error) {
                dispatch(actions.getSegmentTravelTimeError(String(error.message)));
            }
        };

export const getAverageVehicleSpeedOperation
    = (params = {}) =>
        async (dispatch) => {
            dispatch(actions.getAverageVehicleSpeedRequest());

            try {
                const response = await requests
                    .getAverageVehicleSpeedRequest(params);
                dispatch(actions.getAverageVehicleSpeedSuccess(response));
            } catch(error) {
                dispatch(actions.getAverageVehicleSpeedError(String(error.message)));
            }
        };
