import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

const SkeletonUsersAndPermissionsGroups = ({ colors }) => {
    return (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={213}
            backgroundColor={colors.background}
            foregroundColor={colors.foreground}
        >
            <rect x="0" y="0" rx="15" ry="15" width="100%" height="213" />
        </ContentLoader>
    );
};

SkeletonUsersAndPermissionsGroups.propTypes = {
    colors: PropTypes.shape({
        background: PropTypes.string.isRequired,
        foreground: PropTypes.string.isRequired,
    }),
};
export default SkeletonUsersAndPermissionsGroups;
