import dayjs from "dayjs";

export const setActiveType = (startDate, endDate) => {
    const intervalsTypes = {
        7: "1-week",
        30: "1-month",
        90: "3-months",
        100000: "forever",
    };

    const start_date = dayjs(startDate);
    const end_date = dayjs(endDate);
    const differenceOfDays = end_date.diff(start_date, "days") + 1;

    return intervalsTypes[differenceOfDays] || "";
};

export const getIntervalDateValue = (interval) => {
    const currentDate = new Date();

    switch (interval) {
        case "1-week":
            return {
                start_date: currentDate,
                end_date: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 7
                ),
            };
        case "1-month":
            return {
                start_date: currentDate,
                end_date: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 30
                ),
            };
        case "3-months":
            return {
                start_date: currentDate,
                end_date: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 90
                ),
            };
        case "forever":
            return {
                start_date: currentDate,
                end_date: new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 100000
                ),
            };
    }
};
