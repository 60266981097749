import { types } from './spm.types';

const initialSate = {
    liveModeStatus: 'on',
    daterangeStatus: {
        from: null,
        to: null
    },
    intersections: {
        data: [],
        loading: false,
        error: null,
    },
    intersection: {
        id: null,
        pagination: {},
        data: {},
        loading: false,
        error: null,
    },
    history: {
        data: [],
        loading: false,
        error: null,
    },
    liveData: {
        data: []
    }
};

const intersectionSPM = (state = initialSate, { type, payload }) => {
    switch (type) {
    // intersections list

    case types.GET_INTERSECTIONS_LIST_REQUEST:
        return {
            ...state,
            intersections: {
                ...state.intersections,
                loading: true,
            },
        };

    case types.GET_INTERSECTIONS_LIST_SUCCESS:
        return {
            ...state,
            intersections: {
                ...state.intersections,
                ...payload,
                loading: false,
            },
        };

    case types.GET_INTERSECTIONS_LIST_ERROR:
        return {
            ...state,
            intersections: {
                ...state.intersections,
                error: payload,
                loading: false,
            },
        };

        // intersection by id

    case types.GET_INTERSECTION_BY_ID_REQUEST:
        return {
            ...state,
            intersection: {
                ...state.intersection,
                loading: true,
                id: payload
            },
        };

    case types.GET_INTERSECTION_BY_ID_SUCCESS:
        return {
            ...state,
            intersection: {
                ...state.intersection,
                data: payload,
                loading: false,
            },
        };

    case types.GET_INTERSECTION_BY_ID_ERROR:
        return {
            ...state,
            intersection: {
                ...state.intersection,
                error: payload,
                loading: false,
            },
        };

    case types.CLEAR_INTERSECTION_DATA:
        return {
            ...state,
            intersection: {
                ...initialSate.intersection,
            },
        };

    case types.GET_INTERSECTION_HISTORY_REQUEST:
        return {
            ...state,
            history: {
                ...state.history,
                loading: true,
            },
        };

    case types.GET_INTERSECTION_HISTORY_SUCCESS:
        return {
            ...state,
            history: {
                ...state.history,
                data: payload,
                loading: false,
            },
        };

    case types.GET_INTERSECTION_HISTORY_ERROR:
        return {
            ...state,
            history: {
                ...state.history,
                error: payload,
                loading: false,
            },
        };

    case types.SET_LIVE_DATA:
        return {
            ...state,
            liveData: {
                ...state.liveData,
                data: payload,
            }
        };

    case types.GET_INFORMATION_BY_DATE_AND_TIME_REQUEST:
        return {
            ...state,
            intersection: {
                ...state.intersection,
                loading: true,
            },
        };

    case types.GET_INFORMATION_BY_DATE_AND_TIME_SUCCESS:
        return {
            ...state,
            intersection: {
                ...state.intersection,
                data: payload,
                loading: false,
            },
        };

    case types.GET_INFORMATION_BY_DATE_AND_TIME_ERROR:
        return {
            ...state,
            intersection: {
                ...state.intersection,
                error: payload,
                loading: false,
            },
        };

    case types.SET_LIVE_MODE_STATUS:
        return {
            ...state,
            liveModeStatus: payload,
        };

    case types.SET_DATERANGE_STATUS:
        return {
            ...state,
            daterangeStatus: { from: payload.from, to: payload.to }
        };

    default:
        return state;
    }
};

export default intersectionSPM;
