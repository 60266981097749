import ContentLoader from "react-content-loader";

const SkeletonMapRouteDetails = ({ colors }) => {
    return (
        <ContentLoader
            speed={1}
            width={'100%'}
            height={40}
            backgroundColor={colors.background}
            foregroundColor={colors.foreground}
        >
            <rect x="0" y="0" rx="15" ry="15" width="100%" height="30" />
        </ContentLoader>
    );
};

export default SkeletonMapRouteDetails;
 