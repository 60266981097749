/* eslint-disable keyword-spacing */
import { useEffect, useRef, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth0 } from '@auth0/auth0-react';

import { toast } from 'react-toastify';

import { bookList } from '../../../../constants/bookList';
import { userPersonalInformationSelector } from '../../../../store/user/user.selectors';
import { setUserName, clearUserStoredInformation } from '../../../../store/user/user.actions';
import { clearSession } from '../../../../routes/auth/auth.actions';


import { getUserPersonalInformation } from '../../../../store/user/user.operations';
import { INFORMATION_TYPES, STATIC_USER_INFORMATION } from './profile.config';

import Avatar from '../../../ui-core/avatar';
import SvgIcon from '../../../ui-core/svg-icon';

import styles from './styles.scss';

const Profile = () => {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const [isVisibleOptions, changeVisibleStatus] = useState(false);
    const { logout } = useAuth0();

    const { username, usertype, firstname, lastname, picture, loading } = useSelector(userPersonalInformationSelector);

    useEffect(() => {
        dispatch(getUserPersonalInformation());

        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    useEffect(() => {
        if (!username || username.length <= 0) {
            if (firstname && lastname) {
                if (firstname.length > 0 && lastname.length > 0) {
                    const firstCap = firstname.charAt(0).toUpperCase();
                    const lastCap = lastname.charAt(0).toUpperCase();
                    dispatch(setUserName(`${firstCap + firstname.slice(1)} ${lastCap}${lastname.slice(1)}`));
                }
            }
        }
    }, [firstname, lastname, username, loading]);

    const validateUserDisplayData = (type = '') => {
        const defaultName = STATIC_USER_INFORMATION.NAME;
        const defaultType = STATIC_USER_INFORMATION.TYPE;

        switch (type) {
        case INFORMATION_TYPES.NAME:
            return username ? username : defaultName;
        case INFORMATION_TYPES.TYPE:
            return usertype ? `${usertype.replace(/_/g, ' ')}` : defaultType;
        }
    };

    const handleOutsideClick = ({ target }) => {
        if (wrapperRef.current && !wrapperRef.current.contains(target)) {
            changeVisibleStatus(false);
        }
    };

    const handleLogoutClick = () => {
        changeVisibleStatus(false);
        dispatch(clearSession());
        dispatch(clearUserStoredInformation());
        toast.success('Logged out successfully', {autoClose: 1000});
        logout({ logoutParams: { returnTo: `${window.location.origin}${bookList.auth.login}` } });
    };

    return (
        <div className={styles.profile}>
            <div className={styles['profile__img-container']} onClick={() => changeVisibleStatus(!isVisibleOptions)}>
                <Avatar
                    size="md"
                    name={validateUserDisplayData(INFORMATION_TYPES.NAME)}
                    logo={picture ? picture : ''}
                />
            </div>

            <div className={`${styles.profile__info} profile__info--g`}>
                <p className={styles.profile__name}>
                    {validateUserDisplayData(INFORMATION_TYPES.NAME)}
                </p>
                <p className={styles.profile__type}>
                    {validateUserDisplayData(INFORMATION_TYPES.TYPE)}
                </p>
            </div>

            <div
                ref={wrapperRef}
                className={`${styles.profile__action} ${isVisibleOptions ? styles['profile__action--active'] : null
                }`}
            >
                <div onClick={() => changeVisibleStatus(!isVisibleOptions)}>
                    <SvgIcon type="options" />
                </div>

                {isVisibleOptions
                    && <ul className={styles['profile__options-list']}>
                        <li
                            onClick={handleLogoutClick}
                            className={styles['profile__options-item']}
                        >
                            Logout
                        </li>
                    </ul>
                }
            </div>
        </div>
    );
};

export default Profile;
