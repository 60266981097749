/* eslint-disable space-before-function-paren */
/* eslint-disable keyword-spacing */
import { get } from '../../../api';
import { getUserAgencyId } from '../../request.utils';
import { permissionsMatrix } from '../../../../components/permission/permissions-matrix';
import { ROLE_GROUPS } from '../../../../constants/roles';

export const getRolesTypesListsRequest = async () =>
    new Promise(resolve =>
        resolve(Object.keys(permissionsMatrix).map(key => (
            {name_role: permissionsMatrix[key].name_role, id: permissionsMatrix[key].id}
        )))
    );

export const getAccessSettingsByTypeRequest = async (params = {}) => {
    const { roles_name = '', roles_id } = params;
    const response = {
        groupRole: ROLE_GROUPS,
        name_role: roles_name.replace(/_/g, ' '),
        role_id: roles_id,
    };

    const nameRolePermissions = permissionsMatrix[roles_name.toUpperCase()];

    response.groupRole.forEach(group => {
        group.data.forEach(item => {
            item.isTrue = nameRolePermissions[item.key];
        });
    });
    return response;
};

