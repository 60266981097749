import { h } from "preact";
import PropTypes from "prop-types";

import styles from "./styles.scss";

const IntersectionSpmItem = ({
    listItem: { id, description, ...itemRest },
    handleClick,
}) => {
    return (
        <div
            key={id}
            className={styles["intersection-spm-item"]}
            onClick={() => handleClick({ id, description, ...itemRest })}
        >
            <p className={styles["intersection-spm-item__primary"]}>
                {description}
            </p>
            <p className={styles["intersection-spm-item__secondary"]}>
                ID {id}
            </p>
        </div>
    );
};

IntersectionSpmItem.propTypes = {
    listItem: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default IntersectionSpmItem;
