import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

const SkeletonCriticalAlerts = ({ colors, ...props }) => (
    <ContentLoader
        speed={2}
        width={180}
        height={236}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <rect x="0" y="0" rx="15" ry="15" width="156" height="205" />
    </ContentLoader>
);

SkeletonCriticalAlerts.propTypes = {
    colors: PropTypes.object,
};

export default SkeletonCriticalAlerts;
