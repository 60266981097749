import { useMemo, useState } from "preact/hooks";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import { usersListSelector } from "../../../../routes/users-and-permissions/groups/groups.selectors";
import { createNewGroupOperation } from "../../../../routes/users-and-permissions/groups/groups.operations";
import { VIEW_TYPES_LIST } from "../../../ui-core/button/button.constants";
import { schema } from "./new-group.schema";
import { clearModalState } from "../../modal.actions";
import { DATA_LIST_ITEM_TYPES } from "../../../ui-core/inputs/list-with-search/list-with-search.constants";

import Button from "../../../ui-core/button";
import ListWithSearch from "../../../ui-core/inputs/list-with-search";
import SelectableList from "../../../ui-core/inputs/selectable-list";
import Field from "../../../ui-core/inputs/field";

import styles from "./styles.scss";

const NewGroup = () => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            name: "",
            users_id: [],
        },
        resolver: yupResolver(schema),
    });

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isWorking, setWorking] = useState(false);

    const dispatch = useDispatch();

    const users = useSelector(usersListSelector);

    const onSubmit = async (data) => {
        setWorking(true);

        try {
            await dispatch(createNewGroupOperation(data));
            dispatch(clearModalState());
            setWorking(false);
        } catch (error) {
            setWorking(false);
        }
    };


    const handleAddUser = (selectedUser) => {
        //is user already exists
        const isUser = selectedUsers.find(({ id }) => id === selectedUser.id);
        const cloneUsersIds = [...getValues().users_id];

        if (isUser) return;

        setSelectedUsers([...selectedUsers, selectedUser]);
        setValue("users_id", [...cloneUsersIds, selectedUser.id]);
    };

    const handleRemoveUser = (userId) => {
        const list = selectedUsers.filter(({ id }) => userId !== id);
        const cloneUsersIds = [...getValues().users_id];
        const newUsersIds = cloneUsersIds.filter((id) => userId !== id);

        setValue("users_id", newUsersIds);
        setSelectedUsers(list);
    };

    const normalizedListOfLytUsers = useMemo(() => users.reduce(
        (acc, { firstname, lastname, ...rest }) => {
            return [...acc, { value: `${firstname} ${lastname}`, ...rest }];
        }, []), [users],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles["new-group"]}>
            <p className={styles["new-group__title"]}>New Group</p>

            <div className={styles["new-group__item"]}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <Field
                            {...field}
                            errorText={""}
                            label={"Group name"}
                            placeholder="Enter group name"
                            type="text"
                        />
                    )}
                />
            </div>

            <div className={styles["new-group__item"]}>
                <ListWithSearch
                    itemType={DATA_LIST_ITEM_TYPES.SHARE_ALERTS}
                    dataList={normalizedListOfLytUsers}
                    placeholder="Search users"
                    isInitialOpenDropdown={false}
                    handleClick={handleAddUser}
                />
            </div>
            <div className={styles["new-group__item"]}>
                <SelectableList
                    list={selectedUsers}
                    handleDelete={handleRemoveUser}
                />
            </div>
            <div className={styles["new-group__buttons"]}>
                <div className={styles["new-group__button"]}>
                    <Button
                        handleClick={() => dispatch(clearModalState())}
                        label={"Cancel"}
                    />
                </div>
                <div className={styles["new-group__button"]}>
                    <Button
                        type={"submit"}
                        label={"Save group"}
                        viewType={VIEW_TYPES_LIST.PRIMARY}
                        loading={isWorking}
                        disabled={isWorking}
                    />
                </div>
            </div>
        </form>
    );
};

export default NewGroup;
