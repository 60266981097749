import {
    PERIOD_OPTIONS,
    SECOND_PERIOD_OPTIONS,
} from '../../components/ui-core/inputs/select/select.constants';
import { types } from './routes.types';

const initialSate = {
    tspStatistics: {
        tlAndOnTimePerformance: {
            data: [],
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
                secondPeriod: SECOND_PERIOD_OPTIONS[0].value,
            },
        },
        tspActivity: {
            data: [],
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
            },
        },
        routesPerformance: {
            list: [],
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
                secondPeriod: SECOND_PERIOD_OPTIONS[0].value,
            },
        },
    },
    routes: {
        priorityRequests: {
            list: null,
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
            },
        },

        chartsStatus: {
            expanded: true
        },

        routes: {
            data: null,
            loading: false,
            error: '',
        },

        routeStops: {
            data: null,
            loading: true,
            error: '',
        },

        routeDetails: {
            list: [],
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
                route: '',
            },
        },

        headway: {
            data: [],
            loading: true,
            error: '',
            directions: [],
            filters: {
                period: PERIOD_OPTIONS[0].value,
                secondPeriod: SECOND_PERIOD_OPTIONS[0].value,
                direction: '',
                route: ''
            },
        },

        routePerformanceByStop: {
            data: null,
            loading: true,
            error: '',
            directions: [],
            filters: {
                period: PERIOD_OPTIONS[0].value,
                secondPeriod: SECOND_PERIOD_OPTIONS[0].value,
                direction: '',
                route: ''
            },
        },

        travelTime: {
            data: null,
            average: 0,
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
                route: '',
                direction: '',
            },
        },

        redLightWaitTime: {
            data: null,
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
            },
        },

        busStopDwellTime: {
            data: null,
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
            },
        },

        averageVehicleSpeed: {
            data: null,
            loading: true,
            error: '',
            filters: {
                period: PERIOD_OPTIONS[0].value,
            },
        },

        segmentTravelTime: {
            data: null,
            loading: true,
            error: '',
            directions: [],
            filters: {
                period: PERIOD_OPTIONS[0].value,
                direction: '',
                route: ''
            },
        },
    },
};

const routes = (state = initialSate, { type, payload }) => {
    switch (type) {
    case types.SET_CHARTS_EXPANDED:
        return {
            ...state,
            routes: {
                ...state.routes,
                chartsStatus: {
                    ...state.routes.chartsStatus,
                    expanded: true,
                }
            }
        };
    case types.SET_CHARTS_COLLAPSED:
        return {
            ...state,
            routes: {
                ...state.routes,
                chartsStatus: {
                    ...state.routes.chartsStatus,
                    expanded: false,
                }
            }
        };
    case types.GET_AGENCY_ROUTES_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                routes: {
                    ...state.routes.routes,
                    loading: true,
                }
            }
        };
    case types.GET_AGENCY_ROUTES_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routes: {
                    ...state.routes.routes,
                    data: payload,
                    loading: false,
                    error: ''
                }
            }
        };
    case types.GET_AGENCY_ROUTES_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                routes: {
                    ...state.routes.routes,
                    loading: false,
                    error: payload
                }
            }
        };
    case types.GET_TL_AND_ON_TIME_PERFORMANCE_REQUEST:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tlAndOnTimePerformance: {
                    ...state.tspStatistics.tlAndOnTimePerformance,
                    loading: true,
                },
            },
        };
    case types.GET_TL_AND_ON_TIME_PERFORMANCE_SUCCESS:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tlAndOnTimePerformance: {
                    ...state.tspStatistics.tlAndOnTimePerformance,
                    loading: false,
                    data: payload,
                    error: ''
                },
            },
        };
    case types.SET_TL_AND_ON_TIME_PERFORMANCE_FILTERS:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tlAndOnTimePerformance: {
                    ...state.tspStatistics.tlAndOnTimePerformance,
                    filters: {
                        ...state.tspStatistics.tlAndOnTimePerformance
                            .filters,
                        ...payload,
                    },
                },
            },
        };
    case types.GET_TL_AND_ON_TIME_PERFORMANCE_ERROR:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tlAndOnTimePerformance: {
                    ...state.tspStatistics.tlAndOnTimePerformance,
                    loading: false,
                    error: payload,
                },
            },
        };

    case types.GET_TSP_ACTIVITY_REQUEST:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tspActivity: {
                    ...state.tspStatistics.tspActivity,
                    loading: true,
                },
            },
        };
    case types.GET_TSP_ACTIVITY_SUCCESS:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tspActivity: {
                    ...state.tspStatistics.tspActivity,
                    loading: false,
                    data: payload,
                    error: ''
                },
            },
        };
    case types.GET_TSP_ACTIVITY_ERROR:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tspActivity: {
                    ...state.tspStatistics.tspActivity,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_TSP_ACTIVITY_FILTERS:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                tspActivity: {
                    ...state.tspStatistics.tspActivity,
                    filters: {
                        ...state.tspStatistics.tspActivity.filters,
                        ...payload,
                    },
                },
            },
        };
    case types.GET_ROUTES_STOPS_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeStops: {
                    ...state.routes.routeStops,
                    loading: true,
                }
            }
        };
    case types.GET_ROUTES_STOPS_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeStops: {
                    ...state.routes.routeStops,
                    data: payload,
                    loading: false,
                }
            }
        };
    case types.GET_ROUTES_STOPS_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeStops: {
                    ...state.routes.routeStops,
                    loading: false,
                    error: payload,
                },
            },
        };

    case types.GET_ROUTES_PERFORMANCE_REQUEST:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                routesPerformance: {
                    ...state.tspStatistics.routesPerformance,
                    loading: true,
                },
            },
        };
    case types.GET_ROUTES_PERFORMANCE_SUCCESS:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                routesPerformance: {
                    ...state.tspStatistics.routesPerformance,
                    loading: false,
                    list: payload,
                    error: ''
                },
            },
        };
    case types.GET_ROUTES_PERFORMANCE_ERROR:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                routesPerformance: {
                    ...state.tspStatistics.routesPerformance,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_ROUTES_PERFORMANCE_FILTERS:
        return {
            ...state,
            tspStatistics: {
                ...state.tspStatistics,
                routesPerformance: {
                    ...state.tspStatistics.routesPerformance,
                    filters: {
                        ...state.tspStatistics.routesPerformance.filters,
                        ...payload,
                    },
                },
            },
        };

    case types.GET_PRIORITY_REQUESTS_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                priorityRequests: {
                    ...state.routes.priorityRequests,
                    loading: true,
                },
            },
        };
    case types.GET_PRIORITY_REQUESTS_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                priorityRequests: {
                    ...state.routes.priorityRequests,
                    loading: false,
                    list: payload,
                    error: ''
                },
            },
        };
    case types.GET_PRIORITY_REQUESTS_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                priorityRequests: {
                    ...state.routes.priorityRequests,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_PRIORITY_REQUESTS_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                priorityRequests: {
                    ...state.routes.priorityRequests,
                    filters: {
                        ...state.routes.priorityRequests.filters,
                        ...payload,
                    },
                },
            },
        };

    case types.GET_ROUTE_DETAILS_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeDetails: {
                    ...state.routes.routeDetails,
                    loading: true,
                },
            },
        };
    case types.GET_ROUTE_DETAILS_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeDetails: {
                    ...state.routes.routeDetails,
                    loading: false,
                    list: payload,
                    error: ''
                },
            },
        };
    case types.GET_ROUTE_DETAILS_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeDetails: {
                    ...state.routes.routeDetails,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_ROUTE_DETAILS_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeDetails: {
                    ...state.routes.routeDetails,
                    filters: {
                        ...state.routes.routeDetails.filters,
                        ...payload,
                    },
                },
            },
        };

    case types.RESET_ROUTE_DETAILS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routeDetails: {
                    list: [],
                    loading: true,
                    error: '',
                    filters: {
                        period: PERIOD_OPTIONS[0].value,
                        route: '',
                    }
                }
            }
        };

    case types.GET_HEADWAY_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                headway: {
                    ...state.routes.headway,
                    loading: true,
                },
            },
        };
    case types.GET_HEADWAY_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                headway: {
                    ...state.routes.headway,
                    loading: false,
                    data: payload.result,
                    count: payload.count,
                    error: ''
                },
            },
        };
    case types.GET_HEADWAY_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                headway: {
                    ...state.routes.headway,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_HEADWAY_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                headway: {
                    ...state.routes.headway,
                    filters: {
                        ...state.routes.headway.filters,
                        ...payload,
                    },
                },
            },
        };
    case types.SET_HEADWAY_DIRECTIONS:
        return {
            ...state,
            routes: {
                ...state.routes,
                headway: {
                    ...state.routes.headway,
                    directions: payload,
                },
            },
        };

    case types.GET_ROUTE_PERFORMANCE_BY_STOP_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                routePerformanceByStop: {
                    ...state.routes.routePerformanceByStop,
                    loading: true,
                },
            },
        };
    case types.GET_ROUTE_PERFORMANCE_BY_STOP_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routePerformanceByStop: {
                    ...state.routes.routePerformanceByStop,
                    loading: false,
                    data: payload.result,
                },
            },
        };
    case types.GET_ROUTE_PERFORMANCE_BY_STOP_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                routePerformanceByStop: {
                    ...state.routes.routePerformanceByStop,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_ROUTE_PERFORMANCE_BY_STOP_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routePerformanceByStop: {
                    ...state.routes.routePerformanceByStop,
                    filters: {
                        ...state.routes.routePerformanceByStop.filters,
                        ...payload,
                    },
                },
            },
        };
    case types.SET_ROUTE_PERFORMANCE_BY_STOP_DIRECTIONS:
        return {
            ...state,
            routes: {
                ...state.routes,
                routePerformanceByStop: {
                    ...state.routes.routePerformanceByStop,
                    directions: payload,
                },
            },
        };

    case types.GET_TRAVEL_TIME_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                travelTime: {
                    ...state.routes.travelTime,
                    loading: true,
                },
            },
        };
    case types.GET_TRAVEL_TIME_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                travelTime: {
                    ...state.routes.travelTime,
                    loading: false,
                    data: payload.result.data,
                    average: payload.result.average,
                    error: ''
                },
            },
        };
    case types.GET_TRAVEL_TIME_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                travelTime: {
                    ...state.routes.travelTime,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_TRAVEL_TIME_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                travelTime: {
                    ...state.routes.travelTime,
                    filters: {
                        ...state.routes.travelTime.filters,
                        ...payload,
                    },
                },
            },
        };

    case types.GET_RED_LIGHT_WAIT_TIME_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                redLightWaitTime: {
                    ...state.routes.redLightWaitTime,
                    loading: true,
                },
            },
        };
    case types.GET_RED_LIGHT_WAIT_TIME_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                redLightWaitTime: {
                    ...state.routes.redLightWaitTime,
                    loading: false,
                    data: payload,
                    error: ''
                },
            },
        };
    case types.GET_RED_LIGHT_WAIT_TIME_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                redLightWaitTime: {
                    ...state.routes.redLightWaitTime,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_RED_LIGHT_WAIT_TIME_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                redLightWaitTime: {
                    ...state.routes.redLightWaitTime,
                    filters: {
                        ...state.routes.redLightWaitTime.filters,
                        ...payload,
                    },
                },
            },
        };

    case types.GET_BUS_STOP_DWELL_TIME_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                busStopDwellTime: {
                    ...state.routes.busStopDwellTime,
                    loading: true,
                },
            },
        };
    case types.GET_BUS_STOP_DWELL_TIME_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                busStopDwellTime: {
                    ...state.routes.busStopDwellTime,
                    loading: false,
                    data: payload.result,
                    count: payload.count,
                    error: ''
                },
            },
        };
    case types.GET_BUS_STOP_DWELL_TIME_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                busStopDwellTime: {
                    ...state.routes.busStopDwellTime,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_BUS_STOP_DWELL_TIME_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                busStopDwellTime: {
                    ...state.routes.busStopDwellTime,
                    filters: {
                        ...state.routes.busStopDwellTime.filters,
                        ...payload,
                    },
                },
            },
        };

    case types.GET_AVERAGE_VEHICLE_SPEED_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                averageVehicleSpeed: {
                    ...state.routes.averageVehicleSpeed,
                    loading: true,
                },
            },
        };
    case types.GET_AVERAGE_VEHICLE_SPEED_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                averageVehicleSpeed: {
                    ...state.routes.averageVehicleSpeed,
                    loading: false,
                    data: payload.result,
                    count: payload.count,
                    error: ''
                },
            },
        };
    case types.GET_AVERAGE_VEHICLE_SPEED_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                averageVehicleSpeed: {
                    ...state.routes.averageVehicleSpeed,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_AVERAGE_VEHICLE_SPEED_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                averageVehicleSpeed: {
                    ...state.routes.averageVehicleSpeed,
                    filters: {
                        ...state.routes.averageVehicleSpeed.filters,
                        ...payload,
                    },
                },
            },
        };

    case types.GET_SEGMENT_TRAVEL_TIME_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                segmentTravelTime: {
                    ...state.routes.segmentTravelTime,
                    loading: true,
                },
            },
        };
    case types.GET_SEGMENT_TRAVEL_TIME_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                segmentTravelTime: {
                    ...state.routes.segmentTravelTime,
                    loading: false,
                    data: payload.result,
                    count: payload.count,
                    error: ''
                },
            },
        };
    case types.GET_SEGMENT_TRAVEL_TIME_ERROR:
        return {
            ...state,
            routes: {
                ...state.routes,
                segmentTravelTime: {
                    ...state.routes.segmentTravelTime,
                    loading: false,
                    error: payload,
                },
            },
        };
    case types.SET_SEGMENT_TRAVEL_TIME_FILTERS:
        return {
            ...state,
            routes: {
                ...state.routes,
                segmentTravelTime: {
                    ...state.routes.segmentTravelTime,
                    filters: {
                        ...state.routes.segmentTravelTime.filters,
                        ...payload,
                    },
                },
            },
        };
    case types.SET_SEGMENT_TRAVEL_TIME_DIRECTIONS:
        return {
            ...state,
            routes: {
                ...state.routes,
                segmentTravelTime: {
                    ...state.routes.segmentTravelTime,
                    directions: payload,
                },
            },
        };
    default:
        return state;
    }
};

export default routes;
