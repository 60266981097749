import { types } from './users.types';

// list
export const getUsersListRequest = () => ({
    type: types.GET_USERS_LIST_REQUEST,
});
export const getUsersListError = (error) => ({
    type: types.GET_USERS_LIST_ERROR,
    payload: error,
});
export const getUsersListSuccess = (data = {}) => ({
    type: types.GET_USERS_LIST_SUCCESS,
    payload: data,
});
export const mergeFullUserList = data => ({
    type: types.MERGE_USERS_LISTS,
    payload: data,
});

// admin list with agencies
export const getAdminListRequest = () => ({
    type: types.GET_ADMIN_LIST_REQUEST,
});
export const getAdminListError = (error) => ({
    type: types.GET_ADMIN_LIST_ERROR,
    payload: error,
});
export const getAdminListSuccess = (data = {}) => ({
    type: types.GET_ADMIN_LIST_SUCCESS,
    payload: data,
});

// update user
export const updateUserRequest = () => ({
    type: types.UPDATE_USER_REQUEST
});
export const updateUserSuccess = (data = {}) => ({
    type: types.UPDATE_USER_SUCCESS,
    payload: data
});
export const updateUserError = (error) => ({
    type: types.UPDATE_USER_ERROR,
    payload: error,
});

// remove user by id
export const removeUserByIDRequest = () => ({
    type: types.REMOVE_USER_BY_ID_REQUEST,
});
export const removeUserByIDError = (error) => ({
    type: types.REMOVE_USER_BY_ID_ERROR,
    payload: error,
});
export const removeUserByIDSuccess = (data = {}) => ({
    type: types.REMOVE_USER_BY_ID_SUCCESS,
    payload: data,
});

// set user role
export const setUserRoleRequest = () => ({
    type: types.SET_USER_ROLE_REQUEST,
});
export const setUserRoleError = (error) => ({
    type: types.SET_USER_ROLE_ERROR,
    payload: error,
});
export const setUserRoleSuccess = (data = {}) => ({
    type: types.SET_USER_ROLE_SUCCESS,
    payload: data,
});

// set approved cities
export const setUserApprovedCitiesRequest = () => ({
    type: types.SET_USER_APPROVED_CITIES_REQUEST,
});
export const setUserApprovedCitiesError = (error) => ({
    type: types.SET_USER_APPROVED_CITIES_ERROR,
    payload: error,
});
export const setUserApprovedCitiesSuccess = (data = {}) => ({
    type: types.SET_USER_APPROVED_CITIES_SUCCESS,
    payload: data,
});

// set approved agencies
export const setUserApprovedAgenciesRequest = () => ({
    type: types.SET_USER_APPROVED_AGENCIES_REQUEST,
});
export const setUserApprovedAgenciesError = (error) => ({
    type: types.SET_USER_APPROVED_AGENCIES_ERROR,
    payload: error,
});
export const setUserApprovedAgenciesSuccess = (data = {}) => ({
    type: types.SET_USER_APPROVED_AGENCIES_SUCCESS,
    payload: data,
});

// remove approved cities
export const removeUserApprovedCitiesRequest = () => ({
    type: types.REMOVE_USER_APPROVED_CITY_REQUEST,
});
export const removeUserApprovedCitiesError = (error) => ({
    type: types.REMOVE_USER_APPROVED_CITY_ERROR,
    payload: error,
});
export const removeUserApprovedCitiesSuccess = (data = {}) => ({
    type: types.REMOVE_USER_APPROVED_CITY_SUCCESS,
    payload: data,
});

//remove approved agencies
export const removeUserApprovedAgencyRequest = () => ({
    type: types.REMOVE_USER_APPROVED_AGENCY_REQUEST,
});
export const removeUserApprovedAgencyError = (error) => ({
    type: types.REMOVE_USER_APPROVED_AGENCY_ERROR,
    payload: error,
});
export const removeUserApprovedAgencySuccess = (data = {}) => ({
    type: types.REMOVE_USER_APPROVED_AGENCY_SUCCESS,
    payload: data,
});

// filter
export const filterUsersList = (data = {}) => ({
    type: types.FILTER_USERS_LIST_BY_NAME,
    payload: data,
});
