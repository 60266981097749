export const types = {
    SET_USER_SELECTED_LOCATION_INFO: 'user/SET_USER_SELECTED_LOCATION_INFO',
    SET_USER_PERSONAL_INFORMATION: 'user/SET_USER_PERSONAL_INFORMATION',
    SET_USER_PERSONAL_INFORMATION_REQUEST: 'user/SET_USER_PERSONAL_INFORMATION_REQUEST',
    SET_USER_PERSONAL_INFORMATION_SUCCESS: 'user/SET_USER_PERSONAL_INFORMATION_SUCCESS',
    SET_USER_PERSONAL_INFORMATION_ERROR: 'user/SET_USER_PERSONAL_INFORMATION_ERROR',

    //
    CHANGE_PASSWORD_REQUEST: 'user/CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'user/CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR: 'user/CHANGE_PASSWORD_ERROR',

    //
    GET_USER_PERSONAL_INFORMATION_REQUEST: 'user/GET_USER_PERSONAL_INFORMATION_REQUEST',
    GET_USER_PERSONAL_INFORMATION_SUCCESS: 'user/GET_USER_PERSONAL_INFORMATION_SUCCESS',
    GET_USER_PERSONAL_INFORMATION_ERROR: 'user/GET_USER_PERSONAL_INFORMATION_ERROR',

    //
    SET_USER_THEME: 'user/SET_USER_THEME',

    SET_USER_NAME: 'user/SET_USER_NAME',

    CLEAR_USER_STORED_INFO: 'user/CLEAR_USER_STORED_INFO',

    //
    GET_CITY_INFORMATION_BY_ID_REQUEST: 'dashboard/map/GET_CITY_INFORMATION_BY_ID_REQUEST',
    GET_CITY_INFORMATION_BY_ID_SUCCESS: 'dashboard/map/GET_CITY_INFORMATION_BY_ID_SUCCESS',
    GET_CITY_INFORMATION_BY_ID_ERROR: 'dashboard/map/GET_CITY_INFORMATION_BY_ID_ERROR',
};
