export const SVG_CODES = {
    BELL: 'bell',
    ALERT: 'alert',
    ARROW: 'arrow',
    DASHBOARD: 'dashboard',
    LOGO_PRIMARY: 'logo-primary',
    SWITCH_MOON: 'switch-moon',
    SWITCH_SUN: 'switch-sun',
    LOCATION: 'location',
    ARROW_DOWN_SELECT: 'arrow-down-select',
    CLOCK: 'clock',
    CALENDAR: 'calendar',
    PLUS: 'plus',
    ALERTS: 'alerts',
    EMERGENCY: 'emergency',
    HELP: 'help',
    INTERSECTIONS: 'intersections',
    SETTINGS: 'settings',
    ROUTES: 'routes',
    NO_SIGNALE: 'no-signale',
    CANCEL: 'cancel',
    DONE: 'done',
    ARROW_SECONDARY: 'arrow-secondary',
    CROSS: 'cross',
    TRASH: 'trash',
    SEARCH: 'search',
    VERTICAL_TWO_SIDE_ARROW: 'vertical-two-side-arrow',
    DOWNLOAD_CHART_DATA: 'download-chart-data',
    RESET: 'reset',
    EYE_CLOSED: 'eye-closed',
    EYE_OPENED: 'eye-opened',
    USA_NATIONAL_FLAG: 'usa-national-flag',
    ARROW_BACK: 'arrow-back',
    ERROR_CROSS: 'error-cross',
    SUCCESS: 'success',
    INFO: 'info',
    SORT: 'sort',
    BUS: 'bus',
    MINUS: 'minus',
    OPTIONS: 'options',
    WARNING: 'warning',
    DATE: 'date',
    LIGHT_BULB: 'light-bulb',
    MUTED: 'muted',
    LOGS_FILE: 'logs-file',
    TRAFFIC_LIGHTS: 'traffic-lights',
    LOST_CONNECTION: 'lost-connection',
    SUITCASE: 'suitcase',
    POINT: 'point',
    ARROWS_DIAGONAL_OUTSIDE: 'arrows-diagonal-outside',
    ALERT_GRAY: 'alert-gray',
    SIDEBAR_DASHBOARD: 'sidebar-dashboard',
    SIDEBAR_ROUTES: 'sidebar-routes',
    SIDEBAR_INTERSECTIONS: 'sidebar-intersections',
    SIDEBAR_ALERTS: 'sidebar-alerts',
    SIDEBAR_SETTINGS: 'sidebar-settings',
    SIDEBAR_HELP: 'sidebar-help',
    SIDEBAR_LOCATION: 'sidebar-location',
    SIDEBAR_AGENCY: 'sidebar-agency',
    SIDEBAR_DOCUMENTS: 'sidebar-documents',
    SIDEBAR_USER_GROUPS: 'sidebar-user-groups',
    PENCIL: 'pencil',
    INFO_FILLED: 'info-filled',
};
