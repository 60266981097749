export const userSelectedLocationInfoSelector = (state) =>
    state.user.selectedLocation;
export const userCityIdSelector = (state) => state.user.selectedLocation.cityId;
export const userAgencyIdSelector = (state) => state.user.selectedLocation.agencyId;
export const userInAgencyIdSelector = (state) => state.user.personalInformation.inAgency;
export const userIdSelector = (state) => state.user.personalInformation.user_id;
export const userEmailSelector = (state) => state.user.personalInformation.email;
export const userPersonalInformationSelector = (state) => state.user.personalInformation;
export const userThemeSelector = (state) => state.user.theme;
export const userRoleSelector = (state) => state.user.personalInformation.usertype;
export const userCityCenterCoordinates = (state) => state.user.cityInformation.data.details.center;
export const userPermissionsSelector = (state) => state.user.personalInformation.role.roleParse;
export const userChangePasswordSelector = (state) => state.user.changePassword;
export const userUpdatedSelector = (state) => state.user.updateUser;
