import ContentLoader from "react-content-loader";

const SkeletonWidgetsManagement = ({ colors, ...props }) => (
    <ContentLoader
        width={"100%"}
        height={320}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <rect x="40" y="0" rx="6" ry="6" width="40%" height="15" />
        <rect x="370" y="0" rx="6" ry="6" width="20%" height="15" />
        <rect x="40" y="40" rx="6" ry="6" width="40%" height="15" />
        <rect x="370" y="40" rx="6" ry="6" width="40%" height="15" />
        <rect x="40" y="80" rx="6" ry="6" width="40%" height="15" />
        <rect x="370" y="80" rx="6" ry="6" width="40%" height="15" />
        <rect x="40" y="120" rx="6" ry="6" width="40%" height="15" />
        <rect x="40" y="160" rx="6" ry="6" width="40%" height="15" />
        <rect x="40" y="200" rx="6" ry="6" width="40%" height="15" />
        <rect x="40" y="240" rx="6" ry="6" width="40%" height="15" />
        <rect x="40" y="280" rx="6" ry="6" width="40%" height="15" />
        <rect x="40" y="320" rx="6" ry="6" width="40%" height="15" />
    </ContentLoader>
);

export default SkeletonWidgetsManagement;
