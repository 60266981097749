import ContentLoader from "react-content-loader";

const SkeletonMapRouteStops = ({ colors }) => {
    return (
        <ContentLoader
            speed={2}
            width={"100%"}
            height={40}
            backgroundColor={colors.background}
            foregroundColor={colors.foreground}
        >
            <circle x="0" cx="13" cy="10" r="10" />
            <rect x="45" y="0" rx="10" ry="10" width="80%" height="20" />
        </ContentLoader>
    );
};

export default SkeletonMapRouteStops;
