export const types = {
    GET_ROLES_TYPES_LIST_REQUEST:
        "users-and-permissions/roles/GET_ROLES_TYPES_LIST_REQUEST",
    GET_ROLES_TYPES_LIST_SUCCESS:
        "users-and-permissions/roles/GET_ROLES_TYPES_LIST_SUCCESS",
    GET_ROLES_TYPES_LIST_ERROR:
        "users-and-permissions/roles/GET_ROLES_TYPES_LIST_ERROR",

    GET_ACCESS_SETTINGS_BY_TYPE_REQUEST:
        "users-and-permissions/roles/GET_ACCESS_SETTINGS_BY_TYPE_REQUEST",
    GET_ACCESS_SETTINGS_BY_TYPE_SUCCESS:
        "users-and-permissions/roles/GET_ACCESS_SETTINGS_BY_TYPE_SUCCESS",
    GET_ACCESS_SETTINGS_BY_TYPE_ERROR:
        "users-and-permissions/roles/GET_ACCESS_SETTINGS_BY_TYPE_ERROR",

    // edit access settings by type
    EDIT_ACCESS_SETTINGS_BY_TYPE_REQUEST:
        "users-and-permissions/roles/EDIT_ACCESS_SETTINGS_BY_TYPE_REQUEST",
    EDIT_ACCESS_SETTINGS_BY_TYPE_SUCCESS:
        "users-and-permissions/roles/EDIT_ACCESS_SETTINGS_BY_TYPE_SUCCESS",
    EDIT_ACCESS_SETTINGS_BY_TYPE_ERROR:
        "users-and-permissions/roles/EDIT_ACCESS_SETTINGS_BY_TYPE_ERROR",

    SET_ACCESS_SETTINGS_PARAMS:
        "users-and-permissions/roles/SET_ACCESS_SETTINGS_PARAMS",
};
