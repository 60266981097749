import PropTypes from 'prop-types';

import SvgIcon from '../../../svg-icon';

import { VIEW_TYPES, COLOR_TYPES } from '../selectable-list.constants';
import Avatar from '../../../avatar';

import styles from './styles.scss';

const SelectedItem = ({
    value,
    notifications = false,
    handleDelete = () => { },
    id = '',
    colorType = COLOR_TYPES.PRIMARY,
    viewType = VIEW_TYPES.ACTIONS,
    isNeedToShowAvatar = true
}) => {
    let className = `${styles['selected-user-item']} selected-user-item--g`;

    const viewTypeClass = `selected-user-item--${viewType}`;
    const colorTypeClass = `selected-user-item--${colorType}`;
    className += ` ${styles[viewTypeClass]} ${styles[colorTypeClass]}`;

    return (
        <div className={className}>
            {
                isNeedToShowAvatar && <Avatar name={value} />
            }
            <p className={styles['selected-user-item__name']}>{value}</p>

            {notifications && (
                <div className={styles['selected-user-item__muted']}>
                    <SvgIcon type="muted" />
                </div>
            )}
            <button
                type="button"
                className={styles['selected-user-item__cancel-button']}
                onClick={() => handleDelete(id)}
            >
                <SvgIcon type="cross" />
            </button>
        </div>
    );
};

SelectedItem.propTypes = {
    value: PropTypes.string.isRequired,
    notifications: PropTypes.bool,
    handleDelete: PropTypes.func,
    id: PropTypes.string,
    viewType: PropTypes.oneOf(Object.values(VIEW_TYPES)),
    colorType: PropTypes.oneOf(Object.values(COLOR_TYPES)),
    isNeedToShowAvatar: PropTypes.bool
};

export default SelectedItem;
