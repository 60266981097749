import { types } from './requests.types';

const initialState = {
    list: [],
    loading: true,
    error: '',
};

const requests = (state = initialState, { type, payload }) => {
    switch (type) {
    //list
    case types.GET_REQUESTS_LIST_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.GET_REQUESTS_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            list: payload,
        };

        // errors
    case types.GET_REQUESTS_LIST_ERROR:
    case types.APPROVE_REQUEST_ERROR:
    case types.REJECT_REQUEST_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        // approve & reject
    case types.APPROVE_REQUEST_SUCCESS:
        return {
            ...state,
            list: state.list.filter(
                (item) => String(item.user_id) !== String(payload)
            ),
        };
    case types.REJECT_REQUEST_SUCCESS:
        return {
            ...state,
            list: state.list.filter(
                (item) => String(item.user_id) !== String(payload)
            ),
        };

    default:
        return state;
    }
};

export default requests;
