/* eslint-disable max-len */
/* eslint-disable space-before-function-paren */
/* eslint-disable camelcase */
/* eslint-disable keyword-spacing */
import ReactGA from 'react-ga4';
import * as actions from './user.actions';
import * as utils from './user.utils';

import { sidebarAgenciesSelector } from '../../components/sidebar/sidebar.selectors';
import { userSelectedLocationInfoSelector } from '../user/user.selectors';
import { getMapRoutesOperation } from '../../routes/dashboard/map/map.operations';
import { getIntersectionsListOperation } from '../../routes/intersections/spm/spm.operations';
import { getAllActiveAlertsOperation } from '../../routes/alerts/alerts.operations';

import { pageSize } from '../../routes/alerts/alerts-active/alerts-active.config';

import { bookList } from '../../constants/bookList';

import * as requests from '../../services/requests/user/user';

// Scot's unblocking fake so he can see the Map //////
const mockReturns = {
    pdx: {zipcode: 97035, number_of_intersections: 1, maestro_pk: 6, installed_date: null, timezone: 'America/Los_Angeles', ip_address: 'NULL', contact_person: 'Menard', contact_email: 'tim@lyt.ai', details: {center: {lat: 45.523969, lng: -122.663988}, agencies: [{name: 'tri', display: 'TriMet', type: 'transit', search: 'true'}]}, maestros: '{6}'},
    psv: {zipcode: 95054, number_of_intersections: 3, maestro_pk: 1, installed_date: null, timezone: 'America/Los_Angeles', ip_address: 'NULL', contact_person: 'NULL', contact_email: 'NULL', details: {center: {lat: 37.363571, lng: -121.890994}, agencies: [{name: 'psvfire', display: 'PSV Fire', type: 'transit', search: 'false'}]}, maestros: '{1,9,14,15}'}
};
////////// remove this block later ///////////////////

export const getCityInformationByIdOperation = (id) => (dispatch) => {
    dispatch(actions.getCityInformationByIdRequest());
    return requests
        .getCityInformationByIdRequest({ id })
        .then((response) => {
            // Use fake data to unblock Scot ///
            if (Object.keys(mockReturns).includes(id)) {
                response = mockReturns[id];
            } else {
                response = mockReturns.pdx;
            }
            ////////////////////////////////////
            dispatch(actions.getCityInformationByIdSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getCityInformationByIdError(error));
        });
};

export const getUserPersonalInformation = () => (dispatch) => {
    dispatch(actions.getUserPersonalInformationRequest());

    return requests
        .getUserPersonalInformationRequest()
        .then((response) => {
            dispatch(actions.getUserPersonalInformationSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getUserPersonalInformationError(error));
        });
};

export const sendUserUpdateOperation = (data) => (dispatch) => {
    dispatch(actions.setUserPersonalInformationRequest());

    return requests
        .updateUserRequest(data)
        .then(response => {
            dispatch(actions.setUserPersonalInformationSuccess(response));
        })
        .catch((error) => {
            console.log('error', error);
            dispatch(actions.setUserPersonalInformationError(error));
        });
};

export const changePasswordOperation = (data) => async (dispatch) => {
    dispatch(actions.changePasswordRequest());
    try {
        const response = await requests
            .changePasswordRequest(data);
        dispatch(actions.changePasswordSuccess(response));
    } catch (error) {
        dispatch(actions.changePasswordError(error));
    }
};

export const setUserSelectedLocationInitialOperation = () => (dispatch, getStore) => {
    const { list } = sidebarAgenciesSelector(getStore());
    const { agencyId, cityId } = userSelectedLocationInfoSelector(
        getStore()
    );
    // agent can be added from local storage with (redux-persist)
    // if the user does not have an agency selected, we add the first one from the list, the same for the city
    if (agencyId.length <= 0) {
        const firstElAgencyId = list[0]?.agencies_id || '';
        const firstElCityId = list[0]?.cities[0]?.id || '';

        dispatch(
            actions.setUserSelectedLocation({
                agencyId: firstElAgencyId,
                cityId: firstElCityId,
            })
        );
        dispatch(getCityInformationByIdOperation(firstElCityId));
    } else {
        dispatch(getCityInformationByIdOperation(cityId));
    }
};

export const setUserAgencyIdOperation = (agencyId) => (dispatch, getStore) => {
    ReactGA.gtag('event', 'lyt-agency-change', {
        agency: agencyId,
    });
    const { list } = sidebarAgenciesSelector(getStore());
    const { cities } = list.find(({ agencies_id }) => agencies_id === agencyId);

    // when we change the agency we need to add the first city from the list
    const firstElCityId = cities[0]?.id || '';
    const selectedLocationData = { agencyId, cityId: firstElCityId };

    dispatch(actions.setUserSelectedLocation(selectedLocationData));
    dispatch(getCityInformationByIdOperation(firstElCityId));
    dispatch(locationDataChangeTrackingOperation());
};

// function to send requests when city/agency is updated
// connected with (sidebar/select-groupe) UI component
// launched by above functions

export const locationDataChangeTrackingOperation = () => (dispatch) => {
    const currentPathname = window.location.pathname;

    switch (currentPathname) {
    case bookList.dashboard.map:
        dispatch(getMapRoutesOperation());
        break;

    case bookList.intersections.spm:
        dispatch(getIntersectionsListOperation());
        break;

    case bookList.alerts.active:
        dispatch(getAllActiveAlertsOperation(0, pageSize));
        break;

    case bookList.dashboard.entry_point:
        dispatch(utils.dashboardGeneralPageDistributor());
        break;

    case bookList.dashboard.general:
        dispatch(utils.dashboardGeneralPageDistributor());
        break;

    case bookList.routes.tsp_statistics:
        dispatch(utils.routesTSPStatisticsPageDistributor());
        break;

    case bookList.routes.tsp_requests:
        dispatch(utils.routesTSPRequestsPageDistributor());
        break;

    case bookList.routes.reports:
        dispatch(utils.reportsPageDistributor());
        break;

    case bookList.users_and_permissions.users:
        dispatch(utils.usersPageDistributor());
        break;

    case bookList.users_and_permissions.groups:
        dispatch(utils.groupsPageDistributor());
        break;

    case bookList.users_and_permissions.requests:
        dispatch(utils.requestsPageDistributor());
        break;

    case bookList.users_and_permissions.roles:
        dispatch(utils.rolesPageDistributor());
        break;

    default:
        break;
    }
};
