import { types } from './routes.types';

export const getChartsExpansionStatus = () => ({
    type: types.GET_CHARTS_EXPANSION_STATUS
});

export const setChartsExpanded = () => ({
    type: types.SET_CHARTS_EXPANDED,
    payload: true
});

export const setChartsCollapsed = () => ({
    type: types.SET_CHARTS_COLLAPSED,
    payload: false
});

export const getAgencyRoutesRequest = () => ({
    type: types.GET_AGENCY_ROUTES_REQUEST
});

export const getAgencyRoutesSuccess = (data) => ({
    type: types.GET_AGENCY_ROUTES_SUCCESS,
    payload: data
});

export const getAgencyRoutesError = (error) => ({
    type: types.GET_AGENCY_ROUTES_ERROR,
    payload: error
});

export const getTLAndOnTimePerformanceRequest = () => ({
    type: types.GET_TL_AND_ON_TIME_PERFORMANCE_REQUEST,
});

export const getTLAndOnTimePerformanceSuccess = (data) => ({
    type: types.GET_TL_AND_ON_TIME_PERFORMANCE_SUCCESS,
    payload: data,
});

export const getTLAndOnTimePerformanceError = (error) => ({
    type: types.GET_TL_AND_ON_TIME_PERFORMANCE_ERROR,
    payload: error,
});

export const setTLAndOnTimePerformanceFilters = (data) => ({
    type: types.SET_TL_AND_ON_TIME_PERFORMANCE_FILTERS,
    payload: data,
});

export const getTSPActivityRequest = () => ({
    type: types.GET_TSP_ACTIVITY_REQUEST,
});

export const getTSPActivitySuccess = (data) => ({
    type: types.GET_TSP_ACTIVITY_SUCCESS,
    payload: data,
});

export const getTSPActivityError = (error) => ({
    type: types.GET_TSP_ACTIVITY_ERROR,
    payload: error,
});

export const setTSPActivityFilters = (data) => ({
    type: types.SET_TSP_ACTIVITY_FILTERS,
    payload: data,
});

export const getRoutesStopsRequest = () => ({
    type: types.GET_ROUTES_STOPS_REQUEST,
});

export const getRoutesStopsSuccess = (data) => ({
    type: types.GET_ROUTES_STOPS_SUCCESS,
    payload: data,
});

export const getRoutesStopsError = (error) => ({
    type: types.GET_ROUTES_STOPS_ERROR,
    payload: error,
});

export const getRoutesPerformanceRequest = () => ({
    type: types.GET_ROUTES_PERFORMANCE_REQUEST,
});

export const getRoutesPerformanceSuccess = (data) => ({
    type: types.GET_ROUTES_PERFORMANCE_SUCCESS,
    payload: data,
});

export const getRoutesPerformanceError = (error) => ({
    type: types.GET_ROUTES_PERFORMANCE_ERROR,
    payload: error,
});

export const setRoutesPerformanceFilters = (data) => ({
    type: types.SET_ROUTES_PERFORMANCE_FILTERS,
    payload: data,
});

export const getPriorityRequestsRequest = () => ({
    type: types.GET_PRIORITY_REQUESTS_REQUEST,
});

export const getPriorityRequestsSuccess = (data) => ({
    type: types.GET_PRIORITY_REQUESTS_SUCCESS,
    payload: data,
});

export const getPriorityRequestsError = (error) => ({
    type: types.GET_PRIORITY_REQUESTS_ERROR,
    payload: error,
});

export const setPriorityRequestsFilters = (data) => ({
    type: types.SET_PRIORITY_REQUESTS_FILTERS,
    payload: data,
});

export const getRouteDetailsRequest = () => ({
    type: types.GET_ROUTE_DETAILS_REQUEST,
});

export const getRouteDetailsSuccess = (data) => ({
    type: types.GET_ROUTE_DETAILS_SUCCESS,
    payload: data,
});

export const getRouteDetailsError = (error) => ({
    type: types.GET_ROUTE_DETAILS_ERROR,
    payload: error,
});

export const setRouteDetailsFilters = (data) => ({
    type: types.SET_ROUTE_DETAILS_FILTERS,
    payload: data,
});

export const resetRouteDetails = () => ({
    type: types.RESET_ROUTE_DETAILS,
});

export const getHeadwayRequest = () => ({
    type: types.GET_HEADWAY_REQUEST,
});

export const getHeadwaySuccess = (data) => ({
    type: types.GET_HEADWAY_SUCCESS,
    payload: data,
});

export const getHeadwayError = (error) => ({
    type: types.GET_HEADWAY_ERROR,
    payload: error,
});

export const setHeadwayFilters = (data) => ({
    type: types.SET_HEADWAY_FILTERS,
    payload: data,
});

export const setHeadwayDirections = (data) => ({
    type: types.SET_HEADWAY_DIRECTIONS,
    payload: data,
});

export const getRoutePerformanceByStopRequest = () => ({
    type: types.GET_ROUTE_PERFORMANCE_BY_STOP_REQUEST,
});

export const getRoutePerformanceByStopSuccess = (data) => ({
    type: types.GET_ROUTE_PERFORMANCE_BY_STOP_SUCCESS,
    payload: data,
});

export const getRoutePerformanceByStopError = (error) => ({
    type: types.GET_ROUTE_PERFORMANCE_BY_STOP_ERROR,
    payload: error,
});

export const setRoutePerformanceByStopFilters = (data) => ({
    type: types.SET_ROUTE_PERFORMANCE_BY_STOP_FILTERS,
    payload: data,
});

export const setRoutePerformanceByStopDirections = (data) => ({
    type: types.SET_ROUTE_PERFORMANCE_BY_STOP_DIRECTIONS,
    payload: data,
});

export const getRedLightWaitTimeRequest = () => ({
    type: types.GET_RED_LIGHT_WAIT_TIME_REQUEST,
});

export const getRedLightWaitTimeSuccess = (data) => ({
    type: types.GET_RED_LIGHT_WAIT_TIME_SUCCESS,
    payload: data,
});

export const getRedLightWaitTimeError = (error) => ({
    type: types.GET_RED_LIGHT_WAIT_TIME_ERROR,
    payload: error,
});

export const setRedLightWaitTimeFilters = (data) => ({
    type: types.SET_RED_LIGHT_WAIT_TIME_FILTERS,
    payload: data,
});

export const getBusStopDwellTimeRequest = () => ({
    type: types.GET_BUS_STOP_DWELL_TIME_REQUEST,
});

export const getBusStopDwellTimeSuccess = (data) => ({
    type: types.GET_BUS_STOP_DWELL_TIME_SUCCESS,
    payload: data,
});

export const getBusStopDwellTimeError = (error) => ({
    type: types.GET_BUS_STOP_DWELL_TIME_ERROR,
    payload: error,
});

export const setBusStopDwellTimeFilters = (data) => ({
    type: types.SET_BUS_STOP_DWELL_TIME_FILTERS,
    payload: data,
});

export const getAverageVehicleSpeedRequest = () => ({
    type: types.GET_AVERAGE_VEHICLE_SPEED_REQUEST,
});

export const getAverageVehicleSpeedSuccess = (data) => ({
    type: types.GET_AVERAGE_VEHICLE_SPEED_SUCCESS,
    payload: data,
});

export const getAverageVehicleSpeedError = (error) => ({
    type: types.GET_AVERAGE_VEHICLE_SPEED_ERROR,
    payload: error,
});

export const setAverageVehicleSpeedFilters = (data) => ({
    type: types.SET_AVERAGE_VEHICLE_SPEED_FILTERS,
    payload: data,
});

export const getSegmentTravelTimeRequest = () => ({
    type: types.GET_SEGMENT_TRAVEL_TIME_REQUEST,
});

export const getSegmentTravelTimeSuccess = (data) => ({
    type: types.GET_SEGMENT_TRAVEL_TIME_SUCCESS,
    payload: data,
});

export const getSegmentTravelTimeError = (error) => ({
    type: types.GET_SEGMENT_TRAVEL_TIME_ERROR,
    payload: error,
});

export const setSegmentTravelTimeFilters = (data) => ({
    type: types.SET_SEGMENT_TRAVEL_TIME_FILTERS,
    payload: data,
});

export const setSegmentTravelTimeDirections = (data) => ({
    type: types.SET_SEGMENT_TRAVEL_TIME_DIRECTIONS,
    payload: data,
});

export const getTravelTimeRequest = () => ({
    type: types.GET_TRAVEL_TIME_REQUEST,
});

export const getTravelTimeSuccess = (data) => ({
    type: types.GET_TRAVEL_TIME_SUCCESS,
    payload: data,
});

export const getTravelTimeError = (error) => ({
    type: types.GET_TRAVEL_TIME_ERROR,
    payload: error,
});

export const setTravelTimeFilters = (data) => ({
    type: types.SET_TRAVEL_TIME_FILTERS,
    payload: data,
});
