export const types = {
    // groups list
    GET_GROUPS_LIST_REQUEST:
        "users-and-permissions/groups/GET_GROUPS_LIST_REQUEST",
    GET_GROUPS_LIST_SUCCESS:
        "users-and-permissions/groups/GET_GROUPS_LIST_SUCCESS",
    GET_GROUPS_LIST_ERROR: "users-and-permissions/groups/GET_GROUPS_LIST_ERROR",

    // users list
    GET_USERS_LIST_REQUEST:
        "users-and-permissions/groups/GET_USERS_LIST_REQUEST",
    GET_USERS_LIST_SUCCESS:
        "users-and-permissions/groups/GET_USERS_LIST_SUCCESS",
    GET_USERS_LIST_ERROR: "users-and-permissions/groups/GET_USERS_LIST_ERROR",

    // new group
    ADD_NEW_GROUP_REQUEST: "users-and-permissions/groups/ADD_NEW_GROUP_REQUEST",
    ADD_NEW_GROUP_SUCCESS: "users-and-permissions/groups/ADD_NEW_GROUP_SUCCESS",
    ADD_NEW_GROUP_ERROR: "users-and-permissions/groups/ADD_NEW_GROUP_ERROR",

    // new members
    ADD_NEW_MEMBERS_REQUEST:
        "users-and-permissions/groups/ADD_NEW_MEMBERS_REQUEST",
    ADD_NEW_MEMBERS_SUCCESS:
        "users-and-permissions/groups/ADD_NEW_MEMBERS_SUCCESS",
    ADD_NEW_MEMBERS_ERROR: "users-and-permissions/groups/ADD_NEW_MEMBERS_ERROR",

    // edit group name
    EDIT_GROUP_NAME_REQUEST:
        "users-and-permissions/groups/EDIT_GROUP_NAME_REQUEST",
    EDIT_GROUP_NAME_SUCCESS:
        "users-and-permissions/groups/EDIT_GROUP_NAME_SUCCESS",
    EDIT_GROUP_NAME_ERROR: "users-and-permissions/groups/EDIT_GROUP_NAME_ERROR",

    // delete group
    DELETE_GROUP_REQUEST: "users-and-permissions/groups/DELETE_GROUP_REQUEST",
    DELETE_GROUP_SUCCESS: "users-and-permissions/groups/DELETE_GROUP_SUCCESS",
    DELETE_GROUP_ERROR: "users-and-permissions/groups/DELETE_GROUP_ERROR",

    // delete group
    DELETE_GROUP_MEMBER_REQUEST:
        "users-and-permissions/groups/DELETE_GROUP_MEMBER_REQUEST",
    DELETE_GROUP_MEMBER_SUCCESS:
        "users-and-permissions/groups/DELETE_GROUP_MEMBER_SUCCESS",
    DELETE_GROUP_MEMBER_ERROR:
        "users-and-permissions/groups/DELETE_GROUP_MEMBER_ERROR",
};
