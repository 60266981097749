import SkeletonOverallPerformance from './components/skeleton-overall-performance';
import SkeletonCriticalAlerts from './components/skeleton-critical-alerts';
import SkeletonPreviewTable from './components/skeleton-preview-table';
import SkeletonTrafficLights from './components/skeleton-traffic-lights';
import SkeletonRecentAlertsTL from './components/skeleton-recent-alerts-TL';
import SkeletonRecentAlertsPR from './components/skeleton-recent-alerts-PR';
import SkeletonRecentAlertsVI from './components/skeleton-recent-alerts-VI';
import SkeletonWidgetsManagement from './components/skeleton-widgets-management';
import SkeletonRoutesTable from './components/skeleton-routes-table';
import SkeletonCityTrafficPerformance from './components/skeleton-city-traffic-performance';
import SkeletonSidebarSelectorsGroup from './components/skeleton-sidebar-selectors-group';
import SkeletonRoutesTrafficLightsOperationData from './components/skeleton-routes-traffic-lights-operation-data';
import SkeletonChartBar from './components/skeleton-chart-bar';
import SkeletonRoutesPerformanceTable from './components/skeleton-routes-performance';
import SkeletonRoutesPriorityRequests from './components/skeleton-routes-priority-requests';
import SkeletonMapRouteDetails from './components/skeleton-map-route-details';
import SkeletonMapRouteStops from './components/skeleton-map-route-stops';

// admin panel
import SkeletonRequestsCards from './components/skeleton-requests-cards';
import SkeletonUsersTable from './components/skeleton-users-table';
import SkeletonUsersAndPermissionsGroups from './components/skeleton-users-and-permissions-groups';
import SkeletonTabsRoles from './components/skeleton-tabs-roles';
import SkeletonRolesSettings from './components/skeleton-roles-settings';
import SkeletonBRRoutesTable from './components/skeleton-br-routes-table';

export const skeletonConfig = {
    'dashboard-overall-performance': <SkeletonOverallPerformance />,
    'dashboard-critical-alerts': <SkeletonCriticalAlerts />,
    'dashboard-preview-table': <SkeletonPreviewTable />,
    'dashboard-traffic-lights': <SkeletonTrafficLights />,
    'dashboard-recent-alerts-tl': <SkeletonRecentAlertsTL />,
    'dashboard-recent-alerts-pr': <SkeletonRecentAlertsPR />,
    'dashboard-recent-alerts-vi': <SkeletonRecentAlertsVI />,
    'modal-widgets-management': <SkeletonWidgetsManagement />,
    'dashboard-tsp-routes': <SkeletonRoutesTable />,
    'dashboard-tsp-city-traffic-performance': <SkeletonCityTrafficPerformance />,
    // "sidebar-selectors-group": <SkeletonSidebarSelectorsGroup colors={{background: "#fff", forground: "#f0f2f8"}}/>,
    'sidebar-selectors-group': <SkeletonSidebarSelectorsGroup />,
    'routes-traffic-lights-operational-data': <SkeletonRoutesTrafficLightsOperationData />,
    'chart-bar': <SkeletonChartBar />,
    'routes-routes-performance': <SkeletonRoutesPerformanceTable />,
    'routes-routes-priority-requests': <SkeletonRoutesPriorityRequests />,
    'map-route-details': <SkeletonMapRouteDetails />,
    'map-route-stops': <SkeletonMapRouteStops />,

    // admin panel
    'users-table': <SkeletonUsersTable />,
    'requests-cards': <SkeletonRequestsCards />,
    'users-and-permissions-groups': <SkeletonUsersAndPermissionsGroups />,
    'tabs-roles': <SkeletonTabsRoles />,
    'roles-settings': <SkeletonRolesSettings />,
    'br-routes-table': <SkeletonBRRoutesTable />,
};
