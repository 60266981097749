import ContentLoader from "react-content-loader";

const SkeletonRecentAlertsVI = ({ colors, ...props }) => (
    <tr>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={48}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="10" y="20" rx="6" ry="6" width="30%" height="15" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={42}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="10" y="20" rx="6" ry="6" width="65%" height="20" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={42}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="10" y="20" rx="6" ry="6" width="64" height="14" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={42}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="10" y="20" rx="6" ry="6" width="64" height="14" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={42}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="60%" y="20" rx="10" ry="10" width="44" height="23" />
            </ContentLoader>
        </th>
    </tr>
);

export default SkeletonRecentAlertsVI;
