import { types } from './auth.types';


// login

export const sendLoginFormRequest = (data) => ({
    type: types.SEND_LOGIN_FORM_REQUEST,
    payload: data,
});

export const sendLoginFormSuccess = (payload) => ({
    type: types.SEND_LOGIN_FORM_SUCCESS,
    payload,
});

export const sendLoginFormError = (payload) => ({
    type: types.SEND_LOGIN_FORM_ERROR,
    payload,
});

// login-otp

export const sendLoginOtpFormRequest = () => ({
    type: types.SEND_LOGIN_OTP_FORM_REQUEST,
});

export const sendLoginOtpFormSuccess = (payload) => ({
    type: types.SEND_LOGIN_OTP_FORM_SUCCESS,
    payload,
});

export const sendLoginOtpFormError = (payload) => ({
    type: types.SEND_LOGIN_OTP_FORM_ERROR,
    payload,
});


// registration

export const sendRegistrationFormRequest = () => ({
    type: types.SEND_REGISTRATION_FORM_REQUEST,
});

export const sendRegistrationFormSuccess = (payload) => ({
    type: types.SEND_REGISTRATION_FORM_SUCCESS,
    payload,
});


//forgot

export const sendForgotConfirmationFormRequest = () => ({
    type: types.SEND_FORGOT_CONFIRMATION_FORM_REQUEST,
});

export const sendForgotConfirmationFormSuccess = (payload) => ({
    type: types.SEND_FORGOT_CONFIRMATION_FORM_SUCCESS,
    payload,
});

export const sendForgotConfirmationFormError = (payload) => ({
    type: types.SEND_FORGOT_CONFIRMATION_FORM_ERROR,
    payload,
});

export const sendForgotCodeFormRequest = () => ({
    type: types.SEND_FORGOT_CODE_FORM_REQUEST,
});

export const sendForgotCodeFormSuccess = (payload) => ({
    type: types.SEND_FORGOT_CODE_FORM_SUCCESS,
    payload,
});

export const sendForgotCodeFormError = (payload) => ({
    type: types.SEND_FORGOT_CODE_FORM_ERROR,
    payload,
});

export const sendForgotChangeFormRequest = () => ({
    type: types.SEND_FORGOT_CHANGE_FORM_REQUEST,
});

export const sendForgotChangeFormSuccess = (payload) => ({
    type: types.SEND_FORGOT_CHANGE_FORM_SUCCESS,
    payload,
});

export const sendForgotChangeFormError = (payload) => ({
    type: types.SEND_FORGOT_CHANGE_FORM_ERROR,
    payload,
});

export const sendForgotPasswordFormRequest = () => ({
    type: types.SEND_FORGOT_PASSWORD_FORM_REQUEST,
});

export const sendForgotPasswordFormSuccess = () => ({
    type: types.SEND_FORGOT_PASSWORD_FORM_SUCCESS,
});

export const sendForgotPasswordFormError = (payload) => ({
    type: types.SEND_FORGOT_PASSWORD_FORM_ERROR,
    payload,
});

//other

export const sendRegistrationFormError = (payload) => ({
    type: types.SEND_REGISTRATION_FORM_ERROR,
    payload,
});

export const receiveAuth0Success = (payload) => ({
    type: types.RECEIVE_AUTH0_SUCCESS,
    payload,
});

export const clearAuthMutualData = () => ({
    type: types.CLEAR_AUTH_MUTUAL_DATA,
});

export const updateToken = (payload) => ({
    type: types.UPDATE_TOKEN,
    payload,
});

export const clearSession = () => ({
    type: types.CLEAR_SESSION,
});
