import PropTypes from "prop-types";
import { useMemo, useState } from "preact/hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../ui-core/button";
import ListWithSearch from "../../../ui-core/inputs/list-with-search";
import SelectableList from "../../../ui-core/inputs/selectable-list";

import { VIEW_TYPES_LIST } from "../../../ui-core/button/button.constants";
import { schema } from "./add-members.schema";
import { clearModalState } from "../../modal.actions";
import { DATA_LIST_ITEM_TYPES } from "../../../ui-core/inputs/list-with-search/list-with-search.constants";
import { addNewMembersOperation } from "../../../../routes/users-and-permissions/groups/groups.operations";
import { usersListSelector } from "../../../../routes/users-and-permissions/groups/groups.selectors";
import { normalizeListOfUsers } from "./add-members.utils";

import styles from "./styles.scss";

const AddMembers = ({ id = "" }) => {
    const {
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
    } = useForm({
        defaultValues: {
            users_id: [],
        },
        resolver: yupResolver(schema),
    });

    const users = useSelector(usersListSelector);
    const dispatch = useDispatch();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isWorking, setWorking] = useState(false);

    const normalizedListOfUsers = useMemo(
        () => normalizeListOfUsers(users),
        [users],
    );

    const onSubmit = async (data) => {
        setWorking(true);
        try {
            await dispatch(addNewMembersOperation(data, { id }));
            dispatch(clearModalState());
            setWorking(false);
        } catch (err) {
            setWorking(false);
        }
    };

    const handleAddUser = (selectedUser) => {
        //is user already exists
        const isUser = selectedUsers.find(({ id }) => id === selectedUser.id);
        const users_id = getValues().users_id;

        if (isUser) return;

        setSelectedUsers([...selectedUsers, selectedUser]);
        setValue("users_id", [...users_id, selectedUser.id]);
    };

    const handleRemoveUser = (userId) => {
        const list = selectedUsers.filter(({ id }) => userId !== id);
        const usersIds = [...getValues().users_id];
        const newUsersIds = usersIds.filter((id) => userId !== id);

        setValue("users_id", newUsersIds);
        setSelectedUsers(list);
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles["add-members"]}
        >
            <p className={styles["add-members__title"]}>Add members</p>

            <div className={styles["add-members__item"]}>
                <ListWithSearch
                    itemType={DATA_LIST_ITEM_TYPES.SHARE_ALERTS}
                    dataList={normalizedListOfUsers}
                    placeholder="Search users"
                    isInitialOpenDropdown={false}
                    handleClick={handleAddUser}
                />
            </div>
            <div className={styles["add-members__item"]}>
                <SelectableList
                    list={selectedUsers}
                    handleDelete={handleRemoveUser}
                />
            </div>
            <div className={styles["add-members__buttons"]}>
                <div className={styles["add-members__button"]}>
                    <Button
                        handleClick={() => dispatch(clearModalState())}
                        label={"Cancel"}
                    />
                </div>
                <div className={styles["add-members__button"]}>
                    <Button
                        type={"submit"}
                        label={"Save group"}
                        viewType={VIEW_TYPES_LIST.PRIMARY}
                        loading={isWorking}
                        disabled={isWorking}
                    />
                </div>
            </div>
        </form>
    );
};

AddMembers.propTypes = {
    id: PropTypes.string.isRequired,
};

export default AddMembers;
