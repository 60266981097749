import { types } from './sidebar.types';

export const getUserAgenciesRequest = () => ({
    type: types.GET_USER_AGENCIES_REQUEST,
});

export const getUserAgenciesSuccess = (data) => ({
    type: types.GET_USER_AGENCIES_SUCCESS,
    payload: data,
});

export const getUserAgenciesError = (error) => ({
    type: types.GET_USER_AGENCIES_ERROR,
    payload: error,
});

export const setPopoverContent = (data) => ({
    type: types.SET_POPOVER_CONTENT,
    payload: data,
});

export const showPopoverContent = () => ({
    type: types.SHOW_POPOVER_CONTENT,
});

export const closePopover = () => ({
    type: types.CLOSE_POPOVER,
});

export const setNamedList = (data) => ({
    type: types.SET_NAMED_LIST,
    payload: (data)
});
