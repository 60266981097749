/* eslint-disable keyword-spacing */
import * as actions from './cities-and-agencies.actions';
import * as requests from '../../services/requests/cities-and-agencies/cities-and-agencies';
import { setUserSelectedLocation } from '../user/user.actions';
import { userSelectedLocationInfoSelector } from '../user/user.selectors';

export const getCitiesOperation = () => (dispatch) => {
    dispatch(actions.getCitiesRequest());

    return requests
        .getCities()
        .then((response) => {
            dispatch(actions.getCitiesSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getCitiesError(error));
        });
};

export const getAgenciesOperation = () => (dispatch, getState) => {
    dispatch(actions.getAgenciesRequest());

    const currentUserLocation = userSelectedLocationInfoSelector(getState());

    return requests
        .getAgencies()
        .then((response) => {
            dispatch(actions.getAgenciesSuccess(response.data));
            if (currentUserLocation.agenciesId === '') {
                dispatch(
                    setUserSelectedLocation({
                        agencyId: response.data[0].agencies_id,
                    })
                );
            }
        })
        .catch((error) => {
            dispatch(actions.getAgenciesError(error));
        });
};
