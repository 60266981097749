import { types } from "./groups.types";

// groups list
export const getGroupsListRequest = () => ({
    type: types.GET_GROUPS_LIST_REQUEST,
});

export const getGroupsListSuccess = (data = {}) => ({
    type: types.GET_GROUPS_LIST_SUCCESS,
    payload: data,
});

export const getGroupsListError = (error) => ({
    type: types.GET_GROUPS_LIST_ERROR,
    payload: error,
});

// users list

export const getUsersListRequest = () => ({
    type: types.GET_USERS_LIST_REQUEST,
});

export const getUsersListSuccess = (data = {}) => ({
    type: types.GET_USERS_LIST_SUCCESS,
    payload: data,
});

export const getUsersListError = (error) => ({
    type: types.GET_USERS_LIST_ERROR,
    payload: error,
});

// new group

export const addNewGroupRequest = () => ({
    type: types.ADD_NEW_GROUP_REQUEST,
});

export const addNewGroupSuccess = (data = []) => ({
    type: types.ADD_NEW_GROUP_SUCCESS,
    payload: data,
});

export const addNewGroupError = (error) => ({
    type: types.ADD_NEW_GROUP_ERROR,
    payload: error,
});

// add members

export const addNewMembersRequest = () => ({
    type: types.ADD_NEW_MEMBERS_REQUEST,
});

export const addNewMembersSuccess = (data = []) => ({
    type: types.ADD_NEW_MEMBERS_SUCCESS,
    payload: data,
});

export const addNewMembersError = (error) => ({
    type: types.ADD_NEW_MEMBERS_ERROR,
    payload: error,
});

// delete group

export const deleteGroupRequest = () => ({
    type: types.DELETE_GROUP_REQUEST,
});

export const deleteGroupSuccess = (data = []) => ({
    type: types.DELETE_GROUP_SUCCESS,
    payload: data,
});

export const deleteGroupError = (error) => ({
    type: types.DELETE_GROUP_ERROR,
    payload: error,
});

// delete group

export const deleteGroupMemberRequest = () => ({
    type: types.DELETE_GROUP_MEMBER_REQUEST,
});

export const deleteGroupMemberSuccess = (data = []) => ({
    type: types.DELETE_GROUP_MEMBER_SUCCESS,
    payload: data,
});

export const deleteGroupMemberError = (error) => ({
    type: types.DELETE_GROUP_MEMBER_ERROR,
    payload: error,
});

// edit group

export const editGroupNameRequest = () => ({
    type: types.EDIT_GROUP_NAME_REQUEST,
});

export const editGroupNameSuccess = (data = []) => ({
    type: types.EDIT_GROUP_NAME_SUCCESS,
    payload: data,
});

export const editGroupNameError = (error) => ({
    type: types.EDIT_GROUP_NAME_ERROR,
    payload: error,
});
