import * as actions from './spm.actions';
import * as requests from '../../../services/requests/intersections/spm';

export const getIntersectionsListOperation = () => (dispatch) => {
    dispatch(actions.getIntersectionsListRequest());

    return requests
        .getIntersectionsListRequest()
        .then((response) => {
            const firstIntersectionId = response.data[0].signal_pk;

            dispatch(actions.getIntersectionsListSuccess(response));
            dispatch(getIntersectionByIdOperation(firstIntersectionId));
        })
        .catch((error) => {
            dispatch(actions.getIntersectionsListError(error));
        });
};

export const getIntersectionByIdOperation
    = (id = '') =>
        (dispatch) => {
            dispatch(actions.getIntersectionByIdRequest(id));

            return requests
                .getIntersectionByIdRequest(id)
                .then((response) => {
                    const phaseList = JSON.parse(`[${response[0].phase_list.slice(1, -1)}]`);
                    response[0].phase_list = phaseList.map(Number);
                    dispatch(actions.getIntersectionByIdSuccess(response));
                })
                .catch((error) => {
                    dispatch(actions.getIntersectionByIdError(error));
                });
        };

export const getIntersectionHistoryOperation
    = (params = {intersections_id: 0}) => // intersections_id is required
        (dispatch) => {
            dispatch(actions.getIntersectionHistoryRequest());

            return requests
                .getIntersectionHistoryRequest(params)
                .then((response) => {
                    dispatch(actions.getIntersectionHistorySuccess(response));
                })
                .catch((error) => {
                    dispatch(actions.getIntersectionHistoryError(error));
                });
        };
