/* eslint-disable camelcase */
import { types } from './statistics.types';

const initialState = {
    tsp_calls: {
        data: {},
        loading: false,
        error: null
    },
    response_to_priority_request: {
        data: {},
        loading: false,
        error: null
    },
    green_light_success_rate: {
        data: {},
        loading: false,
        error: null
    },
    red_light_wait_time: {
        data: {},
        loading: false,
        error: null
    },
    travel_speed: {
        data: {},
        loading: false,
        error: null
    },
};

const intersectionStatistics = (state = initialState, { type, payload }) => {
    switch (type) {
    case types.GET_STATISTICS_TSP_CALLS_REQUEST:
        return {
            ...state,
            tsp_calls: {
                ...state.tsp_calls,
                loading: true,
            }
        };

    case types.GET_STATISTICS_TSP_CALLS_SUCCESS:
        return {
            ...state,
            tsp_calls: {
                ...state.tsp_calls,
                data: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_TSP_CALLS_ERROR:
        return {
            ...state,
            tsp_calls: {
                ...state.tsp_calls,
                error: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_RESPONSE_TO_PRIORITY_REQUEST:
        return {
            ...state,
            response_to_priority_request: {
                ...state.response_to_priority_request,
                loading: true,
            }
        };

    case types.GET_STATISTICS_RESPONSE_TO_PRIORITY_SUCCESS:
        return {
            ...state,
            response_to_priority_request: {
                ...state.response_to_priority_request,
                data: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_RESPONSE_TO_PRIORITY_ERROR:
        return {
            ...state,
            response_to_priority_request: {
                ...state.response_to_priority_request,
                error: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_GREEN_LIGHT_REQUEST:
        return {
            ...state,
            green_light_success_rate: {
                ...state.green_light_success_rate,
                loading: true,
            }
        };

    case types.GET_STATISTICS_GREEN_LIGHT_SUCCESS:
        return {
            ...state,
            green_light_success_rate: {
                ...state.green_light_success_rate,
                data: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_GREEN_LIGHT_ERROR:
        return {
            ...state,
            green_light_success_rate: {
                ...state.green_light_success_rate,
                error: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_RED_LIGHT_REQUEST:
        return {
            ...state,
            red_light_wait_time: {
                ...state.red_light_wait_time,
                loading: true,
            }
        };

    case types.GET_STATISTICS_RED_LIGHT_SUCCESS:
        return {
            ...state,
            red_light_wait_time: {
                ...state.red_light_wait_time,
                data: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_RED_LIGHT_ERROR:
        return {
            ...state,
            red_light_wait_time: {
                ...state.red_light_wait_time,
                error: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_TRAVEL_TIME_REQUEST:
        return {
            ...state,
            travel_speed: {
                ...state.travel_speed,
                loading: true,
            }
        };

    case types.GET_STATISTICS_TRAVEL_TIME_SUCCESS:
        return {
            ...state,
            travel_speed: {
                ...state.travel_speed,
                data: payload,
                loading: false,
            },
        };

    case types.GET_STATISTICS_TRAVEL_TIME_ERROR:
        return {
            ...state,
            travel_speed: {
                ...state.travel_speed,
                error: payload,
                loading: false,
            },
        };

    default:
        return state;
    }
};

export default intersectionStatistics;
