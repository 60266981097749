/* eslint-disable space-before-function-paren */
/* eslint-disable no-sequences */
/* eslint-disable keyword-spacing */
import axios from 'axios';
import { store } from '../../store/store';
import { BASE_URL } from './api.config';
import { route } from 'preact-router';
import { bookList } from '../../constants/bookList';
import {
    sessionAccessTokenSelector,
    sessionTokenExpirationSelector,
    sessionRefreshTokenSelector,
} from '../../routes/auth/auth.selectors';

import { clearSession, updateToken } from '../../routes/auth/auth.actions';
import { clearModalState } from '../../components/modal/modal.actions';
import jwtDecode from 'jwt-decode';

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if(error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

const apiClient = axios.create({
    baseURL: BASE_URL,
});

const abortController = new AbortController();

apiClient.interceptors.request.use(
    (config) => {
        const reduxStore = store.getState();
        const token = sessionAccessTokenSelector(reduxStore);
        const token_expiration = jwtDecode(token).exp;

        if (token_expiration && token_expiration * 1000 < Date.now() - 120000) { // 2 minutes
            console.log('token expired, returning to login');
            abortController.abort();
            store.dispatch(clearSession());
            store.dispatch(clearModalState());
            route(bookList.auth.login);
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers['Access-Control-Allow-Credentials'] = true,
        config.headers['Content-Type'] = 'application/json';

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => response,
    function (error) {
        console.error('should not be 401 error', error);
        return Promise.reject(error);
    }
    // function (error) {
    //     const originalRequest = error.config;
    //     if(error.response.status === 401 && !originalRequest._retry) {
    //         if(isRefreshing) {
    //             return new Promise(function (resolve, reject) {
    //                 failedQueue.push({ resolve, reject });
    //             })
    //                 .then((token) => {
    //                     originalRequest.headers.Authorization
    //                         = `Bearer ${token}`;
    //                     return axios(originalRequest);
    //                 })
    //                 .catch((err) => Promise.reject(err));
    //         }

    //         originalRequest._retry = true;
    //         isRefreshing = true;

    //         const reduxStore = store.getState();
    //         const refreshToken = sessionRefreshTokenSelector(reduxStore);

    //         axios.defaults.headers.common.Authorization
    //             = `Bearer ${refreshToken}`;

    //         return new Promise(function (resolve, reject) {
    //             axios
    //                 .post(`${BASE_URL}auth/refresh_token`)
    //                 .then((res) => {
    //                     if(res.status === 201) {
    //                         store.dispatch(updateToken(res.data));
    //                         axios.defaults.headers.common.Authorization
    //                             = `Bearer ${res.data.access_token}`;
    //                         originalRequest.headers.Authorization
    //                             = `Bearer ${res.data.access_token}`;
    //                         processQueue(null, res.data.access_token);
    //                         resolve(axios(originalRequest));
    //                     }
    //                 })
    //                 .catch((err) => {
    //                     processQueue(err, null);
    //                     reject(err);
    //                     store.dispatch(clearSession());
    //                     store.dispatch(clearModalState());
    //                 })
    //                 .finally(() => {
    //                     isRefreshing = false;
    //                 });
    //         });
    //     }
    //     return Promise.reject(error);
    // }
);

const { get, post, put, patch, delete: destroy } = apiClient;

export { get, post, put, patch, destroy };
