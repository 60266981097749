import { types } from './roles.types';

export const getRolesTypesListRequest = () => ({
    type: types.GET_ROLES_TYPES_LIST_REQUEST,
});

export const getRolesTypesListSuccess = (data = {}) => ({
    type: types.GET_ROLES_TYPES_LIST_SUCCESS,
    payload: data,
});

export const getRolesTypesListError = (error) => ({
    type: types.GET_ROLES_TYPES_LIST_ERROR,
    payload: error,
});

export const getAccessSettingsByTypeRequest = () => ({
    type: types.GET_ACCESS_SETTINGS_BY_TYPE_REQUEST,
});

export const getAccessSettingsByTypeSuccess = (data = {}) => ({
    type: types.GET_ACCESS_SETTINGS_BY_TYPE_SUCCESS,
    payload: data,
});

export const getAccessSettingsByTypeError = (error) => ({
    type: types.GET_ACCESS_SETTINGS_BY_TYPE_ERROR,
    payload: error,
});

export const setAccessSettingsParams = (data = {}) => ({
    type: types.SET_ACCESS_SETTINGS_PARAMS,
    payload: data
});


export const editAccessSettingsByTypeRequest = () => ({
    type: types.EDIT_ACCESS_SETTINGS_BY_TYPE_REQUEST,
});

export const editAccessSettingsByTypeSuccess = (data = {}) => ({
    type: types.EDIT_ACCESS_SETTINGS_BY_TYPE_SUCCESS,
    payload: data,
});

export const editAccessSettingsByTypeError = (error) => ({
    type: types.EDIT_ACCESS_SETTINGS_BY_TYPE_ERROR,
    payload: error,
});
