import { types } from './general.types';

const initialSate = {
    criticalAlerts: {
        list: [],
        loading: true,
        error: '',
    },
    trafficLights: {
        list: [],
        loading: true,
        error: '',
    },
    overallPerformance: {
        list: [],
        loading: true,
        filterParameter: '',
        error: '',
    },
    recentAlerts: {
        trafficLights: {
            list: [],
            loading: true,
            error: '',
        },
        vehicleInformation: {
            list: [],
            loading: true,
            error: '',
        },
        priorityRequests: {
            list: [],
            loading: true,
            error: '',
        },
    },
    systemWideAvgRedLightWaitTimeChart: {
        list: [],
        loading: true,
        filterParameter: '',
        viewType: '',
        error: '',
    },
    systemWideAvgGreenLightSuccessRateChart: {
        list: [],
        loading: true,
        filterParameter: '',
        viewType: '',
        error: '',
    },
    systemWideChart: {
        list: [],
        loading: true,
        filterParameter: '',
        viewType: '',
        error: '',
    },
    totalTSPCallsChart: {
        list: [],
        loading: true,
        filterParameter: '',
        viewType: '',
        error: '',
    },
    averageSpeedByRouteChart: {
        list: [],
        count: 0,
        loading: true,
        filterParameter: '',
        error: '',
    },
    averageRedLightWaitTimeChart: {
        list: [],
        count: 0,
        loading: true,
        filterParameter: '',
        error: '',
    },
    travelTimeByDay: {
        list: [],
        count: 0,
        loading: true,
        filterParameter: '',
        error: '',
    },
    avlHealth: {
        list: [],
        loading: true,
        error: '',
        count: 0,
        range: {
            start: '',
            end: '',
        }
    },
    signalConnectivity: {
        list: {},
        loading: true,
        error: '',
        count: 0,
        range: {
            start: '',
            end: '',
        }
    },
    tspRequestMessages: {
        list: {},
        loading: true,
        error: '',
        count: 0,
        range: {
            start: '',
            end: '',
        }
    },
    signalNetworkPerformance: {
        list: [],
        loading: true,
        error: '',
        count: 0,
        range: {
            start: '',
            end: '',
        }
    },
};

const dashboardGeneral = (state = initialSate, { type, payload }) => {
    switch (type) {
    // Critical Alerts

    case types.GET_CRITICAL_ALERTS_REQUEST:
        return {
            ...state,
            criticalAlerts: {
                ...state.criticalAlerts,
                loading: true,
            },
        };
    case types.GET_CRITICAL_ALERTS_SUCCESS:
        return {
            ...state,
            criticalAlerts: {
                ...state.criticalAlerts,
                list: payload,
                loading: false,
            },
        };
    case types.GET_CRITICAL_ALERTS_ERROR:
        return {
            ...state,
            criticalAlerts: {
                ...state.criticalAlerts,
                error: payload,
                loading: false,
            },
        };

        // Traffic Lights

    case types.GET_TRAFFIC_LIGHTS_REQUEST:
        return {
            ...state,
            trafficLights: {
                ...state.trafficLights,
                loading: true,
            },
        };
    case types.GET_TRAFFIC_LIGHTS_SUCCESS:
        return {
            ...state,
            trafficLights: {
                ...state.trafficLights,
                list: payload,
                loading: false,
            },
        };
    case types.GET_TRAFFIC_LIGHTS_ERROR:
        return {
            ...state,
            trafficLights: {
                ...state.trafficLights,
                error: payload,
                loading: false,
            },
        };

        // Overall Performance

    case types.GET_OVERALL_PERFORMANCE_REQUEST:
        return {
            ...state,
            overallPerformance: {
                ...state.overallPerformance,
                loading: true,
            },
        };
    case types.GET_OVERALL_PERFORMANCE_SUCCESS:
        return {
            ...state,
            overallPerformance: {
                ...state.overallPerformance,
                list: payload,
                loading: false,
            },
        };
    case types.GET_OVERALL_PERFORMANCE_ERROR:
        return {
            ...state,
            overallPerformance: {
                ...state.overallPerformance,
                error: payload,
                loading: false,
            },
        };

    case types.SET_OVERALL_PERFORMANCE_FILTER_PARAMETER:
        return {
            ...state,
            overallPerformance: {
                ...state.overallPerformance,
                filterParameter: payload,
            },
        };

        // Recent alerts -> Traffic Lights

    case types.GET_RECENT_ALERTS_TRAFFIC_LIGHTS_REQUEST:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                trafficLights: {
                    ...state.recentAlerts.trafficLights,
                    loading: true,
                },
            },
        };
    case types.GET_RECENT_ALERTS_TRAFFIC_LIGHTS_SUCCESS:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                trafficLights: {
                    ...state.recentAlerts.trafficLights,
                    list: payload,
                    loading: false,
                },
            },
        };
    case types.GET_RECENT_ALERTS_TRAFFIC_LIGHTS_ERROR:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                trafficLights: {
                    ...state.recentAlerts.trafficLights,
                    error: payload,
                    loading: false,
                },
            },
        };

        // Recent alerts -> Vehicle Information

    case types.GET_RECENT_ALERTS_VECHILE_INFORMATION_REQUEST:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                vehicleInformation: {
                    ...state.recentAlerts.vehicleInformation,
                    loading: true,
                },
            },
        };
    case types.GET_RECENT_ALERTS_VECHILE_INFORMATION_SUCCESS:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                vehicleInformation: {
                    ...state.recentAlerts.vehicleInformation,
                    list: payload,
                    loading: false,
                },
            },
        };
    case types.GET_RECENT_ALERTS_VECHILE_INFORMATION_ERROR:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                vehicleInformation: {
                    ...state.recentAlerts.vehicleInformation,
                    error: payload,
                    loading: false,
                },
            },
        };

        // Recent alerts -> Priority Requests

    case types.GET_RECENT_ALERTS_PRIORITY_REQUESTS_REQUEST:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                priorityRequests: {
                    ...state.recentAlerts.priorityRequests,
                    loading: true,
                },
            },
        };
    case types.GET_RECENT_ALERTS_PRIORITY_REQUESTS_SUCCESS:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                priorityRequests: {
                    ...state.recentAlerts.priorityRequests,
                    list: payload,
                    loading: false,
                },
            },
        };
    case types.GET_RECENT_ALERTS_PRIORITY_REQUESTS_ERROR:
        return {
            ...state,
            recentAlerts: {
                ...state.recentAlerts,
                priorityRequests: {
                    ...state.recentAlerts.priorityRequests,
                    error: payload,
                    loading: false,
                },
            },
        };

        // Systemwide average red light delay chart

    case types.GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_REQUEST:
        return {
            ...state,
            systemWideAvgRedLightWaitTimeChart: {
                ...state.systemWideAvgRedLightWaitTimeChart,
                loading: true,
            }
        };
    case types.GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_SUCCESS:
        return {
            ...state,
            systemWideAvgRedLightWaitTimeChart: {
                ...state.systemWideAvgRedLightWaitTimeChart,
                list: payload,
                error: '',
                loading: false,
            }
        };
    case types.GET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_ERROR:
        return {
            ...state,
            systemWideAvgRedLightWaitTimeChart: {
                ...state.systemWideAvgRedLightWaitTimeChart,
                error: payload,
                loading: false,
            }
        };
    case types.SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_VIEW_TYPE:
        return {
            ...state,
            systemWideAvgRedLightWaitTimeChart: {
                ...state.systemWideAvgRedLightWaitTimeChart,
                viewType: payload,
            }
        };
    case types.SET_SYSTEM_WIDE_AVG_RED_LIGHT_WAIT_CHART_FILTER_PARAMETER:
        return {
            ...state,
            systemWideAvgRedLightWaitTimeChart: {
                ...state.systemWideAvgRedLightWaitTimeChart,
                filterParameter: payload,
            }
        };

        // Systemwide average green light success rate chart

    case types.GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_REQUEST:
        return {
            ...state,
            systemWideAvgGreenLightSuccessRateChart: {
                ...state.systemWideAvgGreenLightSuccessRateChart,
                loading: true,
            }
        };
    case types.GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_SUCCESS:
        return {
            ...state,
            systemWideAvgGreenLightSuccessRateChart: {
                ...state.systemWideAvgGreenLightSuccessRateChart,
                list: payload && payload[0] && payload[0].direction ? payload : [],
                error: '',
                loading: false,
            }
        };
    case types.GET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_ERROR:
        return {
            ...state,
            systemWideAvgGreenLightSuccessRateChart: {
                ...state.systemWideAvgGreenLightSuccessRateChart,
                error: payload,
                loading: false,
            }
        };
    case types.SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_VIEW_TYPE:
        return {
            ...state,
            systemWideAvgGreenLightSuccessRateChart: {
                ...state.systemWideAvgGreenLightSuccessRateChart,
                viewType: payload,
            }
        };
    case types.SET_SYSTEM_WIDE_AVG_GREEN_LIGHT_SUCCESS_CHART_FILTER_PARAMETER:
        return {
            ...state,
            systemWideAvgGreenLightSuccessRateChart: {
                ...state.systemWideAvgGreenLightSuccessRateChart,
                filterParameter: payload,
            }
        };

        // Systemwide chart

    case types.GET_SYSTEM_WIDE_CHART_REQUEST:
        return {
            ...state,
            systemWideChart: {
                ...state.systemWideChart,
                loading: true,
            },
        };
    case types.GET_SYSTEM_WIDE_CHART_SUCCESS:
        return {
            ...state,
            systemWideChart: {
                ...state.systemWideChart,
                list: payload,
                loading: false,
            },
        };
    case types.GET_SYSTEM_WIDE_CHART_ERROR:
        return {
            ...state,
            systemWideChart: {
                ...state.systemWideChart,
                error: payload,
                loading: false,
            },
        };
    case types.SET_SYSTEM_WIDE_CHART_VIEW_TYPE:
        return {
            ...state,
            systemWideChart: {
                ...state.systemWideChart,
                viewType: payload,
            },
        };
    case types.SET_SYSTEM_WIDE_CHART_FILTER_PARAMETER:
        return {
            ...state,
            systemWideChart: {
                ...state.systemWideChart,
                filterParameter: payload,
            },
        };

        // Total TSP calls

    case types.GET_TOTAL_TSP_CALLS_CHART_REQUEST:
        return {
            ...state,
            totalTSPCallsChart: {
                ...state.totalTSPCallsChart,
                loading: true,
            },
        };
    case types.GET_TOTAL_TSP_CALLS_CHART_SUCCESS:
        return {
            ...state,
            totalTSPCallsChart: {
                ...state.totalTSPCallsChart,
                list: payload,
                loading: false,
            },
        };
    case types.GET_TOTAL_TSP_CALLS_CHART_ERROR:
        return {
            ...state,
            totalTSPCallsChart: {
                ...state.totalTSPCallsChart,
                error: payload,
                loading: false,
            },
        };
    case types.SET_TOTAL_TSP_CALLS_CHART_VIEW_TYPE:
        return {
            ...state,
            totalTSPCallsChart: {
                ...state.totalTSPCallsChart,
                viewType: payload,
            },
        };
    case types.SET_TOTAL_TSP_CALLS_CHART_FILTER_PARAMETER:
        return {
            ...state,
            totalTSPCallsChart: {
                ...state.totalTSPCallsChart,
                filterParameter: payload,
            },
        };

        // Average Speed By Route Chart

    case types.GET_AVERAGE_SPEED_BY_ROUTE_CHART_REQUEST:
        return {
            ...state,
            averageSpeedByRouteChart: {
                ...state.averageSpeedByRouteChart,
                loading: true,
            },
        };
    case types.GET_AVERAGE_SPEED_BY_ROUTE_CHART_SUCCESS:
        return {
            ...state,
            averageSpeedByRouteChart: {
                ...state.averageSpeedByRouteChart,
                ...payload,
                loading: false,
            },
        };
    case types.GET_AVERAGE_SPEED_BY_ROUTE_CHART_ERROR:
        return {
            ...state,
            averageSpeedByRouteChart: {
                ...state.averageSpeedByRouteChart,
                error: payload,
                loading: false,
            },
        };
    case types.SET_AVERAGE_SPEED_BY_ROUTE_CHART_FILTER_PARAMETER:
        return {
            ...state,
            averageSpeedByRouteChart: {
                ...state.averageSpeedByRouteChart,
                filterParameter: payload,
            },
        };

        // Average Red Light Delay Chart

    case types.GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_REQUEST:
        return {
            ...state,
            averageRedLightWaitTimeChart: {
                ...state.averageRedLightWaitTimeChart,
                loading: true,
            },
        };
    case types.GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_SUCCESS:
        return {
            ...state,
            averageRedLightWaitTimeChart: {
                ...state.averageRedLightWaitTimeChart,
                list: payload.list,
                count: payload.count,
                loading: false,
            },
        };
    case types.GET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_ERROR:
        return {
            ...state,
            averageRedLightWaitTimeChart: {
                ...state.averageRedLightWaitTimeChart,
                error: payload,
                loading: false,
            },
        };
    case types.SET_AVERAGE_RED_LIGHT_WAIT_TIME_CHART_PARAMETER:
        return {
            ...state,
            averageRedLightWaitTimeChart: {
                ...state.averageRedLightWaitTimeChart,
                filterParameter: payload,
            },
        };

    case types.GET_TRAVEL_TIME_BY_DAY_REQUEST:
        return {
            ...state,
            travelTimeByDay: {
                ...state.travelTimeByDay,
                loading: true,
            },
        };
    case types.GET_TRAVEL_TIME_BY_DAY_SUCCESS:
        return {
            ...state,
            travelTimeByDay: {
                ...state.travelTimeByDay,
                list: payload.list,
                count: payload.count,
                loading: false,
            },
        };
    case types.GET_TRAVEL_TIME_BY_DAY_ERROR:
        return {
            ...state,
            travelTimeByDay: {
                ...state.travelTimeByDay,
                error: payload,
                loading: false,
            },
        };
    case types.SET_TRAVEL_TIME_BY_DAY_PARAMETER:
        return {
            ...state,
            travelTimeByDay: {
                ...state.travelTimeByDay,
                filterParameter: payload,
            },
        };
    case types.GET_AVL_HEALTH_REQUEST:
        return {
            ...state,
            avlHealth: {
                ...state.avlHealth,
                error: '',
                list: [],
                loading: true,
            },
        };
    case types.GET_AVL_HEALTH_SUCCESS:
        return {
            ...state,
            avlHealth: {
                ...state.avlHealth,
                list: payload,
                error: '',
                loading: false,
            },
        };
    case types.GET_AVL_HEALTH_ERROR:
        return {
            ...state,
            avlHealth: {
                ...state.avlHealth,
                error: payload,
                loading: false,
                list: []
            },
        };
    case types.SET_AVL_HEALTH_RANGE:
        return {
            ...state,
            avlHealth: {
                ...state.avlHealth,
                range: payload,
            },
        };
    case types.GET_SIGNAL_CONNECTIVITY_REQUEST:
        return {
            ...state,
            signalConnectivity: {
                ...state.signalConnectivity,
                loading: true,
            },
        };
    case types.GET_SIGNAL_CONNECTIVITY_SUCCESS:
        return {
            ...state,
            signalConnectivity: {
                ...state.signalConnectivity,
                list: payload,
                loading: false,
            },
        };
    case types.GET_SIGNAL_CONNECTIVITY_ERROR:
        return {
            ...state,
            signalConnectivity: {
                ...state.signalConnectivity,
                error: payload,
                loading: false,
            },
        };
    case types.SET_SIGNAL_CONNECTIVITY_RANGE:
        return {
            ...state,
            signalConnectivity: {
                ...state.signalConnectivity,
                range: payload,
            },
        };
    case types.GET_TSP_REQUEST_MESSAGES_REQUEST:
        return {
            ...state,
            tspRequestMessages: {
                ...state.tspRequestMessages,
                loading: true,
            },
        };
    case types.GET_TSP_REQUEST_MESSAGES_SUCCESS:
        return {
            ...state,
            tspRequestMessages: {
                ...state.tspRequestMessages,
                list: payload,
                loading: false,
            },
        };
    case types.GET_TSP_REQUEST_MESSAGES_ERROR:
        return {
            ...state,
            tspRequestMessages: {
                ...state.tspRequestMessages,
                error: payload,
                loading: false,
            },
        };
    case types.SET_TSP_REQUEST_MESSAGES_RANGE:
        return {
            ...state,
            tspRequestMessages: {
                ...state.tspRequestMessages,
                range: payload,
            },
        };

    case types.GET_SIGNAL_NETWORK_PERFORMANCE_REQUEST:
        return {
            ...state,
            signalNetworkPerformance: {
                ...state.signalNetworkPerformance,
                loading: true,
            },
        };
    case types.GET_SIGNAL_NETWORK_PERFORMANCE_SUCCESS:
        return {
            ...state,
            signalNetworkPerformance: {
                ...state.signalNetworkPerformance,
                list: payload,
                loading: false,
            },
        };
    case types.GET_SIGNAL_NETWORK_PERFORMANCE_ERROR:
        return {
            ...state,
            signalNetworkPerformance: {
                ...state.signalNetworkPerformance,
                error: payload,
                loading: false,
            },
        };
    case types.SET_SIGNAL_NETWORK_PERFORMANCE_RANGE:
        return {
            ...state,
            signalNetworkPerformance: {
                ...state.signalNetworkPerformance,
                range: payload,
            },
        };

    default:
        return state;
    }
};

export default dashboardGeneral;
