import ContentLoader from "react-content-loader";

const SkeletonRecentAlertsPR = ({ colors, ...props }) => (
    <tr>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={42}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="20" y="20" rx="6" ry="6" width="30%" height="15" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={42}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="20" y="20" rx="6" ry="6" width="90%" height="15" />
            </ContentLoader>
        </th>

        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={42}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="65%" y="20" rx="6" ry="6" width="30%" height="12" />
            </ContentLoader>
        </th>
    </tr>
);

export default SkeletonRecentAlertsPR;
