import { types } from './modal.types';

const initialSate = {
    type: '',
    isVisible: false,
    data: {
        recipientsList: [],
        selectedList: [],
    },
};

const modal = (state = initialSate, { type, payload }) => {
    switch (type) {
    case types.SET_MODAL_STATE:
        return {
            ...state,
            isVisible: true,
            type: payload.type,
            data: {
                ...state.data,
                ...payload.data,
            },
        };
    case types.CLEAR_MODAL_STATE:
        return initialSate;
    default:
        return state;
    }
};

export default modal;
