import { h } from "preact";
import PropTypes from "prop-types";

import SvgIcon from "../../../../svg-icon";

import { SVG_CODES } from "../../../../svg-icon/svg-icon.constants";
import { getInitials } from "../../../../../../utils/stringMethods";

import styles from "./styles.scss";

const ShareAlertsItem = ({
    listItem: { id, value, notifications, ...itemRest },
    handleClick,
}) => {

    return (
        <div
            key={id + "added"}
            className={styles["share-alerts-item"]}
            onClick={() =>
                handleClick({ id, value, notifications, ...itemRest })
            }
        >
            <div className={styles["share-alerts-item__logo"]}>
                <p className={styles["share-alerts-item__logo-initials"]}>
                    {getInitials(value)}
                </p>
            </div>
            <p className={styles["share-alerts-item__name"]}>{value}</p>
            {notifications && (
                <div className={styles["share-alerts-item__icon-muted"]}>
                    <SvgIcon type={SVG_CODES.MUTED} />
                </div>
            )}
            <div className={styles["share-alerts-item__action-text"]}>
                Press enter to select
            </div>
        </div>
    );
};

ShareAlertsItem.propTypes = {
    listItem: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default ShareAlertsItem;
