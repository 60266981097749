export const DATA_LIST_ITEM_TYPES = {
    WIDGETS: 'widgets',
    SHARE_ALERTS: 'share_alerts',
    ROUTES: 'routes',
    INTERSECTION_SPM: 'intersection_spm',
};

export const VIEW_TYPES = {
    OPEN: 'open',
    CLOSE: 'close',
};
