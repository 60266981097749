import { types } from './roles.types';

const initialState = {
    roles: {
        list: [],
        loading: true,
        error: '',
    },
    settings: {
        list: {},
        params: {
            rolesName: '',
            roleId: '',
        },
        loading: true,
        error: '',
    },
};

const requests = (state = initialState, { type, payload }) => {
    switch (type) {
    case types.GET_ROLES_TYPES_LIST_REQUEST:
        return {
            ...state,
            roles: {
                ...state.roles,
                loading: true,
            },
        };
    case types.GET_ROLES_TYPES_LIST_SUCCESS:
        return {
            ...state,
            roles: {
                ...state.roles,
                list: payload,
                loading: false,
            },
        };
    case types.GET_ROLES_TYPES_LIST_ERROR:
        return {
            ...state,
            roles: {
                ...state.roles,
                error: payload,
                loading: false,
            },
        };

    case types.GET_ACCESS_SETTINGS_BY_TYPE_REQUEST:
        return {
            ...state,
            settings: {
                ...state.settings,
                loading: true,
            },
        };
    case types.GET_ACCESS_SETTINGS_BY_TYPE_SUCCESS:
        return {
            ...state,
            settings: {
                ...state.settings,
                list: payload,
                loading: false,
            },
        };
    case types.GET_ACCESS_SETTINGS_BY_TYPE_ERROR:
        return {
            ...state,
            settings: {
                ...state.settings,
                error: payload,
                loading: false,
            },
        };


    case types.SET_ACCESS_SETTINGS_PARAMS:
        return {
            ...state,
            settings: {
                ...state.settings,
                params: payload,
            },
        };

    default:
        return state;
    }
};

export default requests;
