export const types = {
    GET_STATISTICS_TSP_CALLS_REQUEST:
        'intersection/statistics/GET_STATISTICS_TSP_CALLS_REQUEST',
    GET_STATISTICS_TSP_CALLS_SUCCESS:
        'intersection/statistics/GET_STATISTICS_TSP_CALLS_SUCCESS',
    GET_STATISTICS_TSP_CALLS_ERROR:
        'intersection/statistics/GET_STATISTICS_TSP_CALLS_ERROR',

    GET_STATISTICS_RESPONSE_TO_PRIORITY_REQUEST:
        'intersection/statistics/GET_STATISTICS_RESPONSE_TO_PRIORITY_REQUEST',
    GET_STATISTICS_RESPONSE_TO_PRIORITY_SUCCESS:
        'intersection/statistics/GET_STATISTICS_RESPONSE_TO_PRIORITY_SUCCESS',
    GET_STATISTICS_RESPONSE_TO_PRIORITY_ERROR:
        'intersection/statistics/GET_STATISTICS_RESPONSE_TO_PRIORITY_ERROR',

    GET_STATISTICS_GREEN_LIGHT_REQUEST:
        'intersection/statistics/GET_STATISTICS_GREEN_LIGHT_REQUEST',
    GET_STATISTICS_GREEN_LIGHT_SUCCESS:
        'intersection/statistics/GET_STATISTICS_GREEN_LIGHT_SUCCESS',
    GET_STATISTICS_GREEN_LIGHT_ERROR:
        'intersection/statistics/GET_STATISTICS_GREEN_LIGHT_ERROR',

    GET_STATISTICS_RED_LIGHT_REQUEST:
        'intersection/statistics/GET_STATISTICS_RED_LIGHT_REQUEST',
    GET_STATISTICS_RED_LIGHT_SUCCESS:
        'intersection/statistics/GET_STATISTICS_RED_LIGHT_SUCCESS',
    GET_STATISTICS_RED_LIGHT_ERROR:
        'intersection/statistics/GET_STATISTICS_RED_LIGHT_ERROR',

    GET_STATISTICS_TRAVEL_TIME_REQUEST:
        'intersection/statistics/GET_STATISTICS_TRAVEL_TIME_REQUEST',
    GET_STATISTICS_TRAVEL_TIME_SUCCESS:
        'intersection/statistics/GET_STATISTICS_TRAVEL_TIME_SUCCESS',
    GET_STATISTICS_TRAVEL_TIME_ERROR:
        'intersection/statistics/GET_STATISTICS_TRAVEL_TIME_ERROR',

};
