import { pageTitlesConfig } from "./header.config";

export const setPageTitle = (url) => {
    let currentPageTitle = "";

    Object.keys(pageTitlesConfig).forEach((key) => {
        if (url.includes(key)) currentPageTitle = pageTitlesConfig[key];
    });

    return currentPageTitle;
};
