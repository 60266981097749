/* eslint-disable keyword-spacing */
/* eslint-disable max-len */
export const LONG_DESCRIPTIONS = {
    'DASHBOARD.AVERAGE_RED_LIGHT_WAIT_TIME_DELAY_OVER_TIME':
      'Cumulative red light wait time for all tracked transit vehicles traveling in the selected direction each day (only intersections with LYT.transit enabled are included). (Lower is better)',
    'DASHBOARD.AVERAGE_GREEN_LIGHT_SUCCESS_RATE_OVER_TIME':
      'Rate of success of when at tracked transit vehicle arrived at a LYT.transit enabled intersection and received a green light without stopping (must be traveling faster than 1.5 mph). (Higher is better)',
    'DASHBOARD.GREEN_LIGHT_SUCCESS_RATE_PER_HOUR':
      'Percentage of TSP requests made by the tracked transit vehicle that was honored by the traffic controller, segmented by hourly time bands. (Higher is better)',
    'DASHBOARD.TOTAL_TSP_CALLS_PER_HOUR':
      'Number of TSP requests made agency-wide at LYT.transit enabled intersections',
    'DASHBOARD.AVERAGE_SPEED_BY_ROUTE':
      'Average overall speed of tracked transit vehicle by route and direction.',
    'DASHBOARD.AVERAGE_RED_LIGHT_DELAY_BY_INTERSECTION': 'List of intersections sorted by the longest amount of time that tracked transit vehicles spent delayed at a red light.',
    'DASHBOARD.AVL_HEALTH': 'The number of messages received from the agency\'s onboard Automatic Vehicle Location system in five minute intervals. (Higher is better)',
    'DASHBOARD.SIGNAL_CONNECTIVITY': 'The percentage of signals connected to the LYT system in the five minute intervals broken out by traffic partner. (Higher is better)',
    'DASHBOARD.TRAVEL_TIME_BY_DAY': 'Average trip duration for tracked trips through TSP-enabled segments of the route. Includes bus stop dwell time. (Resets daily)',
    'DASHBOARD.TSP_REQUEST_MESSAGES': 'The number of TSP request messages generated by the LYT system in five minute intervals, broken out by traffic partner.  These include TSP check-ins, ETA updates, clear, cancel, and check-out messages. (Higher is better)',
    'DASHBOARD.SIGNAL_NETWORK_PERFORMANCE': 'Average latency between Maestro and traffic signal as well as SNMP error count per traffic partner in five-minute buckets. (Lower is better)',
    'ROUTES.TSP_STATISTICS.INCOMING_TSP_REQUESTS':
        'Number of TSP requests made route-wide at LYT.transit enabled intersections. ',
    'ROUTES.TSP_STATISTICS.TSP_REQUEST_SUCCESS_RATE':
        'Percentage of TSP requests made by the tracked transit vehicle on this route that were honored by the traffic controller.',
    'ROUTES.TSP_STATISTICS.TSP_REQUEST_ERRORS':
        'Number of TSP requests made by a tracked transit vehicle that was not honored by the traffic controller.',
    'ROUTES.TSP_STATISTICS.ON_TIME_VEHICLES':
        'Percentage of tracked transit vehicles on this route that were on-time (per agency rules).',
    'ROUTES.TSP_STATISTICS.TSP_ACTIVITY':
        'Total overall number of Transit Signal Priority requests made, reflected as successful vs. failed vs. total requests.',
    'ROUTES.PERFORMANCE.SCHEDULED_TRIP_DURATION':
        'Scheduled trip duration along TSP-enabled intersections for the selected route. (Resets daily)',
    'ROUTES.PERFORMANCE.ACTUAL_TRIP_DURATION':
        'Cumulative time spent by tracked transit vehicles traveling through LYT.transit enabled intersections on this route only. (Resets daily)',
    'ROUTES.PERFORMANCE.SIGNAL_DELAY':
        'Average time a tracked transit vehicle spent delayed at a red light on a trip.',
    'ROUTES.PERFORMANCE.AVERAGE_BUS_SPEED':
        'Average speed of all tracked transit vehicles along this specific route.  Excludes time spent at bus stops. Resets Daily.',
    'ROUTES.PERFORMANCE.ROUTE_PERFORMANCE_BY_STOP':
        'Average On-time performance of tracked transit vehicle at each stop. Negative is late. Positive is early. (Resets daily)',
    'ROUTES.PERFORMANCE.TRAVEL_TIME':
        'Average travel time for tracked trips by hour of the day through TSP-enabled segments of the route. (Resets daily)',
    'ROUTES.PERFORMANCE.BUS_STOP_DWELL_TIME':
        'Cumulative time spent at bus stops in one direction along TSP-enabled segments of the selected route, averaged daily.',
    'ROUTES.PERFORMANCE.HEADWAY':
        'Average time between consecutive arrivals of transit vehicles at a given stop serving the selected route in one direction.',
    'ROUTES.PERFORMANCE.SEGMENT_TRAVEL_TIME':
        'Average travel time between two consecutive bus stops.',

    'INTERSECTIONS.PREEMPT_CHANNELS': 'No help available',
    'INTERSECTIONS.PHASES': 'No help available',

    'On Time Vehicles': 'Average on-time performance of all of the agency\'s transit buses tracked by LYT.',
    'Daily Signal Delay Per Vehicle':
        'Total amount of time spent at red lights per day per vehicle at TSP-enabled intersections.',
    'Lost Connections With Controllers':
        'The number of signals that are currently not communicating with LYT. May also indicate a disrupted connection between the traffic signal and your agency’s network.',
    'Average Bus Update Interval':
        'The amount of time in milliseconds between CAD/AVL updates from a bus assigned to a TSP-provided route.',
    'Average Controller Latency':
        'Average latency time between LYT’s on-premise “Maestro” device and all traffic controllers connected to LYT (lower is better).',
    'TSP Request Success Rate':
        'Percentage of TSP requests made by the tracked transit vehicle on this route that were honored by the traffic controller.',

    'Incoming TSP Requests':
        'Number of TSP requests made route-wide at LYT.transit enabled intersections. ',
    'TSP Request Error Rate':
        'Percentage of TSP requests made by the transit vehicle that was not honored by the traffic controller.',

    'Scheduled Trip Duration':
        'Scheduled trip duration along TSP-enabled intersections for the selected route. (Resets daily)',
    'Actual Trip Duration':
        'Cumulative time spent by tracked transit vehicles traveling through LYT.transit enabled intersections on this route only. (Resets daily)',

    'Average Bus Speed': 'Average speed of all tracked transit vehicles along this specific route.  Excludes time spent at bus stops (Resets daily).',

    'TSP Request Errors':
        'Number of TSP requests made by a tracked transit vehicle that was not honored by the traffic controller.',

};
