import PropTypes from 'prop-types';
import { useRef, useEffect } from 'preact/hooks';

import SvgIcon from '../ui-core/svg-icon';

import { SVG_CODES } from '../ui-core/svg-icon/svg-icon.constants';

import styles from './styles.scss';

const Modal = ({ children, onClose }) => {
    const backdropRef = useRef();

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);

        return () => window.removeEventListener('keydown', handleKeyPress);
    });

    const handleKeyPress = (e) => {
        if(e.code !== 'Escape') return;

        onClose();
    };

    const handleBackdropClick = ({ target }) => {
        const { current } = backdropRef;

        if(current && target !== current) return;
        onClose();
    };

    return (
        <div
            className={styles.modal__backdrop}
            ref={backdropRef}
            onClick={handleBackdropClick}
        >
            <div className={styles.modal}>
                {children}
                <div onClick={onClose} className={styles.modal__close}>
                    <div className={styles['modal__close-icon']}>
                        <SvgIcon type={SVG_CODES.CROSS} />
                    </div>
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.object,
};

export default Modal;
