/* eslint-disable camelcase */
export const listOfTableTypesOptions = [
    { key: 'active-alerts', label: 'Active alerts', count: 108 },
    { key: 'all-alerts', label: 'All alerts', count: 0 },
    { key: 'muted-alerts', label: 'Muted alerts', count: 2 },
];

export const temporaryDataMyAlertsSTM = [
    {
        id: '1414dfdf1414',
        alert: 'Unable to connect to the system',
        date: '16/09/2021, 8:12:19 AM',
        status: 'fixed',
        type: 'lost-connection',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
    {
        id: '1414dfd234234f1414',
        alert: 'SE Division St & NW Eastwood Ave lost signal connection',
        date: '16/09/2021, 8:12:19 AM',
        status: 'none',
        type: 'traffic-lights',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
    {
        id: '1414df2334d234234f1414',
        alert: 'SE Division St & NW Eastwood Ave',
        date: '16/09/2021, 8:12:19 AM',
        status: 'OPENED',
        type: 'traffic-lights',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
    {
        id: '1414dfd234234f141445654',
        alert: 'Missing LYT cloud system logs from ',
        date: '16/09/2021, 8:12:19 AM',
        status: 'fixed',
        type: 'logs-file',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
    {
        id: '1414df2334d234234f1414',
        alert: 'AVL Communication with the vehicle  has been lost',
        date: '16/09/2021, 8:12:19 AM',
        status: 'NONE',
        type: 'bus',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
    {
        id: '1414dfd234234f141445654',
        alert: 'Unable to connect to the system',
        date: '16/09/2021, 8:12:19 AM',
        status: 'fixed',
        type: 'logs-file',

        history_changes: [
            {
                sender: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '1s1d2rtewqwe14eddfdfd',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214eeeeddsdfdffdfd',
            },
            {
                sender: {
                    first_name: 'Margarett',
                    last_name: 'Pittman',
                },
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'Internal',
                date: '16/09/2021, 8:12:19',
                id: '11214edererfdfdwer',
            },
            {
                sender: null,
                resipient: {
                    first_name: 'Mike',
                    last_name: 'Fernando',
                },
                notification_method: 'External',
                date: '16/09/2021, 8:12:19',
                id: '1s1d214qweqweeddsdfdffdfd',
            },
        ],
        recipients: [
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '454545344444324523',
                notifications: false,
            },
            {
                first_name: 'Mike',
                last_name: 'Fernando',
                id: '454556789765434534324523',
                notifications: false,
            },
            {
                first_name: 'Margarett',
                last_name: 'Pittman',
                id: '423223324534324523',
                notifications: false,
            },
            {
                first_name: 'Craig',
                last_name: 'Henderson',
                id: '4547654354534324523',
                notifications: false,
            },
            {
                first_name: 'Drake',
                last_name: 'Baould',
                id: '45454534ewewe324523',
                notifications: false,
            },
        ],
    },
];
