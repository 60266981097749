import PropTypes from 'prop-types';

import { VIEW_TYPES_LIST, CONFIG_LIST } from './ui-issue-message.constants';

import styles from './styles.scss';

const UIIssueMessage = ({
    viewType = VIEW_TYPES_LIST.HAS_NO_DATA,
    height = 100,
}) => {
    const config = CONFIG_LIST[viewType];
    const className = `${styles['ui-issue-message']} ${styles[`ui-issue-message--${viewType}`]} ui-issue-message--g`;

    return (
        <div style={{ minHeight: height }} className={className}>
            <div className={styles['ui-issue-message__inner-container']}>
                <p className={styles['ui-issue-message__title']}>
                    {config.title}
                </p>
                <p className={styles['ui-issue-message__description']}>
                    {config.description}
                </p>

                {/* <div className={styles['ui-issue-message__button']}>
                    <Button
                        handleClick={config.button.action}
                        viewType="outline"
                        label={config.button.label}
                        disabled={true}
                    />
                </div> */}
            </div>
        </div>
    );
};

UIIssueMessage.propTypes = {
    viewType: PropTypes.oneOf(Object.values(VIEW_TYPES_LIST)),
    height: PropTypes.number,
};

export default UIIssueMessage;
