export const criticalAlertsSelector = (state) =>
    state.dashboardGeneral.criticalAlerts;

export const trafficLightSelector = (state) =>
    state.dashboardGeneral.trafficLights;

export const overallPerformanceSelector = (state) =>
    state.dashboardGeneral.overallPerformance;

export const overallPerformanceFilterParameterSelector = (state) =>
    state.dashboardGeneral.overallPerformance.filterParameter;

export const recentAlertsTrafficLightSelector = (state) =>
    state.dashboardGeneral.recentAlerts.trafficLights;

export const recentAlertsVechileInformationSelector = (state) =>
    state.dashboardGeneral.recentAlerts.vehicleInformation;

export const recentAlertsPriorityRequestsSelector = (state) =>
    state.dashboardGeneral.recentAlerts.priorityRequests;

export const getSystemWideAverageRedLightWaitChartSelector = (state) =>
    state.dashboardGeneral.systemWideChartFilterParameterSelector;

export const systemWideChartSelector = (state) =>
    state.dashboardGeneral.systemWideChart;

export const systemWideChartFilterParameterSelector = (state) =>
    state.dashboardGeneral.systemWideChart.filterParameter;

export const getTotalTSPCallsChartSelector = (state) =>
    state.dashboardGeneral.totalTSPCallsChart;

export const totalTSPCallsChartFilterParameterSelector = (state) =>
    state.dashboardGeneral.totalTSPCallsChart.filterParameter;

export const getAverageSpeedByRouteChartSelector = (state) =>
    state.dashboardGeneral.averageSpeedByRouteChart;

export const averageSpeedByRouteChartFilterParameterSelector = (state) =>
    state.dashboardGeneral.averageSpeedByRouteChart.filterParameter;

export const getAverageRedLightWaitTimeChartSelector = (state) =>
    state.dashboardGeneral.averageRedLightWaitTimeChart;

export const averageRedLightWaitTimeChartFilterParameterSelector = (state) =>
    state.dashboardGeneral.averageRedLightWaitTimeChart.filterParameter;

export const getSystemWideAverageRedLightWaitTimeSelector = (state) =>
    state.dashboardGeneral.systemWideAvgRedLightWaitTimeChart;

export const getSystemWideAverageGreenLightSuccessRateSelector = (state) =>
    state.dashboardGeneral.systemWideAvgGreenLightSuccessRateChart;

export const getTravelTimeByDaySelector = (state) =>
    state.dashboardGeneral.travelTimeByDay;

export const getAVLHealthSelector = (state) =>
    state.dashboardGeneral.avlHealth;

export const getSignalConnectivitySelector = (state) =>
    state.dashboardGeneral.signalConnectivity;

export const getTSPRequestMessagesSelector = (state) =>
    state.dashboardGeneral.tspRequestMessages;

export const getSignalNetworkPerformanceSelector = (state) =>
    state.dashboardGeneral.signalNetworkPerformance;
