/* eslint-disable dot-notation */
/* eslint-disable no-useless-computed-key */
import styles from './styles.scss';

export const GEOJSON_DEFAULT_TYPE = {
    type: 'LineString',
};

export const GEOJSON_DEFAULT_PROPERTIES = {
    style: {
        color: '#000000',
        weight: 5,
    },
};

export const LAYERS_INDEX_NUMBERS = {
    ['route']: 0,
    ['bus-stop']: 1,
    ['insight']: 2,
    ['fire-station']: 3,
    ['hospital']: 4,
    ['traffic-light']: 5,
    ['intersection']: 6,
    ['cross-walk']: 7,
    ['buses']: 8,
};

export const MAP_STYLE = {
    WHITE_MAP: 'mapbox://styles/mapbox/light-v10',
    SATELLITE: 'mapbox://styles/mapbox/satellite-v9',
    DARK_MAP: 'mapbox://styles/ilyssssha/cl1c7ufpo000z15myjye823jn',
};

export const ROUTE_LAYER_IDS = {
    WHITE_MAP: 'route-layer',
    SATELLITE: 'route-layer-satellite',
    DAR_MAP: 'route-layer-dark',
};

export const MAP_STYLE_TYPES = {
    MAP_LIGHT: 'MAP_LIGHT',
    MAP_DARK: 'MAP_DARK',
    MAP_SATELLITE: 'MAP_SATELLITE',
};

export const ROUTE_COLORS_BY_MAP_STYLE = {
    MAP_LIGHT: [0, 0, 0],
    MAP_DARK: [187, 191, 200],
    MAP_SATELLITE: [255, 255, 255],
};

export const INITIAL_VIEW_STATE_PARAMS = {
    zoom: 10,
    pitch: 0,
    bearing: 0,
};

export const generateBusStopTooltipLayout = ({ id = '', name = '' }) =>
    `<div class="${styles['map__tooltip__bus-stop']}">
            <div class="${styles['map__tooltip__bus-stop__title']}">Stop ${id}</div>
            <div class="${styles['map__tooltip__bus-stop__data-titles']}">Stop name</div>
            <div class="${styles['map__tooltip__bus-stop__data-values']}">${name}</div>
            <div class="${styles['map__tooltip__bus-stop__data-titles']}">Last arrival time</div>
            <div class="${styles['map__tooltip__bus-stop__data-values']}">10:42:08 AM <div class="${styles['map__tooltip__bus-stop__status--on-time']}">ON TIME</div></div>
            <div class="${styles['map__tooltip__bus-stop__data-titles']}">Next arrival time</div>
            <div class="${styles['map__tooltip__bus-stop__data-values']}">10:46:23 AM <div class="${styles['map__tooltip__bus-stop__status--early']}">EARLY</div></div>
        </div>`;

export const generateTrafficLightTooltipLayout = () =>
    `<div class="${styles['map__tooltip__traffic-light']}">
        <div class="${styles['map__tooltip__traffic-light__title']}">SE Division St & NW Eastwood Ave</div>
        <div class="${styles['map__tooltip__traffic-light__data-titles']}">Phase 1</div>
        <div class="${styles['map__tooltip__traffic-light__phase']}">
            <div class="${styles['map__tooltip__traffic-light__phase__signal--sm']} ${styles['map__tooltip__traffic-light__phase__signal--green']}"></div>Green
        </div>
        <div class="${styles['map__tooltip__traffic-light__data-titles']}">Phase 2</div>
        <div class="${styles['map__tooltip__traffic-light__phase']}">
            <div class="${styles['map__tooltip__traffic-light__phase__signal--sm']} ${styles['map__tooltip__traffic-light__phase__signal--green']}"></div>Green
        </div>
        <div class="${styles['map__tooltip__traffic-light__data-titles']}">Phase 3</div>
        <div class="${styles['map__tooltip__traffic-light__phase']}">
            <div class="${styles['map__tooltip__traffic-light__phase__signal--sm']} ${styles['map__tooltip__traffic-light__phase__signal--red']}"></div>Red
        </div>
        <div class="${styles['map__tooltip__traffic-light__data-titles']}">Phase 4</div>
        <div class="${styles['map__tooltip__traffic-light__phase']}">
            <div class="${styles['map__tooltip__traffic-light__phase__signal--sm']} ${styles['map__tooltip__traffic-light__phase__signal--green']}"></div>Green
        </div>
        <div class="${styles['map__tooltip__traffic-light__data-titles']}">Incoming requests (today)</div>
        <div class="${styles['map__tooltip__traffic-light__phase']}">
            <div class="${styles['map__tooltip__traffic-light__phase__signal--xl']} ${styles['map__tooltip__traffic-light__phase__signal--green']}">
                <span class="${styles['map__tooltip__traffic-light__phase__signal__arrow']}"/>
            </div>68
        </div>
        <div class="${styles['map__tooltip__traffic-light__data-titles']}">Signal strategy</div>
        <div class="${styles['map__tooltip__traffic-light__signal-titles']}">Activated phase 2</div>
        <div class="${styles['map__tooltip__traffic-light__signal-titles']}">Activated phase 4</div>
    </div>`;

export const generateBusTooltipLayout = (id) =>
    `<div class="${styles['map__tooltip__bus']}">
        <div class="${styles['map__tooltip__bus__header']}">
            <span class="${styles['map__tooltip__bus__header__icon']}"></span>
            <div class="${styles['map__tooltip__bus__header__title']}">TriMet ${id}</div>
        </div>
        <div class="${styles['map__tooltip__bus__data-title']}">
            Route
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            ML King – Jr Blvd
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Delay
        </div>
        <div class="${styles['map__tooltip__bus__delay']}">
            <div class="${styles['map__tooltip__bus__data-value']}">-116 sec</div>
            <div class="${styles['map__tooltip__bus__delay__status--early']}">EARLY</div>
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Next stop
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            Berryessa Transit Center (bay 2)
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Next stop scheduled arrival
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            10:40:32 PM
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Next stop fact arrival
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            10:42:38 PM
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Priority signal
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            MaxGreen
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Status
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            No call
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Priority status
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            No call
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Last Updated
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            11:52:54 AM (20 seconds ago)
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Cardinal direction
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            98 degress ESE
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Speed
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            26 mph
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Occupancy
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            20%
        </div>

        <div class="${styles['map__tooltip__bus__data-title']}">
            Signal strategy
        </div>
        <div class="${styles['map__tooltip__bus__data-value']}">
            Inactive
        </div>
    </div>`;

export const generateWarningTooltipLayout = () =>
    `<div class="${styles['map__tooltip__warning']}">
        Vehicle’s speed on this stretch was increased by 10 mph compared to yesterday
    </div>`;

export const generateBusIconLayout = () =>
    '<div class="bus-icon-container"><div class="bus-icon"></div><div class="bus-tooltip">7409</div></div>';

export const MARKERS_TYPES = {
    BUS: {
        size: [50, 50],
        className: 'bus',
    },
    BUS_STOP: {
        size: [24, 24],
        className: 'bus-stop-icon',
    },
    TRAFFIC_LIGHT_RG: {
        size: [24, 24],
        className: 'traffic-light-icon--rg',
    },
    TRAFFIC_LIGHT_RY: {
        size: [24, 24],
        className: 'traffic-light-icon--ry',
    },
    TRAFFIC_LIGHT_GY: {
        size: [24, 24],
        className: 'traffic-light-icon--gy',
    },
    TRAFFIC_LIGHT_BG: {
        size: [24, 24],
        className: 'traffic-light-icon--bg',
    },
    WARNING: {
        size: [24, 24],
        className: 'warning-icon',
    },
};
