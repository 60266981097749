/* eslint-disable space-before-function-paren */
/* eslint-disable keyword-spacing */
import { toast } from 'react-toastify';

import * as actions from './users.actions';
import * as requests from '../../../services/requests/users-and-permissions/users/users';
import { getCitiesOperation, getAgenciesOperation } from '../../../store/cities-and-agencies/cities-and-agencies.operations';
import { citiesSelector, agenciesSelector } from '../../../store/cities-and-agencies/cities-and-agencies.selectors';

export const getUsersListOperation = () => (dispatch, getState) => {
    dispatch(actions.getUsersListRequest());

    return requests
        .getAgencyUsersList()
        .then(async (response) => {
            const { list } = citiesSelector(getState());
            if (!list.length) {
                await dispatch(getCitiesOperation());
            }

            dispatch(actions.getUsersListSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getUsersListError(error));
        });
};

const getCitiesAndAgencies = async (dispatch, getState) => {
    const cities = citiesSelector(getState()).list;
    if (!cities.length) {
        await dispatch(getCitiesOperation());
    }

    const agencies = agenciesSelector(getState()).list;
    if (!agencies.length) {
        await dispatch(getAgenciesOperation());
    }
};

export const getAdminUsersListOperation = () => (dispatch, getState) => {
    dispatch(actions.getAdminListRequest());

    return requests
        .getAdminAgenciesList()
        .then(async (response) => {
            getCitiesAndAgencies(dispatch, getState);
            dispatch(actions.getAdminListSuccess(response));
            dispatch(actions.getUsersListRequest());
            requests.getAllUsers()
                .then(async (fullList) => {
                    dispatch(actions.mergeFullUserList(fullList));
                })
                .catch((fullListError) => {
                    dispatch(actions.getUsersListError(fullListError));
                });
        })
        .catch((error) => {
            dispatch(actions.getAdminListError(error));
        });
};

export const getAgencyAdminUsersListOperation = () => (dispatch, getState) => {
    dispatch(actions.getAdminListRequest());

    return requests
        .getAgencyUsersList()
        .then(async (response) => {
            getCitiesAndAgencies(dispatch, getState);
            dispatch(actions.getAdminListSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getAdminListError(error));
        });
};

export const getAllUsersListOperation = () => async (dispatch) => {
    dispatch(actions.getUsersListRequest());

    try {
        const response = await requests.getAllUsers();
        dispatch(actions.getUsersListSuccess(response.data));
    } catch (error) {
        dispatch(actions.getUsersListError(error));
    }
};

export const removeUserByIDOperation = (userID) => (dispatch) => {
    dispatch(actions.removeUserByIDRequest());

    return requests
        .removeUserByID(userID)
        .then(() => {
            dispatch(actions.removeUserByIDSuccess(userID));
            toast.success('User has been successfully deleted!');
        })
        .catch((error) => {
            toast.error(error.message);
        });
};

export const setUserRoleOperation = (params) => (dispatch) => {
    dispatch(actions.setUserRoleRequest());

    return requests
        .setUserRoleOrApprovedCities(params)
        .then(() => {
            dispatch(actions.setUserRoleSuccess(params));
        })
        .catch((error) => {
            toast.error(error.message);
            dispatch(actions.setUserRoleError(error));
            throw error;
        });
};

export const setUserApprovedCitiesOperation = (params) => (dispatch) =>
    requests
        .setUserRoleOrApprovedCities(params)
        .then((response) => {
            dispatch(actions.setUserApprovedCitiesSuccess(response.data[0]));
        })
        .catch((error) => {
            toast.error(error.message);
            dispatch(actions.updateUserError(error));
        });


export const setUserApprovedAgenciesOperation = (params) => async (dispatch) => {
    try {
        const response = await requests
            .setUserApprovedAgencies(params);
        dispatch(actions.setUserApprovedAgenciesSuccess(response.data[0]));
    } catch (error) {
        toast.error(error.message);
    }
};

export const updateUserOperation = (params) => async (dispatch) => {
    try {
        const response = await requests
            .updateUser(params);
        if (response) {
            toast.success('User updated');
            dispatch(actions.updateUserSuccess(params));
        } else {
            toast.error('User NOT updated');
        }
    } catch (error) {
        toast.error(error.message);
        dispatch(actions.updateUserError(error));
    }
};

export const removeUserApprovedAgencyOperation = (params) => async (dispatch) => {
    try {
        await requests
            .removeUserApprovedCityOrAgency(params);
        dispatch(actions.removeUserApprovedAgencySuccess(params));
    } catch (error) {
        toast.error(error.message);
    }
};
