/* eslint-disable prefer-reflect */
/* eslint-disable no-useless-catch */
/* eslint-disable space-before-function-paren */
import { get, post, patch, destroy } from '../../api';

export const getAlerts = async (offset, limit, sortKey, sortOrder, includeResolved, city_id) => {
    let params = [
        `${offset ? 'offset=' : ''}${offset ? offset : ''}`,
        `${limit ? 'limit=' : ''}${limit ? limit : ''}`,
        `${sortKey ? 'sortKey=' : ''}${sortKey ? sortKey : ''}`,
        `${sortOrder ? 'sortOrder=' : ''}${sortOrder ? sortOrder : ''}`,
        `${includeResolved ? 'includeResolved=true' : ''}`,
        `${city_id ? 'city_id=' : ''}${city_id ? city_id : ''}`
    ].filter(item => item.length > 0).join('&');
    params = params.length > 0 ? `?${params}` : '';

    const url = `/alerts${params}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const getAlert = async (params = {}) => {
    const { id = '1' } = params;
    const url = `/alerts/${id}`;

    try {
        const {
            data: { data },
        } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const addAlert = async (params = {}) => {
    const url = '/alerts';

    const body = {
        ...params
    };
    try {
        const {
            data: { data }
        } = await post(url, body);
        return data;
    } catch(err) {
        throw err;
    }
};

export const removeAlert = async (params = {}) => {
    const { id } = params;
    const url = `/alerts/${id}`;

    try {
        const {
            data: { data },
        } = await destroy(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const updateAlert = async (params = {}) => {
    const { id } = params;
    const newValues = Object.assign({}, params);
    delete newValues.id;
    const url = `/alerts/${id}`;

    const body = {
        ...newValues
    };
    try {
        const {
            data: { data },
        } = await patch(url, body);
        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};
