import { route } from 'preact-router';

const listOfParameterKeys = {
    type: 'type=',
    sort: 'sort=',
};

export const setTypeParamURL = (
    baseUrl = '',
    parameterKey = '',
    parameterValue = ''
) => {
    const type = listOfParameterKeys[parameterKey];
    const str = `${baseUrl}?${type}${parameterValue}`;

    return route(str);
};


export const getParamURL = (parameterKey) => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(parameterKey);
};
