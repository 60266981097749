/* eslint-disable camelcase */
import * as actions from './sidebar.actions';
import * as requests from '../../services/requests/sidebar/sidebar';
import { setUserSelectedLocationInitialOperation } from '../../store/user/user.operations';

export const getUserAgenciesOperation = () => (dispatch) => {
    dispatch(actions.getUserAgenciesRequest());

    return requests
        .getUserAgenciesRequest()
        .then((response) => {
            dispatch(actions.getUserAgenciesSuccess(response));
            dispatch(setUserSelectedLocationInitialOperation());
        })
        .catch((error) => {
            dispatch(actions.getUserAgenciesError(error));
        });
};
