import ContentLoader from "react-content-loader";

const SkeletonSidebarSelectorsGroup = ({ colors, ...props }) => (
    <ContentLoader
        width={"244"}
        height={68}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <rect x="52" y="20" rx="6" ry="6" width="50%" height="20" />
    </ContentLoader>
);

export default SkeletonSidebarSelectorsGroup;
