export const VIEW_TYPES = {
    ACTIONS: 'actions',
    PREVIEW: 'preview',
};

export const COLOR_TYPES = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    HIGHLIGHT: 'highlight'
};

