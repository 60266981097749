import { types } from './user.types';

const initialState = {
    theme: 'theme-light',
    selectedLocation: {
        agencyId: '',
        cityId: '',
    },
    cityInformation: {
        loading: false,
        error: '',
        data: {
            details: {
                center: {
                    lng: '',
                    lat: '',
                },
            },
        },
    },
    personalInformation: {
        id: '',
        user_id: '',
        email: '',
        username: '',
        firstname: '',
        lastname: '',
        usertype: '',
        loading: false,
        error: '',
        role: {
            role: null,
            roleParse: {},
        },
        picture: '',
    },
    changePassword: {
        passwordChanged: false,
        loading: false,
        error: ''
    },
    updateUser: {
        userUpdated: false,
        loading: false,
        error: ''
    }
};

const user = (state = initialState, { type, payload }) => {
    switch (type) {
    //city
    case types.GET_CITY_INFORMATION_BY_ID_REQUEST:
        return {
            ...state,
            cityInformation: {
                ...state.cityInformation,
                loading: true,
            },
        };

    case types.GET_CITY_INFORMATION_BY_ID_SUCCESS:
        return {
            ...state,
            cityInformation: {
                ...state.cityInformation,
                data: payload,
                loading: false,
            },
        };

    case types.GET_CITY_INFORMATION_BY_ID_ERROR:
        return {
            ...state,
            cityInformation: {
                ...initialState.cityInformation,
                error: payload,
                loading: false,
            },
        };

    case types.SET_USER_SELECTED_LOCATION_INFO:
        return {
            ...state,
            selectedLocation: {
                ...state.selectedLocation,
                ...payload,
            },
        };

    case types.SET_USER_PERSONAL_INFORMATION:
        return {
            ...state,
            personalInformation: {
                ...state.personalInformation,
                ...payload
            },
        };

    case types.SET_USER_PERSONAL_INFORMATION_REQUEST:
        return {
            ...state,
            updateUser: {
                ...state.updateUser,
                userUpdated: false,
                loading: true,
                error: 'checking'
            }
        };

    case types.SET_USER_PERSONAL_INFORMATION_SUCCESS:
        return {
            ...state,
            updateUser: {
                ...state.updateUser,
                loading: false,
                userUpdated: payload.data,
                error: ''
            }
        };

    case types.SET_USER_PERSONAL_INFORMATION_ERROR:
        return {
            ...state,
            updateUser: {
                ...state.updateUser,
                loading: false,
                error: payload,
                userUpdated: false
            }
        };

    case types.CHANGE_PASSWORD_REQUEST:
        return {
            ...state,
            changePassword: {
                ...state.changePassword,
                loading: true,
                passwordChanged: false,
                error: ''
            }
        };

    case types.CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            changePassword: {
                ...state.changePassword,
                loading: false,
                passwordChanged: payload.data,
                error: ''
            }
        };

    case types.CHANGE_PASSWORD_ERROR:
        return {
            ...state,
            changePassword: {
                ...state.changePassword,
                loading: false,
                error: payload.message,
                passwordChanged: false
            }
        };

    case types.GET_USER_PERSONAL_INFORMATION_REQUEST:
        return {
            ...state,
            personalInformation: {
                ...state.personalInformation,
                loading: true,
            },
        };
    case types.GET_USER_PERSONAL_INFORMATION_SUCCESS:
        return {
            ...state,
            personalInformation: {
                ...state.personalInformation,
                loading: false,
                ...payload,
            },
        };

    case types.GET_USER_PERSONAL_INFORMATION_ERROR:
        return {
            ...state,
            personalInformation: {
                ...state.personalInformation,
                loading: false,
                error: payload,
            },
        };

    case types.SET_USER_THEME:
        return {
            ...state,
            theme: payload,
        };

    case types.SET_USER_NAME:
        return {
            ...state,
            personalInformation: {
                ...state.personalInformation,
                username: payload,
            }
        };

    case types.CLEAR_USER_STORED_INFO:
        return initialState;

    default:
        return state;
    }
};

export default user;
