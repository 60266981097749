export const SIZES_LIST = {
  SM: 'sm',
  MD: "md",
  LG: "lg",
};


export const TYPES_LIST = {
  default: "default",
  primary: "primary",
};
