import ContentLoader from "react-content-loader";

const SkeletonRoutesPriorityRequests = ({ colors, ...props }) => (

    <ContentLoader
        speed={2}
        width={"100%"}
        height={47}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >

        <rect x="0" y="20" rx="6" ry="6" width="20%" height="20" />
        <rect x="25%" y="20" rx="6" ry="6" width="40%" height="20" />
        <rect x="70%" y="20" rx="6" ry="6" width="30%" height="20" />
    </ContentLoader>

);

export default SkeletonRoutesPriorityRequests;
