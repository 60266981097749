import * as yup from 'yup';

export const defaultWelcomeMessage = 'Welcome to the LYT Analytics Portal!';

yup.addMethod(yup.string, 'stripEmptyString', function() {
    // eslint-disable-next-line no-invalid-this
    return this.transform((value) => (value === '' ? undefined : value));
});

export const schema = yup.object().shape({
    type: yup.string(),
    email: yup
        .string()
        .email()
        .required('Email address is a required field')
        .trim(),
    agency: yup
        .string()
        .trim(),
    role: yup
        .string()
        .trim(),
});
