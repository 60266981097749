/* eslint-disable keyword-spacing */
/* eslint-disable react/jsx-key */
import Match, { Link } from 'preact-router/match';
import { route } from 'preact-router';
import { useEffect, useState, useRef } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import { adminData, primaryData, secondaryData, productionAdminData, productionData, productionSecondaryData} from './sidebar.config';
import { bookList } from '../../constants/bookList';

import Switch from './components/switch';
import SelectorsGroupe from './components/selectors-groupe/selectors-groupe.jsx';
import Badge from '../ui-core/badge';
import Profile from './components/profile';
import IconSVG from '../ui-core/svg-icon';

import { sidebarPopoverSelector } from './sidebar.selectors';
import { closePopover } from './sidebar.actions';

import styles from './styles.scss';

const Sidebar = () => {
    const dispatch = useDispatch();
    const smallerWidth = 1439;
    const smallerHeight = 880 + 44 * 2;

    const selectsGroup = useRef(null);
    const popoverElement = useRef(null);
    const primaryNavElement = useRef(null);

    const [isSidebarVisible, setIsSidebarVisible] = useState(!(window.innerWidth <= smallerWidth));
    const [isSidebarLong, setIsSidebarLong] = useState(window.innerHeight > smallerHeight);
    const [locationSelect, setLocationSelect] = useState(0);
    const [agencySelect, setAgencySelect] = useState(0);

    const mediaHeightQueryList = window.matchMedia(`(max-height: ${smallerHeight}px)`);
    const mediaWidthQueryList = window.matchMedia(`(max-width: ${smallerWidth}px)`);

    const { content, isShowing } = useSelector(sidebarPopoverSelector);

    const isProduction = process.env.npm_config_preact_app_environment === 'production';
    const isStage = isProduction && window.location.hostname.includes('stage');

    const handleHeightChange = mql => {
        setIsSidebarLong(!mql.matches);
    };

    const handleWidthChange = mql => {
        setIsSidebarVisible(!mql.matches);
    };

    // eslint-disable-next-line no-extra-parens
    const setActiveClassName = (path = '', key = '') => (path.includes(`/${key.toLowerCase()}/`)
        ? 'active'
        : '')
    ;

    const computeSelectorBaseLines = () => {
        if (selectsGroup.current.base) {
            let locationOffset = selectsGroup.current.base.lastChild;
            locationOffset = locationOffset.offsetTop + locationOffset.offsetHeight - primaryNavElement.current.scrollTop;
            setLocationSelect(locationOffset);
            let agencyOffset = selectsGroup.current.base.children[0];
            agencyOffset = agencyOffset.offsetTop + agencyOffset.offsetHeight - primaryNavElement.current.scrollTop;
            setAgencySelect(agencyOffset);
        }
    };

    const scrollHandler = () => {
        computeSelectorBaseLines();
    };

    useEffect(() => {
        mediaHeightQueryList.addEventListener('change', handleHeightChange);
        mediaWidthQueryList.addEventListener('change', handleWidthChange);
        primaryNavElement.current.addEventListener('scroll', scrollHandler);
        return () => {
            mediaHeightQueryList.removeEventListener('change', handleHeightChange);
            mediaWidthQueryList.removeEventListener('change', handleWidthChange);
            primaryNavElement.current.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    useEffect(() => {
        if (isShowing) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, [isShowing]);

    useEffect(() => {
        computeSelectorBaseLines();
    });

    const handleChangeLocation = () => {
        dispatch(closePopover());
    };

    const handleOutsideClick = event => {
        if (!popoverElement.current.contains(event.target) && !selectsGroup.current.base.contains(event.target)) {
            dispatch(closePopover());
        }
    };

    const addSelectPopover = (insides) => {
        if (insides && locationSelect > 0) {
            const topStyle = {top: insides.key === 'type-location'
                ? `${locationSelect}px`
                : `${agencySelect}px`};
            return <div className={styles['select-list-popover']}
                style={topStyle}
                onClick={handleChangeLocation}>
                {insides}
            </div>;
        }
    };

    return (
        <div className={`${styles.sidebar} ${styles[`sidebar${isSidebarVisible ? '--opened' : '--hidden'}`]}`}>
            <div
                className={styles.sidebar__logo}
                onClick={() => route(bookList.dashboard.entry_point)}
            >
                <IconSVG type="logo-primary" />
            </div>
            {
                window.innerWidth <= smallerWidth
                    ? <div
                        className={styles[`sidebar__menu-trigger${!isSidebarVisible ? '--hidden' : '--opened'}`]}
                        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                    >
                        {isSidebarVisible ? '-' : '+'}
                    </div>
                    : null
            }

            <div ref={primaryNavElement} className={styles['nav-primary']}>
                {(isProduction ? !isStage ? productionData : primaryData : primaryData)
                    .map(({ title, key, icon: Icon, href, isBadge, cName }) =>
                        <Match>
                            {({ url }) =>
                                <Link
                                    key={href + title}
                                    href={href}
                                    className={`${styles.nav__item} ${setActiveClassName(url, key)}`}
                                >
                                    <div className={`nav__icon ${cName}`}>{Icon}</div>
                                    <p className={styles.nav__text}>{title}</p>
                                    {isSidebarVisible && isBadge
                                        && <div className={styles.nav__badge}>
                                            <Badge viewType="number" content={100} />{' '}
                                        </div>
                                    }
                                </Link>
                            }

                        </Match>
                    )}
                {(isProduction
                    ? productionAdminData
                    : adminData).map(({ title, key, icon: Icon, href, isBadge, cName }) =>
                    <Match>
                        {({ url }) =>
                            <Link
                                key={href + title}
                                href={href}
                                className={`${styles.nav__item} ${setActiveClassName(url, key)}`}
                            >
                                <div className={`nav__icon ${cName}`}>{Icon}</div>
                                <p className={styles.nav__text}>{title}</p>
                                {isSidebarVisible && isBadge
                                    && <div className={styles.nav__badge}>
                                        <Badge viewType="number" content={100} />{' '}
                                    </div>
                                }
                            </Link>
                        }

                    </Match>
                )}
                { !isSidebarLong
                    && <>
                        {(isProduction
                            ? productionSecondaryData
                            : secondaryData).map(({ title, key, icon: Icon, href, cName }) =>
                            <Match>
                                {({ url }) =>
                                    <Link
                                        key={href + title}
                                        activeClassName={styles.active}
                                        href={href}
                                        className={`${styles.nav__item}  ${setActiveClassName(url, key)}`}
                                    >
                                        <div className={`nav__icon ${cName}`}>
                                            {Icon}
                                        </div>
                                        <p className={styles.nav__text}>{title}</p>
                                    </Link>
                                }
                            </Match>
                        )}
                        <SelectorsGroupe ref={selectsGroup} />
                    </>
                }

            </div>
            {isSidebarLong
                && <div className={styles['nav-secondary']}>
                    {(isProduction
                        ? productionSecondaryData
                        : secondaryData).map(({ title, key, icon: Icon, href, cName }) =>
                        <Match>
                            {({ url }) =>
                                <Link
                                    key={href + title}
                                    activeClassName={styles.active}
                                    href={href}
                                    className={`${styles.nav__item}  ${setActiveClassName(url, key)}`}
                                >
                                    <div className={`nav__icon ${cName}`}>
                                        {Icon}
                                    </div>
                                    <p className={styles.nav__text}>{title}</p>
                                </Link>
                            }
                        </Match>
                    )}
                    <SelectorsGroupe ref={selectsGroup} />
                </div>
            }


            <div className={styles['sidebar__inner-wrapper']}>
                <div className={styles['nav-bottom']}>
                    <Profile />
                    <Switch />
                </div>
            </div>

            <div ref={popoverElement}>
                {isShowing && addSelectPopover(content)}
            </div>
        </div>
    );
};

export default Sidebar;
