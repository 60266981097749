export const sessionSelector = (state) => state.session;

export const sessionAccessSelector = (state) => state.session.access;
export const sessionAccessTokenSelector = (state) => state.session.access_token;
export const sessionTokenExpirationSelector = (state) => state.session.token_expiration;
export const sessionRefreshTokenSelector = (state) => state.session.refresh_token;
export const sessionOTPSelector = (state) => state.session.otp;
export const sessionMessageSelector = (state) => state.session.message;
export const sessionAccountApprovedSelector = (state) => state.session.account_approved;
export const sessionLoggingInEmail = (state) => state.session.logging_in_email;
