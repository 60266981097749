export const hookFormGetErrorMessage = (name = "", errors = {}) => {
    const errorInObj = errors.hasOwnProperty(name);

    return errorInObj ? errors[name].message : "";
};

export const hookFormGetObjectErrorMessage = (name = "", errors = {}) => {
    const errorInObj = name in errors;

    if (!errorInObj) return null;

    const errorMessagesArray = Object.values(errors[name]);

    return errorMessagesArray[0].message || "";
};