import PropTypes from 'prop-types';

//icons
import BellIcon from '../../../assets/images/vector/bell.svg';
import AlertIcon from '../../../assets/images/vector/alert.svg';
import AlertGrayIcon from '../../../assets/images/vector/alert-gray.svg';
import ArrowIcon from '../../../assets/images/vector/arrow.svg';
import CalendarIcon from '../../../assets/images/vector/calendar.svg';
import DashboardIcon from '../../../assets/images/vector/dashboard.svg';
import LogoPrimaryIcon from '../../../assets/images/vector/logoPrimary.svg';
import SwitchMoonIcon from '../../../assets/images/vector/switch-moon.svg';
import SwitchSunIcon from '../../../assets/images/vector/switch-sun.svg';
import LocationIcon from '../../../assets/images/vector/location.svg';
import ArrowDownSelectIcon from '../../../assets/images/vector/arrowDownSelect.svg';
import ClockIcon from '../../../assets/images/vector/clock.svg';
import PlusIcon from '../../../assets/images/vector/plus.svg';
import CrossIcon from '../../../assets/images/vector/cross.svg';
import TrashIcon from '../../../assets/images/vector/trash.svg';
import SearchIcon from '../../../assets/images/vector/search.svg';
import LogoSecondaryIcon from '../../../assets/images/vector/logoSecondary.svg';

import EyeClosedIcon from '../../../assets/images/vector/eye-closed.svg';
import EyeOpenedIcon from '../../../assets/images/vector/eye-opened.svg';
import UsaNationalFlagIcon from '../../../assets/images/vector/united-states.svg';
import ArrowBackIcon from '../../../assets/images/vector/arrow-back.svg';
import ErrorCrossIcon from '../../../assets/images/vector/error-cross.svg';

import SuccessIcon from '../../../assets/images/vector/success.svg';
import InfoIcon from '../../../assets/images/vector/info.svg';
import SortIcon from '../../../assets/images/vector/sort.svg';
import BusIcon from '../../../assets/images/vector/bus.svg';
import MinusIcon from '../../../assets/images/vector/minus.svg';
import OptionsIcon from '../../../assets/images/vector/options.svg';

import AlertsIcon from '../../../assets/images/vector/alerts.svg';
import EmergencyIcon from '../../../assets/images/vector/emergency.svg';
import HelpIcon from '../../../assets/images/vector/help.svg';
import IntersectionsIcon from '../../../assets/images/vector/intersections.svg';
import RoutesIcon from '../../../assets/images/vector/routes.svg';
import SettingsIcon from '../../../assets/images/vector/settings.svg';
import NoSignale from '../../../assets/images/vector/noSignale.svg';
import Done from '../../../assets/images/vector/done.svg';
import Cancel from '../../../assets/images/vector/cancel.svg';
import ArrowSecondary from '../../../assets/images/vector/arrow-secondary.svg';
import VerticalTwoSideArrowIcon from '../../../assets/images/vector/vertical-two-side-arrow.svg';
import DownloadChartDataIcon from '../../../assets/images/vector/download-chart-data.svg';
import ResetIcon from '../../../assets/images/vector/reset.svg';
import WarningIcon from '../../../assets/images/vector/warning.svg';
import DateIcon from '../../../assets/images/vector/date.svg';
import LightBulbIcon from '../../../assets/images/vector/lightBulb.svg';
import MutedIcon from '../../../assets/images/vector/muted.svg';
import DirectedArrowIcon from '../../../assets/images/vector/directed-arrow.svg';
import RouteBadgeIcon from '../../../assets/images/vector/route-badge.svg';
import BusStopBadgeIcon from '../../../assets/images/vector/bus-stop-badge.svg';
import AlertBadgeIcon from '../../../assets/images/vector/alert-badge.svg';
import TrafficLightBadgeIcon from '../../../assets/images/vector/traffic-light-badge.svg';
import FireStationBadgeIcon from '../../../assets/images/vector/fire-station-badge.svg';
import HospitalBadgeIcon from '../../../assets/images/vector/hospital-badge.svg';
import ConfirmedRequestBadgeIcon from '../../../assets/images/vector/confirmed-request-badge.svg';
import DeniedRequestBadgeIcon from '../../../assets/images/vector/denied-request-badge.svg';
import InoperativeTrafficLightBadgeIcon from '../../../assets/images/vector/inoperative-traffic-light-badge.svg';

import LogsFileIcon from '../../../assets/images/vector/logs-file.svg';
import TrafficLightsIcon from '../../../assets/images/vector/traffic-lights.svg';
import LostConnectionIcon from '../../../assets/images/vector/lost-connection.svg';
import SuitcaseIcon from '../../../assets/images/vector/suitcase.svg';
import PointIcon from '../../../assets/images/vector/point.svg';
import StartPointIcon from '../../../assets/images/vector/start-point.svg';
import ChartIcon from '../../../assets/images/vector/chart.svg';
import LineChartIcon from '../../../assets/images/vector/line-chart.svg';
import BarChartIcon from '../../../assets/images/vector/bar-chart.svg';
import DistanceIcon from '../../../assets/images/vector/distance.svg';
import BusStopIcon from '../../../assets/images/vector/bus-stop.svg';
import ClockIcon2 from '../../../assets/images/vector/clock-2.svg';
import ArrowsDiagonalOutside from '../../../assets/images/map/arrows-diagonal-outside.svg';
import SignalIcon from '../../../assets/images/vector/signal.svg';
import CheckmarkIcon from '../../../assets/images/vector/checkmark.svg';
import ArrowDownBold from '../../../assets/images/vector/arrow-down-bold.svg';
import PencilIcon from '../../../assets/images/vector/pencil.svg';
import InfoFilled from '../../../assets/images/vector/info-filled.svg';


// sidebar

import SidebarDashboardIcon from '../../../assets/images/vector/sidebar/dashboard.svg';
import SidebarRoutesIcon from '../../../assets/images/vector/sidebar/routes.svg';
import SidebarIntersectionsIcon from '../../../assets/images/vector/sidebar/intersections.svg';
import SidebarAlertsIcon from '../../../assets/images/vector/sidebar/alerts.svg';
import SidebarSettingsIcon from '../../../assets/images/vector/sidebar/settings.svg';
import SidebarHelpIcon from '../../../assets/images/vector/sidebar/help.svg';
import SidebarLocationIcon from '../../../assets/images/vector/sidebar/location.svg';
import SidebarAgencyIcon from '../../../assets/images/vector/sidebar/agency.svg';
import SidebarUserGroups from '../../../assets/images/vector/sidebar/user-goups.svg';
import SidebarDocuments from '../../../assets/images/vector/sidebar/documents.svg';

import './styles.scss';

const svgIconCodes = {
    bell: <BellIcon />,
    alert: <AlertIcon />,
    arrow: <ArrowIcon />,
    dashboard: <DashboardIcon />,
    'logo-primary': <LogoPrimaryIcon />,
    'switch-moon': <SwitchMoonIcon />,
    'switch-sun': <SwitchSunIcon />,
    location: <LocationIcon />,
    'arrow-down-select': <ArrowDownSelectIcon />,
    clock: <ClockIcon />,
    alerts: <AlertsIcon />,
    emergency: <EmergencyIcon />,
    help: <HelpIcon />,
    intersections: <IntersectionsIcon />,
    routes: <RoutesIcon />,
    settings: <SettingsIcon />,
    'no-signale': <NoSignale />,
    calendar: <CalendarIcon />,
    plus: <PlusIcon />,
    'arrow-secondary': <ArrowSecondary />,
    done: <Done />,
    cancel: <Cancel />,
    cross: <CrossIcon />,
    trash: <TrashIcon />,
    search: <SearchIcon />,
    'vertical-two-side-arrow': <VerticalTwoSideArrowIcon />,
    'download-chart-data': <DownloadChartDataIcon />,
    reset: <ResetIcon />,
    'logo-secondary': <LogoSecondaryIcon />,
    'eye-closed': <EyeClosedIcon />,
    'eye-opened': <EyeOpenedIcon />,
    'usa-national-flag': <UsaNationalFlagIcon />,
    'arrow-back': <ArrowBackIcon />,
    'error-cross': <ErrorCrossIcon />,
    success: <SuccessIcon />,
    info: <InfoIcon />,
    sort: <SortIcon />,
    bus: <BusIcon />,
    minus: <MinusIcon />,
    options: <OptionsIcon />,
    warning: <WarningIcon />,
    date: <DateIcon />,
    'light-bulb': <LightBulbIcon />,
    muted: <MutedIcon />,
    'directed-arrow': <DirectedArrowIcon />,
    'traffic-lights': <TrafficLightsIcon />,
    'logs-file': <LogsFileIcon />,
    'lost-connection': <LostConnectionIcon />,
    suitcase: <SuitcaseIcon />,
    point: <PointIcon />,
    'route-badge': <RouteBadgeIcon />,
    'bus-stop-badge': <BusStopBadgeIcon />,
    'alert-badge': <AlertBadgeIcon />,
    'traffic-light-badge': <TrafficLightBadgeIcon />,
    'fire-station-badge': <FireStationBadgeIcon />,
    'hospital-badge': <HospitalBadgeIcon />,
    'confirmed-request-badge': <ConfirmedRequestBadgeIcon />,
    'denied-request-badge': <DeniedRequestBadgeIcon />,
    'inoperative-traffic-light-badge': <InoperativeTrafficLightBadgeIcon />,
    'start-point': <StartPointIcon />,
    chart: <ChartIcon />,
    'bar-chart': <BarChartIcon />,
    'line-chart': <LineChartIcon />,
    distance: <DistanceIcon />,
    'bus-stop': <BusStopIcon />,
    'clock-2': <ClockIcon2 />,
    'arrows-diagonal-outside': <ArrowsDiagonalOutside />,
    signal: <SignalIcon />,
    'alert-gray': <AlertGrayIcon />,
    checkmark: <CheckmarkIcon />,
    'arrow-down-bold': <ArrowDownBold />,
    pencil: <PencilIcon />,
    'info-filled': <InfoFilled />,

    //sidebar
    'sidebar-dashboard': <SidebarDashboardIcon />,
    'sidebar-routes': <SidebarRoutesIcon />,
    'sidebar-intersections': <SidebarIntersectionsIcon />,
    'sidebar-alerts': <SidebarAlertsIcon />,
    'sidebar-settings': <SidebarSettingsIcon />,
    'sidebar-help': <SidebarHelpIcon />,
    'sidebar-location': <SidebarLocationIcon />,
    'sidebar-agency': <SidebarAgencyIcon />,
    'sidebar-documents': <SidebarDocuments />,
    'sidebar-user-groups': <SidebarUserGroups />,
};

const SvgIcon = ({ type }) => svgIconCodes[type];

SvgIcon.propTypes = {
    type: PropTypes.oneOf(Object.keys(svgIconCodes)).isRequired,
};

export default SvgIcon;
