import { lazy } from 'preact/compat';
import { bookList } from './bookList';

//private
const dashboard = lazy(() => import('../routes/dashboard'));

const alertsActive = lazy(() => import('../routes/alerts/alerts-active'));
const alertsMyAlerts = lazy(() => import('../routes/alerts/alerts-my-alerts'));
const alertsSingle = lazy(() => import('../routes/alerts/alerts-single'));
const liveOperations = lazy(() => import('../routes/live-operations'));
const routesTSPStatistics = lazy(() =>
    import('../routes/routes/tsp-statistics')
);
const routes = lazy(() => import('../routes/routes/routes'));
const intersections = lazy(() => import('../routes/intersections'));
const usersAndPermissions = lazy(() =>
    import('../routes/users-and-permissions')
);
const businessRules = lazy(() =>
    import('../routes/business-rules')
);
const settings = lazy(() => import('../routes/settings'));
const help = lazy(() => import('../routes/help'));

//public
const login = lazy(() => import('../routes/auth/login'));
const notFound = lazy(() => import('../routes/404'));

export const privateRoutes = [
    {
        component: dashboard,
        path: bookList.dashboard.general,
        isDefault: false,
    },
    {
        component: notFound,
        path: bookList.notFound,
        isDefault: true,
    },
    {
        component: alertsActive,
        path: bookList.alerts.active,
        isDefault: false,
    },
    {
        component: alertsMyAlerts,
        path: bookList.alerts.my_alerts,
        isDefault: false,
    },
    {
        component: alertsSingle,
        path: bookList.alerts.single,
        isDefault: false,
    },
    {
        component: routesTSPStatistics,
        path: bookList.routes.tsp_statistics,
        isDefault: false,
    },

    {
        component: routes,
        path: bookList.routes.general,
        isDefault: false,
    },
    {
        component: liveOperations,
        path: bookList.live_operations.general,
        isDefault: false,
    },
    {
        component: intersections,
        path: bookList.intersections.general,
        isDefault: false,
    },
    {
        component: usersAndPermissions,
        path: bookList.users_and_permissions.general,
        isDefault: false,
    },
    {
        component: businessRules,
        path: bookList.business_rules.general,
        isDefault: false,
    },
    // {
    //     component: settings,
    //     path: bookList.settings.profile,
    //     isDefault: false,
    // },
    {
        component: help,
        path: bookList.help.faq,
        isDefault: false,
    }
];

export const publicRoutes = [
    // {
    //     component: notFound,
    //     path: bookList.notFound,
    //     isDefault: true,
    // },
    {
        component: login,
        path: bookList.auth.login,
        isDefault: false,
    },
    // {
    //     component: loginConfirmation,
    //     path: bookList.auth.login_confirmation,
    //     isDefault: false,
    // },
    // {
    //     component: registration,
    //     path: bookList.auth.registration,
    //     isDefault: false,
    // },
    // {
    //     component: registrationForm,
    //     path: bookList.auth.registration_form,
    //     isDefault: false,
    // },
    // {
    //     component: registrationSuccess,
    //     path: bookList.auth.registration_success,
    //     isDefault: false,
    // },
    // {
    //     component: forgotConfirmation,
    //     path: bookList.auth.forgot_confirmation,
    //     isDefault: false,
    // },
    // {
    //     component: forgotChange,
    //     path: bookList.auth.forgot_change,
    //     isDefault: false,
    // },
    // {
    //     component: forgotSuccess,
    //     path: bookList.auth.forgot_success,
    //     isDefault: false,
    // },
];

export const productionRoutes = privateRoutes.filter(item => !(
    item.path.includes('intersections') || item.path.includes('help')
    || item.path.includes('business-rules')
));

export const stageRoutes = privateRoutes.filter(item => !(
    item.path.includes('help') || item.path.includes('business-rules')
));
