/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import * as actions from './map.actions';
import * as requests from '../../../services/requests/dashboard/map/map';

export const getMapRoutesOperation = () => async (dispatch) => {
    dispatch(actions.getMapRoutesRequest());

    try {
        const response = await requests
            .getMapRoutesRequest();
        dispatch(actions.getMapRoutesSuccess(response));
    } catch(error) {
        dispatch(actions.getMapRoutesError(error));
    }
};

export const getMapRouteByIdOperation
    = (id = '') =>
        async (dispatch) => {
            dispatch(actions.getMapRouteByIdRequest());
            dispatch(getMapRouteDetailsByIdOperation(id));
            try {
                const response = await requests
                    .getMapRouteByIdRequest({ id });
                dispatch(actions.getMapRouteByIdSuccess(response));
            } catch(error) {
                dispatch(actions.getMapRouteByIdError(error));
            }
        };

export const getMapRouteDetailsByIdOperation
    = (id = '') =>
        async (dispatch) => {
            dispatch(actions.getMapRouteDetailsByIdRequest());
            try {
                const response = await requests
                    .getMapRouteDetailsByIdRequest({ id });
                dispatch(actions.getMapRouteDetailsByIdSuccess(response));
            } catch(error) {
                dispatch(actions.getMapRouteDetailsByIdError(error));
            }
        };

export const getMapStopsByIdOperation
    = (id = '') =>
        async (dispatch) => {
            dispatch(actions.getMapStopsByIdRequest());
            try {
                const response = await requests
                    .getMapStopsByIdRequest({ id });
                dispatch(actions.getMapStopsByIdSuccess(response));
            } catch(error) {
                dispatch(actions.getMapStopsByIdError(error));
            }
        };

export const resettingDataOnUnmountMapOperation = () => (dispatch) => {
    dispatch(actions.resetMapInformation());
};

export const getMapTrafficLightsOperation = () => async (dispatch) => {
    dispatch(actions.getMapTrafficLightsRequest());
    try {
        const response = await requests
            .getMapTrafficLightsRequest();
        const normalizedTrafficLightsList = response.data.map((item) => ({
            signal_pk: Number(item.signal_pk),
            coordinates: [
                Number(item.longitude),
                Number(item.latitude),
            ],
        }));

        const normalizedIntersectionsList = response.data.reduce(
            (acc, { geometry, signal_pk }) => {
                const normalizedGeometry = geometry.reduce(
                    (acc_1, { lng, lat, phase, bearing }) => [
                        ...acc_1,
                        {
                            coordinates: [lng, lat],
                            phase,
                            bearing,
                            color: '#FFFFFF',
                        },
                    ],
                    []
                );

                return [
                    ...acc,
                    {
                        geometry: normalizedGeometry,
                        signal_pk: Number(signal_pk),
                    },
                ];
            },
            []
        );

        const normalizedCrosswalksList = response.data.reduce(
            (acc_2, { geometry: geometry_1, signal_pk: signal_pk_1 }) => [
                ...acc_2,
                ...geometry_1.reduce((acc_3, { pedpaths }) => {
                    if (pedpaths) {
                        acc_3.push({
                            contour: pedpaths.map((item_1) => [
                                item_1.lng,
                                item_1.lat,
                            ]),
                            signal_pk_1,
                        });
                    }

                    return acc_3;
                }, []),
            ],
            []
        );

        dispatch(
            actions.getMapTrafficLightsSuccess({
                trafficLightList: normalizedTrafficLightsList,
                intersectionsList: normalizedIntersectionsList,
                crosswalksList: normalizedCrosswalksList,
            })
        );
    } catch (error) {
        dispatch(actions.getMapTrafficLightsError(error));
    }
};
