export const timesList = [
    { key: "1-week", label: "1 week" },
    { key: "1-month", label: "1 month" },
    { key: "3-months", label: "3 months" },
    { key: "forever", label: "Forever" },
];


export const typesList = [
    { value: "lost-connection-1", label: "Lost connection" },
    { value: "lost-connection-2", label: "Lost connection-2" },
    { value: "lost-connection-3", label: "Lost connection-3" },
    { value: "lost-connection-4", label: "Lost connection-4" },
];

export const intersectionsList = [
    { value: "value-1", label: "Powel & Milwaukie" },
    { value: "value-2", label: "Powel & Milwaukie-2" },
    { value: "value-3", label: "Powel & Milwaukie-3" },
];


