/* eslint-disable keyword-spacing */
import PropTypes from 'prop-types';

import styles from './styles.scss';

const LinkTab = ({
    label = '',
    handleClick = () => {},
    isActive = false,
    count = 0,
    responsive = false,
}) => {
    const setActiveClassName = () => {
        if (isActive) return '--active';
        return '';
    };

    return (
        <div
            onClick={handleClick}
            className={`${styles[`link-tab${setActiveClassName()}`]}  ${
                responsive ? styles['link-tab--responsive'] : ''
            } link-tab--g`}
        >
            <div className={styles['link-tab__content']}>
                {label}
                {count > 0
                    && <span className={styles['link-tab__count']}>{count}</span>
                }
            </div>
        </div>
    );
};

LinkTab.propTypes = {
    label: PropTypes.string,
    isActive: PropTypes.bool,
    handleClick: PropTypes.func,
    count: PropTypes.number,
    responsive: PropTypes.bool,
};

export default LinkTab;
