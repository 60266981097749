import { get, put } from "../../api";

export const getAllWidgetsListRequest = async (params = {}) => {
    const url = `/user-setting/widgets`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getAddedWidgetsListRequest = async (params = {}) => {
    const url = `/user-setting/user-widgets`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const sendAddedWidgets = async (reqData = {}, params = {}) => {
    const url = `/user-setting/user-widgets`;

    try {
        const { data } = await put(url, reqData);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
