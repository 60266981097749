import { store } from '../../store/store';
import {
    userCityIdSelector,
    userAgencyIdSelector,
    userInAgencyIdSelector,
    userIdSelector,
    userEmailSelector,
} from '../../store/user/user.selectors';
// cities and agencies
import {
    agenciesSelector
} from '../../store/cities-and-agencies/cities-and-agencies.selectors';
import {
    sidebarAgenciesSelector
} from '../../components/sidebar/sidebar.selectors';


// get the global city and agency ids selected by the user
export const getUserCityId = () => userCityIdSelector(store.getState());
export const getUserAgencyId = () => userAgencyIdSelector(store.getState());
export const getUserInAgencyId = () => userInAgencyIdSelector(store.getState());
export const getUserAgencies = () => sidebarAgenciesSelector(store.getState());
export const getUserId = () => userIdSelector(store.getState());
export const getUserEmail = () => userEmailSelector(store.getState());
export const getLytHqId = () => {
    const { list } = agenciesSelector(store.getState());
    return list.filter(agency => agency.agencies_long_name === 'LYT').agencies_id;
};
