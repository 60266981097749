/* eslint-disable prefer-reflect */
/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { useEffect, useState } from 'preact/hooks';
import { Controller, useForm } from 'react-hook-form';
import { route } from 'preact-router';
import { bookList } from '../../../../constants/bookList';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../../ui-core/button';
import Field from '../../../ui-core/inputs/field';
import Select from '../../../ui-core/inputs/select';

import { schema, defaultWelcomeMessage } from './invite-new-user.schema';
import { VIEW_TYPES_LIST } from '../../../ui-core/button/button.constants';
import { OPTION_TYPES } from '../../../ui-core/inputs/select/select.constants';
import { clearModalState } from '../../modal.actions';
import { sendRegistrationDataRequest } from '../../../../services/requests/auth/login';
import { getUserAgencyId, getUserAgencies } from '../../../../services/requests/request.utils';
import { userInAgencyIdSelector } from '../../../../store/user/user.selectors';
import { isLYTAdmin, getUserRole, isAdmin } from '../../../../store/user/user.utils';
import { AGENCY_USER_TYPES } from '../../../../constants/user-types';
// eslint-disable-next-line no-duplicate-imports
import USER_TYPES from '../../../../constants/user-types';
import { adaptAgenciesToLabelValueObject } from '../../../../store/cities-and-agencies/cities-and-agencies.utils';

import styles from './styles.scss';

const InviteNewUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const thisUserRole = getUserRole();
    let agencySelected = getUserAgencyId();
    let roleSelected = USER_TYPES.AGENCY_VIEWER.value;
    let wasSubmitted = false;
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            notice: defaultWelcomeMessage
        },
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        () => {
            if (wasSubmitted && isAdmin()) {
                route(bookList.users_and_permissions.requests);
            }
        };
    }, []);

    const onSubmit = async (data) => {
        setIsLoading(true);
        delete data.agency;
        delete data.role;
        sendRegistrationDataRequest({ ...data, agencies_id: agencySelected, role: roleSelected})
            .then(() => {
                toast.success('Invitation was successfully sent!');
                wasSubmitted = true;
            })
            .catch((errorData) => {
                toast.error(errorData);
            })
            .finally(() => {
                setIsLoading(false);
                dispatch(clearModalState());
            });
    };

    const createAgenciesList = () => {
        const agenciesList = adaptAgenciesToLabelValueObject(getUserAgencies().list);
        console.log('agenciesList', agenciesList);
        console.log(`user role is ${thisUserRole}`);
        const currentUserAgency = useSelector(userInAgencyIdSelector);
        if (!agenciesList.find(agency => agency.value === currentUserAgency)) {
            agenciesList.push({value: currentUserAgency, label: currentUserAgency});
        }
        return agenciesList;
    };

    const createRolesList = () => {
        let list = [];
        switch (thisUserRole) {
        case USER_TYPES.LYT_ADMIN.value:
            Object.keys(USER_TYPES).forEach(key => {
                list.push({label: USER_TYPES[key].label, value: USER_TYPES[key].value});
            });
            break;
        case USER_TYPES.LYT_EMPLOYEE.value:
            list = Object.keys(USER_TYPES).reduce((result, key) => {
                if (key !== 'LYT_ADMIN') {
                    result.push({label: USER_TYPES[key].label, value: USER_TYPES[key].value});
                }
                return result;
            }, []);
            break;
        case USER_TYPES.AGENCY_OWNER.value:
            Object.keys(AGENCY_USER_TYPES).forEach(key => {
                list.push({label: USER_TYPES[key].label, value: USER_TYPES[key].value});
            });
            break;
        case USER_TYPES.AGENCY_ADMIN.value:
            list.push({...AGENCY_USER_TYPES.AGENCY_ADMIN});
            list.push({...AGENCY_USER_TYPES.AGENCY_VIEWER});
            break;
        default:
        }
        return list;
    };

    const agenciesForSelect = createAgenciesList();

    const rolesForSelect = createRolesList();
    console.log('rolesForSelect', rolesForSelect);

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles['invite-new-user']}
        >
            <p className={styles['invite-new-user__title']}>
                Invite New Member
            </p>
            <Controller
                name="email"
                control={control}
                render={({ field }) =>
                    <Field
                        {...field}
                        errorText={errors.email?.message}
                        label="Email"
                        placeholder="Enter an email"
                        type="text"
                    />
                }
            />

            { (isLYTAdmin() || thisUserRole === USER_TYPES.LYT_EMPLOYEE.value)
                && <Controller
                    name="agency"
                    control={control}
                    render={() =>
                        <div className={styles['invite-new-user__selector']}>
                            <Select
                                optionsList={agenciesForSelect}
                                label={'Assign Agency'}
                                onOptionSelected={(agency) => {
                                    agencySelected = agency;
                                    setValue('agency', agencySelected);
                                }}
                                type={OPTION_TYPES.PRIMARY}
                                defaultValue={agenciesForSelect.find(ag => ag.value === agencySelected)}
                                defaultSelectLabel="Add agency"
                                isNeedToShowSelectedValue={true}
                            />
                        </div>
                    }
                />
            }
            { (isLYTAdmin() || [USER_TYPES.LYT_EMPLOYEE.value, AGENCY_USER_TYPES.AGENCY_OWNER.value, AGENCY_USER_TYPES.AGENCY_ADMIN.value].includes(thisUserRole))
                && <Controller
                    name="role"
                    control={control}
                    render={() =>
                        <div className={styles['invite-new-user__selector']}>
                            <Select
                                optionsList={rolesForSelect}
                                label={'Assign Agency Role'}
                                onOptionSelected={(role) => {
                                    roleSelected = role;
                                    setValue('role', roleSelected);
                                }}
                                type={OPTION_TYPES.PRIMARY}
                                defaultValue={rolesForSelect.find(role => role.value === roleSelected)}
                                defaultSelectLabel="Add role"
                                isNeedToShowSelectedValue={true}
                            />
                        </div>
                    }
                />
            }

            <div className={styles['invite-new-user__buttons']}>
                <div className={styles['invite-new-user__button']}>
                    <Button
                        handleClick={() => dispatch(clearModalState())}
                        label={'Cancel'}
                    />
                </div>
                <div className={styles['invite-new-user__button']}>
                    <Button
                        type={'submit'}
                        label={'Send invite'}
                        viewType={VIEW_TYPES_LIST.PRIMARY}
                        loading={isLoading}
                    />
                </div>
            </div>
        </form>
    );
};

export default InviteNewUser;
