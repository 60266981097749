/* eslint-disable keyword-spacing */
/* eslint-disable dot-notation */
import { Fragment } from 'preact';
import { useRef, useLayoutEffect } from 'preact/hooks';
import PropTypes from 'prop-types';

import SvgIcon from '../../svg-icon';

import styles from './styles.scss';

const NumberField = ({ name, placeholder, value, mask, viewType, onChange, autoFocus = false }) => {
    const numberField = useRef(null);
    useLayoutEffect(() => {
        if (autoFocus) {
            numberField.current?.focus();
        }
    }, []);

    return <Fragment>
        {viewType === 'phone-number' && <div className={styles['field__icon--flag']}>
            {<SvgIcon type="usa-national-flag" />}
                +1
        </div>}

        <input
            ref={numberField}
            className={`${styles['field__input']} ${
                viewType === 'phone-number' ? styles['field__input--number'] : ''
            } `}
            type="text"
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(mask(e))}
            autoFocus={autoFocus}
        />
    </Fragment>;
}
;

NumberField.propTypes = {
    viewType: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.string,
    validation: PropTypes.object,
    mask: PropTypes.func,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool
};

export default NumberField;
