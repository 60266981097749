export const types = {
    //login
    SEND_LOGIN_FORM_REQUEST: 'auth/SEND_LOGIN_FORM_REQUEST',
    SEND_LOGIN_FORM_SUCCESS: 'auth/SEND_LOGIN_FORM_SUCCESS',
    SEND_LOGIN_FORM_ERROR: 'auth/SEND_LOGIN_FORM_ERROR',
    SEND_LOGIN_OTP_FORM_REQUEST: 'auth/SEND_LOGIN_OTP_FORM_REQUEST',
    SEND_LOGIN_OTP_FORM_SUCCESS: 'auth/SEND_LOGIN_OTP_FORM_SUCCESS',
    SEND_LOGIN_OTP_FORM_ERROR: 'auth/SEND_LOGIN_OTP_FORM_ERROR',

    //registration
    SEND_REGISTRATION_FORM_REQUEST: 'auth/SEND_REGISTRATION_FORM_REQUEST',
    SEND_REGISTRATION_FORM_SUCCESS: 'auth/SEND_REGISTRATION_FORM_SUCCESS',
    SEND_REGISTRATION_FORM_ERROR: 'auth/SEND_REGISTRATION_FORM_ERROR',

    //forgot
    SEND_FORGOT_CONFIRMATION_FORM_REQUEST: 'auth/SEND_FORGOT_CONFIRMATION_FORM_REQUEST',
    SEND_FORGOT_CONFIRMATION_FORM_SUCCESS: 'auth/SEND_FORGOT_CONFIRMATION_FORM_SUCCESS',
    SEND_FORGOT_CONFIRMATION_FORM_ERROR: 'auth/SEND_FORGOT_CONFIRMATION_FORM_ERROR',

    SEND_FORGOT_CODE_FORM_REQUEST: 'auth/SEND_FORGOT_CODE_FORM_REQUEST',
    SEND_FORGOT_CODE_FORM_SUCCESS: 'auth/SEND_FORGOT_CODE_FORM_SUCCESS',
    SEND_FORGOT_CODE_FORM_ERROR: 'auth/SEND_FORGOT_CODE_FORM_ERROR',

    SEND_FORGOT_CHANGE_FORM_REQUEST: 'auth/SEND_FORGOT_CHANGE_FORM_REQUEST',
    SEND_FORGOT_CHANGE_FORM_SUCCESS: 'auth/SEND_FORGOT_CHANGE_FORM_SUCCESS',
    SEND_FORGOT_CHANGE_FORM_ERROR: 'auth/SEND_FORGOT_CHANGE_FORM_ERROR',

    SEND_FORGOT_PASSWORD_FORM_REQUEST: 'auth/SEND_FORGOT_PASSWORD_FORM_REQUEST',
    SEND_FORGOT_PASSWORD_FORM_SUCCESS: 'auth/SEND_FORGOT_PASSWORD_FORM_SUCCESS',
    SEND_FORGOT_PASSWORD_FORM_ERROR: 'auth/SEND_FORGOT_PASSWORD_FORM_ERROR',

    //other
    RECEIVE_AUTH0_SUCCESS: 'auth/RECEIVE_AUTH0_SUCCESS',
    CLEAR_AUTH_MUTUAL_DATA: 'auth/CLEAR_AUTH_MUTUAL_DATA',
    UPDATE_TOKEN: 'auth/UPDATE_TOKEN',
    CLEAR_SESSION: 'auth/CLEAR_SESSION'
};
