import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { userThemeSelector } from '../../store/user/user.selectors';
import { skeletonConfig } from './skeleton.config';
import { COLORS_TYPES } from './skeleton.constants';

const Skeleton = ({ type = '', colorType = 'default', height = 0 }) => {
    const theme = useSelector(userThemeSelector);

    const component = skeletonConfig[type];
    const colors = COLORS_TYPES[colorType][theme];

    return { ...component, props: { colors, height } };
};

Skeleton.propTypes = {
    type: PropTypes.oneOf(Object.keys(skeletonConfig)).isRequired,
    colorType: PropTypes.oneOf(Object.keys(COLORS_TYPES)),
    height: PropTypes.number,
};

export default Skeleton;
