export const MODAL_TYPES = {
    WIDGET_MANAGEMENT: 'WIDGET_MANAGEMENT',
    SHARE_ALERT: 'SHARE_ALERT',
    SHARE_ACTIVE_ALERT: 'SHARE_ACTIVE_ALERT',
    SHARE_CRITICAL_MUTED_ALERT: 'SHARE_CRITICAL_MUTED_ALERT',
    MUTE_ALERTS: 'MUTE_ALERTS',

    // Users & Permissions

    GROUPS_ADD_NEW_GROUP: 'GROUPS_ADD_NEW_GROUP',
    GROUPS_ADD_NEW_MEMBERS: 'GROUPS_ADD_NEW_MEMBERS',
    GROUPS_GROUP_DELETE_CONFIRMATION: 'GROUPS_GROUP_DELETE_CONFIRMATION',
    GROUPS_PAGE_LEAVE_CONFIRMATION: 'GROUPS_PAGE_LEAVE_CONFIRMATION',
    INVITE_NEW_USER: 'INVITE_NEW_USER',
    CONFIRM_USER_UPDATE: 'CONFIRM_USER_UPDATE',
    DELETE_USER_CONFIRMATION: 'DELETE_USER_CONFIRMATION'
};
