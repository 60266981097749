/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable keyword-spacing */
import { store } from '../store';

import { userPermissionsSelector, userRoleSelector } from './user.selectors';
import { AGENCY_USER_TYPES, LYT_USER_TYPES, USER_TYPES } from '../../constants/user-types';

//users and permissions
import { getRequestsListOperation } from '../../routes/users-and-permissions/requests/requests.operations';
import { getUsersListOperation } from '../../routes/users-and-permissions/users/users.operations';
import { getGroupsListOperation } from '../../routes/users-and-permissions/groups/groups.operations';
import { getAccessSettingsByTypeOperation } from '../../routes/users-and-permissions/roles/roles.operations';
import { settingsSelector } from '../../routes/users-and-permissions/roles/roles.selectors';
// routes
import * as routesSelectors from '../../routes/routes/routes.selectors';
import * as routesOperation from '../../routes/routes/routes.operations';

// dashboard general
import * as dashboardGeneralOperations from '../../routes/dashboard/general/general.operations';
import * as dashboardGeneralSelectors from '../../routes/dashboard/general/general.selectors';
import { getUserInAgencyId, getLytHqId } from '../../services/requests/request.utils';
import { PERIOD_LIST } from '../../routes/dashboard/general/components/total-tsp-calls/total-tsp-valls.config';

// users and permissions, users page
export const usersPageDistributor = () => (dispatch) => {
    dispatch(getUsersListOperation());
};

export const getUserRole = () => userRoleSelector(store.getState());

export const isLYTAdmin = () => {
    if (LYT_USER_TYPES.LYT_ADMIN.value === getUserRole()) {
        return true;
    }

    return false;
};

export const isAdmin = () => {
    switch (getUserRole()) {
    case LYT_USER_TYPES.LYT_ADMIN.value:
    case AGENCY_USER_TYPES.AGENCY_ADMIN.value:
    case AGENCY_USER_TYPES.AGENCY_OWNER.value:
        return true;

    default:
        return false;
    }
};

export const isLYTAdminInLYTAgency = () => {
    // tmp solution, as BE doesn`t know which agency is the main
    if (isLYTAdmin() && getUserInAgencyId() === getLytHqId()) {
        return true;
    }

    return false;
};
export const checkPermissionByName = (name = '') => {
    const permissionsList = userPermissionsSelector(store.getState());

    if (name in permissionsList) {
        return permissionsList[name];
    } else {
        console.error('wrong permission name');
    }
};


// users and permissions, requests page
export const requestsPageDistributor = () => (dispatch) => {
    dispatch(getRequestsListOperation());
};

// users and permissions, groups page
export const groupsPageDistributor = () => (dispatch) => {
    dispatch(getGroupsListOperation());
};

// users and permissions, roles page
export const rolesPageDistributor = () => (dispatch, getState) => {
    const {
        params: { rolesName, roleId },
    } = settingsSelector(getState());
    const params = { roles_name: rolesName, roles_id: roleId };

    dispatch(getAccessSettingsByTypeOperation(params));
};

// format US phone number
export const formatPhoneNumber = phoneNumberString => {
    if (phoneNumberString === '**********') {
        return '*** ***-****';
    }
    var cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};
// routes, reports page
export const reportsPageDistributor = () => (dispatch, getStore) => {
    const store = getStore();

    const headwayFP = routesSelectors.headwayFPSelector(store);
    const routePerformanceByStopFP = routesSelectors.routePerformanceByStopSelector(store);
    const travelTimeFP = routesSelectors.travelTimeFPSelector(store);
    const redLightWaitTimeFP = routesSelectors.redLightWaitTimeFPSelector(store);
    const busStopDwellTimeFP = routesSelectors.busStopDwellTimeFPSelector(store);
    const averageVehicleSpeedFP = routesSelectors.averageVehicleSpeedFPSelector(store);
    const segmentTravelTimeFP = routesSelectors.segmentTravelTimeFPSelector(store);

    dispatch(
        routesOperation.getAgencyRoutesOperation()
    );

    dispatch(
        routesOperation.getRoutesStopsOperation()
    );

    dispatch(
        routesOperation.getHeadwayOperation({
            period: headwayFP.period,
            secondPeriod: headwayFP.secondPeriod,
        })
    );
    dispatch(
        routesOperation.getRoutePerformanceByStopOperation({
            period: routePerformanceByStopFP.period,
            secondPeriod: routePerformanceByStopFP.secondPeriod,
        })
    );
    dispatch(
        routesOperation.getTravelTimeOperation({ period: travelTimeFP.period })
    );

    dispatch(
        routesOperation.getRedLightWaitTimeOperation({
            period: redLightWaitTimeFP.period,
        })
    );
    dispatch(
        routesOperation.getBusStopDwellTimeOperation({
            period: busStopDwellTimeFP.period,
        })
    );

    dispatch(
        routesOperation.getAverageVehicleSpeedOperation({
            period: averageVehicleSpeedFP.period,
        })
    );

    dispatch(
        routesOperation.getSegmentTravelTimeOperation({
            period: segmentTravelTimeFP.period,
        })
    );
};

// routes, tsp requests page
export const routesTSPRequestsPageDistributor = () => (dispatch, getStore) => {
    const store = getStore();
    const priorityRequestFP = routesSelectors.priorityRequestFPSelector(store);

    dispatch(
        routesOperation.getPriorityRequestsOperation({
            period: priorityRequestFP.period,
        })
    );

    dispatch(
        routesOperation.getRoutesStopsOperation()
    );
};

// routes, tsp statistics page
export const routesTSPStatisticsPageDistributor = () => (dispatch, getStore) => {
    const store = getStore();

    const TLAndOnTimePerformanceFP = routesSelectors.tlAndOnTimePerformanceFPSelector(store);
    const routesPerformanceFP = routesSelectors.routesPerformanceFPSelector(store);
    const TSPActivityFP = routesSelectors.tspActivityFPSelector(store);

    dispatch(
        routesOperation.getTLAndOnTimePerformanceOperation({
            period: TLAndOnTimePerformanceFP.period,
            secondPeriod: TLAndOnTimePerformanceFP.secondPeriod,
        })
    );
    dispatch(
        routesOperation.getRoutesStopsOperation()
    );
    dispatch(
        routesOperation.getRoutesPerformanceOperation({
            period: routesPerformanceFP.period,
            secondPeriod: routesPerformanceFP.secondPeriod,
        })
    );
    dispatch(
        routesOperation.getTSPActivityOperation({
            period: TSPActivityFP.period,
        })
    );
};

export const dashboardGeneralPageDistributor = () => (dispatch, getStore) => {
    const store = getStore();

    dispatch(
        routesOperation.getAgencyRoutesOperation()
    );

    dispatch(
        routesOperation.getRoutesStopsOperation()
    );

    const overallPerformanceFP
        = dashboardGeneralSelectors.overallPerformanceFilterParameterSelector(
            store
        );
    const systemWideFP
        = dashboardGeneralSelectors.systemWideChartFilterParameterSelector(store);
    const totalTSPCallsFP
        = dashboardGeneralSelectors.totalTSPCallsChartFilterParameterSelector(
            store
        );
    const averageSpeedByRouteFP
        = dashboardGeneralSelectors.averageSpeedByRouteChartFilterParameterSelector(
            store
        );
    const averageRedLightWaitTimeChartFP
        = dashboardGeneralSelectors.averageRedLightWaitTimeChartFilterParameterSelector(
            store
        );
    const { range: avlRange } = dashboardGeneralSelectors.getAVLHealthSelector(store);
    const { range: signalConnectivityRange } = dashboardGeneralSelectors.getSignalConnectivitySelector(store);

    dispatch(
        dashboardGeneralOperations.getOverallPerformanceOperation({
            period: overallPerformanceFP,
        })
    );
    dispatch(dashboardGeneralOperations.getTrafficLightsOperation());
    dispatch(dashboardGeneralOperations.getCriticalAlertsOperation());
    // dispatch(dashboardGeneralOperations.getSystemWideAverageRedLightWaitChartOperation());
    // dispatch(dashboardGeneralOperations.getSystemWideAverageGreenLightSuccessChartOperation());
    dispatch(
        dashboardGeneralOperations.getSystemWideChartOperation({
            period: systemWideFP,
        })
    );
    dispatch(
        dashboardGeneralOperations.getTotalTSPCallsChartOperation({
            period: totalTSPCallsFP !== '' ? totalTSPCallsFP : PERIOD_LIST[0].value,
        })
    );
    dispatch(
        dashboardGeneralOperations.getAverageSpeedByRouteChartOperation(
            averageSpeedByRouteFP,
        )
    );

    dispatch(
        dashboardGeneralOperations.getAverageRedLightWaitTimeChartOperation(
            averageRedLightWaitTimeChartFP,
        )
    );

    dispatch(dashboardGeneralOperations.getAVLHealthOperation(avlRange));

    dispatch(dashboardGeneralOperations.getSignalConnectivityOperation(signalConnectivityRange));

    dispatch(dashboardGeneralOperations.getTSPRequestMessagesOperation(signalConnectivityRange));

    dispatch(dashboardGeneralOperations.getSignalNetworkPerformanceOperation(signalConnectivityRange));
};
