/* eslint-disable dot-notation */
import SvgIcon from '../svg-icon';
import Tooltip from '../Tooltip';

import styles from './styles.scss';

const info = ({ message, type = 'info', forDescription = false, forHeading = false }) =>
    <div className={styles['info']}>
        <Tooltip
            message={message}
            position={forDescription ? 'description' : forHeading ? 'heading' : 'top'}
        >
            <div className={styles['info__icon']}>
                <SvgIcon type={type} />
            </div>
        </Tooltip>
    </div>
    ;

export default info;
