import { h } from "preact";
import PropTypes from "prop-types";

import SvgIcon from "../../../../svg-icon";

import { SVG_CODES } from "../../../../svg-icon/svg-icon.constants";

import styles from "./styles.scss";

const WidgetsManagementItem = ({
    listItem: { id, description, ...itemRest },
    handleClick,
}) => {
    return (
        <div
            key={id + "added"}
            className={styles["widgets-management-item"]}
            onClick={() => handleClick({ id, description, ...itemRest })}
        >
            <p className={styles["widgets-management-item__title"]}>
                {description}
            </p>
            <div className={styles["widgets-management-item__icon"]}>
                <SvgIcon type={SVG_CODES.PLUS} />
            </div>
        </div>
    );
};

WidgetsManagementItem.propTypes = {
    listItem: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default WidgetsManagementItem;
