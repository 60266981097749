export const types = {
    // list
    GET_REQUESTS_LIST_REQUEST:
        "users-and-permissions/GET_REQUESTS_LIST_REQUEST",
    GET_REQUESTS_LIST_ERROR: "users-and-permissions/GET_REQUESTS_LIST_ERROR",
    GET_REQUESTS_LIST_SUCCESS:
        "users-and-permissions/GET_REQUESTS_LIST_SUCCESS",

    // approve request
    APPROVE_REQUEST_ERROR: "users-and-permissions/APPROVE_REQUEST_ERROR",
    APPROVE_REQUEST_SUCCESS: "users-and-permissions/APPROVE_REQUEST_SUCCESS",

    // reject request
    REJECT_REQUEST_ERROR: "users-and-permissions/REJECT_REQUEST_ERROR",
    REJECT_REQUEST_SUCCESS: "users-and-permissions/REJECT_REQUEST_SUCCESS",
};
