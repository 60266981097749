import SvgIcon from "../ui-core/svg-icon";

import styles from "./styles.scss";

const LearnMoreFormsEl = () => {
    return (
        <div className={styles["learn-more"]}>
            <div className={styles["learn-more__icon-bulb"]}>
                <SvgIcon type={"light-bulb"} />
            </div>

            <p className={styles["learn-more__text"]}>
                Learn more on how to add users to alert groups
            </p>
            <div className={styles["learn-more__icon-arrow"]}>
                <SvgIcon type={"directed-arrow"} />
            </div>
        </div>
    );
};

export default LearnMoreFormsEl;
