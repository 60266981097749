import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';

import rootReducer from './root-reducers';

const saveSessionFilter = createFilter('session', [
    'access_token',
    'refresh_token',
    'access',
    'message',
]);

const persistConfig = {
    key: 'session',
    storage,
    whitelist: ['session', 'user'], // which reducer want to store
    transforms: [saveSessionFilter],
};
const pReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const store = createStore(
    pReducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

export { store, persistor };
