/* eslint-disable keyword-spacing */
import * as actions from './general.actions';
import * as requests from '../../../services/requests/dashboard/general/general';

export const getCriticalAlertsOperation = () => (dispatch) => {
    dispatch(actions.getCriticalAlertsRequest());

    return requests
        .getCriticalAlertsRequest()
        .then((response) => {
            dispatch(actions.getCriticalAlertsSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getCriticalAlertsError(error));
        });
};

export const getTrafficLightsOperation = (params = {}) => (dispatch) => {
    dispatch(actions.getTrafficLightsRequest());

    return requests
        .getTrafficLightsRequest(params)
        .then((response) => {
            dispatch(actions.getTrafficLightsSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getTrafficLightsError(error));
        });
};

export const getOverallPerformanceOperation =
    (params = {}) =>
        (dispatch) => {
            dispatch(actions.getOverallPerformanceRequest());
            return requests
                .getOverallPerformanceRequest(params)
                .then((response) => {
                    dispatch(actions.getOverallPerformanceSuccess(response));
                })
                .catch((error) => {
                    dispatch(actions.getOverallPerformanceError(error));
                });
        };

// Recent alerts

export const getRecentAlertsTrafficLightOperation = () => (dispatch) => {
    dispatch(actions.getRecentAlertsTrafficLightsRequest());
    return requests
        .getRecentAlertsTrafficLightRequest()
        .then((response) => {
            dispatch(actions.getRecentAlertsTrafficLightsSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getRecentAlertsTrafficLightsError(error));
        });
};

export const getRecentAlertsVechileInformationOperation = () => (dispatch) => {
    dispatch(actions.getRecentAlertsVechileInformationRequest());
    return requests
        .getRecentAlertsVechileInformationRequest()
        .then((response) => {
            dispatch(
                actions.getRecentAlertsVechileInformationSuccess(response)
            );
        })
        .catch((error) => {
            dispatch(actions.getRecentAlertsVechileInformationError(error));
        });
};

export const getRecentAlertsPriorityRequestsOperation = () => (dispatch) => {
    dispatch(actions.getRecentAlertsPriorityRequestsRequest());
    return requests
        .getRecentAlertsPriorityRequestsRequest()
        .then((response) => {
            dispatch(actions.getRecentAlertsPriorityRequestsSuccess(response));
        })
        .catch((error) => {
            dispatch(actions.getRecentAlertsPriorityRequestsError(error));
        });
};

// Systemwide average red light delay chart

export const getSystemWideAverageRedLightWaitChartOperation
    = (params = {}) =>
        (dispatch) => {
            dispatch(actions.getSystemWideRedLightWaitChartRequest());
            return requests
                .getSWAvgRedLightWaitTimeChartRequest(params)
                .then((response) => {
                    dispatch(actions.getSystemWideRedLightWaitChartSuccess(response));
                })
                .catch((error) => {
                    dispatch(actions.getSystemWideRedLightWaitChartError(error));
                });
        };

// Average Green Light Success Rate Chart

export const getSystemWideAverageGreenLightSuccessChartOperation
    = (params = {}) =>
        (dispatch) => {
            dispatch(actions.getSystemWideGreenLightSuccessChartRequest());
            return requests
                .getSWAvgGreenLightSuccessRateChartRequest(params)
                .then((response) => {
                    dispatch(actions.getSystemWideGreenLightSuccessChartSuccess(response));
                })
                .catch((error) => {
                    dispatch(actions.getSystemWideGreenLightSuccessChartError(error));
                });
        };


// Systemwide chart

export const getSystemWideChartOperation =
    (params = {}) =>
        (dispatch) => {
            dispatch(actions.getSystemWideChartRequest());
            return requests
                .getSystemWideRequest(params)
                .then((response) => {
                    dispatch(actions.getSystemWideChartSuccess(response.result));
                })
                .catch((error) => {
                    dispatch(actions.getSystemWideChartError(error));
                });
        };

// Total TSP calls chart

export const getTotalTSPCallsChartOperation =
    (params = {}) =>
        (dispatch) => {
            dispatch(actions.getTotalTSPCallsChartRequest());
            return requests
                .getTotalTSPCallsChartRequest(params)
                .then((response) => {
                    dispatch(actions.getTotalTSPCallsChartSuccess(response.result));
                })
                .catch((error) => {
                    dispatch(actions.getTotalTSPCallsChartError(error));
                });
        };

// Average Speed By Route Chart

export const getAverageSpeedByRouteChartOperation
    = (params = {}) =>
        (dispatch) => {
            dispatch(actions.getAverageSpeedByRouteChartRequest());
            return requests
                .getAverageSpeedByRouteChartRequest(params)
                .then(({ result, count }) => {
                    const data = {
                        list: result,
                        count,
                    };
                    // console.log('getAverageSpeedByRouteChartOperation data', data, params);
                    dispatch(actions.getAverageSpeedByRouteChartSuccess(data));
                })
                .catch((error) => {
                    dispatch(actions.getAverageSpeedByRouteChartError(error));
                });
        };

// Average Red Light Delay Chart

export const getAverageRedLightWaitTimeChartOperation
    = (params = {}) =>
        (dispatch) => {
            dispatch(actions.getAverageRedLightWaitTimeChartRequest());
            return requests
                .getAverageRedLightWaitTimeChartRequest(params)
                .then(({ result, count }) => {
                    const data = {
                        list: result,
                        count,
                    };
                    dispatch(actions.getAverageRedLightWaitTimeChartSuccess(data));
                })
                .catch((error) => {
                    dispatch(actions.getAverageRedLightWaitTimeChartError(error));
                });
        };

export const getTravelTimeByDayOperation
    = (params = {}) =>
        (dispatch) => {
            dispatch(actions.getTravelTimeByDayRequest());
            return requests
                .getTravelTimeByDayRequest(params)
                .then(({ result, count }) => {
                    const data = {
                        list: result,
                        count,
                    };
                    dispatch(actions.getTravelTimeByDaySuccess(data));
                })
                .catch((error) => {
                    dispatch(actions.getTravelTimeByDayError(error));
                });
        };

// AVL Health

export const getAVLHealthOperation = (params = {}) => (dispatch) => {
    dispatch(actions.getAVLHealthRequest());
    return requests
        .getAVLHealthRequest(params)
        .then((response) => {
            const modified = response.map((item) => ({
                ...item,
                date: new Date(item.local_end_ts.replace('Z', '')).getTime(),
                messages: item.ct_inserted_last_5m || 0,
            }));
            dispatch(actions.getAVLHealthSuccess(modified));
        })
        .catch((error) => {
            dispatch(actions.getAVLHealthError(error));
        });
};

// Signal Connectivity

export const getSignalConnectivityOperation = (params = {}) => (dispatch) => {
    dispatch(actions.getSignalConnectivityRequest());
    return requests
        .getSignalConnectivityRequest(params)
        .then((response) => {
            const modified = response.map((item) => ({
                ...item,
                date: new Date(item.local_ts.replace('Z', '')).getTime(),
                value: parseInt(item.active_signals_perc, 10),
            }));
            dispatch(actions.getSignalConnectivitySuccess(modified));
        })
        .catch((error) => {
            dispatch(actions.getSignalConnectivityError(error));
        });
};

export const getTSPRequestMessagesOperation = (params = {}) => (dispatch) => {
    dispatch(actions.getTSPRequestMessagesRequest());
    return requests
        .getTSPRequestMessagesRequest(params)
        .then((response) => {
            const modified = response.map((item) => ({
                ...item,
                date: new Date(item.local_end_ts.replace('Z', '')).getTime(),
                value: item.ct_request_messages_last_5m,
            }));
            dispatch(actions.getTSPRequestMessagesSuccess(modified));
        })
        .catch((error) => {
            dispatch(actions.getTSPRequestMessagesError(error));
        });
};

export const getSignalNetworkPerformanceOperation = (params = {}) => (dispatch) => {
    dispatch(actions.getSignalNetworkPerformanceRequest());
    return requests
        .getSignalNetworkPerformanceRequest(params)
        .then((response) => {
            const modified = response.result.map((item) => ({
                ...item,
                date: new Date(item.local_end_ts.replace('Z', '')).getTime(),
                avgLatency: item.avg_latency_ms,
                errorCount: item.ct_network_errors,
            }));
            dispatch(actions.getSignalNetworkPerformanceSuccess(modified));
        })
        .catch((error) => {
            dispatch(actions.getSignalNetworkPerformanceError(error));
        });
};
