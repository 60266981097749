/* eslint-disable space-before-function-paren */
/* eslint-disable keyword-spacing */
import { get } from '../../api';

// const mockCities = {
//     data: [
//         {
//             zipcode: null,
//             number_of_intersections: null,
//             maestro_pk: null,
//             installed_date: null,
//             timezone: null,
//             ip_address: null,
//             contact_person: null,
//             contact_email: null,
//             details: {
//                 center: {
//                     lat: 45.52,
//                     lng: 45.52
//                 }
//             },
//             status_schema: null,
//             maestros: null,
//             redis_mapping: null
//         },
//         {
//             zipcode: null,
//             number_of_intersections: null,
//             maestro_pk: null,
//             installed_date: null,
//             timezone: null,
//             ip_address: null,
//             contact_person: null,
//             contact_email: null,
//             details: {
//                 center: {
//                     lat: 45.5,
//                     lng: 45.5
//                 }
//             },
//             status_schema: null,
//             maestros: null,
//             redis_mapping: null
//         }
//     ],
//     pagination: {
//         count: 2,
//         offset: 0,
//         limit: 0
//     }
// };

// const mockAgencies = {
//     data: [
//         {
//             agencies_id: 'trimet',
//             agencies_short_name: 'TriMet',
//             agencies_long_name: 'TriMet',
//             code: null,
//             url: null,
//             description: null
//         }
//     ]
// };

export const getCities = async (params = {}) => {
    const {id, name} = params;
    let url = '/cities?offset=0&limit=1000';
    if (id) {
        url += `&select=${id}`;
    }
    if (name) {
        url += `&select=${name}`;
    }

    try {
        const {
            data: { data },
        } = await get(url);

        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getAgencies = async (params = {}) => {
    const url = '/agencies';

    try {
        const response = await get(url);

        return response;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
