/* eslint-disable space-before-function-paren */
/* eslint-disable keyword-spacing */
import * as actions from './alerts.actions';
import * as requests from '../../services/requests/alerts/alerts';
import {
    activeAlertsSelector,
    myAlertsSTMSelector,
    myAlertsSBMSelector,
    myAlertsMutedSelector,
} from './alerts.selectors';
import { MODAL_TYPES } from '../../components/modal/modal.constants';
import { openModal } from '../../components/modal/modal.actions';
import {
    selectUniqueUsers,
    updateRecipientList,
} from './alerts.utils';
import { getUserCityId } from '../../services/requests/request.utils';

//active-alerts

export const getAllActiveAlertsOperation
    = (offset = null, limit = null, sortKey = null, sortOrder = null, includeResolved = false) => (dispatch) => {
        const city_id = getUserCityId();
        dispatch(actions.getAllActiveAlertsRequest());

        if (sortKey) {
            if (!sortOrder) {
                sortOrder = 'ASC';
            }
        }

        return requests
            .getAlerts(offset, limit, sortKey, sortOrder, includeResolved, city_id)
            .then((response) => {
                dispatch(actions.getAllActiveAlertsSuccess(response));
            })
            .catch((error) => {
                dispatch(actions.getAllActiveAlertsError(error));
            });
    };

export const selectAlertActiveIdOperation
    = (checkedStatus, id) => (dispatch, getState) => {
        const { selectedList, list } = activeAlertsSelector(getState());

        let newSelectedList;

        if (!checkedStatus) {
            newSelectedList = selectedList.filter((itemId) => itemId !== id);
        } else {
            newSelectedList = [...selectedList, id];
        }

        return dispatch(
            actions.setSelectedAlertActiveId({
                selectedList: newSelectedList,
                recipientsList: updateRecipientList(newSelectedList, list),
            })
        );
    };

export const selectAlertActiveAllOperation
    = (checkedStatus) => (dispatch, getState) => {
        const { list } = activeAlertsSelector(getState());

        let listOfSelectedRecipients;
        let listOfSelectedAlerts;

        if (!checkedStatus) {
            listOfSelectedRecipients = [];
            listOfSelectedAlerts = [];
        } else {
            const allRecipients = list.map(({ recipients }) => recipients);

            listOfSelectedRecipients = selectUniqueUsers(allRecipients);
            listOfSelectedAlerts = list.map(({ alert_id }) => alert_id);
        }

        return dispatch(
            actions.setSelectedAlertActiveAll({
                isSelectedAll: checkedStatus,
                selectedList: listOfSelectedAlerts,
                recipientsList: listOfSelectedRecipients,
            })
        );
    };

export const modalConnectionActiveOperation
    = (alertId) => (dispatch, getState) => {
        const { selectedList, recipientsList, list } = activeAlertsSelector(
            getState()
        );

        if (alertId) {
            const listOfSelectedAlerts = list.find(({ id }) => alertId === id);

            dispatch(
                openModal({
                    type: MODAL_TYPES.SHARE_ALERT,
                    data: {
                        recipientsList: [...listOfSelectedAlerts.recipients],
                        selectedList: [listOfSelectedAlerts.id],
                    },
                })
            );
        } else {
            dispatch(
                openModal({
                    type: MODAL_TYPES.SHARE_ALERT,
                    data: {
                        recipientsList,
                        selectedList,
                    },
                })
            );
        }
    };

// Shared to me

export const selectMyAlertsSTMIdOperation
    = (checkedStatus, id) => (dispatch, getState) => {
        const { selectedList, list } = myAlertsSTMSelector(getState());

        let newSelectedList;

        if (!checkedStatus) {
            newSelectedList = selectedList.filter((itemId) => itemId !== id);
        } else {
            newSelectedList = [...selectedList, id];
        }

        dispatch(
            actions.setSelectedAlertMyAlertsSTMId({
                selectedList: newSelectedList,
                recipientsList: updateRecipientList(newSelectedList, list),
            })
        );
    };

export const selectMyAlertsSTMAllOperation
    = (checkedStatus) => (dispatch, getState) => {
        const { list } = myAlertsSTMSelector(getState());

        let listOfSelectedRecipients;
        let listOfSelectedAlerts;

        if (!checkedStatus) {
            listOfSelectedRecipients = [];
            listOfSelectedAlerts = [];
        } else {
            const allRecipients = list.map(({ recipients }) => recipients);
            listOfSelectedRecipients = selectUniqueUsers(allRecipients);
            listOfSelectedAlerts = list.map(({ id }) => id);
        }

        dispatch(
            actions.setSelectedAlertMyAlertsSTMAll({
                isSelectedAll: checkedStatus,
                selectedList: listOfSelectedAlerts,
                recipientsList: listOfSelectedRecipients,
            })
        );
    };

export const modalConnectionMyAlertsSTMOperation
    = (alertId) => (dispatch, getState) => {
        const { selectedList, recipientsList, list } = myAlertsSTMSelector(
            getState()
        );

        if (alertId) {
            const listOfSelectedAlerts = list.find(({ id }) => alertId === id);

            dispatch(
                openModal({
                    type: MODAL_TYPES.SHARE_ALERT,
                    data: {
                        recipientsList: [...listOfSelectedAlerts.recipients],
                        selectedList: [listOfSelectedAlerts.id],
                    },
                })
            );
        } else {
            dispatch(
                openModal({
                    type: MODAL_TYPES.SHARE_ALERT,
                    data: {
                        recipientsList,
                        selectedList,
                    },
                })
            );
        }
    };

// Shared by me

export const selectMyAlertsSBMIdOperation
    = (checkedStatus, id) => (dispatch, getState) => {
        const { selectedList, list } = myAlertsSBMSelector(getState());

        let newSelectedList;

        if (!checkedStatus) {
            newSelectedList = selectedList.filter((itemId) => itemId !== id);
        } else {
            newSelectedList = [...selectedList, id];
        }

        dispatch(
            actions.setSelectedAlertMyAlertsSBMId({
                selectedList: newSelectedList,
                recipientsList: updateRecipientList(newSelectedList, list),
            })
        );

        // dispatch(actions.setMyAlertsTotalInformation());
    };

export const selectMyAlertsSBMAllOperation
    = (checkedStatus) => (dispatch, getState) => {
        const { list } = myAlertsSBMSelector(getState());

        let listOfSelectedRecipients;
        let listOfSelectedAlerts;

        if (!checkedStatus) {
            listOfSelectedRecipients = [];
            listOfSelectedAlerts = [];
        } else {
            const allRecipients = list.map(({ recipients }) => recipients);
            listOfSelectedRecipients = selectUniqueUsers(allRecipients);
            listOfSelectedAlerts = list.map(({ id }) => id);
        }

        dispatch(
            actions.setSelectedAllMyAlertsSBMAll({
                isSelectedAll: checkedStatus,
                selectedList: listOfSelectedAlerts,
                recipientsList: listOfSelectedRecipients,
            })
        );
    };

export const modalConnectionMyAlertsSBMOperation
    = (alertId) => (dispatch, getState) => {
        const { selectedList, recipientsList, list } = myAlertsSBMSelector(
            getState()
        );

        if (alertId) {
            const listOfSelectedAlerts = list.find(({ id }) => alertId === id);

            dispatch(
                openModal({
                    type: MODAL_TYPES.SHARE_ALERT,
                    data: {
                        recipientsList: [...listOfSelectedAlerts.recipients],
                        selectedList: [listOfSelectedAlerts.id],
                    },
                })
            );
        } else {
            dispatch(
                openModal({
                    type: MODAL_TYPES.SHARE_ALERT,
                    data: {
                        recipientsList,
                        selectedList,
                    },
                })
            );
        }
    };

//muted

export const selectMyAlertsMutedIdOperation
    = (checkedStatus, id) => (dispatch, getState) => {
        const { selectedList, list } = myAlertsMutedSelector(getState());

        let newSelectedList;

        if (!checkedStatus) {
            newSelectedList = selectedList.filter((itemId) => itemId !== id);
        } else {
            newSelectedList = [...selectedList, id];
        }

        return dispatch(
            actions.setSelectedAlertMyAlertsMutedId({
                selectedList: newSelectedList,
                recipientsList: updateRecipientList(newSelectedList, list),
            })
        );
    };

export const selectMyAlertsMutedAllOperation
    = (checkedStatus) => (dispatch, getState) => {
        const { list } = myAlertsMutedSelector(getState());

        let listOfSelectedRecipients;
        let listOfSelectedAlerts;

        if (!checkedStatus) {
            listOfSelectedRecipients = [];
            listOfSelectedAlerts = [];
        } else {
            const allRecipients = list.map(({ recipients }) => recipients);

            listOfSelectedRecipients = selectUniqueUsers(allRecipients);
            listOfSelectedAlerts = list.map(({ id }) => id);
        }

        return dispatch(
            actions.setSelectedAlertMyAlertsMutedAll({
                isSelectedAll: checkedStatus,
                selectedList: listOfSelectedAlerts,
                recipientsList: listOfSelectedRecipients,
            })
        );
    };

// export const modalConnectionCriticalMutedOperation =
//     (alertId) => (dispatch, getState) => {
//         const { selectedList, recipientsList, list } =
//             criticalMutedAlertsSelector(getState());

//         if (alertId) {
//             const listOfSelectedAlerts = list.find(({ id }) => alertId === id);

//             dispatch(
//                 openModal({
//                     type: MODAL_TYPES.SHARE_CRITICAL_ACTIVE_ALERT,
//                     data: {
//                         recipientsList: [...listOfSelectedAlerts.recipients],
//                         selectedList: [listOfSelectedAlerts.id],
//                     },
//                 })
//             );
//         } else {
//             dispatch(
//                 openModal({
//                     type: MODAL_TYPES.SHARE_CRITICAL_ACTIVE_ALERT,
//                     data: {
//                         recipientsList: recipientsList,
//                         selectedList: selectedList,
//                     },
//                 })
//             );
//         }
//     };
