import { types } from './requests.types';

// list
export const getRequestsListRequest = () => ({
    type: types.GET_REQUESTS_LIST_REQUEST,
});
export const getRequestsListError = (error) => ({
    type: types.GET_REQUESTS_LIST_ERROR,
    payload: error,
});
export const getRequestsListSuccess = (data = {}) => ({
    type: types.GET_REQUESTS_LIST_SUCCESS,
    payload: data,
});

// approve request
export const sendApproveRequestError = (error) => ({
    type: types.APPROVE_REQUEST_ERROR,
    payload: error,
});
export const sendApproveRequestSuccess = (data = {}) => ({
    type: types.APPROVE_REQUEST_SUCCESS,
    payload: data,
});

// reject request
export const sendRejectRequestError = (error) => ({
    type: types.REJECT_REQUEST_ERROR,
    payload: error,
});
export const sendRejectRequestSuccess = (data = {}) => ({
    type: types.REJECT_REQUEST_SUCCESS,
    payload: data,
});
