export const chartExpansionStatus = (state) =>
    state.routes.routes.chartsStatus;

export const agencyRoutesSelector = (state) =>
    state.routes.routes.routes;

export const tlAndOnTimePerformanceSelector = (state) =>
    state.routes.tspStatistics.tlAndOnTimePerformance;

export const tlAndOnTimePerformanceFPSelector = (state) =>
    state.routes.tspStatistics.tlAndOnTimePerformance.filters;

export const tspActivitySelector = (state) =>
    state.routes.tspStatistics.tspActivity;

export const tspActivityFPSelector = (state) =>
    state.routes.tspStatistics.tspActivity.filters;

export const routeStopsSelector = (state) =>
    state.routes.routes.routeStops;

export const routesPerformanceSelector = (state) =>
    state.routes.tspStatistics.routesPerformance;

export const routesPerformanceFPSelector = (state) =>
    state.routes.tspStatistics.routesPerformance.filters;

export const priorityRequestSelector = (state) =>
    state.routes.routes.priorityRequests;

export const priorityRequestFPSelector = (state) =>
    state.routes.routes.priorityRequests.filters;

export const routeDetailsSelector = (state) => state.routes.routes.routeDetails;

export const routeDetailsFPSelector = (state) =>
    state.routes.routes.routeDetails.filters;

export const headwaySelector = (state) => state.routes.routes.headway;

export const headwayFPSelector = (state) => state.routes.routes.headway.filters;

export const routePerformanceByStopSelector = (state) =>
    state.routes.routes.routePerformanceByStop;

export const routePerformanceByStopFPSelector = (state) =>
    state.routes.routes.routePerformanceByStop.filters;

export const travelTimeSelector = (state) => state.routes.routes.travelTime;

export const travelTimeFPSelector = (state) =>
    state.routes.routes.travelTime.filters;

export const redLightWaitTimeSelector = (state) =>
    state.routes.routes.redLightWaitTime;

export const redLightWaitTimeFPSelector = (state) =>
    state.routes.routes.redLightWaitTime.filters;

export const busStopDwellTimeSelector = (state) =>
    state.routes.routes.busStopDwellTime;

export const busStopDwellTimeFPSelector = (state) =>
    state.routes.routes.busStopDwellTime.filters;

export const averageVehicleSpeedSelector = (state) =>
    state.routes.routes.averageVehicleSpeed;

export const averageVehicleSpeedFPSelector = (state) =>
    state.routes.routes.averageVehicleSpeed.filters;

export const segmentTravelTimeSelector = (state) =>
    state.routes.routes.segmentTravelTime;

export const segmentTravelTimeFPSelector = (state) =>
    state.routes.routes.segmentTravelTime.filters;
