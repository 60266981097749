import { types } from './user.types';

export const setUserSelectedLocation = (data = {}) => ({
    type: types.SET_USER_SELECTED_LOCATION_INFO,
    payload: data,
});

export const getCityInformationByIdRequest = () => ({
    type: types.GET_CITY_INFORMATION_BY_ID_REQUEST,
});

export const getCityInformationByIdSuccess = (data = {}) => ({
    type: types.GET_CITY_INFORMATION_BY_ID_SUCCESS,
    payload: data,
});

export const getCityInformationByIdError = (error = '') => ({
    type: types.GET_CITY_INFORMATION_BY_ID_ERROR,
    payload: error,
});

export const getUserPersonalInformationRequest = () => ({
    type: types.GET_USER_PERSONAL_INFORMATION_REQUEST,
});

export const getUserPersonalInformationSuccess = (data) => ({
    type: types.GET_USER_PERSONAL_INFORMATION_SUCCESS,
    payload: data,
});

export const getUserPersonalInformationError = (error) => ({
    type: types.GET_USER_PERSONAL_INFORMATION_ERROR,
    payload: error,
});

export const setUserPersonalInformation = (data) => ({
    type: types.SET_USER_PERSONAL_INFORMATION,
    payload: data,
});

export const setUserPersonalInformationRequest = () => ({
    type: types.SET_USER_PERSONAL_INFORMATION_REQUEST,
});

export const setUserPersonalInformationSuccess = (data) => ({
    type: types.SET_USER_PERSONAL_INFORMATION_SUCCESS,
    payload: data,
});

export const setUserPersonalInformationError = (error) => ({
    type: types.SET_USER_PERSONAL_INFORMATION_ERROR,
    payload: error,
});

export const clearUserStoredInformation = () => ({
    type: types.CLEAR_USER_STORED_INFO,
});

export const changePasswordRequest = () => ({
    type: types.CHANGE_PASSWORD_REQUEST
});

export const changePasswordSuccess = (data) => ({
    type: types.CHANGE_PASSWORD_SUCCESS,
    payload: data
});

export const changePasswordError = (error) => ({
    type: types.CHANGE_PASSWORD_ERROR,
    payload: error
});

export const setUserTheme = (data) => ({
    type: types.SET_USER_THEME,
    payload: data,
});

export const setUserName = (data) => ({
    type: types.SET_USER_NAME,
    payload: data
});
