import { h } from "preact";
import { useSelector, useDispatch } from "react-redux";
import { userThemeSelector } from "../../../../store/user/user.selectors";
import { setUserTheme } from "../../../../store/user/user.actions";

import IconSVG from "../../../ui-core/svg-icon";

import styles from "./styles.scss";

const Switch = () => {
    const dispatch = useDispatch();

    const theme = useSelector(userThemeSelector);

    const handleCheckboxClick = () => {
        if (theme === "theme-light") {
            return dispatch(setUserTheme("theme-dark"));
        }
        return dispatch(setUserTheme("theme-light"));
    };

    const setCheckedStatus = () => {
        if (theme === "theme-light") return false;
        if (theme === "theme-dark") return true;
    };

    return (
        <div className={`${styles.switch__wrapper} switch__wrapper--g`}>
            <div className={styles["switch__checkbox-wrapper"]}>
                <input
                    type="checkbox"
                    checked={setCheckedStatus()}
                    onClick={handleCheckboxClick}
                    className={`${styles.switch__checkbox} switch__checkbox--g`}
                    id="checkbox"
                />
                <label htmlFor="checkbox" className={styles.switch__label}>
                    <div className={`${styles.switch__light} switch__light--g`}>
                        <div
                            width="20"
                            height="20"
                            className={styles["switch__fa-sun"]}
                        >
                            <IconSVG type="switch-sun" />
                        </div>
                        <p className={`${styles.switch__text} switch__text--g`}>Light</p>
                    </div>

                    <div className={`${styles.switch__dark} switch__dark--g`}>
                        <div
                            width="20"
                            height="20"
                            className={styles["switch__fa-moon"]}
                        >
                            <IconSVG type="switch-moon" />
                        </div>
                        <p className={`${styles.switch__text} switch__text--g`}>Dark</p>
                    </div>

                    <div className={`${styles.switch__ball} switch__ball--g`}></div>
                </label>
            </div>
        </div>
    );
};

export default Switch;
