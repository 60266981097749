import { types } from './cities-and-agencies.types';

// cities
export const getCitiesRequest = () => ({
    type: types.GET_CITIES_REQUEST,
});
export const getCitiesSuccess = (data = {}) => ({
    type: types.GET_CITIES_SUCCESS,
    payload: data,
});
export const getCitiesError = (error) => ({
    type: types.GET_CITIES_ERROR,
    payload: error,
});

// agencies
export const getAgenciesRequest = () => ({
    type: types.GET_AGENCIES_REQUEST,
});
export const getAgenciesSuccess = (data = {}) => ({
    type: types.GET_AGENCIES_SUCCESS,
    payload: data,
});
export const getAgenciesError = (error) => ({
    type: types.GET_AGENCIES_ERROR,
    payload: error,
});
