/* eslint-disable space-before-function-paren */
import { get, put } from '../../api';

import { getUserEmail } from '../request.utils';

// const mockUser = {
//     id: 1027,
//     email: 'richard@lyt.ai',
//     username: null,
//     firstname: null,
//     lastname: null,
//     usertype: 'Agency_Admin',
//     phone: null,
//     notification_through: null,
//     account_approved: true,
//     inviting_user_email: 'testJest2@gmail.com',
//     approving_user_email: null
// };

// const mockCities = {
//     zipcode: null,
//     number_of_intersections: null,
//     maestro_pk: null,
//     installed_date: null,
//     timezone: null,
//     ip_address: null,
//     contact_person: null,
//     contact_email: null,
//     details: {
//         center: {
//             lat: 45.5,
//             lng: 45.5
//         }
//     },
//     maestros: null
// };


export const getUserPersonalInformationRequest = async (params = {}) => {
    const url = `/users/email?email=${getUserEmail()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};


export const getCityInformationByIdRequest = async (params = {}) => {
    const { id = '' } = params;

    const url = `cities/${id}`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const updateUserRequest = async (data = {}) => {
    const url = '/users/user';

    try {
        const response = await put(url, data);

        return response;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const changePasswordRequest = async (data = {}) => {
    const url = 'users/password';

    try {
        const response = await put(url, data);

        return response;
    } catch(err) {
        console.error(err);
        throw err;
    }
};
