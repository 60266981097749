
const DEFAULT_DARK_THEME_COLORS = {
    background: '#2c303b',
    foreground: '#393d48',
};

const DEFAULT_LIGHT_THEME_COLORS = {
    background: '#f0f2f8',
    foreground: '#ededed',
};

const PRIMARY_THEME_COLORS = {
    background: '#fff',
    foreground: '#f0f2f8',
};

const SECONDARY_THEME_COLORS = {
    background: '#E7F0FF',
    foreground: '#0057E0',
};

export const COLORS_TYPES = {
    default: {
        'theme-light': DEFAULT_LIGHT_THEME_COLORS,
        'theme-dark': DEFAULT_DARK_THEME_COLORS,
    },
    primary: {
        'theme-light': PRIMARY_THEME_COLORS,
        'theme-dark': DEFAULT_DARK_THEME_COLORS,
    },
    secondary: {
        'theme-light': SECONDARY_THEME_COLORS,
        'theme-dark': DEFAULT_DARK_THEME_COLORS,
    },
};
