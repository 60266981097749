/* eslint-disable react/prop-types */
/* eslint-disable keyword-spacing */
import { Component } from 'preact';

import ErrorBoundaryCases from './error-boundary.config';

class ErrorBoundary extends Component {
    state = { error: null };

    static getDerivedStateFromError(error) {
        return { error: error.message };
    }

    componentDidCatch(error) {
        this.setState({ error: error.message });
    }

    render() {
        if (this.state.error) {
            return <ErrorBoundaryCases errorCase={this.props.fallBack} errorComponent={this.props.children.type.name} />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
