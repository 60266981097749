import { h, Fragment } from "preact";
import PropTypes from "prop-types";

import { isVisible, getNumberContent } from "./badge.utils";
import {
    VIEW_TYPES_LIST,
    SIZES_LIST,
    COLOR_TYPES_LIST,
} from "./badge.constants";

import styles from "./styles.scss";

const Badge = ({
    viewType = VIEW_TYPES_LIST.DEFAULT,
    colorType = COLOR_TYPES_LIST.DEFAULT,
    size = SIZES_LIST.SM,
    content = 0,
    isShowAlways = false,
    handleClick = () => {},
    disabled = false,
}) => {
    const className = `${styles.badge} ${styles[`badge-${viewType}`]} ${
        styles[`badge--color-${colorType}`]
    } ${styles[`badge-${viewType}--${size}`]} ${
        disabled ? styles[`badge--disabled`] : ""
    }`;

    return (
        <Fragment>
            {isVisible(viewType, content, isShowAlways) && (
                <div onClick={handleClick} className={className}>
                    {viewType === VIEW_TYPES_LIST.NUMBER &&
                        getNumberContent(content, isShowAlways)}
                </div>
            )}
        </Fragment>
    );
};

Badge.propTypes = {
    viewType: PropTypes.oneOf(Object.values(VIEW_TYPES_LIST)),
    colorType: PropTypes.oneOf(Object.values(COLOR_TYPES_LIST)),
    size: PropTypes.oneOf(Object.values(SIZES_LIST)),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
    isShowAlways: PropTypes.bool,
};

export default Badge;
