import { useState, useRef, useEffect } from "preact/hooks";
import Tooltip from "../../Tooltip";
import SvgIcon from "../../svg-icon";

import { isEmail, isInList } from "./email-chips.utils";

import styles from "./styles.scss";

const EmailChips = () => {
    const [items, setItems] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(null);

    const textFieldRef = useRef(null);

    useEffect(() => {
        textFieldRef.current.focus();
    }, []);

    const handleKeyDown = (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            if (value && isValid(value)) {
                setItems([...items, value]);
                setValue("");
            }
        }
    };

    const handleChange = (evt) => {
        setValue(evt.target.value);
        setError(null);
    };

    const handleDelete = (item) => {
        setItems(items.filter((i) => i !== item));
    };

    const handlePaste = (evt) => {
        evt.preventDefault();

        let paste = evt.clipboardData.getData("text");
        let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
            let toBeAdded = emails.filter(
                (email) => !this.isInList(email, items)
            );
            setItems([...items, ...toBeAdded]);
        }
    };

    const isValid = (email) => {
        let errorMessage = null;

        if (isInList(email, items)) {
            errorMessage = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            errorMessage = `${email} is not a valid email address.`;
        }

        if (errorMessage) {
            setError(errorMessage);
            return false;
        }

        return true;
    };

    const setFocus = ({ target }) => {
        if (target.id === "tag-item") return;

        textFieldRef.current.focus();
    };

    return (
        <>
            <label className={styles["email-chips__label"]}>
                Share with external recipient{" "}
                {items.length > 0 && (
                    <span className={styles["email-chips__label-count"]}>
                        {items.length}
                    </span>
                )}
            </label>
            <div onClick={setFocus} className={styles["email-chips__input"]}>
                {items.map((item) => (
                    <div
                        id="tag-item"
                        className={styles["email-chips__tag-item"]}
                        key={item}>
                        {item}
                        <button
                            type="button"
                            className={styles["email-chips__cancel-button"]}
                            onClick={() => handleDelete(item)}>
                            <SvgIcon type="cross" />
                        </button>
                    </div>
                ))}

                <div className={styles["email-chips__field-container"]}>
                    <input
                        className={`${styles["email-chips__field"]} ${
                            error ? styles["email-chips__field--error"] : ""
                        }`}
                        value={value}
                        ref={textFieldRef}
                        placeholder={'Enter emails you want to share alert with'}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        onPaste={handlePaste}
                    />
                </div>

                <div className={styles["email-chips__input-tooltip"]}>
                    <Tooltip
                        isShow={error ? true : false}
                        position={"right"}
                        icon={<SvgIcon type={"warning"} />}
                        message={error}
                    />
                </div>
            </div>
        </>
    );
};

export default EmailChips;
