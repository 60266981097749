import { types } from "./settings.types";

const initialSate = {
    widgets: {
        added: {
            list: [],
            loading: false,
            error: "",
        },
        all: {
            list: [],
            loading: true,
            error: "",
        },
    },
};

const settings = (state = initialSate, { type, payload }) => {
    switch (type) {
        // All widgets list

        case types.GET_ALL_WIDGETS_LIST_REQUEST:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    all: {
                        ...state.widgets.all,
                        loading: true,
                    },
                },
            };

        case types.GET_ALL_WIDGETS_LIST_SUCCESS:
            const { list: listOfAddedWidgets } = state.widgets.added;

            if (listOfAddedWidgets.length) {
                const filteredArr = payload.filter((allWidgetsItem) => {
                    if (
                        !listOfAddedWidgets.find(
                            (addedWidgetsItem) =>
                                allWidgetsItem.id === addedWidgetsItem.id
                        )
                    ) {
                        return allWidgetsItem;
                    }
                });

                return {
                    ...state,
                    widgets: {
                        ...state.widgets,
                        all: {
                            ...state.widgets.all,
                            list: filteredArr,
                            loading: false,
                        },
                    },
                };
            }

            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    all: {
                        ...state.widgets.all,
                        list: payload,
                        loading: false,
                    },
                },
            };

        case types.GET_ALL_WIDGETS_LIST_ERROR:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    all: {
                        ...state.widgets.all,
                        error: payload,
                        loading: false,
                    },
                },
            };

        case types.SET_ALL_WIDGETS_LIST:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    all: {
                        ...state.widgets.all,
                        list: payload,
                    },
                },
            };

        // Added widgets

        case types.GET_ADDED_WIDGETS_REQUEST:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    added: {
                        ...state.widgets.added,
                        loading: true,
                    },
                },
            };

        case types.GET_ADDED_WIDGETS_SUCCESS:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    added: {
                        ...state.widgets.added,
                        list: payload,
                        loading: false,
                    },
                },
            };

        case types.GET_ADDED_WIDGETS_ERROR:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    added: {
                        ...state.widgets.added,
                        error: payload,
                        loading: false,
                    },
                },
            };

        case types.SET_ADDED_WIDGETS_LIST:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    added: {
                        ...state.widgets.added,
                        list: payload,
                    },
                },
            };

        case types.SEND_ADDED_WIDGETS_REQUEST:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    added: {
                        ...state.widgets.added,
                        loading: true,
                    },
                },
            };

        case types.SEND_ADDED_WIDGETS_SUCCESS:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    added: {
                        ...state.widgets.added,
                        list: payload,
                        loading: false,
                    },
                },
            };

        case types.SEND_ADDED_WIDGETS_ERROR:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    added: {
                        ...state.widgets.added,
                        error: payload,
                        loading: false,
                    },
                },
            };
        default:
            return state;
    }
};

export default settings;
