import ContentLoader from "react-content-loader";

const SkeletonRoutesTable = ({ colors, ...props }) => (
    <tr>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={47}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="10" y="20" rx="6" ry="6" width="90%" height="20" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={47}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="15" y="20" rx="6" ry="6" width="45%" height="20" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={47}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="15" y="20" rx="6" ry="6" width="55%" height="20" />
            </ContentLoader>
        </th>
        <th>
            <ContentLoader
                speed={2}
                width={"100%"}
                height={47}
                backgroundColor={colors.background}
                foregroundColor={colors.foreground}
            >
                <rect x="40" y="20" rx="10" ry="10" width="60%" height="23" />
            </ContentLoader>
        </th>
    </tr>
);

export default SkeletonRoutesTable;
