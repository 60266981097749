import { Fragment } from "preact";
import PropTypes from "prop-types";
import SvgIcon from "../../../svg-icon";

import styles from "./styles.scss";

const DateRangeInput = ({
    value,
    onClick,
    placeholder = "mm/dd/yyyy - mm/dd/yyyy",
    ...props
}) => {
    return (
        <Fragment>
            <input
                className={styles["date-range-input"]}
                value={value}
                onClick={onClick}
                disabled={true}
                placeholder={placeholder}
            />
            <div onClick={onClick} className={styles["date-range-input__icon"]}>
                <SvgIcon type={"date"} />
            </div>
        </Fragment>
    );
};

DateRangeInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    placeholder: PropTypes.string,
};

export default DateRangeInput;
