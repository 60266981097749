export const SIZES_LIST = {
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    SQUARE_MD: 'square-md',
    SQUARE_38: 'square-38',
    SQUARE_42: 'square-42',
    RECTANGLE_38: 'rectangle-38',
};

export const VIEW_TYPES_LIST = {
    DEFAULT: 'default',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    DANGER: 'danger',
    LINK: 'link',
    DISABLED: 'disabled',
    OUTLINE: 'outline',
};

export const TYPES_LIST = {
    SUBMIT: 'submit',
    BUTTON: 'button',
};
