import { types } from './map.types';


//City

export const getCityInformationByIdRequest = () => ({
    type: types.GET_CITY_INFORMATION_BY_ID_REQUEST,
});

export const getCityInformationByIdSuccess = (data = {}) => ({
    type: types.GET_CITY_INFORMATION_BY_ID_SUCCESS,
    payload: data,
});

export const getCityInformationByIdError = (error = '') => ({
    type: types.GET_CITY_INFORMATION_BY_ID_ERROR,
    payload: error,
});


// Routes

export const getMapRoutesRequest = () => ({
    type: types.GET_MAP_ROUTES_REQUEST,
});

export const getMapRoutesSuccess = (data = {}) => ({
    type: types.GET_MAP_ROUTES_SUCCESS,
    payload: data,
});

export const getMapRoutesError = (error = '') => ({
    type: types.GET_MAP_ROUTES_ERROR,
    payload: error,
});

export const getMapRouteByIdRequest = () => ({
    type: types.GET_MAP_ROUTE_BY_ID_REQUEST,
});

export const getMapRouteByIdSuccess = (data = {}) => ({
    type: types.GET_MAP_ROUTE_BY_ID_SUCCESS,
    payload: data,
});

export const getMapRouteByIdError = (error = '') => ({
    type: types.GET_MAP_ROUTE_BY_ID_ERROR,
    payload: error,
});

export const getMapRouteDetailsByIdRequest = () => ({
    type: types.GET_MAP_ROUTE_DETAILS_BY_ID_REQUEST,
});

export const getMapRouteDetailsByIdSuccess = (data = {}) => ({
    type: types.GET_MAP_ROUTE_DETAILS_BY_ID_SUCCESS,
    payload: data,
});

export const getMapRouteDetailsByIdError = (error = '') => ({
    type: types.GET_MAP_ROUTE_DETAILS_BY_ID_ERROR,
    payload: error,
});

export const setMapRouteId = (id) => ({
    type: types.SET_MAP_ROUTE_ID,
    payload: id,
});

export const resetMapRoute = () => ({
    type: types.RESET_MAP_ROUTE,
});

// Stops

export const getMapStopsRequest = () => ({
    type: types.GET_MAP_STOPS_REQUEST,
});

export const getMapStopsSuccess = (data) => ({
    type: types.GET_MAP_STOPS_SUCCESS,
    payload: data,
});

export const getMapStopsError = (error) => ({
    type: types.GET_MAP_STOPS_ERROR,
    payload: error,
});

//insights

export const getMapInsightsRequest = () => ({
    type: types.GET_MAP_INSIGHT_REQUEST,
});

export const getMapInsightsSuccess = (data) => ({
    type: types.GET_MAP_INSIGHT_SUCCESS,
    payload: data,
});

export const getMapInsightError = (error) => ({
    type: types.GET_MAP_INSIGHT_ERROR,
    payload: error,
});

//fire-station

export const getMapFireStationRequest = () => ({
    type: types.GET_MAP_FIRE_STATION_REQUEST,
});

export const getMapFireStationSuccess = (data) => ({
    type: types.GET_MAP_FIRE_STATION_SUCCESS,
    payload: data,
});

export const getMapFireStationError = (error) => ({
    type: types.GET_MAP_FIRE_STATION_ERROR,
    payload: error,
});

//fire-station

export const getMapTrafficLightsRequest = () => ({
    type: types.GET_MAP_TRAFFIC_LIGHTS_REQUEST,
});

export const getMapTrafficLightsSuccess = (data) => ({
    type: types.GET_MAP_TRAFFIC_LIGHTS_SUCCESS,
    payload: data,
});

export const getMapTrafficLightsError = (error) => ({
    type: types.GET_MAP_TRAFFIC_LIGHTS_ERROR,
    payload: error,
});

export const getMapStopsByIdRequest = () => ({
    type: types.GET_MAP_STOPS_BY_ID_REQUEST,
});

export const getMapStopsByIdSuccess = (data = {}) => ({
    type: types.GET_MAP_STOPS_BY_ID_SUCCESS,
    payload: data,
});

export const getMapStopsByIdError = (error = '') => ({
    type: types.GET_MAP_STOPS_BY_ID_ERROR,
    payload: error,
});

export const resetMapStops = () => ({
    type: types.RESET_MAP_STOPS,
});

export const setMapPopupInformation = (data) => ({
    type: types.SET_MAP_POPUP_INFORMATION,
    payload: data,
});

export const resetMapInformation = () => ({
    type: types.RESET_MAP_INFORMATION,
});

export const setMapLayerVisibilityStatus = (data) => ({
    type: types.SET_MAP_LAYER_VISIBILITY_STATUS,
    payload: data,
});

export const setRouteLineColor = (data) => ({
    type: types.SET_ROUTE_LINE_COLOR,
    payload: data,
});

export const setMapStyle = (data) => ({
    type: types.SET_MAP_STYLE,
    payload: data,
});
