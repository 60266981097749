/* eslint-disable no-confusing-arrow */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { useDispatch } from 'react-redux';
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import Button from '../../../ui-core/button';

import { sendUserUpdateOperation } from '../../../../store/user/user.operations';
import { VIEW_TYPES_LIST } from '../../../ui-core/button/button.constants';
import { clearModalState } from '../../modal.actions';
import { profileLabels, notificationLabels } from '../../../../routes/settings/components/profile-form-labels';

import styles from './styles.scss';

const ChangeProfileConfirmation = ({ fieldsToChange = {}, onCancel = () => {}}) => {
    const dispatch = useDispatch();
    const [isWorking, setWorking] = useState(false);

    const handleUpdateButton = () => {
        setWorking(true);
        try {
            dispatch(sendUserUpdateOperation(fieldsToChange));
        } catch (error) {
            console.log('handleUpdateButton error', error);
        } finally {
            dispatch(clearModalState());
            setWorking(false);
        }
    };

    return (
        <div className={styles['update-profile-confirmation']}>
            <p className={styles['update-profile-confirmation__title']}>
                Are you sure you want to update these fields ?
            </p>
            <p className={styles['update-profile-confirmation__description']}>
                {
                    Object.keys(fieldsToChange)
                        .map(field =>
                            field === 'notification_through'
                                ? `${profileLabels[field]}: ${notificationLabels[fieldsToChange.notification_through]}`
                                : profileLabels[field]
                        )
                        .join(', ')
                        .slice(0, -2)
                }
            </p>
            <div className={styles['update-profile-confirmation__buttons']}>
                <div className={styles['update-profile-confirmation__button']}>
                    <Button
                        handleClick={() => {
                            dispatch(clearModalState());
                            onCancel();
                        }}
                        label={'No, cancel'}
                    />
                </div>
                <div className={styles['update-profile-confirmation__button']}>
                    <Button
                        handleClick={handleUpdateButton}
                        label={'Yes, update'}
                        viewType={VIEW_TYPES_LIST.PRIMARY}
                        loading={isWorking}
                        disabled={isWorking}
                    />
                </div>
            </div>
        </div>
    );
};

ChangeProfileConfirmation.PropTypes = {
    fieldsToChange: PropTypes.array.isRequired
};

export default ChangeProfileConfirmation;
