import { types } from './alerts.types';

// import { temporaryDataActive } from './alerts-active/components/active-alerts-table/active-alerts-table.config';
import { temporaryDataMyAlertsMuted } from './alerts-my-alerts/components/muted-table/muted-table.config';
import { temporaryDataMyAlertsSTM } from './alerts-my-alerts/components/shared-to-me-table/shared-to-me-table.config';
import { temporaryDataMyAlertsSBM } from './alerts-my-alerts/components/shared-by-me-table/shared-by-me-table.config';
const initialState = {
    active: {
        list: [], // was [...temporaryDataActive]
        offset: 0,
        limit: 1000,
        count: 0,
        loading: false,
        error: '',
        sortingParams: {},
        selectedList: [],
        recipientsList: [],
        isSelectedAll: false,
    },

    myAlerts: {
        sharedToMe: {
            list: [...temporaryDataMyAlertsSTM],
            count: 0,
            loading: false,
            error: '',
            sortingParams: {},
            selectedList: [],
            recipientsList: [],
            isSelectedAll: false,
        },
        sharedByMe: {
            list: [...temporaryDataMyAlertsSBM],
            count: 0,
            loading: false,
            error: '',
            sortingParams: {},
            selectedList: [],
            recipientsList: [],
            isSelectedAll: false,
        },

        muted: {
            list: [...temporaryDataMyAlertsMuted],
            count: 0,
            loading: false,
            error: '',
            sortingParams: {},
            selectedList: [],
            recipientsList: [],
            isSelectedAll: false,
        },
    },
};

const alerts = (state = initialState, { type, payload }) => {
    //active
    switch (type) {
    case types.GET_ALL_ACTIVE_ALERTS_REQUEST:
        return {
            ...state,
            active: {
                ...state.active,
                loading: true
            }
        };
    case types.GET_ALL_ACTIVE_ALERTS_SUCCESS:
        return {
            ...state,
            active: {
                ...state.active,
                list: payload.data,
                offset: payload.pagination.offset,
                limit: payload.pagination.limit,
                count: payload.pagination.count,
                error: '',
                loading: false
            }
        };
    case types.GET_ALL_ACTIVE_ALERTS_ERROR:
        return {
            ...state,
            active: {
                ...state.active,
                list: [],
                error: payload,
                loading: false
            }
        };
    case types.SET_SELECTED_ALERT_ACTIVE_ID:
        return {
            ...state,
            active: {
                ...state.active,
                ...payload,
            },
        };
    case types.SET_SELECTED_ALERT_ACTIVE_ALL:
        return {
            ...state,
            active: {
                ...state.active,
                ...payload,
            },
        };

        //my-alerts

        // Shared to me

    case types.SET_SELECTED_ALERT_MY_ALERTS_STM_ID:
        return {
            ...state,
            myAlerts: {
                ...state.myAlerts,
                sharedToMe: {
                    ...state.myAlerts.sharedToMe,
                    ...payload,
                },
            },
        };

    case types.SET_SELECTED_ALERT_MY_ALERTS_STM_ALL:
        return {
            ...state,
            myAlerts: {
                ...state.myAlerts,
                sharedToMe: {
                    ...state.myAlerts.sharedToMe,
                    ...payload,
                },
            },
        };

        // Shared by me

    case types.SET_SELECTED_ALERT_MY_ALERTS_SBM_ID:
        return {
            ...state,
            myAlerts: {
                ...state.myAlerts,
                sharedByMe: {
                    ...state.myAlerts.sharedByMe,
                    ...payload,
                },
            },
        };

    case types.SET_SELECTED_ALERT_MY_ALERTS_SBM_ALL:
        return {
            ...state,
            myAlerts: {
                ...state.myAlerts,
                sharedByMe: {
                    ...state.myAlerts.sharedByMe,
                    ...payload,
                },
            },
        };

        //muted
    case types.SET_SELECTED_ALERT_MY_ALERTS_MUTED_ID:
        return {
            ...state,
            myAlerts: {
                ...state.myAlerts.muted,
                muted: {
                    ...state.myAlerts.muted,
                    ...payload,
                },
            },
        };

    case types.SET_SELECTED_ALERT_MY_ALERTS_MUTED_ALL:
        return {
            ...state,
            myAlerts: {
                ...state.myAlerts,
                muted: {
                    ...state.myAlerts.muted,
                    ...payload,
                },
            },
        };

    default:
        return state;
    }
};

export default alerts;
