/* eslint-disable camelcase */
export const bookList = Object.freeze({
    dashboard: {
        general: '/dashboard/:rest*',
        entry_point: '/dashboard/general',
        tsp_statistics: '/dashboard/tsp-statistics',
        map: '/dashboard/map',
        lyt_efficiency: '/dashboard/lyt-efficiency',
    },
    auth: {
        login: '/login',
        login_confirmation: '/login-confirmation',
        // registration: '/registration',
        // registration_form: '/registration',
        // registration_success: '/registration-success',
        // forgot_confirmation: '/forgot-confirmation',
        // forgot_change: '/forgot-password/recovery/:token',
        // forgot_success: '/forgot-success',
    },
    alerts: {
        default: '/alerts',
        active: '/alerts/active',
        my_alerts: '/alerts/my-alerts',
        single: '/alerts/:id',
    },
    routes: {
        general: '/routes/:rest*',
        tsp_statistics: '/routes/tsp-statistics',
        reports: '/routes/reports',
        tsp_requests: '/routes/tsp-requests',
    },
    intersections: {
        general: '/intersections/:rest*',
        lost_connection: '/intersections/lost-connection',
        priority_requests: {
            general: '/intersections/priority-requests/:rest*',
            successful: '/intersections/priority-requests/successful',
            denied: '/intersections/priority-requests/denied',
        },
        spm: '/intersections/spm',
        statistics: '/intersections/statistics',
    },
    live_operations: {
        general: '/live-operations',
        default: '/live-operations',
    },
    users_and_permissions: {
        general: '/users-and-permissions/:rest*',
        users: '/users-and-permissions/users',
        groups: '/users-and-permissions/groups',
        roles: '/users-and-permissions/roles',
        requests: '/users-and-permissions/requests',
    },
    business_rules: {
        general: '/business-rules/:rest*',
        routes: '/business-rules/routes',
        priorityRules: '/business-rules/priority-rules',
    },
    settings: {
        profile: '/settings/profile',
        notifications_and_alerts: '/settings/notifications_and_alerts',
        groups: '/settings/groups',
    },
    help: {
        faq: 'help/:rest*',
        getting_started: 'help/getting-started'
    },
    notFound: '/404',
    mapGL: '/map-gl',
});
