/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { get, put } from '../../../api';
import { getUserAgencyId } from '../../request.utils';

export const getRequestsLists = async (params = {}) => {
    let url;
    if (params.userIsLytAdmin) {
        url = '/users/agencies/cities?offset=0&limit=1000';
    } else {
        url = `/users/agencies/cities?offset=0&limit=1000&agencies_id=${getUserAgencyId()}`;
    }

    try {
        let { data } = await get(url);
        const filteredData = data.filter(d => !d.account_approved);
        return filteredData;
    } catch (err) {
        throw err.response.data;
    }
};

export const approveRequest = async (params = {}) => {
    const url = '/users/approve';

    try {
        const { data } = await put(url, params);

        return data;
    } catch (err) {
        throw err.response.data;
    }
};
