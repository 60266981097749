import ContentLoader from "react-content-loader";

const SkeletonRoutesTrafficLightsOperationData = ({ colors, ...props }) => {
    return (
        <ContentLoader
            speed={2}
            width={'100%'}
            height={60}
            backgroundColor={colors.background}
            foregroundColor={colors.foreground}
        >
            <rect x="0" y="0" rx="15" ry="15" width="240" height="60" />
            <rect x="260" y="0" rx="15" ry="15" width="240" height="60" />
            <rect x="520" y="0" rx="15" ry="15" width="240" height="60" />
            <rect x="780" y="0" rx="15" ry="15" width="270" height="60" />
        </ContentLoader>
    );
};

export default SkeletonRoutesTrafficLightsOperationData;
