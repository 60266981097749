/* eslint-disable space-before-function-paren */
/* eslint-disable keyword-spacing */
import { get } from '../../api';

// const mockCities = [
//     {
//         agencies_id: 'trimet',
//         agencies_short_name: 'TriMet',
//         agencies_long_name: 'TriMet',
//         cities: [
//             {
//                 name: 'Gresham',
//                 id: 'grsm',
//                 details: {
//                     center: {
//                         lat: 45.5,
//                         lng: -122.44
//                     }
//                 }
//             },
//             {
//                 name: 'ODOT',
//                 id: 'odot',
//                 details: {
//                     center: {
//                         lat: 45.5,
//                         lng: -122.57
//                     }
//                 }
//             },
//             {
//                 name: 'Portland',
//                 id: 'pdx',
//                 details: {
//                     center: {
//                         lat: 45.52,
//                         lng: -122.66
//                     }
//                 }
//             },
//             {
//                 name: 'TriMet Test City',
//                 id: 'tritest',
//                 details: {
//                     center: {
//                         lat: 45.52,
//                         lng: -122.66
//                     }
//                 }
//             }
//         ]
//     }
// ]

export const getUserAgenciesRequest = async () => {
    const url = 'agencies/cities';

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err;
    }
};
