
export const listOfLytUsers = [
    {
        id:'wwiuyfdweeeewreekwerwejnw',
        firstname:'John',
        lastname:'Doe',
        notifications: false,
    },
    {
        id:'wwiuyfdweeeeekjnb',
        firstname:'Mark',
        lastname:'Croinsberg',
        notifications: true,
    },
    {
        id:'wwiuyfd',
        firstname:'Maruccio',
        lastname:'Feloni',
        notifications: true,
    },
    {
        id:'wwewwbvbvbww',
        firstname:'Drake',
        lastname:'Bell',
        notifications: false,
    },
    {
        id:'weggwwerwerwwwww',
        firstname:'Thomas',
        lastname:'Murfy',
        notifications: true,
    },
    {
        id:'weggwwwwww',
        firstname:'Nicolet',
        lastname:'Johnson',
        notifications: true,
    },
    {
        id:'wegggjhgfdf',
        firstname:'Mark',
        lastname:'Oldey',
        notifications: true,
    },
    {
        id:'wegggewrdsdfsdf',
        firstname:'Heron',
        lastname:'McFlow',
        notifications: false,
    },
];

export const listOfLytGroupes = [
    {
        id:'werwerwerdsdfsdf',
        name: "Groupe 1",
        notifications: false,
    },
    {
        id:'werwerwerwerewrdsdfsdf',
        name: "Groupe 2",
        notifications: false,
    },
    {
        id:'werwerewrdsdfsdf',
        name: "Groupe 3",
        notifications: false,
    },
    {
        id:'werwerewefwrdsdfsdf',
        name: "Groupe 4",
        notifications: false,
    },
];




