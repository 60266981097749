import { types } from './modal.types';

export const clearModalState = () => ({
    type: types.CLEAR_MODAL_STATE,
});

export const openModal = (data) => ({
    type: types.SET_MODAL_STATE,
    payload: data,
});
