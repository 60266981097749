/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import PropTypes from 'prop-types';

import styles from './styles.scss';

const Spinner = ({ size, color }) => {
    color = color || [255, 255, 255];
    let styleSpinner = {
        width: size, height: size,
        border: `3px solid rgba(${color.join(',')}, 0.3)`,
        borderTopColor: `rgb(${color.join(',')})`
    };

    return (
        <div className={styles['spinner-container']}>
            <div className={styles['spinner']} style={styleSpinner}></div>
        </div>
    );
};


Spinner.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Spinner;
