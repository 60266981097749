export const types = {
    GET_USER_AGENCIES_REQUEST: 'sidebar/GET_USER_AGENCIES_REQUEST',
    GET_USER_AGENCIES_SUCCESS: 'sidebar/GET_USER_AGENCIES_SUCCESS',
    GET_USER_AGENCIES_ERROR: 'sidebar/GET_USER_AGENCIES_ERROR',

    SET_POPOVER_CONTENT: 'sidebar/SET_POPOVER_CONTENT',
    CLOSE_POPOVER: 'sidebar/CLOSE_POPOVER',
    SHOW_POPOVER_CONTENT: 'sidebar/SHOW_POPOVER_CONTENT',
    SET_NAMED_LIST: 'sidebar/SET_NAMED_LIST'
};
