import { combineReducers } from 'redux';

import session from '../routes/auth/auth.reducer';
import dashboardGeneral from '../routes/dashboard/general/general.reducer';
import dashboardMap from '../routes/dashboard/map/map.reducer';
import registration from '../routes/auth/registration/registration.reducer';

import modal from '../components/modal/modal.reducer';
import settings from '../routes/settings/settings.reducer';
import alerts from '../routes/alerts/alerts.reducer';
import sidebar from '../components/sidebar/sidebar.reducer';

import intersectionSPM from '../routes/intersections/spm/spm.reducer';
import intersectionStatistics from '../routes/intersections/statistics/statistics.reducer';

import routes from '../routes/routes/routes.reducer';

import user from './user/user.reducer';

import usersAndPermissions from '../routes/users-and-permissions/users-and-permissions.reducer';
import businessRules from '../routes/business-rules/business-rules.reducer';

import citiesAndAgencies from '../store/cities-and-agencies/cities-and-agencies.reducer';

const rootReducer = combineReducers({
    session,
    dashboardGeneral,
    dashboardMap,
    modal,
    settings,
    alerts,
    sidebar,
    user,
    intersectionSPM,
    intersectionStatistics,
    routes,
    usersAndPermissions,
    businessRules,
    citiesAndAgencies,
    registration,
});

export default rootReducer;
