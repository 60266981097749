/* eslint-disable dot-notation */
import PropTypes from 'prop-types';

import { getInitials } from '../../../utils/stringMethods';
import { SIZES_LIST } from './avatar.config';

import styles from './styles.scss';

const Avatar = ({
    logo = '',
    name = '',
    handleClick = () => {},
    active = false,
    size = SIZES_LIST.SM,
}) => (
    <div
        className={`${styles['avatar']} ${
            styles[`avatar--${size}`]
        } avatar--g`}
    >
        {logo && <img src={logo} alt="avatar" className={styles[`avatar__img--${size}`]} />}
        {!logo && <p className={styles['avatar__initials']}>{getInitials(name)}</p>}
    </div>
);

Avatar.propTypes = {
    logo: PropTypes.string,
    name: PropTypes.string,
    handleClick: PropTypes.func,
    active: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(SIZES_LIST)),
};

export default Avatar;
