import PropTypes from "prop-types";

import ContentLoader from "react-content-loader";

const SkeletonTabsRoles = ({ colors }) => (
    <ContentLoader
        speed={2}
        width={400}
        height={54}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <rect x="0" y="0" rx="6" ry="6" width="400" height="54" />
    </ContentLoader>
);

SkeletonTabsRoles.propTypes = {
    colors: PropTypes.shape({
        background: PropTypes.string.isRequired,
        foreground: PropTypes.string.isRequired,
    }),
};

export default SkeletonTabsRoles;
