import PropTypes from "prop-types";
import { useEffect } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../ui-core/button";
import SvgIcon from "../../../ui-core/svg-icon";
import Skeleton from "../../../skeleton/skeleton";
import ListWithSearch from "../../../ui-core/inputs/list-with-search/list-with-search";

import {
    getWidgetsListsOperation,
    sendAddedWidgetsOperation,
} from "../../../../routes/settings/settings.operations";
import {
    addedWidgetsSelector,
    allWidgetsSelector,
} from "../../../../routes/settings/settings.selectors";
import {
    setAllWidgetsList,
    setAddedWidgetsList,
} from "../../../../routes/settings/settings.actions";
import { getOverallPerformanceOperation } from "../../../../routes/dashboard/general/general.operations";

import { SVG_CODES } from "../../../ui-core/svg-icon/svg-icon.constants";
import { VIEW_TYPES_LIST } from "../../../ui-core/button/button.constants";
import { DATA_LIST_ITEM_TYPES } from "../../../ui-core/inputs/list-with-search/list-with-search.constants";

import styles from "./styles.scss";

const WidgetsManagement = ({ closeModal = () => {} }) => {
    const dispatch = useDispatch();
    const {
        loading,
        error,
        list: allWidgetsList,
    } = useSelector((state) => allWidgetsSelector(state));

    const { list: addedWidgetsList, loading: addedWidgetsLoadingStatus } =
        useSelector((state) => addedWidgetsSelector(state));

    useEffect(() => {
        dispatch(getWidgetsListsOperation());
    }, []);

    const generateAddedWidgetsLayout = () => {
        if (addedWidgetsList.length) {
            return addedWidgetsList.map(({ id, description, ...itemRest }) => (
                <div
                    key={id}
                    className={styles["widgets-modal__controls__added__item"]}
                    onClick={() => {
                        dispatch(
                            setAllWidgetsList([
                                ...allWidgetsList,
                                { id, description, ...itemRest },
                            ])
                        );
                        dispatch(
                            setAddedWidgetsList(
                                addedWidgetsList.filter(
                                    (item) => item.id !== id
                                )
                            )
                        );
                    }}
                >
                    <p
                        className={
                            styles[
                                "widgets-modal__controls__added__item__title"
                            ]
                        }
                    >
                        {description}
                    </p>
                    <span
                        className={
                            styles[
                                "widgets-modal__controls__added__item__trash"
                            ]
                        }
                    >
                        <SvgIcon type={SVG_CODES.TRASH} />
                    </span>
                </div>
            ));
        }
        return (
            <p className={styles["widgets-modal__controls__nothing-to-show"]}>
                Your widgets list are empty. Please, add your first widget.
            </p>
        );
    };

    return (
        <div className={styles["widgets-modal"]}>
            <div className={styles["widgets-modal__header"]}>
                <p className={styles["widgets-modal__header__title"]}>
                    Edit widgets
                </p>
                <p className={styles["widgets-modal__header__description"]}>
                    Select metric widgets you want to allocate or remove on your
                    dashboard
                </p>
            </div>
            <div className={styles["widgets-modal__controls"]}>
                {loading ? (
                    <div className={styles["widgets-modal"]}>
                        <Skeleton type="modal-widgets-management" />
                    </div>
                ) : (
                    <>
                        <div className={styles[`widgets-modal__controls__all`]}>
                            <ListWithSearch
                                handleClick={({ id, ...itemRest }) => {
                                    dispatch(
                                        setAllWidgetsList(
                                            allWidgetsList.filter(
                                                (item) => item.id !== id
                                            )
                                        )
                                    );
                                    dispatch(
                                        setAddedWidgetsList([
                                            ...addedWidgetsList,
                                            { id, ...itemRest },
                                        ])
                                    );
                                }}
                                dataList={allWidgetsList}
                                itemType={DATA_LIST_ITEM_TYPES.WIDGETS}
                            />
                        </div>
                        <div
                            className={styles["widgets-modal__controls__added"]}
                        >
                            <p
                                className={
                                    styles[
                                        "widgets-modal__controls__added__title"
                                    ]
                                }
                            >
                                Added widgets
                            </p>
                            {generateAddedWidgetsLayout()}
                        </div>
                    </>
                )}
            </div>
            <div className={styles["widgets-modal__footer"]}>
                <Button
                    label="Cancel"
                    viewType={VIEW_TYPES_LIST.DEFAULT}
                    handleClick={closeModal}
                />
                <Button
                    label="Apply changes"
                    viewType={VIEW_TYPES_LIST.PRIMARY}
                    disabled={addedWidgetsLoadingStatus}
                    loading={addedWidgetsLoadingStatus}
                    handleClick={() => {
                        dispatch(
                            sendAddedWidgetsOperation(
                                {
                                    widget_id: addedWidgetsList.map(
                                        (item) => item.id
                                    ),
                                },
                                closeModal,
                                () => dispatch(getOverallPerformanceOperation())
                            )
                        );
                    }}
                />
            </div>
        </div>
    );
};

WidgetsManagement.propTypes = {
    closeModal: PropTypes.func.isRequired,
};

export default WidgetsManagement;
