/* eslint-disable keyword-spacing */
import { types } from './sidebar.types';

const initialSate = {
    agencies: {
        list: [],
        loading: false,
        error: '',
    },

    cities: {
    },

    popover: {
        content: '',
        isShowing: false,
        namedLists: {}
    }
};

const addNamedList = (currentLists, name, content) => {
    const blankObject = {};
    blankObject[name] = content;
    const newLists = Object.assign(currentLists, blankObject);
    return newLists;
};

const sidebar = (state = initialSate, { type, payload }) => {
    switch (type) {
    case types.GET_USER_AGENCIES_REQUEST:
        return {
            ...state,
            agencies: {
                ...state.agencies,
                loading: true,
            },
        };
    case types.GET_USER_AGENCIES_SUCCESS:
        return {
            ...state,
            agencies: {
                ...state.agencies,
                loading: false,
                list: payload,
            },
            cities: payload.reduce((acc, agency) => {
                agency.cities.forEach((city) => {
                    acc[city.id] = city.name;
                });
                return acc;
            }, {}),
        };

    case types.GET_USER_AGENCIES_ERROR:
        return {
            ...state,
            agencies: {
                ...state.agencies,
                loading: false,
                error: payload,
            },
        };

    case types.SET_POPOVER_CONTENT:
        return {
            ...state,
            popover: {
                ...state.popover,
                content: payload,
            }
        };

    case types.SHOW_POPOVER_CONTENT:
        return {
            ...state,
            popover: {
                ...state.popover,
                isShowing: true,
            }
        };

    case types.CLOSE_POPOVER:
        return {
            ...state,
            popover: {
                ...state.popover,
                isShowing: false,
            }
        };

    case types.SET_NAMED_LIST:
        return {
            ...state,
            popover: {
                ...state.popover,
                namedLists: addNamedList(state.popover.namedLists, payload.name, payload.content)
            }
        };

    default:
        return state;
    }
};

export default sidebar;
