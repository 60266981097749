export const types = {
    // list
    GET_USERS_LIST_REQUEST: 'users-and-permissions/GET_USERS_LIST_REQUEST',
    GET_USERS_LIST_ERROR: 'users-and-permissions/GET_USERS_LIST_ERROR',
    GET_USERS_LIST_SUCCESS: 'users-and-permissions/GET_USERS_LIST_SUCCESS',

    //
    GET_ADMIN_LIST_REQUEST: 'users-and-permissions/GET_ADMIN_LIST_REQUEST',
    GET_ADMIN_LIST_ERROR: 'users-and-permissions/GET_ADMIN_LIST_ERROR',
    GET_ADMIN_LIST_SUCCESS: 'users-and-permissions/GET_ADMIN_LIST_SUCCESS',

    // filter
    FILTER_USERS_LIST_BY_NAME:
        'users-and-permissions/FILTER_USERS_LIST_BY_NAME',

    // update user
    UPDATE_USER_REQUEST: 'users-and-permissions/UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'users-and-permissions/UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR: 'users-and-permissions/UPDATE_USER_ERROR',

    // merge into users list
    MERGE_USERS_LISTS: 'users-and-permissions/MERGE_USERS_LISTS',

    // remove user
    REMOVE_USER_BY_ID_REQUEST:
        'users-and-permissions/REMOVE_USER_BY_ID_REQUEST',
    REMOVE_USER_BY_ID_ERROR: 'users-and-permissions/REMOVE_USER_BY_ID_ERROR',
    REMOVE_USER_BY_ID_SUCCESS:
        'users-and-permissions/REMOVE_USER_BY_ID_SUCCESS',

    // role
    SET_USER_ROLE_REQUEST: 'users-and-permissions/SET_USER_ROLE_REQUEST',
    SET_USER_ROLE_SUCCESS: 'users-and-permissions/SET_USER_ROLE_SUCCESS',
    SET_USER_ROLE_ERROR: 'users-and-permissions/SET_USER_ROLE_ERROR',

    // set approved cities
    SET_USER_APPROVED_CITIES_REQUEST:
        'users-and-permissions/SET_USER_APPROVED_CITIES_REQUEST',
    SET_USER_APPROVED_CITIES_SUCCESS:
        'users-and-permissions/SET_USER_APPROVED_CITIES_SUCCESS',
    SET_USER_APPROVED_CITIES_ERROR:
        'users-and-permissions/SET_USER_APPROVED_CITIES_ERROR',

    // remove approved city
    REMOVE_USER_APPROVED_CITY_REQUEST:
        'users-and-permissions/REMOVE_USER_APPROVED_CITY_REQUEST',
    REMOVE_USER_APPROVED_CITY_SUCCESS:
        'users-and-permissions/REMOVE_USER_APPROVED_CITY_SUCCESS',
    REMOVE_USER_APPROVED_CITY_ERROR:
        'users-and-permissions/REMOVE_USER_APPROVED_CITY_ERROR',

    // remove approved agency
    REMOVE_USER_APPROVED_AGENCY_REQUEST:
        'users-and-permissions/REMOVE_USER_APPROVED_AGENCY_REQUEST',
    REMOVE_USER_APPROVED_AGENCY_SUCCESS:
        'users-and-permissions/REMOVE_USER_APPROVED_AGENCY_SUCCESS',
    REMOVE_USER_APPROVED_AGENCY_ERROR:
        'users-and-permissions/REMOVE_USER_APPROVED_AGENCY_ERROR',

    // set approved agencies
    SET_USER_APPROVED_AGENCIES_REQUEST:
        'users-and-permissions/SET_USER_APPROVED_AGENCIES_REQUEST',
    SET_USER_APPROVED_AGENCIES_SUCCESS:
        'users-and-permissions/SET_USER_APPROVED_AGENCIES_SUCCESS',
    SET_USER_APPROVED_AGENCIES_ERROR:
        'users-and-permissions/SET_USER_APPROVED_AGENCIES_ERROR',
};
