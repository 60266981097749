import ContentLoader from "react-content-loader";

const SkeletonPreviewTable = ({ colors, ...props }) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={396}
        viewBox="0 0 100% 396"
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="396" />
    </ContentLoader>
);

export default SkeletonPreviewTable;
