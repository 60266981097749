import { types } from './map.types';
import { MAP_STYLE_TYPES } from '../../../components/map/map.constants';

const initialState = {
    style: MAP_STYLE_TYPES.MAP_LIGHT,
    loading: {
        type: '',
        status: true,

    },
    routes: {
        list: [],
        loading: false,
        error: '',
    },
    route: {
        information: {
            id: '',
            geometry: {
                coordinates: [],
            },
            color: null,
            loading: false,
            error: '',
        },
        details: {
            list: [],
            loading: false,
            error: '',
        },
    },
    busStops: {
        list: [],
        pagination: {},
        loading: false,
        error: '',
    },
    insight: {
        list: [],
        loading: false,
        error: '',
    },
    fireStation: {
        list: [],
        loading: false,
        error: '',
    },
    trafficLights: {
        trafficLightList: [],
        intersectionsList: [],
        crosswalksList: [],
        loading: false,
        error: '',
    },
    popup: {
        type: '',
        elementId: '',
    },
    layerVisibility: {
        route: true,
        bus: true,
        ambulance: true,
        lightRail: true,
        busStop: true,
        insight: false,
        trafficLight: true,
        fireStation: false,
        hospital: false,
        confirmedRequest: true,
        deniedRequest: true,
        inoperativeTrafficLight: true,
    },
};

const dashboardMap = (state = initialState, { type, payload }) => {
    switch (type) {
    // routes
    case types.GET_MAP_ROUTES_REQUEST:
        return {
            ...state,
            routes: {
                ...state.routes,
                loading: true,
            },
        };

    case types.GET_MAP_ROUTES_SUCCESS:
        return {
            ...state,
            routes: {
                ...state.routes,
                list: payload,
                loading: false,
            },
        };

    case types.GET_MAP_ROUTES_ERROR:
        return {
            ...initialState,
            routes: {
                ...initialState.routes,
                error: payload,
                loading: false,
            },
        };

    case types.GET_MAP_ROUTE_BY_ID_REQUEST:
        return {
            ...state,
            route: {
                ...state.route,
                information: {
                    ...state.route.information,
                    loading: true,
                },
            },
        };

    case types.GET_MAP_ROUTE_BY_ID_SUCCESS:
        return {
            ...state,
            route: {
                ...state.route,
                information: {
                    ...state.route.information,
                    ...payload[0],
                    id: payload[0].route_id,
                    loading: false,
                },
            },
        };

    case types.GET_MAP_ROUTE_BY_ID_ERROR:
        return {
            ...initialState,
            route: {
                ...initialState.route,
            },
        };

    case types.GET_MAP_ROUTE_DETAILS_BY_ID_REQUEST:
        return {
            ...state,
            route: {
                ...state.route,
                details: {
                    ...state.route.details,
                    loading: true,
                },
            },
        };

    case types.GET_MAP_ROUTE_DETAILS_BY_ID_SUCCESS:
        return {
            ...state,
            route: {
                ...state.route,
                details: {
                    ...state.route.details,
                    list: payload,
                    loading: false,
                },
            },
        };

    case types.GET_MAP_ROUTE_DETAILS_BY_ID_ERROR:
        return {
            ...initialState,
            route: {
                ...initialState.route,
            },
        };

    case types.SET_MAP_ROUTE_ID:
        return {
            ...state,
            route: {
                ...state.route,
                id: payload,
            },
        };

    case types.RESET_MAP_ROUTE:
        return {
            ...state,
            route: {
                ...initialState.route,
            },
            busStops: {
                ...initialState.busStops,
            }
        };

    case types.GET_MAP_STOPS_BY_ID_REQUEST:
        return {
            ...state,
            busStops: {
                ...state.busStops,
                loading: true,
            },
        };

    case types.GET_MAP_STOPS_BY_ID_SUCCESS:
        return {
            ...state,
            busStops: {
                ...state.busStops,
                list: payload.data,
                pagination: payload.pagination,
                loading: false,
            },
        };

    case types.GET_MAP_STOPS_BY_ID_ERROR:
        return {
            ...state,
            busStops: {
                ...initialState.busStops,
                error: payload,
                loading: false,
            },
        };

    case types.RESET_MAP_INFORMATION:
        return {
            ...initialState,
        };

    case types.SET_MAP_POPUP_INFORMATION:
        return {
            ...state,
            popup: {
                ...state.popup,
                ...payload,
            },
        };

    case types.SET_MAP_LAYER_VISIBILITY_STATUS:
        return {
            ...state,
            layerVisibility: {
                ...state.layerVisibility,
                ...payload,
            },
        };

    case types.GET_MAP_INSIGHT_REQUEST:
        return {
            ...state,
            insight: {
                ...state.insight,
                loading: true,
            },
        };

    case types.GET_MAP_INSIGHT_SUCCESS:
        return {
            ...state,
            insight: {
                ...state.insight,
                list: payload,
                loading: false,
            },
        };

    case types.GET_MAP_INSIGHT_ERROR:
        return {
            ...initialState,
            insight: {
                ...initialState.insight,
                error: payload,
                loading: false,
            },
        };

    case types.GET_MAP_FIRE_STATION_REQUEST:
        return {
            ...state,
            fireStation: {
                ...state.fireStation,
                loading: true,
            },
        };

    case types.GET_MAP_FIRE_STATION_SUCCESS:
        return {
            ...state,
            fireStation: {
                ...state.fireStation,
                list: payload,
                loading: false,
            },
        };

    case types.GET_MAP_FIRE_STATION_ERROR:
        return {
            ...initialState,
            fireStation: {
                ...initialState.fireStation,
                error: payload,
                loading: false,
            },
        };

    case types.SET_ROUTE_LINE_COLOR:
        return {
            ...state,
            route: {
                ...state.route,
                information: {
                    ...state.route.information,
                    color: payload,
                },
            },
        };

    case types.GET_MAP_TRAFFIC_LIGHTS_REQUEST:
        return {
            ...state,
            trafficLights: {
                ...state.trafficLights,
                loading: true,
            },
        };

    case types.GET_MAP_TRAFFIC_LIGHTS_SUCCESS:
        return {
            ...state,
            trafficLights: {
                ...state.trafficLights,
                ...payload,
                loading: false,
            },
        };

    case types.GET_MAP_TRAFFIC_LIGHTS_ERROR:
        return {
            ...initialState,
            trafficLights: {
                ...initialState.trafficLights,
                error: payload,
                loading: false,
            },
        };

    case types.SET_MAP_STYLE:
        return {
            ...state,
            style: payload,
        };

    default:
        return state;
    }
};

export default dashboardMap;
