export const types = {
    // Profile

    CHANGE_PASSWORD_REQUEST: "settings/CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "settings/CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_ERROR: "settings/CHANGE_PASSWORD_ERROR",

    UPDATE_USER_REQUEST: "settings/UPDATE_USER_REQUEST",
    UPDATE_USER_SUCCESS: "settings/UPDATE_USER_SUCCESS",
    UPDATE_USER_ERROR: "settings/UPDATE_USER_ERROR",

    // Widgets

    GET_ALL_WIDGETS_LIST_REQUEST: "settings/GET_ALL_WIDGETS_LIST_REQUEST",
    GET_ALL_WIDGETS_LIST_SUCCESS: "settings/GET_ALL_WIDGETS_LIST_SUCCESS",
    GET_ALL_WIDGETS_LIST_ERROR: "settings/GET_ALL_WIDGETS_LIST_ERROR",

    GET_ADDED_WIDGETS_REQUEST: "settings/GET_ADDED_WIDGETS_REQUEST",
    GET_ADDED_WIDGETS_SUCCESS: "settings/GET_ADDED_WIDGETS_SUCCESS",
    GET_ADDED_WIDGETS_ERROR: "settings/GET_ADDED_WIDGETS_ERROR",

    SET_ALL_WIDGETS_LIST: "settings/SET_ALL_WIDGETS_LIST",
    SET_ADDED_WIDGETS_LIST: "settings/SET_ADDED_WIDGETS_LIST",

    SEND_ADDED_WIDGETS_REQUEST: "settings/SEND_ADDED_WIDGETS_REQUEST",
    SEND_ADDED_WIDGETS_SUCCESS: "settings/SEND_ADDED_WIDGETS_SUCCESS",
    SEND_ADDED_WIDGETS_ERROR: "settings/SEND_ADDED_WIDGETS_ERROR",
};
