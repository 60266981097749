/* eslint-disable keyword-spacing */
/* eslint-disable quotes */
import { getUserAgencyId } from "../../services/requests/request.utils";

export const adaptCitiesToLabelValueObjects = (list) => list.map((item) => ({ label: item.name, value: item.id }));

export const adaptAgenciesToLabelValueObject = (list) =>
    list.map((item) => ({ label: item.agencies_long_name, value: item.agencies_id }));

export const adaptCitiesFromAgenciesToLabelValueObjects = (list) => list.reduce((acc, item) => {
    if (item.agencies_id === String(getUserAgencyId())) {
        acc = item.cities.map((cityItem) => ({ label: cityItem.name, value: cityItem.id }));
        return acc;
    }
    return acc;
}, []);

export const adaptAgenciesToLabelValueObjects = (list) => list.reduce((acc, item) => [
    ...acc,
    { value: item.agencies_id, label: item.agencies_short_name },
], []);
