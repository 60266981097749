/* eslint-disable keyword-spacing */
import { types } from './routes.types';

const initialState = {
    list: [],
    loading: true,
    error: '',
    filteredList: [],
};

const users = (state = initialState, { type, payload }) => {
    switch (type) {
    // routes list
    case types.GET_ROUTES_LIST_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case types.GET_ROUTES_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            list: payload,
            filteredList: payload,
        };
    case types.GET_ROUTES_LIST_ERROR:
        return {
            ...state,
            loading: false,
            error: payload,
        };

        //filter
    case types.FILTER_ROUTES_LIST_BY_NAME: {
        return {
            ...state,
            filteredList: state.list.filter((item) => {
                const routeName = item.name.toLowerCase();

                return routeName.includes(payload.toLowerCase());
            }),
        };
    }

    default:
        return state;
    }
};

export default users;
