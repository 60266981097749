/* eslint-disable dot-notation */
import PropTypes from 'prop-types';

import Tooltip from '../../Tooltip';
import SvgIcon from '../../svg-icon';
import NumberField from './number';
import PasswordField from './password';

import styles from './styles.scss';

const Field = ({
    type = '',
    viewType = '',
    name = '',
    label = '',
    labelDescription = '',
    placeholder = '',
    value = '',
    helperText = '',
    errorText = '',
    validation,
    mask,
    onChange = () => { },
    leftIcon = null,
    disabled = false,
    autoFocus = false,
}) => {
    const errorStyles = errorText ? styles['field--error'] : '';

    return (
        <div className={`${styles['field__wrapper']} ${errorStyles}`}>
            <div className={styles['field']}>
                <label className={styles['field__label']}>
                    {label}
                    <span className={styles['field__label-desc']}>
                        {labelDescription}
                    </span>

                </label>
                <div className={`${styles['field__wrapper-inner']}`}>
                    {leftIcon && leftIcon}
                    {type === 'text'
                        && <input
                            className={styles['field__input']}
                            type={type}
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            onChange={onChange}
                            disabled={disabled}
                            autoFocus={autoFocus}
                        />
                    }
                    {type === 'textarea'
                        && <textarea
                            className={styles['field__input--textarea']}
                            type={type}
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            onChange={onChange}
                            rows="5"
                        />
                    }
                    {type === 'number'
                        && <NumberField
                            value={value}
                            validation={validation}
                            onChange={onChange}
                            placeholder={placeholder}
                            mask={mask}
                            viewType={viewType}
                            autoFocus={autoFocus}
                        />
                    }

                    {type === 'password'
                        && <PasswordField
                            value={value}
                            error={errorText}
                            validation={validation}
                            onChange={onChange}
                            placeholder={placeholder}
                        />
                    }
                    <Tooltip
                        isShow={!!errorText}
                        message={errorText}
                        icon={
                            <div className={styles['field__tooltip-icon']}>
                                <SvgIcon type={'alert-gray'} />
                            </div>
                        }
                    />
                </div>
                {
                    helperText
                        && <p className={styles['field__helper-text']}>{helperText}</p>
                }
            </div>
        </div>
    );
};

Field.propTypes = {
    type: PropTypes.string,
    viewType: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    labelDescription: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    errorText: PropTypes.string,
    validation: PropTypes.object,
    mask: PropTypes.func,
    onChange: PropTypes.func,
    leftIcon: PropTypes.node,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
};

export default Field;
