/* eslint-disable keyword-spacing */
/* eslint-disable react/jsx-key */
import { useEffect } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import Select from './select';
import Skeleton from '../../../skeleton';
import SvgIcon from '../../../ui-core/svg-icon';

import { sidebarAgenciesSelector } from '../../sidebar.selectors';
import { getUserAgenciesOperation } from '../../sidebar.operations';
import { getAgenciesOperation } from '../../../../store/cities-and-agencies/cities-and-agencies.operations';
import { getAgencyRoutesOperation, getRoutesStopsOperation } from '../../../../routes/routes/routes.operations';
import { userSelectedLocationInfoSelector } from '../../../../store/user/user.selectors';
import { setUserAgencyIdOperation } from '../../../../store/user/user.operations';
import { SVG_CODES } from '../../../ui-core/svg-icon/svg-icon.constants';


const SelectGroupe = () => {
    const dispatch = useDispatch();

    const { list, loading, error } = useSelector(sidebarAgenciesSelector);
    const { cityId, agencyId } = useSelector(userSelectedLocationInfoSelector);

    useEffect(() => {
        dispatch(getUserAgenciesOperation());
        dispatch(getAgenciesOperation());
    }, []);

    useEffect(() => {
        if (agencyId) {
            dispatch(getAgencyRoutesOperation());
            dispatch(getRoutesStopsOperation());
        }
    }, [agencyId]);

    const listOfAgencies = list.map(({ agencies_id, agencies_short_name }) => ({
        id: agencies_id,
        name: agencies_short_name,
    })).sort((a, b) => (a.name < b.name ? -1 : 0));

    return (
        <>
            {loading
                && <>
                    {[0, 0].map(() =>
                        <Skeleton type="sidebar-selectors-group" />
                    )}
                </>
            }
            {list.length > 0
                && <div>
                    <Select
                        list={listOfAgencies}
                        selectedItemId={agencyId}
                        handleChange={(id) =>
                            dispatch(setUserAgencyIdOperation(id))
                        }
                        icon={<SvgIcon type={SVG_CODES.SIDEBAR_AGENCY} />}
                        className={'select__icon-agency'}
                        description="Transit Agency"
                        useDropdownOverlay={true}
                    />
                </div>
            }
        </>
    );
};

export default SelectGroupe;
