import { useState } from 'preact/hooks';

import InformationPanel from '../information-panel';
import Button from '../ui-core/button';
import SvgIcon from '../ui-core/svg-icon';
import { SVG_CODES } from '../ui-core/svg-icon/svg-icon.constants';
import UIIssueMessage from '../ui-issue-message';
import { chartTitles } from './error-boundary.constants';

import styles from './styles.scss';
import LongDescription from '../../components/ui-core/long-description';

const ErrorBoundaryCases = (parms) => {
    const [isBlockVisible, setIsBlockVisible] = useState(true);
    const chartTitle = chartTitles[parms.errorComponent];
    switch (parms.errorCase) {
    case 'chart-has-an-error':
        return (
            <>
                <div className={styles['chart-title']}>
                    { chartTitle.descriptionKey !== '' && <LongDescription title={chartTitle.title} longKey={chartTitle.descriptionKey} iconType={'info'}/> }
                    { chartTitle.descriptionKey === '' && chartTitle.title }
                    <Button
                        viewType="secondary"
                        size="square-38"
                        isNeedToInvertIconPosition={true}
                        icon={
                            <SvgIcon
                                type={
                                    isBlockVisible
                                        ? SVG_CODES.EYE_OPENED
                                        : SVG_CODES.EYE_CLOSED
                                }
                            />
                        }
                        handleClick={() => {
                            setIsBlockVisible(!isBlockVisible);
                        }}
                    />
                </div>
                <div className={styles['break-line']}></div>
                { isBlockVisible && <div className={styles['error-block']}>
                    <UIIssueMessage viewType="chart-has-an-error" height={100}/>
                </div> }
            </>
        );
    case 'traffic-lights-error':
    case 'critical-alerts-error':
    case 'overall-performance-error':
        return (
            <div className={styles['error-block']}>
                <UIIssueMessage viewType={parms.errorCase} height={100}/>
            </div>
        );
    case 'route-details-has-error':
        return (
            <>
                <div className={styles['chart-title']}>
                    { chartTitle.descriptionKey !== '' && <LongDescription title={chartTitle.title} longKey={chartTitle.descriptionKey} iconType={'info'}/> }
                    { chartTitle.descriptionKey === '' && chartTitle.title }
                    <Button
                        viewType="secondary"
                        size="square-38"
                        isNeedToInvertIconPosition={true}
                        icon={
                            <SvgIcon
                                type={
                                    isBlockVisible
                                        ? SVG_CODES.EYE_OPENED
                                        : SVG_CODES.EYE_CLOSED
                                }
                            />
                        }
                        handleClick={() => {
                            setIsBlockVisible(!isBlockVisible);
                        }}
                    />
                </div>
                <div className={styles['break-line']}></div>
                { isBlockVisible && <div className={styles['error-block']}>
                    <UIIssueMessage viewType="route-details-has-error" height={100}/>
                </div> }
            </>
        );
    default:
        return (
            <div className={styles['error-boundary']}>
                <InformationPanel />
                <div className={styles['error-boundary__inner-container']}>
                    <div className={styles['error-boundary__content']}>
                        <h1
                            className={
                                styles['error-boundary__content-title']
                            }
                        >
                                Error
                        </h1>
                        <p
                            className={
                                styles[
                                    'error-boundary__content-description'
                                ]
                            }
                        >
                                Sorry, an error occurred, please reload the
                                page and try again.
                        </p>
                        <div
                            className={
                                styles['error-boundary__content-btn']
                            }
                        >
                            <Button
                                handleClick={() => window.location.reload()}
                                viewType="primary"
                                label="Reload page"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ErrorBoundaryCases;
