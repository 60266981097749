import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useState, useMemo } from "preact/hooks";

import Button from "../../../ui-core/button";
import ListWithSearch from "../../../ui-core/inputs/list-with-search";

import { VIEW_TYPES_LIST } from "../../../ui-core/button/button.constants";
import { DATA_LIST_ITEM_TYPES } from "../../../ui-core/inputs/list-with-search/list-with-search.constants";

import { clearModalState } from "../../modal.actions";
import { listOfLytGroupes, listOfLytUsers } from "./share-alert.config";

import EmailChips from "./../../../ui-core/inputs/email-chips";
import LearnMoreFormsEl from "../../../learn-more-forms-el";
import LinkTab from "../../../link-tab";
import SelectableList from "../../../ui-core/inputs/selectable-list/selectable-list";

import styles from "./styles.scss";

const ShareAlert = ({ selectedUsers = [], title = "Share Alert" }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            listOfSelectedLytUsers: [...selectedUsers],
            listOfSelectedLytGroupes: [],
        },
        // resolver: yupResolver(schema),
    });

    const watchFields = watch([
        "listOfSelectedLytUsers",
        "listOfSelectedLytGroupes",
    ]);

    const [activeTab, setActiveTab] = useState("users");
    const dispatch = useDispatch();

    const onSubmit = (data) => console.log(data);

    const handleAddGroup = (newSelectedItem) => {
        const listOfSelectedGroups = getValues().listOfSelectedLytGroupes;
        const group = listOfSelectedGroups.find(
            ({ id }) => newSelectedItem.id === id
        );

        if (!group) {
            setValue("listOfSelectedLytGroupes", [
                ...listOfSelectedGroups,
                newSelectedItem,
            ]);
        }
    };

    const handleAddUser = (newSelectedItem) => {
        const listOfSelectedUsers = getValues().listOfSelectedLytUsers;
        const user = listOfSelectedUsers.find(
            ({ id }) => id === newSelectedItem.id
        );

        if (!user) {
            setValue("listOfSelectedLytUsers", [
                ...listOfSelectedUsers,
                newSelectedItem,
            ]);
        }
    };

    const handleRemoveGroup = (itemId) => {
        const list = getValues().listOfSelectedLytGroupes;
        const newList = list.filter(({ id }) => itemId !== id);

        setValue("listOfSelectedLytGroupes", newList);
    };

    const handleRemoveUser = (itemId) => {
        const list = getValues().listOfSelectedLytUsers;
        const newList = list.filter(({ id }) => itemId !== id);
        setValue("listOfSelectedLytUsers", newList);
    };

    const normalizedListOfLytUsers = useMemo(() => listOfLytUsers.reduce(
        (acc, { firstname, lastname, ...rest }) => {
            return [...acc, { value: `${firstname} ${lastname}`, ...rest }];
        }, []),
        [listOfLytUsers]
    );

    const normalizedListOfLytGroups = useMemo(() => listOfLytGroupes.reduce(
        (acc, { name, ...rest }) => {
            return [...acc, { value: name, ...rest }];
        }, []),
        [listOfLytGroupes]
    );


    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles["share-alert"]}
        >
            <p className={styles["share-alert__title"]}>{title}</p>
            <div className={styles["share-alert__learn-more"]}>
                <LearnMoreFormsEl />
            </div>
            <EmailChips />

            <div className={styles["share-alert__selections"]}>
                <div className={styles["share-alert__tabs-container"]}>
                    <LinkTab
                        isActive={activeTab === "users"}
                        handleClick={() => setActiveTab("users")}
                        label="Users"
                        count={getValues().listOfSelectedLytUsers.length}
                    />
                    <LinkTab
                        isActive={activeTab === "groups"}
                        handleClick={() => setActiveTab("groups")}
                        label="Groups"
                        count={getValues().listOfSelectedLytGroupes.length}
                    />
                </div>
                {activeTab === "users" && (
                    <div className={styles["share-alert__selections-users"]}>
                        <div
                            className={styles["share-alert__selections-search"]}
                        >
                            <ListWithSearch
                                itemType={DATA_LIST_ITEM_TYPES.SHARE_ALERTS}
                                dataList={normalizedListOfLytUsers}
                                placeholder="Search users by name"
                                isInitialOpenDropdown={false}
                                handleClick={handleAddUser}
                            />
                        </div>
                        <SelectableList
                            list={getValues().listOfSelectedLytUsers}
                            handleDelete={handleRemoveUser}
                        />
                    </div>
                )}
                {activeTab === "groups" && (
                    <div className={styles["share-alert__selections-groups"]}>
                        <div
                            className={styles["share-alert__selections-search"]}
                        >
                            <ListWithSearch
                                itemType={DATA_LIST_ITEM_TYPES.SHARE_ALERTS}
                                dataList={normalizedListOfLytGroups}
                                placeholder="Search users by name"
                                isInitialOpenDropdown={false}
                                handleClick={handleAddGroup}
                            />
                        </div>
                        <SelectableList
                            list={getValues().listOfSelectedLytGroupes}
                            handleDelete={handleRemoveGroup}
                        />
                    </div>
                )}
            </div>
            <div className={styles["share-alert__buttons"]}>
                <div className={styles["share-alert__button"]}>
                    <Button
                        handleClick={() => dispatch(clearModalState())}
                        label={"Cancel"}
                    />
                </div>
                <div className={styles["share-alert__button"]}>
                    <Button
                        type={"submit"}
                        label={"Share alerts"}
                        viewType={VIEW_TYPES_LIST.PRIMARY}
                    />
                </div>
            </div>
        </form>
    );
};

export default ShareAlert;
