import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

const SkeletonUsersTable = ({ colors }) => (
    <table>
        <tr>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect x="10" y="20" rx="6" ry="6" width="90%" height="20" />
                </ContentLoader>
            </th>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect x="15" y="20" rx="6" ry="6" width="90%" height="20" />
                </ContentLoader>
            </th>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect x="15" y="20" rx="6" ry="6" width="90%" height="20" />
                </ContentLoader>
            </th>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect
                        x="40"
                        y="20"
                        rx="10"
                        ry="10"
                        width="90%"
                        height="23"
                    />
                </ContentLoader>
            </th>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect
                        x="40"
                        y="20"
                        rx="10"
                        ry="10"
                        width="90%"
                        height="23"
                    />
                </ContentLoader>
            </th>
            <th>
                <ContentLoader
                    speed={2}
                    width={"100%"}
                    height={47}
                    backgroundColor={colors.background}
                    foregroundColor={colors.foreground}
                >
                    <rect
                        x="40"
                        y="20"
                        rx="10"
                        ry="10"
                        width="90%"
                        height="23"
                    />
                </ContentLoader>
            </th>
        </tr>
    </table>
);

SkeletonUsersTable.propTypes = {
    colors: PropTypes.shape({
        background: PropTypes.string.isRequired,
        foreground: PropTypes.string.isRequired,
    }),
};

export default SkeletonUsersTable;
