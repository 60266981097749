import { types } from "./spm.types";

export const getIntersectionByIdRequest = (data) => ({
    type: types.GET_INTERSECTION_BY_ID_REQUEST,
    payload: data
});

export const getIntersectionByIdSuccess = (data) => ({
    type: types.GET_INTERSECTION_BY_ID_SUCCESS,
    payload: data,
});

export const getIntersectionByIdError = (error) => ({
    type: types.GET_INTERSECTION_BY_ID_ERROR,
    payload: error,
});

export const clearIntersectionData = () => ({
    type: types.CLEAR_INTERSECTION_DATA,
});

export const getIntersectionsListRequest = () => ({
    type: types.GET_INTERSECTIONS_LIST_REQUEST,
});

export const getIntersectionsListSuccess = (data) => ({
    type: types.GET_INTERSECTIONS_LIST_SUCCESS,
    payload: data,
});

export const getIntersectionsListError = (error) => ({
    type: types.GET_INTERSECTIONS_LIST_ERROR,
    payload: error,
});

export const getIntersectionHistoryRequest = () => ({
    type: types.GET_INTERSECTION_HISTORY_REQUEST,
});

export const getIntersectionHistorySuccess = (data) => ({
    type: types.GET_INTERSECTION_HISTORY_SUCCESS,
    payload: data,
});

export const getIntersectionHistoryError = (error) => ({
    type: types.GET_INTERSECTION_HISTORY_ERROR,
    payload: error,
});

export const getInformationByDateAndTimeRequest = () => ({
    type: types.GET_INFORMATION_BY_DATE_AND_TIME_REQUEST,
});

export const getInformationByDateAndTimeSuccess = (data) => ({
    type: types.GET_INFORMATION_BY_DATE_AND_TIME_SUCCESS,
    payload: data,
});

export const getInformationByDateAndTimeError = (error) => ({
    type: types.GET_INFORMATION_BY_DATE_AND_TIME_ERROR,
    payload: error,
});

export const setLiveModeStatus = (payload) => ({
    type: types.SET_LIVE_MODE_STATUS,
    payload,
});

export const getLiveModeStatus = () => ({
    type: types.GET_LIVE_MODE_STATUS
})

export const setDateRangeStatus = (payload) => ({
    type: types.SET_DATERANGE_STATUS,
    payload,
})

export const getDateRangeStatus = () => ({
    type: types.GET_DATERANGE_STATUS,
})

export const setLiveData = (payload) => ({
    type: types.SET_LIVE_DATA,
    payload,
})