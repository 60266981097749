import { types } from './alerts.types';

export const getAllActiveAlertsRequest = () => ({
    type: types.GET_ALL_ACTIVE_ALERTS_REQUEST,
});

export const getAllActiveAlertsSuccess = (data) => ({
    type: types.GET_ALL_ACTIVE_ALERTS_SUCCESS,
    payload: data,
});

export const getAllActiveAlertsError = (error) => ({
    type: types.GET_ALL_ACTIVE_ALERTS_ERROR,
    payload: error,
});

export const setSelectedAlertActiveId = (payload) => ({
    type: types.SET_SELECTED_ALERT_ACTIVE_ID,
    payload,
});

export const setSelectedAlertActiveAll = (payload) => ({
    type: types.SET_SELECTED_ALERT_ACTIVE_ALL,
    payload,
});

export const sortAllActiveAlerts = (payload) => ({
    type: types.SORT_ALL_ACTIVE_ALERTS,
    payload,
});

//my-alerts

//Shared to me

export const setSelectedAlertMyAlertsSTMId = (payload) => ({
    type: types.SET_SELECTED_ALERT_MY_ALERTS_STM_ID,
    payload,
});

export const setSelectedAlertMyAlertsSTMAll = (payload) => ({
    type: types.SET_SELECTED_ALERT_MY_ALERTS_STM_ALL,
    payload,
});

// Shared by me

export const setSelectedAlertMyAlertsSBMId = (payload) => ({
    type: types.SET_SELECTED_ALERT_MY_ALERTS_SBM_ID,
    payload,
});

export const setSelectedAllMyAlertsSBMAll = (payload) => ({
    type: types.SET_SELECTED_ALERT_MY_ALERTS_SBM_ALL,
    payload,
});

// muted

export const setSelectedAlertMyAlertsMutedId = (payload) => ({
    type: types.SET_SELECTED_ALERT_MY_ALERTS_MUTED_ID,
    payload,
});

export const setSelectedAlertMyAlertsMutedAll = (payload) => ({
    type: types.SET_SELECTED_ALERT_MY_ALERTS_MUTED_ALL,
    payload,
});
