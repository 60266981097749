import { VIEW_TYPES_LIST, SIZES_LIST } from "./badge.constants";

export const getNumberContent = (content, isShowAlways) => {
    if (content > 99) return "99+";
    if (isShowAlways) return content;
    if (content <= 0) return null;

    return content;
};

export const isVisible = (viewType, content, isShowAlways) => {
    if (viewType === VIEW_TYPES_LIST.NUMBER && isShowAlways) return true;
    if (viewType === VIEW_TYPES_LIST.NUMBER && content <= 0) return false;

    return true;
};
