/* eslint-disable space-before-function-paren */
import { useDispatch } from 'react-redux';
import { useState } from 'preact/hooks';
import PropTypes from 'prop-types';

import Button from '../../../ui-core/button';
import { deleteGroupOperation } from '../../../../routes/users-and-permissions/groups/groups.operations';
import { VIEW_TYPES_LIST } from '../../../ui-core/button/button.constants';
import { clearModalState } from '../../modal.actions';

import styles from './styles.scss';

const DeleteGroupConfirmation = ({ id = '' }) => {
    const dispatch = useDispatch();
    const [isWorking, setWorking] = useState(false);

    const handleDeleteButton = async () => {
        setWorking(true);
        try {
            await dispatch(deleteGroupOperation({ id }));
            dispatch(clearModalState({ id }));
            setWorking(false);
        } catch(error) {
            setWorking(false);
        }
    };

    return (
        <div className={styles['delete-group-confirmation']}>
            <p className={styles['delete-group-confirmation__title']}>
                Are you sure you want to delete group?
            </p>
            <p className={styles['delete-group-confirmation__description']}>
                This is an irreversible action!
            </p>
            <div className={styles['delete-group-confirmation__buttons']}>
                <div className={styles['delete-group-confirmation__button']}>
                    <Button
                        handleClick={() => dispatch(clearModalState())}
                        label={'No, discard'}
                    />
                </div>
                <div className={styles['delete-group-confirmation__button']}>
                    <Button
                        handleClick={handleDeleteButton}
                        label={'Yes, delete'}
                        viewType={VIEW_TYPES_LIST.PRIMARY}
                        loading={isWorking}
                        disabled={isWorking}
                    />
                </div>
            </div>
        </div>
    );
};

DeleteGroupConfirmation.propTypes = {
    id: PropTypes.string.isRequired,
};

export default DeleteGroupConfirmation;
