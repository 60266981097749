export const chartTitles = {
    SystemWideAverageRedLightWaitTime: {
        title: 'Average Red Light Delay Over Time', descriptionKey: 'DASHBOARD.AVERAGE_RED_LIGHT_WAIT_TIME_DELAY_OVER_TIME'
    },
    SystemWideAverageGreenLightSuccessRate: {
        title: 'Average Green Light Success Rate Over Time', descriptionKey: 'DASHBOARD.AVERAGE_GREEN_LIGHT_SUCCESS_RATE_OVER_TIME'
    },
    TravelTimeByDay: {
        title: 'Trip Duration', descriptionKey: 'DASHBOARD.TRAVEL_TIME_BY_DAY'
    },
    SystemWide: {
        title: 'Green Light Success Rate Per Hour', descriptionKey: 'DASHBOARD.GREEN_LIGHT_SUCCESS_RATE_PER_HOUR'
    },
    TotalTSPCalls: {
        title: 'Total TSP Calls Per Hour', descriptionKey: 'DASHBOARD.TOTAL_TSP_CALLS_PER_HOUR'
    },
    AverageSpeedByRoute: {
        title: 'Average Speed By Route', descriptionKey: 'DASHBOARD.AVERAGE_SPEED_BY_ROUTE'
    },
    AverageRedLightWaitTime: {
        title: 'Average Red Light Delay by Intersection', descriptionKey: 'DASHBOARD.AVERAGE_RED_LIGHT_DELAY_BY_INTERSECTION'
    },
    TrafficLights: {
        title: 'TSP Performance', descriptionKey: ''
    },
    TSPActivity: {
        title: 'TSP Activity', descriptionKey: 'ROUTES.TSP_STATISTICS.TSP_ACTIVITY'
    },
    RouteDetails: {
        title: 'Route Details', descriptionKey: ''
    },
    Headway: {
        title: 'Headway', descriptionKey: 'ROUTES.PERFORMANCE.HEADWAY'
    },
    RoutePerformanceByStop: {
        title: 'Route Performance By Stop', descriptionKey: 'ROUTES.PERFORMANCE.ROUTE_PERFORMANCE_BY_STOP'
    },
    TravelTime: {
        title: 'Travel Time By Hour', descriptionKey: 'ROUTES.PERFORMANCE.TRAVEL_TIME'
    },
    RedLightWaitTime: {
        title: 'Red Light Wait Time By Hour', descriptionKey: 'ROUTES.PERFORMANCE.SIGNAL_DELAY'
    },
    BusStopDwellTime: {
        title: 'Bus Stop Dwell Time', descriptionKey: 'ROUTES.PERFORMANCE.BUS_STOP_DWELL_TIME'
    },
    AverageVehicleSpeed: {
        title: 'Average Vehicle Speed', descriptionKey: 'ROUTES.PERFORMANCE.AVERAGE_BUS_SPEED'
    },
    SegmentTravelTime: {
        title: 'Segment Travel Time', descriptionKey: 'ROUTES.PERFORMANCE.SEGMENT_TRAVEL_TIME'
    },
};
