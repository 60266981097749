/* eslint-disable keyword-spacing */
/* eslint-disable space-before-function-paren */
import { PERIOD_OPTIONS, SECOND_PERIOD_OPTIONS } from '../../../components/ui-core/inputs/select/select.constants';
import { PERIOD_LIST } from '../../../routes/dashboard/general/components/system-wide/system-wide.config.js';
import { get } from '../../api';
import { getUserAgencyId } from '../request.utils';

// const mockRoutes = [
//     {
//         route_short_name: 'FX2',
//         route_long_name: 'Division',
//         route_url: 'https://trimet.org/schedules/r002.htm',
//         route_color: '61A744',
//         route_id: '2',
//         route_key: 'tri_route_2'
//     },
//     {
//         route_short_name: '4',
//         route_long_name: 'Fessenden',
//         route_url: 'https://trimet.org/schedules/r004.htm',
//         route_color: '084C8D',
//         route_id: '4',
//         route_key: 'tri_route_4'
//     },
//     {
//         route_short_name: '6',
//         route_long_name: 'Martin Luther King Jr Blvd',
//         route_url: 'https://trimet.org/schedules/r006.htm',
//         route_color: '084C8D',
//         route_id: '6',
//         route_key: 'tri_route_6'
//     },
//     {
//         route_short_name: '8',
//         route_long_name: 'Jackson Park/NE 15th',
//         route_url: 'https://trimet.org/schedules/r008.htm',
//         route_color: '084C8D',
//         route_id: '8',
//         route_key: 'tri_route_8'
//     },
//     {
//         route_short_name: '9',
//         route_long_name: 'Powell Blvd',
//         route_url: 'https://trimet.org/schedules/r009.htm',
//         route_color: '084C8D',
//         route_id: '9',
//         route_key: 'tri_route_9'
//     },
//     {
//         route_short_name: '10',
//         route_long_name: 'Harold St',
//         route_url: 'https://trimet.org/schedules/r010.htm',
//         route_color: '084C8D',
//         route_id: '10',
//         route_key: 'tri_route_10'
//     },
//     {
//         route_short_name: '12',
//         route_long_name: 'Barbur/Sandy Blvd',
//         route_url: 'https://trimet.org/schedules/r012.htm',
//         route_color: '084C8D',
//         route_id: '12',
//         route_key: 'tri_route_12'
//     },
//     {
//         route_short_name: '14',
//         route_long_name: 'Hawthorne/Foster',
//         route_url: 'https://trimet.org/schedules/r014.htm',
//         route_color: '084C8D',
//         route_id: '14',
//         route_key: 'tri_route_14'
//     },
//     {
//         route_short_name: '15',
//         route_long_name: 'Belmont/NW 23rd',
//         route_url: 'https://trimet.org/schedules/r015.htm',
//         route_color: '084C8D',
//         route_id: '15',
//         route_key: 'tri_route_15'
//     },
//     {
//         route_short_name: '20',
//         route_long_name: 'Burnside/Stark',
//         route_url: 'https://trimet.org/schedules/r020.htm',
//         route_color: '084C8D',
//         route_id: '20',
//         route_key: 'tri_route_20'
//     },
//     {
//         route_short_name: '39',
//         route_long_name: 'Arnold Creek/Hillsdale',
//         route_url: 'https://trimet.org/schedules/r039.htm',
//         route_color: '084C8D',
//         route_id: '39',
//         route_key: 'tri_route_39'
//     },
//     {
//         route_short_name: '43',
//         route_long_name: 'Taylors Ferry/Marquam Hill',
//         route_url: 'https://trimet.org/schedules/r043.htm',
//         route_color: '084C8D',
//         route_id: '43',
//         route_key: 'tri_route_43'
//     },
//     {
//         route_short_name: '44',
//         route_long_name: 'Capitol Hwy/Mocks Crest',
//         route_url: 'https://trimet.org/schedules/r044.htm',
//         route_color: '084C8D',
//         route_id: '44',
//         route_key: 'tri_route_44'
//     },
//     {
//         route_short_name: '45',
//         route_long_name: 'Garden Home',
//         route_url: 'https://trimet.org/schedules/r045.htm',
//         route_color: '084C8D',
//         route_id: '45',
//         route_key: 'tri_route_45'
//     },
//     {
//         route_short_name: '51',
//         route_long_name: 'Vista/Sunset Blvd',
//         route_url: 'https://trimet.org/schedules/r051.htm',
//         route_color: '084C8D',
//         route_id: '51',
//         route_key: 'tri_route_51'
//     },
//     {
//         route_short_name: '54',
//         route_long_name: 'Beaverton-Hillsdale Hwy',
//         route_url: 'https://trimet.org/schedules/r054.htm',
//         route_color: '084C8D',
//         route_id: '54',
//         route_key: 'tri_route_54'
//     },
//     {
//         route_short_name: '55',
//         route_long_name: 'Hamilton',
//         route_url: 'https://trimet.org/schedules/r055.htm',
//         route_color: '084C8D',
//         route_id: '55',
//         route_key: 'tri_route_55'
//     },
//     {
//         route_short_name: '56',
//         route_long_name: 'Scholls Ferry/Marquam Hill',
//         route_url: 'https://trimet.org/schedules/r056.htm',
//         route_color: '084C8D',
//         route_id: '56',
//         route_key: 'tri_route_56'
//     },
//     {
//         route_short_name: '70',
//         route_long_name: '12th/NE 33rd Ave',
//         route_url: 'https://trimet.org/schedules/r070.htm',
//         route_color: '084C8D',
//         route_id: '70',
//         route_key: 'tri_route_70'
//     },
//     {
//         route_short_name: '72',
//         route_long_name: 'Killingsworth/82nd Ave',
//         route_url: 'https://trimet.org/schedules/r072.htm',
//         route_color: '084C8D',
//         route_id: '72',
//         route_key: 'tri_route_72'
//     },
//     {
//         route_short_name: '73',
//         route_long_name: '122nd Ave',
//         route_url: 'https://trimet.org/schedules/r073.htm',
//         route_color: '084C8D',
//         route_id: '73',
//         route_key: 'tri_route_73'
//     },
//     {
//         route_short_name: '75',
//         route_long_name: 'Cesar Chavez/Lombard',
//         route_url: 'https://trimet.org/schedules/r075.htm',
//         route_color: '084C8D',
//         route_id: '75',
//         route_key: 'tri_route_75'
//     },
//     {
//         route_short_name: '76',
//         route_long_name: 'Hall/Greenburg',
//         route_url: 'https://trimet.org/schedules/r076.htm',
//         route_color: '084C8D',
//         route_id: '76',
//         route_key: 'tri_route_76'
//     }
// ];

// const mockStops = {
//     pagination: {
//         offset: 0,
//         limit: 100,
//         count: {
//             count: '2857'
//         }
//     },
//     data: [
//         {
//             id: 'tri_route_10-0-11029-59',
//             name: 'SE Harold & 133rd',
//             lon: '-122.526394',
//             lat: '45.483411',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 59,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-13032-67',
//             name: 'SE Foster & 120th',
//             lon: '-122.539648',
//             lat: '45.47661',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 111th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 67,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-13244-58',
//             name: 'SE Harold & 130th',
//             lon: '-122.529278',
//             lat: '45.483431',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 58,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-13245-62',
//             name: 'SE 136th & Foster',
//             lon: '-122.523669',
//             lat: '45.477098',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 62,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1375-8',
//             name: 'SE Division & 12th Ave',
//             lon: '-122.653358',
//             lat: '45.504799',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Division St @ Orange Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 8,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-14196-10',
//             name: 'SE 26th & Division',
//             lon: '-122.63974',
//             lat: '45.504455',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Powell Blvd & SE 26th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 10,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-14203-1',
//             name: 'SW Madison & 5th',
//             lon: '-122.679038',
//             lat: '45.515321',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SW Madison St & SW 4th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 1,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1435-9',
//             name: 'SE Division & 20th Ave',
//             lon: '-122.645972',
//             lat: '45.50477',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Division St @ SE 20th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 9,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1597-41',
//             name: 'SE Ellis & Foster',
//             lon: '-122.576403',
//             lat: '45.4822',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 92nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 41,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1600-42',
//             name: 'SE Ellis & 86th Ct',
//             lon: '-122.574223',
//             lat: '45.482237',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 92nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 42,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1602-43',
//             name: 'SE Ellis & 89th',
//             lon: '-122.571066',
//             lat: '45.482208',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 92nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 43,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1604-44',
//             name: 'SE Ellis & 92nd',
//             lon: '-122.568771',
//             lat: '45.482151',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 92nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 44,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1769-64',
//             name: '13100 Block SE Foster',
//             lon: '-122.528704',
//             lat: '45.476882',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 128th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 64,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1774-68',
//             name: '11600 Block SE Foster',
//             lon: '-122.544543',
//             lat: '45.476552',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 111th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 68,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1780-72',
//             name: 'SE Foster & 101st',
//             lon: '-122.559679',
//             lat: '45.477348',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 101st Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 72,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1781-71',
//             name: 'SE Foster & 103rd',
//             lon: '-122.557301',
//             lat: '45.476881',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 101st Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 71,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1783-70',
//             name: 'SE Foster & 107th',
//             lon: '-122.553549',
//             lat: '45.476197',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 101st Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 70,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1785-69',
//             name: 'SE Foster & 111th',
//             lon: '-122.54916',
//             lat: '45.476196',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 111th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 69,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1787-66',
//             name: 'SE Foster & 122nd',
//             lon: '-122.537626',
//             lat: '45.476637',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 111th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 66,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1789-65',
//             name: 'SE Foster & 128th',
//             lon: '-122.532531',
//             lat: '45.476765',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 122nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 65,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1791-63',
//             name: 'SE Foster & 134th',
//             lon: '-122.526491',
//             lat: '45.476942',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 128th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 63,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1819-40',
//             name: 'SE Foster & 82nd',
//             lon: '-122.578287',
//             lat: '45.482474',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 92nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 40,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1832-73',
//             name: 'SE Foster & 97th',
//             lon: '-122.563094',
//             lat: '45.47839',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & Interstate 205 N. On Ramp',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 73,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-1991-16',
//             name: 'SE Gladstone & 28th',
//             lon: '-122.637753',
//             lat: '45.4933',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Holgate Blvd & SE 28th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 16,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2505-56',
//             name: '12400 Block SE Harold',
//             lon: '-122.535492',
//             lat: '45.483306',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 56,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2512-45',
//             name: 'SE Harold & 92nd',
//             lon: '-122.567674',
//             lat: '45.482716',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 45,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2517-47',
//             name: 'SE Harold & 101st',
//             lon: '-122.559301',
//             lat: '45.482496',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 47,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2518-48',
//             name: 'SE Harold & 103rd',
//             lon: '-122.557297',
//             lat: '45.482521',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 48,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2520-49',
//             name: 'SE Harold & 105th',
//             lon: '-122.554147',
//             lat: '45.482621',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 49,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2522-50',
//             name: 'SE Harold & 109th',
//             lon: '-122.551229',
//             lat: '45.482722',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 50,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2524-51',
//             name: 'SE Harold & 111th',
//             lon: '-122.549334',
//             lat: '45.48277',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 51,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2526-52',
//             name: 'SE Harold & 113th',
//             lon: '-122.547234',
//             lat: '45.482848',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 52,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2528-53',
//             name: 'SE Harold & 115th',
//             lon: '-122.545062',
//             lat: '45.482913',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 53,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2530-54',
//             name: 'SE Harold & 118th',
//             lon: '-122.542473',
//             lat: '45.483013',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 54,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2534-55',
//             name: 'SE Harold & 122nd',
//             lon: '-122.538171',
//             lat: '45.483148',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 55,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2537-57',
//             name: 'SE Harold & 128th',
//             lon: '-122.532354',
//             lat: '45.483282',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 57,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2539-60',
//             name: 'SE Harold & 136th',
//             lon: '-122.52385',
//             lat: '45.483387',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 60,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2542-30',
//             name: 'SE Harold & 54th',
//             lon: '-122.607447',
//             lat: '45.48298',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 30,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2544-31',
//             name: 'SE Harold & 57th',
//             lon: '-122.604846',
//             lat: '45.482978',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 31,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2546-32',
//             name: 'SE Harold & 60th',
//             lon: '-122.602261',
//             lat: '45.483003',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 32,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2548-33',
//             name: 'SE Harold & 62nd',
//             lon: '-122.599689',
//             lat: '45.48298',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 33,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2550-34',
//             name: 'SE Harold & 65th',
//             lon: '-122.597134',
//             lat: '45.482983',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 34,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2551-35',
//             name: 'SE Harold & 67th',
//             lon: '-122.594535',
//             lat: '45.483004',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 35,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2552-36',
//             name: 'SE Harold & 69th',
//             lon: '-122.591988',
//             lat: '45.482988',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 36,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2555-37',
//             name: 'SE Harold & 72nd',
//             lon: '-122.588843',
//             lat: '45.482993',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 82nd Ave & SE Foster Rd',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 37,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2557-38',
//             name: 'SE Harold & 74th',
//             lon: '-122.586809',
//             lat: '45.483',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 82nd Ave & SE Foster Rd',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 38,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2561-39',
//             name: 'SE Harold & 79th',
//             lon: '-122.58255',
//             lat: '45.483009',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 82nd Ave & SE Foster Rd',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 39,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2564-46',
//             name: 'SE Harold & 97th',
//             lon: '-122.563562',
//             lat: '45.482605',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 46,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2594-5',
//             name: 'SE Hawthorne & 6th',
//             lon: '-122.659894',
//             lat: '45.512168',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Hawthorne Blvd & SE 7th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 5,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-2641-4',
//             name: 'Hawthorne Bridge',
//             lon: '-122.665112',
//             lat: '45.512153',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Hawthorne Blvd & SE Grand Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 4,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-3635-3',
//             name: 'SW Madison & 1st',
//             lon: '-122.675839',
//             lat: '45.514455',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SW 1st Ave & SW Madison St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 3,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-3639-2',
//             name: 'SW Madison & 4th',
//             lon: '-122.678573',
//             lat: '45.515185',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SW Madison St & SW 4th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 2,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5515-20',
//             name: 'SE Steele & 30th',
//             lon: '-122.634699',
//             lat: '45.484638',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 20,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5519-21',
//             name: 'SE Steele & 33rd',
//             lon: '-122.630548',
//             lat: '45.484643',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 21,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5520-22',
//             name: 'SE Steele & 35th',
//             lon: '-122.628413',
//             lat: '45.484658',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 22,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5522-23',
//             name: 'SE Steele & 36th Pl',
//             lon: '-122.625602',
//             lat: '45.484674',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 23,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5524-24',
//             name: 'SE Steele & Cesar Chavez Blvd',
//             lon: '-122.622802',
//             lat: '45.484648',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 24,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5526-25',
//             name: 'SE Steele & 41st',
//             lon: '-122.620745',
//             lat: '45.484647',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 52nd Ave & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 25,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5529-26',
//             name: 'SE Steele & 44th',
//             lon: '-122.617628',
//             lat: '45.484626',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 52nd Ave & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 26,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5531-27',
//             name: 'SE Steele & 47th',
//             lon: '-122.613934',
//             lat: '45.484611',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 52nd Ave & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 27,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-5532-28',
//             name: 'SE Steele & 50th',
//             lon: '-122.61094',
//             lat: '45.484633',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 52nd Ave & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 28,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-6730-61',
//             name: 'SE 136th & Ramona',
//             lon: '-122.523639',
//             lat: '45.480137',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 61,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7224-15',
//             name: 'SE 26th & Gladstone',
//             lon: '-122.639659',
//             lat: '45.493462',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Holgate Blvd & SE 28th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 15,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7226-12',
//             name: 'SE 26th & Kelly',
//             lon: '-122.639731',
//             lat: '45.499827',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Powell Blvd & SE 26th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 12,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7228-13',
//             name: 'SE 26th & Powell',
//             lon: '-122.639706',
//             lat: '45.498269',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Powell Blvd & SE 26th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 13,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7230-14',
//             name: 'SE 26th & Rhone',
//             lon: '-122.639681',
//             lat: '45.496147',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Holgate Blvd & SE 28th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 14,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7233-11',
//             name: 'SE 26th & Woodward',
//             lon: '-122.639724',
//             lat: '45.502061',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Powell Blvd & SE 26th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 11,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7281-17',
//             name: 'SE 28th & Holgate',
//             lon: '-122.637674',
//             lat: '45.489891',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 17,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7288-18',
//             name: 'SE 28th & Raymond',
//             lon: '-122.637617',
//             lat: '45.487245',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 18,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7293-19',
//             name: 'SE 28th & Steele',
//             lon: '-122.637562',
//             lat: '45.484871',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE César E. Chávez Blvd & SE Steele St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 19,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7716-29',
//             name: 'SE 52nd & Steele',
//             lon: '-122.60883',
//             lat: '45.484431',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 29,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7855-6',
//             name: 'SE 7th & Clay',
//             lon: '-122.65883',
//             lat: '45.51161',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE 7th Ave & SE Clay St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 6,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-0-7859-7',
//             name: 'SE 7th & Harrison',
//             lon: '-122.658847',
//             lat: '45.508465',
//             route_id: '10',
//             direction: 'To Foster & 94th',
//             direction_id: 0,
//             intersection: 'SE Division St & SE 7th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 7,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-11956-72',
//             name: 'SW Main & 2nd',
//             lon: '-122.675621',
//             lat: '45.515274',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SW Main St & SW 2nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 72,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-13122-27',
//             name: 'SE Harold & 104th',
//             lon: '-122.555497',
//             lat: '45.482695',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 92nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 27,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-13169-73',
//             name: 'SW Main & 6th',
//             lon: '-122.679839',
//             lat: '45.516403',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SW 6th Ave & SW Main St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 73,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-13236-1',
//             name: 'SE Foster & 94th',
//             lon: '-122.567497',
//             lat: '45.479586',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 92nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 1,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-13757-31',
//             name: 'SE Ellis & 89th',
//             lon: '-122.570758',
//             lat: '45.482306',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 82nd Ave & SE Foster Rd',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 31,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-14197-64',
//             name: 'SE 26th & Division',
//             lon: '-122.63958',
//             lat: '45.504646',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Division St @ SE 26th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 64,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-14243-65',
//             name: 'SE Division & 20th Ave',
//             lon: '-122.645979',
//             lat: '45.504888',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Division St @ SE 17th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 65,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-14244-66',
//             name: 'SE Division & 11th Ave',
//             lon: '-122.655159',
//             lat: '45.504921',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Division St @ SE 8th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 66,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1496-67',
//             name: 'SE Division & 8th',
//             lon: '-122.658157',
//             lat: '45.506113',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Division St & SE 7th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 67,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1596-33',
//             name: 'SE Ellis & Foster',
//             lon: '-122.57717',
//             lat: '45.482292',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 82nd Ave & SE Foster Rd',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 33,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1598-32',
//             name: 'SE Ellis & 86th Ave',
//             lon: '-122.574795',
//             lat: '45.482281',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 82nd Ave & SE Foster Rd',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 32,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1766-9',
//             name: 'SE Foster & 120th',
//             lon: '-122.539958',
//             lat: '45.4764',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 122nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 9,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1770-12',
//             name: 'SE Foster & 131st',
//             lon: '-122.5286',
//             lat: '45.476693',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 134th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 12,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1772-5',
//             name: 'SE Foster & 103rd',
//             lon: '-122.556592',
//             lat: '45.476535',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 111th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 5,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1773-8',
//             name: '11600 Block SE Foster',
//             lon: '-122.543608',
//             lat: '45.476335',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 122nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 8,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1779-4',
//             name: 'SE Foster & 101st',
//             lon: '-122.560025',
//             lat: '45.477185',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 101st Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 4,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1782-6',
//             name: 'SE Foster & 107th',
//             lon: '-122.552834',
//             lat: '45.475843',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 111th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 6,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1784-7',
//             name: 'SE Foster & 110th Dr',
//             lon: '-122.548722',
//             lat: '45.476005',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 122nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 7,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1786-10',
//             name: '12300 Block SE Foster',
//             lon: '-122.535655',
//             lat: '45.476471',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 128th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 10,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1788-11',
//             name: 'SE Foster & 128th',
//             lon: '-122.531808',
//             lat: '45.476596',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 134th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 11,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1790-13',
//             name: 'SE Foster & 134th',
//             lon: '-122.525756',
//             lat: '45.476759',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Foster Rd & SE 136th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 13,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1818-34',
//             name: 'SE Foster & 82nd',
//             lon: '-122.579641',
//             lat: '45.483182',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Harold St & SE 72nd Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 34,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-1990-59',
//             name: 'SE Gladstone & 26th',
//             lon: '-122.639385',
//             lat: '45.493413',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE Powell Blvd & SE 26th Ave',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 59,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-2506-20',
//             name: '12400 Block SE Harold',
//             lon: '-122.535487',
//             lat: '45.483404',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 20,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-2507-18',
//             name: '12900 Block SE Harold',
//             lon: '-122.529976',
//             lat: '45.483529',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 18,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-2510-17',
//             name: 'SE Harold & 133rd',
//             lon: '-122.526072',
//             lat: '45.483502',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 122nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 17,
//             agencies_id: 'trimet'
//         },
//         {
//             id: 'tri_route_10-1-2523-26',
//             name: 'SE Harold & 109th',
//             lon: '-122.551026',
//             lat: '45.482845',
//             route_id: '10',
//             direction: 'To Portland City Center',
//             direction_id: 1,
//             intersection: 'SE 92nd Ave & SE Harold St',
//             route_short_name: '10',
//             route_long_name: 'Harold St',
//             stop_sequence: 26,
//             agencies_id: 'trimet'
//         }
//     ]
// };

export const getAgencyRoutes = async () => {
    const url = `/routes?agencies_id=${getUserAgencyId()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getTrafficLightsOperationDataAndOnTimePerformanceRequest = async (
    params = {},
) => {
    const period = params.period || PERIOD_OPTIONS[0].value;
    const periodSecond = params.secondPeriod || SECOND_PERIOD_OPTIONS[0].value;

    const url = `/info/routes/traffic-lights-operation-data?agencies_id=${getUserAgencyId()}&period=${period}&periodSecond=${periodSecond}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getTSPActivityRequest = async (params = {}) => {
    const period = params.period || PERIOD_OPTIONS[0].value;
    const route = params.route;
    const url = `/info/routes/chart/tsp-activity?agencies_id=${getUserAgencyId()}&routes_id=${route}&period=${period}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err.response.data;
    }
};

export const getRoutesStopsRequest = async (params = {}) => {
    const currentAgency = getUserAgencyId();
    let url = '';
    let position = '';
    if (params.offset) position += `&offset=${params.offset}`;
    if (params.limit) position += `&limit=${params.limit}`;
    if (params.route) {
        url = `/stops/routes/${params.route}?agencies_id=${currentAgency}${position}`;
    } else {
        url = `/stops?agencies_id=${currentAgency}${position}`;
    }

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        console.error(err);
        throw err.response.data;
    }
};

export const getRoutePerformanceRequest = async (params = {}) => {
    const period = params.period || PERIOD_OPTIONS[0].value;
    const periodSecond = params.secondPeriod || SECOND_PERIOD_OPTIONS[0].value;

    const url = `/info/routes/route-performance?agencies_id=${getUserAgencyId()}&period=${period}&periodSecond=${periodSecond}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err.response.data;
    }
};

export const getRouteTSPRequestsRequest = async (params = {}) => {
    const route = params.route;
    const url = `/info/routes/tsp-requests?routes_id=${route}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err.response.data;
    }
};

export const getRouteDetailsRequest = async (params = {}) => {
    const period = params.period || PERIOD_OPTIONS[0].value;
    const route = params.route;
    const url = `/info/routes/reports/routes-detail?agencies_id=${getUserAgencyId()}&routes_id=${route}&period=${period}`;
    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        console.error(err);
        throw err.response.data;
    }
};

export const getHeadwayRequest = async (params = {}) => {
    const period = params.period || PERIOD_OPTIONS[0].value;
    const periodSecond = params.secondPeriod || SECOND_PERIOD_OPTIONS[0].value;
    const route = params.route;

    const url = `/info/routes/reports/charts/headway?routes_id=${route}&agencies_id=${getUserAgencyId()}&period=${period}&periodSecond=${periodSecond}`;
    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getRoutePerformanceByStopRequest = async (params = {}) => {
    const period = params.period || PERIOD_OPTIONS[0].value;
    const route = params.route;
    const periodSecond = params.secondPeriod || SECOND_PERIOD_OPTIONS[0].value;

    const directionParam = params.direction ? `&direction=${params.direction}` : '';

    const queryParams = `routes_id=${route}&agencies_id=${getUserAgencyId()}&period=${period}&periodSecond=${periodSecond}${directionParam}`;

    const url = `/info/routes/reports/charts/route-performance-by-stop?${queryParams}`;

    try {
        const { data } = await get(url);

        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getTravelTimeRequest = async (params = {}) => {
    const period = params.period || PERIOD_OPTIONS[0].value;
    const route = params.route;

    const url = `/info/routes/reports/charts/travel-time?routes_id=${route}&agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getRedLightWaitTimeRequest = async (params = {}) => {
    const period = params.period || PERIOD_LIST[0].value;
    const route = params.route;

    const url = `/info/routes/reports/charts/red-light-wait-time?routes_id=${route}&agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getBusStopDwellTimeRequest = async (params = {}) => {
    const period = params.period || PERIOD_LIST[0].value;
    const route = params.route;

    const url = `/info/routes/reports/charts/bus-stop-dwell-time?routes_id=${route}&agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getAverageVehicleSpeedRequest = async (params = {}) => {
    const period = params.period || PERIOD_LIST[0].value;
    const route = params.route;

    const url = `/info/routes/reports/charts/average-vehicle-speed?routes_id=${route}&agencies_id=${getUserAgencyId()}&period=${[
        period,
    ]}`;

    try {
        const { data } = await get(url);
        return data;
    } catch(err) {
        throw err.response.data;
    }
};

export const getSegmentTravelTimeRequest = async (params = {}) => {
    const period = params.period || PERIOD_LIST[0].value;
    const route = params.route;

    const url = `/info/routes/reports/charts/segment-travel-time?routes_id=${route}&agencies_id=${getUserAgencyId()}&period=${period}`;

    try {
        const { data } = await get(url);

        /////////// remove duplicates ///////////
        const result = {};
        result.travelTime = data.result.travelTime.reduce((noDups, dataItem) => {
            const itemAlreadyIn = noDups.find(item =>
                item.bus_stop_name === dataItem.bus_stop_name
                && item.direction === dataItem.direction
                && item.route_key === dataItem.route_key
            );
            if (!itemAlreadyIn) {
                noDups.push(dataItem);
            }
            return noDups;
        }, []);
        result.scheduledTime = data.result.scheduledTime.reduce((noDups, dataItem) => {
            const itemAlreadyIn = noDups.find(item =>
                item.bus_stop_name === dataItem.bus_stop_name
                && item.direction === dataItem.direction
                && item.route_key === dataItem.route_key
            );
            if (!itemAlreadyIn) {
                noDups.push(dataItem);
            }
            return noDups;
        }, []);
        /////////////////////////////////////////
        const newResult = { ...data, result, count: result.travelTime.length};
        return newResult;
    } catch(err) {
        console.error(err);
        throw err.response.data;
    }
};
