/* eslint-disable keyword-spacing */
/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from 'preact/hooks';
import { useDispatch, useSelector } from 'react-redux';

import IconSVG from '../../../ui-core/svg-icon';
import { setPopoverContent, showPopoverContent, closePopover, setNamedList } from '../../sidebar.actions';
import { sidebarPopoverSelector } from '../../sidebar.selectors';

import styles from './styles.scss';

const Select = ({
    list = [],
    selectedItemId = '',
    handleChange = () => {
    },
    icon,
    className,
    description = '',
    useDropdownOverlay = false,
}) => {
    const dispatch = useDispatch();
    const [selectStatus, changeSelectStatus] = useState(false);
    const wrapperRef = useRef(null);
    const { content, namedLists, isShowing } = useSelector(sidebarPopoverSelector);

    useEffect(() => {
        if (useDropdownOverlay) {
            dispatch(setNamedList({name: className, content: dropdownList}));
        }
    }, []);

    useEffect(() => {
        if (useDropdownOverlay) {
            dispatch(setNamedList({name: className, content: dropdownList}));
        }
    }, [selectedItemId]);

    useEffect(() => {
        if (selectStatus) {
            dispatch(showPopoverContent());
            if (!useDropdownOverlay) {
                document.addEventListener('mousedown', handleOutsideClick);
            }
        } else if (!useDropdownOverlay) {
            document.removeEventListener('mousedown', handleOutsideClick);
        } else {
            dispatch(closePopover());
        }
    }, [selectStatus]);

    useEffect(() => {
        if (useDropdownOverlay && !isShowing) {
            changeSelectStatus(false);
        }
    }, [isShowing]);

    const handleOutsideClick = (event) => {
        event.stopPropagation();
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            changeSelectStatus(false);
        }
    };

    const handleChangeLocation = (id) => {
        changeSelectStatus(false);
        handleChange(id);
    };

    const setSelectedItemName = () => {
        const selectedItem = list.find(({ id }) => id === selectedItemId);
        const defaultItem = list[0];

        const result = selectedItem ? selectedItem : defaultItem;

        if (result) return result.name;
        else return '';
    };

    const dropdownList
    = <ul className={styles['sidebar-select__list']}
        key={`type-${className.split('-')[1]}`}
    >
        {list.map(({ name, id }) =>
            <li
                className={styles['sidebar-select__item']}
                onClick={() => handleChangeLocation(id)}
                key={id}
                id={`${className}___${id}`}
            >
                <p
                    className={
                        styles['sidebar-select__item-city']
                    }
                >
                    {name}
                </p>
            </li>
        )}
    </ul>;

    return (
        <>
            {list.length > 0
                && <button
                    ref={wrapperRef}
                    className={`${styles['sidebar-select']} ${
                        selectStatus ? styles['sidebar-select--active'] : ''
                    } `}
                >
                    <div
                        className={styles['sidebar-select__selected']}
                        onClick={() => {
                            dispatch(setPopoverContent(namedLists[className]));
                            changeSelectStatus(!selectStatus);
                        }
                        }
                    >
                        <div className={`${styles['sidebar-select__icon']} ${className}`}>
                            {icon}
                        </div>
                        <div
                            className={
                                `${styles['sidebar-select__selected-wrapper']} sidebar-select__selected-wrapper--g`
                            }
                        >
                            <div
                                className={
                                    styles['sidebar-select__selected-inner']
                                }
                            >
                                <p className={styles['sidebar-select__city']}>
                                    {setSelectedItemName()}
                                </p>
                                <IconSVG type="arrow-down-select" />
                            </div>
                            <p className={styles['sidebar-select__location']}>
                                {description}
                            </p>
                        </div>
                    </div>
                    {selectStatus && !useDropdownOverlay && dropdownList}
                </button>
            }
        </>
    );
};

export default Select;
