/* eslint-disable space-before-function-paren */
/* eslint-disable camelcase */
import { destroy, get, put } from '../../../api';
import { getUserAgencyId } from '../../request.utils';
// import { mockUsers } from './mock-data';

export const getAllUsers = async () => {
    const url = '/users';

    try {
        return await get(url);
    } catch(err) {
        console.error(err);
        throw err.message;
    }
};

export const getAdminAgenciesList = async () => {
    const url = '/users/agencies/cities?offset=0&limit=1000&account_approved=true';

    try {
        return await get(url);
    } catch(err) {
        console.error(err);
        throw err.message;
    }
};

export const getAgencyUsersList = async () => {
    console.log('getUserAgencyId', getUserAgencyId());
    const url = `/users/agencies/cities?offset=0&limit=1000&agencies_id=${getUserAgencyId()}&account_approved=true`;

    try {
        return await get(url);
    } catch(err) {
        console.error(err);
        throw err.message;
    }
};

export const updateUser = async user => {
    const url = '/users/user';

    const body = {
        ...user
    };

    try {
        const response = await put(url, body);

        return response;
    } catch(err) {
        console.error(err);
        throw err;
    }
};

export const removeUserByID = async (userID) => {
    const url = `/users/${userID}`;
    try {
        return await destroy(url);
    } catch(err) {
        throw err.response.data;
    }
};

export const setUserRoleOrApprovedCities = async (params = {}) => {
    const url = '/users/city-role';

    const body = {
        ...params,
        agencies_id: getUserAgencyId(),
    };
    try {
        return await put(url, body);
    } catch(err) {
        throw err.response.data;
    }
};

export const setUserApprovedAgencies = async (params = {}) => {
    const url = '/users/agency-role';

    try {
        return await put(url, params);
    } catch(err) {
        throw err.response.data;
    }
};

export const removeUserApprovedCityOrAgency = async (params = {}) => {
    const url = `/users/${params.userID}/agencies/${params.agencyID}/cities/${
        params.cityID || 'all'
    }`;
    try {
        return await destroy(url);
    } catch(err) {
        throw err.response.data;
    }
};
