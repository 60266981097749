export const pageTitlesConfig = {
    dashboard: 'Dashboard',
    alerts: 'Alerts',
    routes: 'Routes',
    emergency: 'Emergency',
    intersections: 'Intersections',
    settings: 'Settings',
    help: 'Help',
    'business-rules': 'Business Rules',
    'users-and-permissions': 'Users & Permissions',
};
