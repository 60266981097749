import ContentLoader from "react-content-loader";
import PropTypes from "prop-types";

const SkeletonRolesSettings = ({ colors }) => {
    // const random = Math.random() * (1 - 0.7) + 0.7;
    return (
        <ContentLoader
            height={40}
            width={400}
            speed={2}
            backgroundColor={colors.background}
            foregroundColor={colors.foreground}
        >
            <rect x="0" y="15" rx="4" ry="4" width={250} height="21" />
            <rect x="300" y="15" rx="4" ry="4" width="21" height="21" />
        </ContentLoader>
    );
};

SkeletonRolesSettings.propTypes = {
    colors: PropTypes.shape({
        background: PropTypes.string.isRequired,
        foreground: PropTypes.string.isRequired,
    }),
};

export default SkeletonRolesSettings;
