export const LYT_USER_TYPES = {
    LYT_ADMIN: {
        value: 'LYT_Admin',
        label: 'LYT Admin',
    },
    LYT_EMPLOYEE: {
        value: 'LYT_Employee',
        label: 'LYT Employee',
    },
};

export const AGENCY_USER_TYPES = {
    AGENCY_OWNER: {
        value: 'Agency_Owner',
        label: 'Agency Owner',
    },
    AGENCY_ADMIN: {
        value: 'Agency_Admin',
        label: 'Agency Admin',
    },
    AGENCY_VIEWER: {
        value: 'Agency_Viewer',
        label: 'Agency Viewer',
    },
};

const USER_TYPES = {
    ...LYT_USER_TYPES,
    ...AGENCY_USER_TYPES,
};

export default USER_TYPES;
