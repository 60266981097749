/* eslint-disable dot-notation */
import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';
import { POSITIONS_LIST } from './tooltip-config';


import styles from './styles.scss';


const Tooltip = ({ isShow = false, position = 'right', message = '', icon = null, children }) => {
    const [displayTooltip, setDisplayStatusTooltip] = useState(false);


    const isShowComponent = () => isShow || displayTooltip;
    return (
        <span className={styles.tooltip} onMouseLeave={() => setDisplayStatusTooltip(false)}>
            {isShowComponent()
                && <div className={`${styles['tooltip__bubble']} ${styles[`tooltip--${position}`]}`}>
                    <div className={`${styles['tooltip__content']}`}>
                        <div className={`${styles['tooltip__icon']} tooltip__icon--g`}>{icon}</div>
                        <p className={styles['tooltip__message']}>{message}</p>
                    </div>
                </div>
            }
            <span className="tooltip__trigger"
                onMouseOver={() => setDisplayStatusTooltip(true)}>
                {children}
            </span>
        </span>
    );
};


Tooltip.propTypes = {
    isShow: PropTypes.bool,
    position: PropTypes.oneOf(Object.values(POSITIONS_LIST)),
    message: PropTypes.string,
    icon: PropTypes.object,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default Tooltip;
