import { destroy, get, post, put } from "../../../api";

import { getUserAgencyId } from "../../request.utils";

export const getGroupsListRequest = async (params = {}) => {
    const url = `/users-groups/extends?offset=0&limit=1000&agencies_id=${getUserAgencyId()}`;

    try {
        const { data } = await get(url);

        return data;
    } catch (err) {
        throw err.response.data;
    }
};

export const getUsersListRequest = async (params = {}) => {
    const url = `/users?agencies_id=${getUserAgencyId()}`;

    try {
        const { data } = await get(url);
        return data;
    } catch (err) {
        throw err.response.data;
    }
};

export const sendNewGroupDataRequest = async (reqData = {}, params = {}) => {
    const url = "/users-groups";

    const body = {
        ...reqData,
        agencies_id: getUserAgencyId(),
    };

    try {
        const { data } = await post(url, body);
        return data;
    } catch (err) {
        throw err.response.data;
    }
};

export const sendNewMembersDataRequest = async (reqData = {}, params = {}) => {
    const { id } = params;

    const url = `/users-groups/${id}`;

    const body = {
        ...reqData,
        agencies_id: getUserAgencyId(),
    };

    try {
        const { data } = await put(url, body);
        return data;
    } catch (err) {
        throw err.response.data;
    }
};

export const sendNewGroupNameRequest = async (reqData = {}, params = {}) => {
    const { id } = params;

    const url = `/users-groups/${id}`;

    const body = {
        ...reqData,
        agencies_id: getUserAgencyId(),
    };

    try {
        const { data } = await put(url, body);

        return data;
    } catch (err) {
        throw err.response.data;
    }
};

export const deleteGroupRequest = async (reqData = {}, params = {}) => {
    const { id = "" } = params;
    const url = `users-groups/${id}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        const { data: errorData } = err.response;
        throw errorData;
    }
};

export const deleteGroupMemberRequest = async (reqData = {}, params = {}) => {
    const { group_id = "", user_id = "" } = params;
    const url = `users-groups/${group_id}/user/${user_id}`;

    try {
        const { data } = await destroy(url);
        return data;
    } catch (err) {
        throw err.response.data;
    }
};
