export const types = {
    // active
    GET_ALL_ACTIVE_ALERTS_REQUEST:
        'alerts/active/GET_ALL_ACTIVE_ALERTS_REQUEST',
    GET_ALL_ACTIVE_ALERTS_SUCCESS:
        'alerts/active/GET_ALL_ACTIVE_ALERTS_SUCCESS',
    GET_ALL_ACTIVE_ALERTS_ERROR:
        'alerts/active/GET_ALL_ACTIVE_ALERTS_ERROR',

    SET_SELECTED_ALERT_ACTIVE_ID:
        'alerts/active/SET_SELECTED_ALERT_CRITICAL_ACTIVE_ID',
    SET_SELECTED_ALERT_ACTIVE_ALL:
        'alerts/active/SET_SELECTED_ALERT_CRITICAL_ACTIVE_ALL',

    SORT_ALL_ACTIVE_ALERTS:
        'alerts/active/SORT_ALL_ACTIVE_ALERTS',
    //my alerts

    //shared to me
    SET_SELECTED_ALERT_MY_ALERTS_STM_ID:
        'alerts/my-alerts/SET_SELECTED_ALERT_MY_ALERTS_STM_ID',
    SET_SELECTED_ALERT_MY_ALERTS_STM_ALL:
        'alerts/my-alerts/SET_SELECTED_ALERT_MY_ALERTS_STM_ALL',

    //shared by me
    SET_SELECTED_ALERT_MY_ALERTS_SBM_ID:
        'alerts/my-alerts/SET_SELECTED_ALERT_MY_ALERTS_SBM_ID',
    SET_SELECTED_ALERT_MY_ALERTS_SBM_ALL:
        'alerts/my-alerts/SET_SELECTED_ALERT_MY_ALERTS_SBM_ALL',

    // muted
    SET_SELECTED_ALERT_MY_ALERTS_MUTED_ID:
        'alerts/my-alerts/SET_SELECTED_ALERT_MY_ALERTS_MUTED_ID',
    SET_SELECTED_ALERT_MY_ALERTS_MUTED_ALL:
        'alerts/my-alerts/SET_SELECTED_ALERT_MY_ALERTS_MUTED_ALL',
};
