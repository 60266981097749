import ContentLoader from "react-content-loader";

const SkeletonTrafficLights = ({ colors, ...props }) => (
    <ContentLoader
        speed={2}
        width={"100%"}
        height={81}
        backgroundColor={colors.background}
        foregroundColor={colors.foreground}
    >
        <circle x="0" cx="30" cy="27" r="27" />
        <rect x="70" y="20" rx="10" ry="10" width="120" height="23" />
        <rect x="0" y="67" rx="6" ry="6" width="140" height="13" />
    </ContentLoader>
);

export default SkeletonTrafficLights;
