/* eslint-disable keyword-spacing */
/* eslint-disable dot-notation */
export const selectUniqueUsers = (usersList = []) => {
    const duplicateRecipientList = usersList.reduce(
        (acc, elList) => [...acc, ...elList],
        []
    );

    const uniqueRecipientList = new Map(
        duplicateRecipientList.map((item) => [item['alert_id'], item])
    ).values();

    return Array.from(uniqueRecipientList);
};

export const updateRecipientList = (selectedList, itemsList) => {
    //selected list ['id','id']
    //usersList [{},{},{},{}]

    const duplicateRecipientList = itemsList.reduce((acc, el) => {
        if (selectedList.includes(el.id)) return [...acc, ...el.recipients];
        else return acc;
    }, []);

    const uniqueRecipientList = new Map(
        duplicateRecipientList.map((item) => [item['alert_id'], item])
    ).values();

    return Array.from(uniqueRecipientList);
};

export const uniqueRecipients = (recipientsList) => {
    const uniqueRecipientList = new Map(
        recipientsList.map((item) => [item['alert_id'], item])
    ).values();

    return Array.from(uniqueRecipientList);
};
