import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import TypeSelection from "../type-selection";
import DateRangeInput from "./components/date-range.input";
import Tooltip from "../../Tooltip";
import SvgIcon from "../../svg-icon";

import { setActiveType, getIntervalDateValue } from "./data-range.utils";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles.scss";

const DateRange = ({
    onChange = () => {},
    label = "",
    startDate = "",
    endDate = "",
    timesList = [],
    error = "",
}) => {
    const errorStyles = error ? styles["date-range--error"] : "";

    const handleChange = (dates) => {
        return onChange({
            start_date: dates[0] || "",
            end_date: dates[1] || "",
        });
    };

    const handleSetInterval = (interval) => {
        const intervalValues = getIntervalDateValue(interval);

        return onChange(intervalValues);
    };

    return (
        <div>
            <label className={styles["date-range__label"]}>{label}</label>
            <div className={`${styles["date-range"]} ${errorStyles}`}>
                <div className={styles["date-range__input"]}>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleChange}
                        dateFormat="MM.dd.yyyy"
                        customInput={<DateRangeInput />}
                    />
                </div>

                <div className={styles["date-range__error"]}>
                    <Tooltip
                        isShow={error ? true : false}
                        message={error}
                        icon={<SvgIcon type={"warning"} />}
                    />
                </div>
            </div>

            <div>
                <TypeSelection
                    active={setActiveType(startDate, endDate, timesList)}
                    options={timesList}
                    onChange={handleSetInterval}
                />
            </div>
        </div>
    );
};

DateRange.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object,
    label: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    timesList: PropTypes.array,
    error: PropTypes.string,
};

export default DateRange;
