import * as actions from "./groups.actions";
import * as requests from "../../../services/requests/users-and-permissions/groups/groups";
import { groupsSelector } from "./groups.selectors";
import { toast } from "react-toastify";


export const getGroupsListOperation = () => (dispatch) => {
    dispatch(actions.getGroupsListRequest());
    return requests
        .getGroupsListRequest()
        .then((data = {}) => {
            dispatch(actions.getGroupsListSuccess(data));
        })
        .catch((error) => {
            dispatch(actions.getGroupsListError(error));
        });
};

export const getUsersListOperation = () => (dispatch) => {
    dispatch(actions.getUsersListRequest());
    return requests
        .getUsersListRequest()
        .then(({ data = {} }) => {
            dispatch(actions.getUsersListSuccess(data));
        })
        .catch((error) => {
            // dispatch(actions.getUsersListError(error));
            toast.error(error.message);
            throw error.message;
        });
};

export const createNewGroupOperation = (data) => (dispatch, getState) => {
    const { list: groupsList } = groupsSelector(getState());

    dispatch(actions.addNewGroupRequest());

    return requests
        .sendNewGroupDataRequest(data)
        .then((data = {}) => {
            const updatedGroups = [data, ...groupsList];
            dispatch(actions.addNewGroupSuccess(updatedGroups));
            toast.success("The new group has been successfully created!");
        })
        .catch((error) => {
            // dispatch(actions.addNewGroupError(error));
            toast.error(error.message);
            throw error.message;
        });
};

export const addNewMembersOperation =
    (data, params) => (dispatch, getState) => {
        const { list: groupsList } = groupsSelector(getState());

        dispatch(actions.addNewMembersRequest());

        return requests
            .sendNewMembersDataRequest(data, params)
            .then((data = {}) => {
                const updatedGroups = groupsList.reduce((acc, current) => {
                    if (current.id === data.id) return [...acc, data];
                    return [...acc, current];
                }, []);

                dispatch(actions.addNewMembersSuccess(updatedGroups));
                toast.success("The new members have been successfully added!");
            })
            .catch((error) => {
                // dispatch(actions.addNewMembersError(error));
                toast.error(error.message);
                throw error.message;
            });
    };

export const deleteGroupOperation = (params) => (dispatch, getState) => {
    const { list: groupsList } = groupsSelector(getState());
    const { id: idGroup } = params;

    dispatch(actions.deleteGroupRequest());

    return requests
        .deleteGroupRequest({}, params)
        .then(() => {
            const updatedGroups = groupsList.filter(({ id }) => id !== idGroup);
            dispatch(actions.deleteGroupSuccess(updatedGroups));
            toast.success("The group has been successfully deleted!");
        })
        .catch((error) => {
            // dispatch(actions.deleteGroupError(error));
            toast.error(error.message);
            throw error.message;
        });
};

export const deleteGroupMemberOperation = (params) => (dispatch, getState) => {
    const { list: groupsList } = groupsSelector(getState());

    dispatch(actions.deleteGroupMemberRequest());
    return requests
        .deleteGroupMemberRequest({}, params)
        .then((data = {}) => {
            const updatedGroups = groupsList.reduce((acc, current) => {
                if (current.id === data.id) return [...acc, data];
                return [...acc, current];
            }, []);

            dispatch(actions.deleteGroupMemberSuccess(updatedGroups));
            toast.success("The group member has been successfully deleted!");
        })
        .catch((error) => {
            // dispatch(actions.deleteGroupMemberError(error));
            toast.error(error.message);
            throw error.message;
        });

};

export const editGroupNameOperation =
    (data, params) => (dispatch, getState) => {
        const { list: groupsList } = groupsSelector(getState());

        dispatch(actions.editGroupNameRequest());
        return requests
            .sendNewGroupNameRequest(data, params)
            .then((data) => {
                const updatedGroups = groupsList.reduce((acc, current) => {
                    if (current.id === data.id) return [...acc, data];
                    return [...acc, current];
                }, []);

                dispatch(actions.editGroupNameSuccess(updatedGroups));
                toast.success("The group name has been successfully changed!");
            })
            .catch((error) => {
                // dispatch(actions.editGroupNameError(error));
                toast.error(error.message);
                throw error.message;
            });
    };
